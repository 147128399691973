import shared from '../../shared';
import QUESTIONS from '../../questions';
import { GENDERSCORE_PILLAR_DEIACTIONS_OPTION } from '../../../../../constants/genderscore-pillars';

const section = {
	...GENDERSCORE_PILLAR_DEIACTIONS_OPTION,
	details: `
### One out of two women is leaving tech due to **discrimination**.

DE&I policies help reduce unconscious bias in recruitment, promotion... and even among employees.`,
	questions: [
		QUESTIONS.DEI_ANTIHARASSMENT,
		QUESTIONS.DEI_ANTIDISCRIMINATION,
		QUESTIONS.DEI_COMPLAINTSPROCEDURE,
		QUESTIONS.DEI_RESPONSIBILITY,
		QUESTIONS.DEI_SUPPORTDIALOGUE,
		QUESTIONS.DEI_UNBIASEDRECRUITMENT,
		QUESTIONS.DEI_LEARNINGCOMMITMENT,
		QUESTIONS.DEI_APPOINTEDINDIVIDUAL,
		QUESTIONS.DEI_MENTALHEALTH,
		QUESTIONS.DEI_RECRUITMENTPRIOS,
		QUESTIONS.DEI_JUNIORHIRES,
		QUESTIONS.DEI_CAREERCHANGEHIRES,
		QUESTIONS.DEI_INTENTIONSNEXTYEAR,
	],
	scoring: [
		{
			question: QUESTIONS.DEI_ANTIHARASSMENT,
			weight: 20,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.DEI_ANTIDISCRIMINATION,
			weight: 30,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.DEI_COMPLAINTSPROCEDURE,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.DEI_RESPONSIBILITY,
			weight: 5,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 3,
		},
		{
			question: QUESTIONS.DEI_SUPPORTDIALOGUE,
			weight: 5,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.DEI_UNBIASEDRECRUITMENT,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 13,
		},
		{
			question: QUESTIONS.DEI_LEARNINGCOMMITMENT,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 14,
		},
		{
			question: QUESTIONS.DEI_APPOINTEDINDIVIDUAL,
			weight: 5,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.DEI_MENTALHEALTH,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 10,
		},
		{
			question: QUESTIONS.DEI_JUNIORHIRES,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.DEI_CAREERCHANGEHIRES,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
	],
};

export default section;
