import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Button, Space } from 'antd';
import { NOT_ANSWERED_LABEL } from '../../../constants/constant';
import { getSurveySectionsQuestionsFromAnswers, isQuestionAnswered } from '../../../components/genderscore/data/shared';
import { getPillarColorMain } from '../../../constants/genderscore-pillars';
import GenderScorePillarLogoLabel from '../../../components/company/genderscore/pillars/GenderScorePillarLogoLabel';
import ReactMarkdown from 'react-markdown';
import { getCompanySelected } from '../../../reducers/app';
import { documentId, sortOn } from '../../../utils/common';
import moment from 'moment';
import CompanyGenderScoreRingScore from '../../../components/company/genderscore/CompanyGenderScoreRingScore';
import GenderScoreLevelLabel from '../../../components/company/genderscore/levels/GenderScoreLevelLabel';
import { getLevelFromScore } from '../../../constants/genderscore-levels';
import surveys from '../../../components/genderscore/data/surveys';
import SurveyQuestionAnswer from '../../../components/genderscore/survey/SurveyQuestionAnswer';

const GenderScoreAnswers = () => {
	const className = 'GenderScoreAnswers';
	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);
	const [surveyList, setSurveyList] = useState([]);
	const [selectedSurvey, setSelectedSurvey] = useState(null);
	const [surveyConf, setSurveyConf] = useState(null);

	useEffect(() => {
		const list = companySelected?.genderScoreSurveys
			?.filter((gs) => ['PREVIOUS', 'CURRENT'].includes(gs.status))
			?.sort(sortOn({ key: 'submittedAt' }, true));
		setSurveyList(list);
		if (!selectedSurvey || !list.map(documentId).includes(documentId(selectedSurvey))) {
			setSelectedSurvey(list?.[0]);
		}
	}, [companySelected]);

	useEffect(() => {
		const surveyOption = surveys?.getSurveyDataOption?.(selectedSurvey?.survey);
		setSurveyConf(surveyOption);
	}, [selectedSurvey]);

	if (!selectedSurvey || !surveyConf) return 'Please wait...';

	let questionIndex = 0;

	return (
		<div
			style={{
				fontSize: 14,
			}}
		>
			<Space
				style={{
					marginBottom: 26,
				}}
			>
				{surveyList?.map((gs) => {
					const value = documentId(gs);
					const isSelected = documentId(gs) === documentId(selectedSurvey);
					const score = gs?.genderScore?.globalScore;
					return (
						<Button
							key={value}
							disabled={isSelected}
							onClick={() => setSelectedSurvey(gs)}
							style={{
								height: 72,
								padding: '8px 14px',
								...isSelected ? { border: `1px solid ${theme.color.fitBlueElectric}` } : {},
							}}
						>
							<Space
								style={{
									justifyContent: 'flex-start',
									alignItems: 'center',
								}}
							>
								<CompanyGenderScoreRingScore
									value={score}
									size={48}
								/>
								<Space
									direction='vertical'
									size={0}
									style={{
										justifyContent: 'center',
										alignItems: 'flex-start',
										fontSize: 12,
									}}
								>
									<GenderScoreLevelLabel
										level={getLevelFromScore(score)}
										withPopover={false}
										withUnderline={false}
										style={{
											fontSize: 10,
											marginBottom: 4,
										}}
									/>
									<div
										style={{
											opacity: 0.7,
											marginBottom: -4,
										}}
									>
										Submitted on
									</div>
									<div>
										<b>{moment(gs.submittedAt).format('MMM YYYY')}</b>
									</div>
								</Space>
							</Space>
						</Button>
					);
				})}
			</Space>

			<Space
				direction='vertical'
				wrap={false}
				align={'top'}
				size={30}
			>
				{getSurveySectionsQuestionsFromAnswers({ surveyConf, answers: selectedSurvey.answers })
					?.map((section) => {
						return (
							<div
								key={section.value}
								style={{
									paddingLeft: 24,
									borderLeft: `6px solid ${getPillarColorMain(section.value)}`,
								}}
							>
								<GenderScorePillarLogoLabel
									pillar={section.value}
									labelSize={24}
									iconSize={32}
									style={{
										label: {
											fontWeight: 'bold',
											marginBottom: -3,
										},
									}}
								/>

								{section?.questions?.map((question) => {
									questionIndex += 1;
									return (
										<div
											key={question.questionId}
											style={{
												width: '100%',
												borderBottom: '1px dotted #ccc',
											}}
										>
											<Space
												direction='horizontal'
												style={{
													justifyContent: 'space-between',
													alignItems: 'flex-start',
													width: '100%',
													padding: '10px 5px',
												}}
											>
												<div>
													<ReactMarkdown>{`**${questionIndex}** • ${question.label}`}</ReactMarkdown>
												</div>

												{!isQuestionAnswered({ question, answers: selectedSurvey.answers })
													? (
														<p
															style={{
																textAlign: 'right',
																minWidth: 160,
															}}
														>
															<span style={{
																opacity: 0.5,
																verticalAlign: 'middle',
															}}>
																{NOT_ANSWERED_LABEL}
															</span>
														</p>
													) : (
														<Space style={{ paddingTop: 8 }}>
															<Space
																direction='vertical'
																size={3}
																style={{
																	justifyContent: 'flex-end',
																	alignItems: 'flex-end',
																}}
															>
																<SurveyQuestionAnswer
																	question={question}
																	answer={selectedSurvey.answers?.[question?.questionId]}
																/>

															</Space>

														</Space>
													)
												}
											</Space>
										</div>
									);
								})}
							</div>
						);
					})}
			</Space>
		</div>
	);
};

export default GenderScoreAnswers;
