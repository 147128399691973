import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQueryParams = () => {
	const [searchParams] = useSearchParams();

	const queryParams = useMemo(() => {
		if (!searchParams) return null;
		return Object.fromEntries(searchParams.entries());
	}, [searchParams]);

	return queryParams;
};
