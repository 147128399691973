import GenderScoreRateBlock from '../../company/genderscore/dashboard/blocks/GenderScoreRateBlock';
import GenderScoreRatioBlock from '../../company/genderscore/dashboard/blocks/GenderScoreRatioBlock';
import GenderScoreScalarBlock from '../../company/genderscore/dashboard/blocks/GenderScoreScalarBlock';
import QUESTIONS from './questions';
import { ANSWER_VALUE_SKIPPED } from './shared';

const dashboardChartFn = {
	valueMapper: (value) => (value === ANSWER_VALUE_SKIPPED ? undefined : value),
	labelMapper: (value) => value || '',
};
export const DASHBOARD_CHARTS = [
	{
		questionId: QUESTIONS.GENDERDATA_OVERALLPCT.questionId,
		Chart: GenderScoreRatioBlock,
		options: {
			title: 'Women %',
		},
		...dashboardChartFn,
	},
	{
		questionId: QUESTIONS.V3_GD_WORKFORCE_WOMEN_RATIO.questionId,
		Chart: GenderScoreRatioBlock,
		options: {
			title: 'Women %',
		},
		...dashboardChartFn,
	},
	{
		questionId: QUESTIONS.GENDERDATA_TECHPCT.questionId,
		Chart: GenderScoreRatioBlock,
		options: {
			title: 'Women % in Tech',
		},
		...dashboardChartFn,
	},
	{
		questionId: QUESTIONS.GENDERDATA_MGTPCT.questionId,
		Chart: GenderScoreRatioBlock,
		options: {
			title: 'Women % in managerial role',
		},
		...dashboardChartFn,
	},
	{
		questionId: QUESTIONS.GENDERDATA_BOARDPCT.questionId,
		Chart: GenderScoreRatioBlock,
		options: {
			title: 'Women % in directory board',
		},
		...dashboardChartFn,
	},
	{
		questionId: QUESTIONS.GENDERDATA_CLEVELPCT.questionId,
		Chart: GenderScoreRatioBlock,
		options: {
			title: 'Women % in C-level',
		},
		...dashboardChartFn,
	},
	{
		questionId: QUESTIONS.GENDERDATA_PROMOTEDPCT.questionId,
		Chart: GenderScoreRateBlock,
		options: {
			title: 'Women promoted',
		},
		...dashboardChartFn,
	},
	{
		questionId: QUESTIONS.GENDERDATA_TURNOVERPCT.questionId,
		Chart: GenderScoreRateBlock,
		options: {
			title: 'Women turnover rate in last 3 years',
			withPrevious: true,
		},
		...dashboardChartFn,
	},
	{
		questionId: QUESTIONS.GENDERDATA_TENUREOPTIONS.questionId,
		Chart: GenderScoreScalarBlock,
		options: {
			title: 'Median tenure',
			suffix: 'years',
			isPct: false,
		},
		valueMapper: (value) => value?.[1],
		labelMapper: (value) => `${value?.toString()?.slice(0, 2)}` || '',
	},
	{
		questionId: QUESTIONS.EQUALPAY_SALARYINCREASEPCT.questionId,
		Chart: GenderScoreScalarBlock,
		options: {
			title: 'Women salary increase',
			suffix: '%',
			isPct: true,
		},
		...dashboardChartFn,
	},
];

export const getQuestionChart = (questionId) => (
	DASHBOARD_CHARTS?.find((d) => d?.questionId === questionId)
);

export const NOT_APPLICABLE = 'N/A';
