import CompanyModel from '../../../../../models/CompanyModel';
import { NOT_APPLICABLE } from '../../../../genderscore/data/dashboard';
import QUESTIONS from '../../../../genderscore/data/questions';
import { ANSWER_VALUE_SKIPPED } from '../../../../genderscore/data/shared';
import GenderScoreRadialBlock from '../blocks/GenderScoreRadialBlock';

const CompanyGenderScoreWomenPctByJobBox = ({
	company,
	title = 'Women % by job',
	width,
}) => {
	const companyModel = new CompanyModel(company);

	if (!companyModel.genderScore) {
		return null;
	}
	const questions = [{
		questionId: QUESTIONS.GENDERDATA_PRODUCTPCT.questionId,
		label: 'Product',
	},
	{
		questionId: QUESTIONS.GENDERDATA_DATAPCT.questionId,
		label: 'Data',
	},
	{
		questionId: QUESTIONS.GENDERDATA_ENGPCT.questionId,
		label: 'Engineer',
	}];
	const answers = companyModel?.getGenderScoreCurrentSurvey()?.answers;
	let values = questions.map((question) => {
		let value = answers?.[question?.questionId];
		if (!value || value === ANSWER_VALUE_SKIPPED) {
			value = NOT_APPLICABLE;
		}
		return {
			value,
			label: question?.label,
		};
	});
	const notAnsweredCount = values.filter((v) => v.value === NOT_APPLICABLE)?.length;
	if (notAnsweredCount === questions.length) {
		values = [];
	}
	return <GenderScoreRadialBlock
		values={values}
		title={title}
		width={width}
	/>;
};
export default CompanyGenderScoreWomenPctByJobBox;
