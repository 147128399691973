/** @jsxImportSource @emotion/react */

import FormItem from '../app/form/FormItem';
import { Input } from 'antd';
import { useTheme } from '@emotion/react';

const { TextArea } = Input;

const FormInput = ({
						  label,
						  extra,
						  name,
						  maxLength,
						  placeholder = '',
						  isTextarea = false,
						  style = {},
						  rules = [],
						  currentValue, // Current value of the field in DB. Optional. Used in metas
						  onChange,
						  isEnVersion,
						  ...otherProps
					  }) => {
	const theme = useTheme();
	const Component = isTextarea ? TextArea : Input;
	return (
		<FormItem
			extra={extra}
			label={label}
			name={name}
			defaultContainer="div"
			rules={rules}
			currentValue={currentValue}
			{...otherProps}
			styles={{
				rootContainer: {
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
				},
				label: { marginTop: 6, color: theme.color.blue },
				container: { width: '100%' },
			}}
		>
			<Component
				placeholder={placeholder}
				onChange={onChange}
				showCount={{
					formatter: ({ count }) => {
						return `${count} / ${maxLength}`;
					},
				}}
				css={{ ...style }}
			/>
		</FormItem>
	);
};

export default FormInput;
