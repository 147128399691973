import { PERMS_GRANT_STAFF } from '../../menu.utils';
import CompanyGenderScoreSurveyDetailsPage from '../../../containers/staff/companies/CompanyGenderScoreSurveyDetailsPage';
import MenuBase from '../../MenuBase';
import { ExperimentOutlined } from '@ant-design/icons';

export const StaffGSSurveyDetailsIcon = ExperimentOutlined;

class StaffGSSurveyDetailsMenu extends MenuBase {
	static props = {
		name: 'staff-genderscore-survey-details',
		path: '/staff/genderscore/survey/details',
		label: 'Survey Details',
		icon: <StaffGSSurveyDetailsIcon />,
		iconLegacy: <span>📊</span>,
		Component: CompanyGenderScoreSurveyDetailsPage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffGSSurveyDetailsMenu.props);
	}
}

export default StaffGSSurveyDetailsMenu;
