import { mergeReducer } from '../utils/common';

export const RESOURCES_JSON = [
	{
		key: '50intech-solution-annual-review',
		title: 'Annual Review Guide',
		url: 'https://drive.google.com/file/d/18zcy11El9nfCbMHbTLpWyzo8j8xMCm8-/view?usp=drive_link',
		pillars: ['genderData'],
		summary: 'This solution provides you with a framework to develop an objective and unbiased annual review process. It gives you access to a Manager\'s Guide to understand unconscious bias, an Employee Guide to support your employees, and surveys to measure improvements and gauge satisfaction.',
		type: 'Solution',
		tags: [],
		authors: ['50inTech'],
		source: '50inTech',
		date: '',
		readingTime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1712069285/resources/50intech-annual-review-card_rfvwbt.jpg',
	},
	{
		key: '50intech-solution-hiring-practices',
		title: 'Cultivate Inclusivity in Hiring: A Comprehensive Guide to DEI-Driven Recruitment',
		url: 'https://drive.google.com/file/d/1Q-sGv8NX4zfuUNlAXrlWsvaWRie9qJRL/view?usp=drive_link',
		pillars: ['genderData'],
		summary: 'This solution provides you with a framework to develop objective and unbiased hiring practices. It gives you access to a worksheet to create attractive and inclusive job description, assistance for Objective Resume Selection, support to structure and conduct unbiased job interviews and surveys to measure progress.',
		type: 'Solution',
		tags: [],
		authors: ['50inTech'],
		source: '50inTech',
		date: '',
		readingTime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1712069284/resources/50intech-hiring-practices-card_mwfrvs.jpg',
	},
	{
		key: 'gender-pay-gap-guide-key-insights-to-act-in-the-eu-and-uk-2023',
		title: 'Gender Pay Gap Guide: Key Insights to Act in the EU & UK 2023',
		url: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1700011224/resources/pdf/50intech-figures-gender-pay-gap-guide_svn6kf.pdf',
		pillars: ['equalPay'],
		summary: 'This eBook focuses on the existing gender pay gap in the European tech industry, emphasizing the need for increased transparency and action to close this gap. It includes analysis, best practices, and case studies to guide tech companies in implementing effective strategies to achieve gender pay equity.',
		type: 'eBook',
		tags: [],
		authors: '',
		source: 'Figures & 50inTech',
		date: '',
		readingtime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1700011211/resources/50intech-figures-gender-pay-gap-guide_f4fqmy.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: 'comment-retenir-les-talents-feminins-dans-la-tech',
		title: 'Comment retenir les talents féminins dans la Tech?',
		url: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1700014494/resources/pdf/50intech-simundia-retenir-talents-tech_gkaktm.pdf',
		pillars: ['fairCareerPath', 'deiActions'],
		summary: 'This eBook focuses on strategies to retain female talent in the tech industry, emphasizing coaching and professional development to help women overcome challenges like the glass ceiling and enhance their career progression.',
		type: 'eBook',
		tags: [],
		authors: '',
		source: '50inTech & Simundia',
		date: '',
		readingtime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1700014506/resources/50intech-simundia-retenir-talents-tech_pdihit.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: 'b2-b-guide-1-how-to-diversify-your-candidate-pipeline',
		title: 'B2B Guide #1: How to diversify your candidate pipeline',
		url: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699824163/resources/pdf/50intech-guide-01-diversify-pipeline.pdf',
		pillars: ['genderData'],
		summary: 'The eBook explores strategies for tech companies to increase female recruitment, discussing barriers to gender parity and offering ten key actions to diversify candidate pipelines and enhance diversity in the workplace.',
		type: 'eBook',
		tags: ['Tech Changer Club'],
		authors: '',
		source: '50inTech',
		date: '',
		readingtime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699827600/resources/50intech-guide-01-diversify-pipeline_frxlxv.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: 'b2-b-guide-2-start-unbiasing-your-hiring-process',
		title: 'B2B Guide #2: Start unbiasing your hiring process',
		url: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699825904/resources/pdf/50intech-guide-02-unbiased-hiring-process.pdf',
		pillars: ['deiActions', 'fairCareerPath'],
		summary: 'The eBook provides strategies to reduce unconscious hiring bias, addressing common biases and emphasizing diversity in recruitment through improved communication, interview techniques, and proactive diversity actions.',
		type: 'eBook',
		tags: ['Tech Changer Club'],
		authors: '',
		source: '50inTech',
		date: '',
		readingtime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699827605/resources/50intech-guide-02-unbiased-hiring-process_llseif.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: 'b2-b-guide-3-how-to-create-equal-career-paths',
		title: 'B2B Guide #3: How to create equal career paths',
		url: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699824077/resources/pdf/50intech-guide-03-equal-career-paths.pdf',
		pillars: ['fairCareerPath'],
		summary: 'This eBook advocates for meaningful workplace equality actions, focusing on fair promotions, transparent salaries, and active support for underrepresented groups in tech through mentorship and skill development.',
		type: 'eBook',
		tags: ['Tech Changer Club'],
		authors: '',
		source: '50inTech',
		date: '',
		readingtime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699827604/resources/50intech-guide-03-equal-career-paths_nnxuyg.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: 'b2-b-guide-4-how-to-create-an-employee-resource-group',
		title: 'B2B Guide #4: How to create an employee resource group',
		url: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699824126/resources/pdf/50intech-guide-04-ergs.pdf',
		pillars: ['deiActions'],
		summary: 'This eBook discusses the creation and impact of Employee Resource Groups (ERGs) in companies, emphasizing their role in boosting employee engagement, promoting diversity, and aiding business growth, including a case study on their effectiveness.',
		type: 'eBook',
		tags: ['Tech Changer Club'],
		authors: '',
		source: '50inTech',
		date: '',
		readingtime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699827606/resources/50intech-guide-04-ergs_wt2hca.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: 'b2-b-guide-5-reducing-bias-in-management-practices-in-tech',
		title: 'B2B Guide #5: Reducing Bias in Management Practices in Tech',
		url: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699824200/resources/pdf/50intech-guide-05-reduce-bias-management.pdf',
		pillars: ['equalPay'],
		summary: 'This eBook addresses management bias in tech, particularly against women, outlining strategies for HR and managers to reduce bias in recruitment, performance reviews, and career advancement, emphasizing continuous improvement and transparency.',
		type: 'eBook',
		tags: ['Tech Changer Club'],
		authors: '',
		source: '50inTech',
		date: '',
		readingtime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699827612/resources/50intech-guide-05-reduce-bias-management_ogaqim.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: 'b2-b-guide-6-how-tech-companies-can-attract-talent-with-inclusive-parental-policies-and-actions',
		title: 'B2B Guide #6: How Tech Companies can attract talent with inclusive Parental Policies and Actions',
		url: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699824256/resources/pdf/50intech-guide-06-parental-policies.pdf',
		pillars: ['genderData', 'deiActions', 'worklifeBalance'],
		summary: 'This eBook offers steps to implement and align caregiving policies, focusing on the needs of parents and caregivers in the workforce.',
		type: 'eBook',
		tags: ['Tech Changer Club'],
		authors: '',
		source: '50inTech',
		date: '',
		readingtime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699827611/resources/50intech-guide-06-parental-policies_kkj9ys.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: 'b2-b-guide-7-how-to-successfully-attract-and-retain-lgbtq-talent',
		title: 'B2B Guide #7: How to successfully attract and retain LGBTQ+ talent',
		url: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699826838/resources/pdf/50intech-guide-07-attract-retain-lgbtq.pdf',
		pillars: ['genderData', 'deiActions'],
		summary: 'This eBook offers guidance on attracting and retaining LGBTQ+ talent in tech, emphasizing intersectionality, inclusive recruitment, employee retention, and using data for long-term commitment to LGBTQ+ inclusion',
		type: 'eBook',
		tags: ['Tech Changer Club'],
		authors: '',
		source: '50inTech',
		date: '',
		readingtime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699827621/resources/50intech-guide-07-attract-retain-lgbtq_pax7hy.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: 'b2-b-guide-8-sourcing-women-in-tech',
		title: 'B2B Guide #8: Sourcing women in tech',
		url: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699826910/resources/pdf/50intech-guide-08-sourcing-wit.pdf',
		pillars: ['genderData'],
		summary: 'This eBook focuses on strategies and expert insights for recruiting women into technology roles and creating an inclusive work environment.',
		type: 'eBook',
		tags: ['Tech Changer Club'],
		authors: '',
		source: '50inTech',
		date: '',
		readingtime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699827622/resources/50intech-guide-08-sourcing-wit_auadnr.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: '7-ways-to-support-employee-career-advancement',
		title: '7 Ways to Support Employee Career Advancement',
		url: 'https://www.roberthalf.com/us/en/insights/management-tips/7-ways-to-support-employees-career-advancement',
		summary: 'The article provides strategies for employee career development, emphasizing the importance of managerial support in employee growth.',
		type: 'Article',
		tags: ['Career Advancement', 'Employee Development', 'Management'],
		authors: 'Robert Half',
		pillars: ['fairCareerPath', 'equalPay', 'worklifeBalance'],
		source: 'Robert Half',
		date: '',
		readingTime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699810098/resources/7-Ways-to-Support-Employee-Career-Advancement-RH-Blog-08-06-20-2_Large-2x_guyqub.webp',
	},
	{
		key: 'unlocking-employee-potential-a-guide-to-effective-career-mapping',
		title: 'Unlocking Employee Potential: A Guide to Effective Career Mapping',
		url: 'https://www.togetherplatform.com/blog/unlocking-employee-potential-a-guide-to-effective-career-mapping',
		summary: 'Career mapping is a strategic tool used by organizations to foster employee engagement and retention by providing a clear path for career development.',
		tags: ['Career Development', 'Employee Engagement', 'Talent Retention', 'HR Strategy'],
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699823382/resources/6419ae15753feb306442f7df_Identify_20high_20potentials_dyrnc9.png',
		type: 'Article',
		pillars: ['fairCareerPath'],
		source: 'Together Platform',
	},
	{
		key: 'why-companies-should-help-every-employee-chart-a-career-path',
		title: 'Why Companies Should Help Every Employee Chart a Career Path',
		url: 'https://sloanreview.mit.edu/article/why-companies-should-help-every-employee-chart-a-career-path/',
		authors: ['George Westerman', 'Abbie Lundberg'],
		date: '2023-03-02',
		readingtime: '19 min',
		summary: 'The article examines the discrepancy between the career development companies claim to offer and what employees experience.',
		tags: ['Workplace Culture', 'Skills Learning'],
		type: 'Article',
		pillars: ['fairCareerPath'],
		source: 'MIT Sloan Management Review',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699823809/resources/2023SPR-Westerman-1290x860-1_r21kpl.jpg',
	},
	{
		key: 'how-long-must-women-wait-for-equal-pay',
		title: 'How long must women wait for equal pay?',
		url: 'https://blog.ukdataservice.ac.uk/women-equal-pay/',
		date: '2021-02-03',
		authors: ['Dr Bożena Wielgoszewska'],
		summary: 'Dr Bożena Wielgoszewska discusses research on the gender wage gap, highlighting that it may take another 40 years for the pay gap to close based on current trends. The article explores historical data and factors contributing to pay differences between genders.',
		tags: ['Gender Wage Gap', 'Equal Pay', 'Workplace Equality'],
		type: 'Article',
		pillars: ['equalPay'],
		source: 'UK Data Service Blog',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699822906/resources/splash_lxulux.png',
	},
	{
		key: 'diversity-equity-and-inclusion-training-tips-and-best-practices-linked-in-learning',
		title: 'Diversity, Equity, and Inclusion Training: Tips and Best Practices - LinkedIn Learning',
		url: 'https://learning.linkedin.com/resources/learning-culture/diversity-equity-inclusion-training-dei-best-practices',
		summary: 'DEI training demands ongoing commitment, embedding it into organizational culture with consistent leadership at all levels. Effective strategies address workplace diversity comprehensively, promoting accountability and impactful learning.',
		type: 'Article',
		tags: ['DEI', 'Training', 'Best Practices'],
		authors: ['Unknown'],
		source: 'LinkedIn Learning',
		date: '2023-01-01',
		readingTime: '10 minutes',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699842231/resources/Diversity-Equity-and-Inclusion-Training-Tips-and-Best-Practices-hero-bg-dsk-v01-2x_xptg38.jpg',
		pillars: [],
	},
	{
		key: 'navigating-the-new-dei-landscape-morgan-lewis',
		title: 'Navigating the New DEI Landscape - Morgan Lewis',
		url: 'https://www.morganlewis.com/topics/navigating-the-new-dei-landscape',
		summary: 'Comprehensive insights on the evolving DEI strategies and policies.',
		type: 'Report',
		tags: ['DEI', 'Strategy', 'Policy'],
		authors: ['Unknown'],
		source: 'Morgan Lewis',
		date: '2023-02-01',
		readingTime: '15 minutes',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699842083/resources/Diversity_4-767x633_cm9hyp.jpg',
		pillars: [],
	},
	{
		key: '10-ways-managers-can-support-employee-career-growth',
		title: '10 Ways Managers Can Support Employee Career Growth',
		url: 'https://www.forbes.com/sites/forbesbusinesscouncil/2021/06/03/10-ways-managers-can-support-employee-career-growth/',
		summary: '10 methods for managers to support employee career development, focusing on understanding goals, funding education, promoting efficiency, skills training, mentoring, offering online courses, defining career paths, identifying strengths, and engaging in regular feedback.',
		type: 'Article',
		tags: ['Employee Development', 'Managerial Support', 'Skills Training', 'Mentoring', 'Feedback and Growth'],
		authors: ['Forbes Business Council'],
		source: 'Forbes',
		date: '2021-06-03',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699808879/resources/forbes-logo_ugrbvp.png',
		pillars: ['fairCareerPath', 'equalPay', 'worklifeBalance'],
		options: { image: { crop: 'fit' } },
	},
	{
		key: 'brotopia-breaking-up-the-boys-club-of-silicon-valley-by-emily-chang',
		title: "Brotopia: Breaking Up the Boys' Club of Silicon Valley by Emily Chang",
		url: 'https://www.amazon.fr/Brotopia-Breaking-Boys-Silicon-Valley-E-Book/dp/B074LQKJJC/ref=tmm_kin_swatch_0',
		summary: "Silicon Valley's aggressive, misogynistic, work-at-all costs culture has shut women out of the greatest wealth creation in the history of the world. It's time to break up the boys' club. Emily Chang shows us how to fix this toxic culture--to bring down Brotopia, once and for all.",
		type: 'Book',
		tags: [],
		authors: 'Emily Chang',
		pillars: ['genderData'],
		source: 'Emily Chang',
		date: '',
		readingTime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699810159/resources/81nJEN3wWDL._SY522__noq16l.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: 'geek-girls-inequality-and-opportunity-in-silicon-valley-by-dr-france-winddance-twine',
		title: 'Geek Girls: Inequality and Opportunity in Silicon Valley by Dr. France Winddance Twine',
		url: 'https://www.amazon.com/Geek-Girls-Inequality-Opportunity-Silicon-ebook/dp/B09JKHRXXQ/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=&sr=',
		pillars: ['genderData'],
		summary: 'This book explores gender inequality in Silicon Valley, drawing on interviews with tech workers from major firms like Apple and Google.',
		type: 'Book',
		tags: [],
		authors: 'France Winddance Twine',
		source: 'France Winddance Twine',
		date: '',
		readingTime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699811462/resources/geekgirls_rsxgp2.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: 'brown-table-talk-podcast',
		title: 'Brown Table Talk Podcast',
		url: 'https://www.browntabletalkpodcast.com/',
		summary: 'Most leadership lessons, advice and coaching flooding the market place are not speaking to Women of Color. Dee C. Marshall and Mita Mallick offer practical tips and lessons to help them navigate and succeed professionally.',
		type: 'Podcast',
		tags: [],
		authors: 'Dee C. Marshall & Mita Mallick',
		pillars: ['deiActions'],
		source: 'Brown Table Talk',
		date: '',
		readingtime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699810277/resources/1699219428457_apttdf.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: 'confessions-of-a-dei-pro',
		title: 'Confessions of a DEI Pro',
		url: 'https://deiconfessions.libsyn.com/',
		summary: 'Focusing on ethical and accountable training for DEI professionals, it offers insights into career growth, change management, and workplace culture shifts in the Diversity, Equity & Inclusion industry',
		type: 'Podcast',
		tags: [],
		authors: 'Ashanti Bentil-Dhue',
		pillars: ['deiActions'],
		source: 'DEI Confessions',
		date: '',
		readingtime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699810509/resources/rlksnaopxya5bhhx4vbk.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: 'the-balancing-beam-of-diverse-teams-on-hiring-women-in-tech',
		title: 'The balancing beam of diverse teams: On hiring women in tech',
		url: 'https://resources.workable.com/stories-and-insights/hiring-women-in-tech',
		summary: 'The article addresses challenges in recruiting women in tech, emphasizing authentic engagement over tokenism and stereotyping, with a focus on recognizing individual merit and passion for technology',
		type: 'Article',
		tags: [],
		authors: 'Matt Buckland',
		pillars: ['genderData'],
		source: 'Workable',
		date: '',
		readingtime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699811461/resources/balancing-diverse-teams_pgvt2x.png',
	},
	{
		key: 'impact-of-career-advancement-on-employee-retention',
		title: 'Impact of Career Advancement on Employee Retention',
		url: 'https://www.researchgate.net/publication/368273690_Impact_of_Career_Advancement_on_Employee_Retention',
		pillars: ['equalPay'],
		summary: 'Employees prioritize growth and advancement within firms. Career development boosts satisfaction and organizational effectiveness. Firms focusing on this experience higher employee satisfaction and improved performance.',
		type: 'Research publication',
		tags: [],
		authors: [],
		source: 'Research Gate',
		date: '',
		readingTime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699823992/resources/images_lpnln3.png',
	},
	{
		key: 'how-to-hire-women-in-tech-the-ultimate-guide',
		title: 'How to hire women in tech: the ultimate guide',
		url: 'https://nederlia.com/how-to-hire-women-in-tech-the-ultimate-guide/',
		pillars: ['genderData'],
		summary: 'This guide discusses the gender gap in tech, providing strategies for HR teams to hire and retain more women. It emphasizes improving candidate experience, tackling bias, and supporting the next generation.',
		type: 'Article',
		tags: [],
		authors: ['Claude Loeffen'],
		source: 'Nederlia',
		date: '',
		readingTime: '',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1699823651/resources/eb627e2c-e0f4-4e82-b5c8-f992b53fd481-1_mdlebo.png',
	},
	{
		key: 'employee-work-life-balance-what-it-is-and-the-benefits-you-should-be-offering',
		title: 'Employee work life balance: What it is & the benefits you should be offering',
		url: 'https://www.qualtrics.com/uk/experience-management/employee/employee-work-life-balance/?rid=ip&prevsite=en&newsite=uk&geo=FR&geomatch=uk',
		pillars: ['worklifeBalance'],
		summary: 'A healthy employee work life balance drives engagement, productivity, and retention. Why does it matter and how can you support employees to get the balance right?',
		type: 'Article',
		source: 'Qualtrics',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1712134838/resources/work-life-balance_fumd7m.jpg',
	},
	{
		key: 'how-to-improve-your-work-life-balance-today',
		title: 'How to Improve Your Work-Life Balance Today',
		url: 'https://www.businessnewsdaily.com/5244-improve-work-life-balance-today.html',
		pillars: ['worklifeBalance'],
		summary: 'Creating a harmonious work-life balance or work-life integration is critical to improve not only our physical, emotional and mental well-being, but also to succeed in our career.',
		type: 'Article',
		source: 'Business News Daily',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1712134970/resources/what-is-work-life-balance-1024x813_ut8mev.jpg',
	},
	{
		key: 'asymmetric-peer-effects-at-work-the-effect-of-white-coworkers-on-black-womens-careers',
		title: "Asymmetric Peer Effects at Work: The Effect of White Coworkers on Black Women's Careers",
		url: 'https://www.hks.harvard.edu/publications/asymmetric-peer-effects-work-effect-white-coworkers-black-womens-careers',
		pillars: ['deiActions'],
		summary: 'Analysis of new hires at a professional services firm reveals significant racial turnover and promotion gaps. Black employees show a 32% higher turnover rate within two years and a 26% lower likelihood of timely promotion compared to Whites.',
		type: 'Research publication',
		source: 'Harvard Kennedy School',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1712135034/resources/Twitter-Card_cyzyzl.png',
	},
	{
		key: 'a-roadmap-for-ending-unconscious-bias-in-the-workplace',
		title: 'A Roadmap for Ending Unconscious Bias in the Workplace',
		url: 'https://www.worktango.com/resources/articles/ending-unconscious-bias-in-the-workplace',
		pillars: ['deiActions'],
		summary: 'Annual diversity training isn\'t enough to foster true inclusivity; unconscious bias affects all aspects of work. This guide offers strategies for ongoing efforts to create a diverse and inclusive environment where both the organization and its members thrive.',
		type: 'Article',
		source: 'WorkTango',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1712135078/resources/Batch1_39276_Roadmap_1280x720ArticleHeaderandHomepageThumbnail.jpg_yyce5o.jpg',
	},
	{
		key: 'ramadan-in-the-workplace-hr-best-practices-for-2023',
		title: 'Ramadan in the Workplace: HR Best Practices for 2023',
		url: 'https://www.worktango.com/resources/articles/ramadan-in-the-workplace-hr-best-practices',
		pillars: ['deiActions'],
		summary: 'Promoting inclusivity means acknowledging and accommodating diverse practices, including those of different faiths. This guide provides support for Muslim employees during Ramadan, fostering workplace sensitivity and awareness of their needs and experiences during this important month.',
		type: 'Article',
		source: 'WorkTango',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1712135727/resources/Batch22_Action-Planning-Notebook-Pulse-Surveys_1280x720ArticleHeaderandHomepageThumbnail_uqljj7.png',
	},
	{
		key: 'equal-how-we-fix-the-gender-pay-gap',
		title: 'Equal: How we fix the gender pay gap',
		url: 'https://www.amazon.co.uk/Equal-story-women-men-money/dp/0349012253',
		pillars: ['equalPay'],
		summary: 'Carrie Gracie recounts her journey of confronting inequality within her workplace and delves into the broader issue of unequal treatment. This compelling narrative offers both inspiration and practical guidance for individuals looking to tackle inequality wherever they encounter it.',
		type: 'Book',
		source: 'Amazon',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1712135672/resources/41rQmAxgjiL._SY445_SX342__yvynxs.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: 'what-works-gender-equality-by-design',
		title: 'What Works: Gender Equality by Design',
		url: 'https://www.amazon.com/What-Works-Iris-Bohnet-ebook/dp/B01C5MZGS6/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=&sr=',
		pillars: ['equalPay'],
		summary: 'Gender equality is vital for both ethics and business. Iris Bohnet suggests targeting organizational bias for impactful change. Her research offers practical tools to advance equality, benefiting businesses and societies.',
		type: 'Book',
		source: 'Amazon',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1712135860/resources/41pHtvab7NL._SY445_SX342__ttq6lh.png',
		options: { image: { crop: 'fit' } },
	},
	{
		key: '6-key-strategies-for-high-growth-tech-companies-to-successfully-scale-pay-equity',
		title: '6 Key Strategies for High-Growth Tech Companies to Successfully Scale Pay Equity',
		url: 'https://synd.io/blog/tech-companies-pay-equity-strategies/',
		pillars: ['equalPay'],
		summary: 'Ensuring pay equity is challenging for all industries, especially in tech. Companies require a scalable strategy to navigate complexities and ensure fair pay outcomes. Where to start?',
		type: 'Article',
		source: 'Syndio',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1712135907/resources/blog-6-keys-header_EE01_Reporting-2048x1072_xjr2fa.png',
	},
	{
		key: 'the-gender-pay-gap-in-the-tech-industry-and-how-to-fix-it-in-your-business',
		title: 'The gender pay gap in the tech industry (and how to fix it in your business)',
		url: 'https://ravio.com/blog/the-gender-pay-gap-in-tech-and-how-to-improve-it-in-your-business',
		pillars: ['equalPay'],
		summary: 'This article examines gender diversity in European tech, analyzing the gender pay gap across job families and countries, along with women\'s representation in leadership roles.',
		type: 'Article',
		source: 'Ravio',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1712135484/resources/7d6a930c-b33f-4c6f-bf5b-a735e2908cdc_glossary_header_E2_80_93_confusion_fa4gbp.jpg',
	},
	{
		key: 'the-gender-pay-gap-in-tech-how-do-we-close-it',
		title: 'The gender pay gap in Tech: How do we close it?',
		url: 'https://www.womenintech.co.uk/the-gender-pay-gap-in-tech-how-do-we-close-it',
		pillars: ['equalPay'],
		summary: 'The persistent gender gap in the tech industry remains a pressing issue in 2023. This article explores the reasons behind its imbalance and suggests actionable steps to address and narrow the gender pay gap.',
		type: 'Article',
		source: 'Women in Tech',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/c_scale,w_523/v1712135539/resources/Gender-pay-gap_usoujf.jpg',
	},
	{
		key: 'meet-companies-leading-the-way-with-equal-pay',
		title: 'Meet Companies Leading The Way With Equal Pay',
		url: 'https://www.50intech.com/blog/companies-leading-the-way-with-equal-pay',
		pillars: ['equalPay'],
		summary: 'Diversity, Equity, and Inclusion (DEI), notably equal pay, are critical for workplace satisfaction. In tech, the gender pay gap persists, impacting women\'s careers. Analyzing salary data reveals women in tech earn significantly less on average over their working lives.',
		type: 'Article',
		source: '50inTech',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1712136112/resources/companies-leading-the-way_rc8lo1.jpg',
	},
	{
		key: '6-women-in-tech-on-how-to-close-the-gender-pay-gap-in-the-industry',
		title: '6 Women in Tech on How To Close the Gender Pay Gap in the Industry',
		url: 'https://www.spiceworks.com/tech/it-careers-skills/articles/women-in-tech-how-to-close-gender-gap/',
		pillars: ['equalPay'],
		summary: 'In Silicon Valley, the age-old challenge of the gender pay gap persists. We interviewed six women in tech to discuss their perspectives on this issue and strategies to promote pay equity in the workplace.',
		type: 'Article',
		source: 'Spiceworks',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1712135244/resources/shutterstock_1078202258_zn2q1p.jpg',
	},
	{
		key: 'women-in-the-workplace-2023',
		title: 'Women in the Workplace 2023',
		url: 'https://www.mckinsey.com/featured-insights/diversity-and-inclusion/women-in-the-workplace',
		pillars: ['genderData'],
		summary: 'The Women in the Workplace 2023 report by McKinsey & LeanIn.Org highlights female ambition and underrepresentation issues. It offers actionable recommendations for advancing gender parity.',
		type: 'Report',
		source: 'McKinsey',
		image: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1712135200/resources/women-in-the-workplace-2023-1320936551-hero-1536x864-v2_rdd39k.jpg',
	},
];

export const convertJSONToTable = (data) => {
	const headers = Object.keys(data?.[0]);
	// console.log('convertJSONToTable: headers\n', headers);
	const result = [
		headers.join('\t'),
		...data.map((item) => Object.values(item).join('\t')),
	].join('\n');
	// console.log('convertJSONToTable: result\n', result);
};

export const convertTableToJSON = (data) => {
	let [headers, ...lines] = data?.split('\n');
	headers = headers?.split('\t');
	// console.log('convertTableToJSON: headers\n', headers);
	const result = lines
		.map((item) => {
			const values = item.split('\t');
			return headers
				.map((key, index) => ({ [key]: item[index] }))
				.reduce(mergeReducer, {});
		});
	// console.log('convertTableToJSON: result\n', result);
};

export const RESOURCES_TABLE = convertJSONToTable(RESOURCES_JSON);

// convertTableToJSON(RESOURCES_TABLE);
