import { Fragment } from 'react';
import { Tooltip, Button, Popconfirm } from 'antd';

const ActionButton = (
	{
		tooltipTitle = 'action',
		placement = 'top',
		withConfirm = false,
		onClick,
		onConfirm,
		okText,
		cancelText,
		text = '',
		type = 'default',
		icon,
		styles = {},
		disabled = false,
		...props
	},
) => {
	const Component = withConfirm ? Popconfirm : Fragment;
	return (
		<Component
			{...props}
			{...onConfirm ? { onConfirm } : {}}
			{...okText ? { okText } : {}}
			{...cancelText ? { cancelText } : {}}
		>
			<Tooltip title={tooltipTitle} placement={placement}>
				<Button
					type={type}
					onClick={onClick}
					style={{ paddingTop: 5, borderRadius: 4, ...styles }}
					disabled={disabled}
					{...props}
				>
					{icon} {text}
				</Button>
			</Tooltip>
		</Component>
	);
};

export default ActionButton;
