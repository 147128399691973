import { GENDERSCORE_SURVEY_50PLUS_V2_OPTION } from '../../../../../constants/property/genderscore-survey';
import sectionGenderData from './section-genderdata';
import sectionCareerPath from './section-careerpath';
import sectionEqualPay from './section-equalpay';
import sectionWorklifeBalance from './section-worklifebalance';
import sectionDeiActions from './section-deiactions';

export const GENDERSCORE_SURVEY_50PLUS_V2_DATA_OPTION = {
	...GENDERSCORE_SURVEY_50PLUS_V2_OPTION,
	sections: [
		{
			index: 1,
			...sectionGenderData,
		},
		{
			index: 2,
			...sectionCareerPath,
		},
		{
			index: 3,
			...sectionEqualPay,
		},
		{
			index: 4,
			...sectionWorklifeBalance,
		},
		{
			index: 5,
			...sectionDeiActions,
		},
	],
};

export default GENDERSCORE_SURVEY_50PLUS_V2_DATA_OPTION;
