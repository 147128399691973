import * as dayjs from 'dayjs';
import { sha256 } from 'crypto-hash';
import { LocalDebug, localDebug } from './LocalDebug';
import {
	findLastIndex, isArray, isEmpty, isEqual, isNull, isObject, isString, isUndefined, nth, pull,
} from 'lodash';
import LoggingService from '../services/logging';
import CompanyModel from '../models/CompanyModel';
import config from '../config/config';
import {
	Button, Modal, Select, Tag, Tooltip,
} from 'antd';
import FormItem from '../components/company/form/FormItem';
import {
	JOB_FIELDS_JOB_FORM,
	USER_TAG_CAREER_HARD_SKILL,
	USER_TAG_JOB_SEARCH_JOB_ROLE_GROUP,
	USER_TAG_LOCATION_COUNTRY,
} from '../constants/constant';
import FormSelect from '../components/app/form/FormSelect';
import { FaTimes } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { getTagsUsers } from '../reducers/app';
import { push } from '../actions/utils';
import browserHistory from '../browserHistory';
import {
	BiArrowBack, BiCaretDown, BiCaretLeft, BiCaretRight, BiCaretUp,
} from 'react-icons/bi';
import DIACRITIC_LISTS from '../constants/regexpDiacritics.json';
import SPECIAL_REGEXP_CHARS from '../constants/regexpSpecialChars.json';

const pathUser = 'firebase/user';
const { Buffer } = require('buffer/');

const className = 'utils';
const { history } = browserHistory;

const {
	SOURCING_ACTION_STAGE,
	SOURCING_ACTION_STATUS,
	SOURCING_ACTION_STEP,
	sourcingSteps,
	sourcingStatus,
	sourcingStages,
	translations,
	META_TITLE_MAX_LENGTH,
	META_DESCRIPTION_MAX_LENGTH,
	FRENCH_LANG,

	atsProviderOptions,
} = require('../constants/constant');
const {
	ACL_ROLE_50_ADMIN_LABEL,
	ACL_ROLE_50_COACH_LABEL,
	ACL_ROLE_50_STAFF_LABEL,
	ACL_ROLE_DOCUMENT_ADMIN_LABEL,
	ACL_ROLE_DOCUMENT_EDITOR_LABEL,
	ACL_ROLE_COMPANY_RECRUITER_LABEL,
} = require('../constants/acls');

// --------------------------------------------------------//
// ----------------------- ENV ----------------------------//
// --------------------------------------------------------//
const PROTOCOL = window.location.protocol;
const HOSTNAME = window.location.hostname;

export const BASE_URL = (function () {
	return `${PROTOCOL}//${HOSTNAME}`;
}());

export const SERVER_URL = (function () {
	if (HOSTNAME.startsWith('localhost')) return 'http://localhost:8081';
	return BASE_URL;
}());

export const API_URL = `${SERVER_URL}/api`;
export const SOCKET_URL = SERVER_URL;

LocalDebug.logInfo({ className, method: 'Init' }, {
	PROTOCOL, HOSTNAME, BASE_URL, SERVER_URL, API_URL, SOCKET_URL,
});

export const FIT_BLUE_ELECTRIC_COLOR = '#0F3DFB';

export function pTagsAsList(value) {
	if (!value) return [];
	const lines = value
		.replaceAll('&nbsp;', ' ')
		.replaceAll('<ul><li>', '<p>')
		.replaceAll('</li></ul>', '</p>')
		.replaceAll('</li><li>', '</p><p>')
		.replaceAll('<br>', '</p><p>')
		.trim()
		.split('</p><p>');
	if (lines.length === 1) return lines.map((line) => line.replaceAll('<p>', '').replaceAll('</p>', ''));
	return lines.map((line) => line.replaceAll('<p>', '').replaceAll('</p>', ''));
}

export function displayHtml(value, paragraphsAsList = false) {
	return value && paragraphsAsList
		? <p
			className='rawHtml'
			dangerouslySetInnerHTML={{
				__html: (() => {
					const lines = pTagsAsList(value);
					if (lines.length === 1) return lines;
					return `<ul>${lines.map((line) => `<li>${line}</li>`).join('')}</ul>`;
				})(),
			}} />
		: <div
			className='rawHtml'
			dangerouslySetInnerHTML={{ __html: value }}
		 />;
}

export function spanHtml(value) {
	return value && <span className='rawHtml' dangerouslySetInnerHTML={{ __html: value }} />;
}

export function extractTokensFromHtml(value, defaultValue = []) {
	// localDebug('extractTokensFromHtml', value);
	if (!value) return defaultValue;
	if (value.indexOf('<ul>') === 0) {
		return value
			.split('</li><li>')
			.map((s) => s
				.replace('<ul><li>', '')
				.replace('</li></ul>', '')
				.replace('&nbsp;', ' ')
				.trim()).flat();
	}
	return value
		.split('</p><p>')
		.map((s) => s
			.replace('<p>', '')
			.replace('</p>', '')
			.replace('&nbsp;', ' ')
			.trim()
			.split('</li><li>')
			.join('')
			.replace('<ul><li>', '')
			.replace('</li></ul>', '')
			.trim()).flat();
}

export function inlineHtml(value, joiner = ', ') {
	return value && <span
		className='rawHtml'
		dangerouslySetInnerHTML={{ __html: (() => extractTokensFromHtml(value).join(joiner || ', '))() }} />;
}

export function mixColors(colorA, colorB, amount) {
	const [rA, gA, bA] = colorA.match(/\w\w/g).map((c) => parseInt(c, 16));
	const [rB, gB, bB] = colorB.match(/\w\w/g).map((c) => parseInt(c, 16));
	const r = Math.round(rA + (rB - rA) * amount / 100).toString(16).padStart(2, '0');
	const g = Math.round(gA + (gB - gA) * amount / 100).toString(16).padStart(2, '0');
	const b = Math.round(bA + (bB - bA) * amount / 100).toString(16).padStart(2, '0');
	return `#${r}${g}${b}`;
}

export function arrayCompare(array1, array2) {
	const cloneArray1 = [...array1];
	const cloneArray2 = [...array2];
	const array1Sorted = cloneArray1.sort();
	const array2Sorted = cloneArray2.sort();
	return array1.length === array2.length && array1Sorted.every((value, index) => value === array2Sorted[index]);
}

export function isFunction(f) { return f && {}.toString.call(f) === '[object Function]'; }

export function isBoolean(b) { return [true, false].includes(b); }

export function arrayWrap(value, defaultValue) {
	return ![null, undefined].includes(value)
		? (Array.isArray(value)
			? value
			: [value])
		: defaultValue || value;
}

export function arrayToObjectListByProp(list, prop = '_id', mapper, reducer) {
	const data = {};

	const propValue = (item, prop) => (
		isFunction(prop)
			? prop(item)
			: item?.[prop]
	);

	list
		.filter((item) => item && propValue(item, prop))
		.forEach((item) => {
			const id = propValue(item, prop)?.toString();
			data[id] = [
				...data[id] || [],
				item,
			];
		});

	if (mapper) {
		Object.keys(data).forEach((id) => {
			data[id] = data[id].map(mapper);
		});
	}
	if (reducer) {
		Object.keys(data).forEach((id) => {
			data[id] = data[id].reduce(reducer);
		});
	}
	return data;
}

export const fieldReducer = (fields) => (obj) => {
	let value = obj;
	const fieldPath = fields.slice();
	// localDebug({ fieldPath, fields })
	while (fieldPath.length > 0) {
		if (!value) {
			return value;
		}
		value = value[fieldPath.shift()];
	}
	return value;
};

export const stringSorter = (field) => (a, b) => {
	let aValue; let
		bValue;
	if (typeof field === 'string') {
		aValue = (a[field] || '').toLowerCase();
		bValue = (b[field] || '').toLowerCase();
	} else {
		const r = fieldReducer(field);
		aValue = (r(a) || '').toLowerCase();
		bValue = (r(b) || '').toLowerCase();
	}
	return aValue >= bValue ? 1 : -1;
};

// -------------------------------------- //
// ----------- current status ----------- //
// -------------------------------------- //
export const getCurrentStatus = (actions, isReturnKey = true) => {
	if (actions) {
		const currentIndex = findLastIndex(actions, (h) => h.action === SOURCING_ACTION_STATUS);
		return isReturnKey ? currentIndex : nth(actions, currentIndex);
	}
	return null;
};

// -------------------------------------- //
// ----------- current steps ------------ //
// -------------------------------------- //
export const getCurrentSteps = (actions, isReturnKey = true) => {
	if (actions) {
		const currentIndex = findLastIndex(actions, (h) => h.action === SOURCING_ACTION_STEP);
		return isReturnKey ? currentIndex : nth(actions, currentIndex);
	}
	return null;
};

// ---------------------------------------- //
// --------- check if is current step ----- //
// ---------------------------------------- //
export const isCurrentStep = (history, step) => {
	const currentStep = history.find((h) => h.action === SOURCING_ACTION_STEP && h.isActive);
	return currentStep === step;
};

// ---------------------------------------- //
// --------- previous step ---------------- //
// ---------------------------------------- //
export const getPreviousStep = (history, step) => {
	const prevHistory = pull(history, step);
	const previousStep = prevHistory.find((h) => h.action === SOURCING_ACTION_STEP && h.isActive);
	return previousStep;
};

// --------------------------------- //
// ------- copy to clipboard ------- //
// --------------------------------- //
export const copyClipboard = (data) => {
	navigator.clipboard.writeText(data);
};

// -------------------------------------------------------------- //
// -------- get query params for  current location -------------- //
// -------------------------------------------------------------- //
export const getQueryParams = ({
	withCommaSeparatedStringsToArraysConversion = false,
} = {
	withCommaSeparatedStringsToArraysConversion: false,
}) => {
	return browserHistory.parseQueryParamsToObject({ withCommaSeparatedStringsToArraysConversion });
};

export const goToPublicPage = () => {
	history.push('/articles');
};

export const removeNullOptions = (options) => {
	return Array.isArray(options) ? options.filter((o) => o) : options;
};

export const addToFilterOptions = (key, values, setState = () => {}) => {
	const newValues = removeNullOptions(values);
	// set local state
	setState((prev) => ({ ...prev, [key]: newValues }));
	// set query search url
	addQueryParams({ name: key, value: newValues });
};

export const removeFromFilterOptions = (key, setState = () => {}) => {
	setState((prev) => {
		const { [key]: value, ...rest } = prev;
		return rest;
	});
	removeQueryParams([key]);
};

export function addQueryParams(params) {
	const { location } = history;
	const prevQuery = browserHistory.parseQueryParamsToObject();

	LocalDebug.logNull({ className, method: 'addQueryParams' }, { params, location, prevQuery });

	const query = {
		...prevQuery,
		[params.name]: params.value,
	};

	history.push({
		...location,
		search: browserHistory.toSearchParams(query),
	});

	localStorage.setItem(
		location.pathname,
		JSON.stringify(query),
	);
}

export const isValueDefined = (value) => {
	if (isObject(value) && !isArray(value)) {
		return Object
			.entries(value)
			.filter(([key, val]) => isValueDefined(val))
			.length > 0;
	}
	if (isArray(value)) {
		return value
			.filter((val) => isValueDefined(val))
			.length > 0;
	}
	return (
		!isUndefined(value)
		&& !isNull(value)
		&& !['undefined', 'null', ''].includes(value)
	);
};

export const removeEmpty = (target, defaultValue) => {
	if (isObject(target) && !isArray(target)) {
		const obj = Object
			.entries(target)
			.filter(([key, value]) => {
				return isValueDefined(value);
			})
			.map(([key, value]) => ({ [key]: removeEmpty(value) }))
			.reduce(mergeReducer, {});
		return isEmpty(obj)
			? defaultValue
			: obj;
	}

	if (isArray(target)) {
		const arr = target
			.filter((value) => isValueDefined(value))
			.map((value) => removeEmpty(value));
		return arr?.length > 0
			? arr
			: defaultValue;
	}

	if (isValueDefined(target)) {
		return target;
	}

	return defaultValue;
};

// Utility function to recrusively convert strings containing ',' as arrays
export const convertCommaSeparatedStringsToArrays = (target) => {
	if (isObject(target) && !isArray(target)) {
		return Object
			.entries(target)
			.map(([key, value]) => ({
				[key]: convertCommaSeparatedStringsToArrays(value),
			}))
			.reduce(mergeReducer, {});
	}
	if (isArray(target)) {
		return target
			.map((value) => convertCommaSeparatedStringsToArrays(value));
	}
	return target?.indexOf?.(',') >= 0
		? target.split(',')
		: target;
};

export function addMultipleQueryParams(params = {}, withReset = false) {
	const { location } = history;
	const prevQuery = browserHistory.parseQueryParamsToObject();

	const query = removeEmpty({
		...withReset ? {} : prevQuery,
		...params,
	});

	push({ ...location, search: browserHistory.toSearchParams(query) });
	localStorage.setItem(location.pathname, JSON.stringify(query));
}

export function clearQueryParams() {
	const { location } = history;
	history.push({ ...location, search: '' });
	localStorage.setItem(location.pathname, JSON.stringify({}));
}

export function removeQueryParams(params) {
	const { location } = history;
	const query = browserHistory.parseQueryParamsToObject();
	const newQuery = Object.entries(query)
		.filter(([key, value]) => !params.includes(key))
		.map(([key, value]) => ({ [key]: value }))
		.reduce(mergeReducer, {});
	history.push({ ...location, search: browserHistory.toSearchParams(newQuery || {}) });
	localStorage.setItem(location.pathname, JSON.stringify(newQuery));
}

export const getFilterOptionsWithoutForced = (filters = {}, forcedFilters = {}) => {
	const f = {};
	Object.entries(filters || {}).forEach(([key, value]) => {
		if (Object.keys(forcedFilters || {}).includes(key)) return;
		f[key] = value;
	});
	return f;
};

export function inArray(value, arrays = [SOURCING_ACTION_STEP, SOURCING_ACTION_STATUS]) {
	return arrays.includes(value);
}

export function getPermissionOptionsByUser(options = [], user) {
	const { permissions } = user?.acls || {};
	if (!(permissions?.length > 0)) {
		return [];
	}
	const optionsByUser = [];
	options.forEach((option) => {
		const { permissions: optionPermision } = option;
		const isAccess = permissions.some((p) => optionPermision.some((r) => isEqual(r, p)));

		if (isAccess) {
			optionsByUser.push(option);
		}
	});
	return optionsByUser;
}

export function canUserManageRoles(currentUser, targetRoles) {
	const curRoles = currentUser?.acls?.aclLabels || [];
	if (!(curRoles?.length > 0)) {
		return false;
	}
	if (curRoles.includes(ACL_ROLE_50_ADMIN_LABEL)) {
		return true;
	}
	const fitRoles = [ACL_ROLE_50_ADMIN_LABEL, ACL_ROLE_50_STAFF_LABEL, ACL_ROLE_50_COACH_LABEL];
	// if (targetRoles.some((p) => fitRoles.some((r) => r === p)) {
	//     // Target roles  50inTech permissions - Only admin can do anything
	//     return curRoles.includes(ACL_ROLE_50_ADMIN_LABEL);
	// }
	const clientRoles = [ACL_ROLE_DOCUMENT_ADMIN_LABEL, ACL_ROLE_DOCUMENT_EDITOR_LABEL, ACL_ROLE_COMPANY_RECRUITER_LABEL];
	if (targetRoles.some((p) => clientRoles.some((r) => r === p))) {
		return curRoles.some((p) => [...fitRoles, ACL_ROLE_DOCUMENT_ADMIN_LABEL].some((r) => r === p));
	}
	return false;
}

export function sortOn(on, descending = false) {
	on = on && on.constructor === Object ? on : {};
	return (a, b) => {
		// return -1;
		// localDebug(on);
		if (on.string || on.key) {
			a = on.key ? (isFunction(on.key) ? on.key(a) : a[on.key]) : a;
			a = on.string ? String(a).toLowerCase() : a;
			b = on.key ? (isFunction(on.key) ? on.key(b) : b[on.key]) : b;
			b = on.string ? String(b).toLowerCase() : b;
			// if key is not present, move to the end
			if (on.key && on.string && (!b || !a)) {
				return !a && !b ? 1 : !a ? 1 : -1;
			}
		}
		// return a < b? 1: -1;
		// localDebug(a, b, ~~(a < b), ~~(a > b))
		const result = on.string
			? ~~(descending
				? b.localeCompare(a)
				: a.localeCompare(b)
			)
			: (descending
				? ((a < b) ? 1 : -1)
				: ((b < a) ? 1 : -1)
			);

		// ? ~~(on.string ? b.localeCompare(a) : a < b)
		// : ~~(on.string ? a.localeCompare(b) : a > b);
		// localDebug(a, b, result);
		return result;
	};
}

export const getSourcingStageByValue = (value) => {
	const step = sourcingStages.find((step) => step.value === value);
	// localDebug('getSourcingStageByValue', { value, step });
	return step;
};

export const getSourcingStepByValue = (value) => {
	const step = sourcingSteps.find((step) => step.value === value);
	// localDebug('getSourcingStepByValue', { value, step });
	return step;
};

export const getSourcingStatusByValue = (value) => {
	return sourcingStatus.find((status) => status.value === value);
};

/**
 * get max step sourcing
 * @param {*} steps
 * @returns
 */
export const getMaxStepSourcing = (steps = []) => {
	if (steps.length === 0) return null;
	let maxTextStep = steps[0].step; //
	const stepSourcing = getSourcingStepByValue(maxTextStep);
	let stepMax = stepSourcing?.step;
	for (let i = 1; i < steps.length; i++) {
		const tempSourcing = getSourcingStepByValue(steps[i].step);

		if (tempSourcing.step > stepMax) {
			maxTextStep = steps[i].step;
			stepMax = tempSourcing.step;
		}
	}
	return maxTextStep;
};

export const getMaxStageSourcing = (stages = []) => {
	if (stages.length === 0) return null;
	let maxTextStage = stages?.[0]?.stage;
	const stageSourcing = getSourcingStageByValue(maxTextStage);
	let stageMax = stageSourcing?.stage;
	for (let i = 1; i < stages.length; i++) {
		const tempSourcing = getSourcingStageByValue(stages[i]?.stage);
		if (tempSourcing?.stage > stageMax) {
			maxTextStage = stages[i]?.stage;
			stageMax = tempSourcing?.stage;
		}
	}
	return maxTextStage;
};

export const getNextSourcingValueByAction = (currentValue, action = SOURCING_ACTION_STATUS) => {
	if (action === SOURCING_ACTION_STATUS) {
		return getNextSourcingStatus(currentValue);
	}
	if (action === SOURCING_ACTION_STEP) {
		return getNextSourcingStep(currentValue);
	}
	return getNextSourcingStage(currentValue);
};

const getNextSourcingStatus = (currentValue) => {
	const currentStatus = getSourcingStatusByValue(currentValue);
	const defaultStatus = sourcingStatus[1];
	const nextStatus = sourcingStatus.find((status) => status.step === currentStatus?.step + 1);

	if (nextStatus) return nextStatus;
	return defaultStatus;
};

const getNextSourcingStep = (currentValue) => {
	localDebug('getNextSourcingStep', { currentValue, sourcingSteps });
	const currentStep = getSourcingStepByValue(currentValue);
	localDebug('getNextSourcingStep', { currentStep });
	const defaultStep = sourcingSteps[0];
	localDebug('getNextSourcingStep', { defaultStep });
	const nextStep = sourcingSteps
		.find((status) => status.step === currentStep?.step + 1);

	if (nextStep) return nextStep;
	return defaultStep;
};

const getNextSourcingStage = (currentValue) => {
	localDebug('getNextSourcingStage', { currentValue, sourcingStages });
	const currentStage = getSourcingStageByValue(currentValue);
	localDebug('getNextSourcingStage', { currentStage });
	const defaultStage = sourcingStages[0];
	localDebug('getNextSourcingStage', { defaultStage });
	const nextStage = sourcingStages.find((status) => status.step === currentStage?.step + 1);
	if (nextStage) return nextStage;
	return defaultStage;
};

export const getSourcingActionOptions = (currentValue, action) => {
	let options;
	if (action === SOURCING_ACTION_STATUS) {
		options = sourcingStatus;
	}
	if (action === SOURCING_ACTION_STEP) {
		options = sourcingSteps;
	}
	if (action === SOURCING_ACTION_STAGE) {
		options = sourcingStages;
	}
	return options.filter((o) => o.value !== currentValue);
};

// show path
export function goTo(path, query = {}) {
	history.push({ pathname: path, search: browserHistory.toSearchParams(query) });
}

export function capitalize(text, first = true) {
	if (!text) return null;
	return first ? text.charAt(0).toUpperCase() + text.slice(1) : text.toUpperCase();
}

export function findAtsProvider(ats) {
	return atsProviderOptions.find((o) => o.value === ats);
}

export function relabelAtsProvider(ats) {
	return findAtsProvider(ats)?.label
		|| ats?.split('_')?.map((s) => s.split('-'))?.flat()?.map((s) => capitalize(s))
			?.join(' ');
}

export function getCloudinaryPublicId(path) {
	const parts = path.split('/upload/').reverse()[0].split('/');
	parts.shift();
	return parts.join('/');
}

export function buildCloudinaryUrl(publicId) {
	return `https://res.cloudinary.com/${config.cloudinary.cloud_name}/image/upload/v1/${publicId}`;
}

export function orderList(list, startIndex, endIndex) {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
}

export const getTranslationOptions = (lang) => {
	return translations.get(lang);
};

export const getRandomPassword = () => {
	return Math.random().toString(36).slice(-8);
};

export const dateDiffFromNow = (date, type = 'hour') => {
	if (!date) return null;

	const dateNow = dayjs();
	return dateNow.diff(dayjs(date), type);
};

export const objectFilterUndefinedValues = (obj) => Object.entries(obj)
	.filter(([key, value]) => value !== undefined)
	.reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {});

export const writeLog = (message, data = {}) => {
	const currentLocation = history.location;
	LoggingService.write(message, { path: currentLocation?.pathname, ...data });
};
export const openExternalUrl = (url, target = '_blank') => {
	const formattedUrl = (!/^(mailto:|http:|https:)/i.test(url) ? 'https://' : '') + url;
	const notionSafeUrl = formattedUrl
		.replace('www.notion.so/50intech', '50intech.notion.site', 'g')
		.replace('notion.so/50intech', '50intech.notion.site', 'g');
	window.open(notionSafeUrl, target);
};

export const scrollToTop = () => {
	if (typeof window != 'undefined' && window.document) {
		window.scrollTo(0, 0);
	}
};

export const replaceAll = (text = '', find, replace) => {
	return text.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};

export const readTemplates = (content, talent, currentUser) => {
	const props = {
		talentFirstName: talent?.firstName,
		talentFullName: `${talent?.firstName} ${talent?.lastName}`,
		userFirstName: currentUser?.firstName,
		userFullName: `${currentUser?.firstName} ${currentUser?.lastName}`,
		userPosition: currentUser?.information?.position || currentUser?.information?.role,
		userCompany: currentUser?.company?.name,
		companyPage: currentUser?.company?.path
			&& `<a href='${new CompanyModel(currentUser?.company)?.getAppUri()}'>${currentUser?.company?.name}</a>`,
		userCalendly: currentUser?.information?.calendly
			&& `<a href='https://calendly.com/${currentUser?.information?.calendly}'>https://calendly.com/${currentUser?.information?.calendly}</a>`,
	};

	let template = content;
	for (const [key, value] of Object.entries(props)) {
		template = replaceAll(template, `{{${key}}}`, value);
	}

	return template;
};

export function retrieveUserFromLocalStorage() {
	const userIntoLocalStorage = localStorage.getItem(pathUser);

	if (userIntoLocalStorage) {
		return JSON.parse(userIntoLocalStorage);
	}

	return null;
}

export const checkCurrentCompanyRecruiter = () => {
	const currentUser = retrieveUserFromLocalStorage();
	const {
		aclLabels,
		companyId,
		isCompanyRecruiter,
		companyRecruiterDocIds,
		permissions,
	} = currentUser?.acls || {};

	const isRecruiter = inArray(ACL_ROLE_COMPANY_RECRUITER_LABEL, aclLabels)
		&& companyId && isCompanyRecruiter;

	return {
		isCompanyRecruiter: isRecruiter,
		userInfo: {
			id: documentId(currentUser),
			companyId,
			companyRecruiterDocIds,
			slug: currentUser?.slug,
		},
		companyContext: permissions?.reduce((prev, cur) => ({ ...prev, ...cur }), {}),
	};
};

export const htmlToString = (html = '') => {
	return html.replace(/<[^>]+>/g, '');
};

export const getStringContent = (html, maxLength) => {
	return htmlToString(html).substring(0, maxLength);
};

export const getDefaultArticleMeta = (article) => {
	const { title, content, translations } = article;

	const titleMeta = getStringContent(title, META_TITLE_MAX_LENGTH);
	const description = getStringContent(content, META_DESCRIPTION_MAX_LENGTH);

	const meta = {
		title: titleMeta,
		description,
		...(translations && translations.hasOwnProperty(FRENCH_LANG) && {
			[FRENCH_LANG]: {
				title: getStringContent(translations[FRENCH_LANG].title, META_TITLE_MAX_LENGTH),
				description: getStringContent(translations[FRENCH_LANG].content, META_DESCRIPTION_MAX_LENGTH),
			},
		}),
	};

	return meta;
};

export const sortObj = (o) => Object.entries(o)
	.map(([key, value]) => ({ key, value }))
	.sort(sortOn({ key: 'key' }))
	.reduce((p, c) => ({ ...p, [c.key]: c.value }), {});

export const hashValue = async (value) => (await sha256(Buffer.from(JSON.stringify(value)))).toString('hex');
export const lastChars = (value = '', limit = 5) => (value ? value.substr(Math.max(0, value.length - limit)) : '');
export const objectStripUndefinedValues = (obj) => {
	const o = {};
	// LocalDebug.logInfo({ className, method: 'objectStripUndefinedValues' }, { obj });
	Object.entries(obj).forEach(([key, value]) => {
		if (value !== undefined) o[key] = value;
	});
	// LocalDebug.logInfo({ className, method: 'objectStripUndefinedValues' }, { o });
	return o;
};

export const replaceFromNow = (s) => {
	return s
		.replace(/^in /g, '')
		.replace(/a year/g, '1y')
		.replace(/ years/g, 'y')
		.replace(/a month/g, '1mo')
		.replace(/ months/g, 'mo')
		.replace(/a day/g, '1d')
		.replace(/ days/g, 'd')
		.replace(/an hour/g, '1h')
		.replace(/ hours/g, 'h')
		.replace(/a minute/g, '1m')
		.replace(/ minutes/g, 'm')
		.replace(/a few seconds/g, ' <1min')
		.replace(/ seconds/g, 's');
};

export const isCloudinaryUrl = (url) => {
	return (url || '').indexOf('https://res.cloudinary.com/') !== -1;
};

export const url = (url, options, defaultUrl = '') => {
	let newUrl;
	if (!url || url.length === 0 || !isCloudinaryUrl(url)) {
		newUrl = url && url.length === 0 ? defaultUrl : url || defaultUrl;
		newUrl = newUrl.split('%2C').join(',');
		return newUrl;
	}
	const imageOptions = {
		fetchFormat: 'auto',
		quality: 'auto',
		flags: 'lossy',
		...options,
	};

	try {
		const parts = url.split('/upload/');
		const addons = [];
		if (imageOptions.fetchFormat) {
			addons.push(`f_${imageOptions.fetchFormat}`);
		}
		if (imageOptions.quality) {
			addons.push(`q_${imageOptions.quality}`);
		}
		if (imageOptions.flags) {
			addons.push(`fl_${imageOptions.flags}`);
		}
		if (imageOptions.width) {
			addons.push(`w_${imageOptions.width}`);
		}
		if (imageOptions.height) {
			addons.push(`h_${imageOptions.height}`);
		}
		if (imageOptions.crop) {
			addons.push(`c_${imageOptions.crop}`);
		}
		if (imageOptions.gravity) {
			addons.push(`g_${imageOptions.gravity}`);
		}
		if (imageOptions.effect) {
			addons.push(`e_${imageOptions.effect}`);
		}
		if (imageOptions.color) {
			addons.push(`co_${imageOptions.color}`);
		}
		newUrl = `${parts[0]}/upload/${
			addons.length > 0 ? `${addons.join(',')}/` : ''
		}${parts[1]}`;
		newUrl = newUrl.split('%2C').join(',');
		return newUrl;
	} catch (e) {}
	newUrl = url || defaultUrl;
	newUrl = newUrl.split('%2C').join(',');
	return url || defaultUrl;
};

export const formatImage = (imageUrl, options = {}) => {
	const newOptions = {
		...(options || {}),
	};
	return isString(imageUrl) ? url(imageUrl, newOptions) : '';
};

export const formatDelay = (d) => {
	try {
		if (!d) {
			return 'n/a';
		}
		return dayjs(d)
			.fromNow()
			.toString()
			.split('a few seconds ago')
			.join('just now')
			.split('a minute')
			.join('1min')
			.split(' minutes')
			.join('min')
			.split('an hour')
			.join('1h')
			.split(' hours')
			.join('h')
			.split('a day')
			.join('1d')
			.split(' days')
			.join('d')
			.split('a month')
			.join('1mo')
			.split(' months')
			.join('mo')
			.split('a year')
			.join('1y')
			.split(' years')
			.join('y');
	} catch (error) {
		return 'n/a';
	}
};

export const videoUrlParse = (contentLogged) => {
	try {
		const element = new DOMParser().parseFromString(
			`<div>${contentLogged.replaceAll(/&nbsp;/gi, ' ')}</div>`,
			'text/xml',
		);
		const url = element.querySelector('oembed')?.getAttribute('url');
		if (url) {
			return url;
		}
		return '';
	} catch (error) {
		return '';
	}
};

export const replaceLoggedContent = (content) => {
	const spliter = '--content-spliter--';
	const oembed = '<(oembed)[^>]*>(?<content>[^<]*)</oembed>';
	const figure = '<(figure)[^>]*>(?<content>[^<]*)</figure>';
	const regOembed = new RegExp(oembed);
	const regFigure = new RegExp(figure);
	const newContentOembed = content?.replace(regOembed, '-----TOREPLACE-----');
	const newContentFigure = newContentOembed?.replace(regFigure, spliter);
	return newContentFigure?.split(spliter);
};

export const booleanOrReducer = (prev, cur) => prev || cur;
export const booleanAndReducer = (prev, cur) => prev && cur;

export const mergeReducer = (prev, cur) => ({ ...(prev || {}), ...(cur || {}) });

export const concatReducer = (prev, cur) => (prev || []).concat((cur || []));

export const opacify = (value, opacifiedLevel = 0.5) => {
	// LocalDebug.logInfo({ className, method: 'opacify' }, { value });
	return { opacity: value ? opacifiedLevel : 1 };
};

export const hexToRgb = (hex) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? [
		parseInt(result[1], 16),
		parseInt(result[2], 16),
		parseInt(result[3], 16),
	] : null;
};

export const ckEditorReadyMinHeight = (height) => (editor) => {
	// You can store the "editor" and use when it is needed.
	editor?.editing?.view?.change((writer) => {
		writer.setStyle('min-height', `${height}px`, editor?.editing?.view?.document?.getRoot());
	});
};

export const round = (value, decimals = 0) => Math.round(value * 10 ** decimals) / 10 ** decimals;

export const pct = (val, total, decimals = 0) => (total > 0
	? Math.round(100 * (10 ** decimals) * val / total) / (10 ** decimals)
	: 0
);

/**
 * Ensure that the main percentage is an integer between 0 an 100
 * @param {*} value
 * @returns
 */
export const normalizePct = ({ value, roundDown = true, decimals = false }) => {
	if (isNaN(value)) {
		return value;
	}
	let normalizedValue = Math.floor(value);
	if (roundDown) {
		normalizedValue = decimals ? Math.round(value) : Math.floor(value);
	} else {
		normalizedValue = decimals ? Math.round(value) : Math.ceil(value);
	}
	if (value < 0) {
		normalizedValue = 0;
	}
	if (value > 100) {
		normalizedValue = 100;
	}
	return normalizedValue;
};
export const confirmDispatch = (label, callback) => {
	Modal.confirm({
		title: <>
			<div><b>{label}</b></div>
			Are you sure you want to proceed?</>,
		okText: 'Yes',
		cancelText: 'No',
		onOk() {
			callback();
		},
	});
};

export const buildConfirmBtnStyleLarge = {
	width: 220,
	maxWidth: 220,
	height: 120,
	whiteSpace: 'normal',
	wordWrap: 'normal',
	wordBreak: 'break-word',
	borderRadius: 6,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	borderWidth: 2,
	fontSize: 13,
	lineHeight: 1.2,
	overflow: 'hidden',
	icon: {
		fontSize: 20,
		color: FIT_BLUE_ELECTRIC_COLOR,
		marginBottom: 8,
	},
};

export const buildConfirmBtn = (
	{
		theme,
		title,
		path,
		withPath = true,
		params,
		Icon,
		confirmText,
		dispatch,
		dispatcher,
		callback,
		tooltip,
		disabled,
		style = buildConfirmBtnStyleLarge,
	},
) => {
	const button = (
		<Button
			style={style}
			icon={Icon ? <Icon style={{ marginRight: 8, marginBottom: -2, ...style.icon }} /> : <></>}
			disabled={disabled}
			onClick={
				() => confirmDispatch(
					confirmText || title,
					async () => {
						await dispatch(dispatcher(params));
						await callback?.();
					},
				)
			}
		>
			<span>{title}</span>
			{withPath && (path || params) && (
				<div
					style={{
						position: 'absolute',
						bottom: -4,
						width: '100%',
						background: mixColors(theme?.color?.fitBlueElectric, theme?.color?.white, 95),
					}}
				>
					<code
						style={{
							width: '100%',
							fontSize: 8,
							color: mixColors(theme?.color?.fitBlueElectric, theme?.color?.white, 50),
							background: 'transparent',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}>
						{path} {params && Object.keys(params)?.length && JSON.stringify(params)}
					</code>
				</div>
			)}
		</Button>
	);

	if (tooltip) {
		return (
			<Tooltip title={tooltip}>
				{button}
			</Tooltip>
		);
	}

	return button;
};

export const getTagsOptions = (tags, category, noneValue = '-1', sortOnKey = null) => {
	let options = [];
	const categoryTags = (tags.find((tag) => tag.category === category));
	if (categoryTags) {
		options = (categoryTags?.items || []);
		if (sortOnKey) options.sort(sortOn({ key: sortOnKey }));
		options = [
			...noneValue ? [{
				value: noneValue, label: <span style={{ fontStyle: 'italic' }}>Not provided</span>,
			}] : [],
			...options,
		];
	}
	return options;
};

export const tagSelectOption = ({ label, value }, index) => (
	<Select.Option key={value + index} value={value} label={label}>
		{label}
	</Select.Option>
);

export const tagSelect = (tags, category) => {
	const [noneOption, ...options] = getTagsOptions(tags, category?.value) || [];
	// LocalDebug.logInfo({ className, method: 'tagSelect' }, category, options);
	const groupedTags = {};

	if ([USER_TAG_CAREER_HARD_SKILL.value, USER_TAG_LOCATION_COUNTRY.value].includes(category?.value)) {
		options.sort(sortOn({ key: 'label', string: true }));
	}
	options.forEach(({ group, ...rest }, index, arr) => {
		if (!group || [USER_TAG_CAREER_HARD_SKILL.value].includes(category.value)) return;
		groupedTags[group] = [
			...groupedTags[group] || [],
			rest,
		];
	});

	const content = <>
		{/* {tagSelectOption(noneOption)} */}
		{Object.keys(groupedTags).length > 0
			? Object.entries(groupedTags).map(([group, tags], index) => <Select.OptGroup
				key={index}
				label={getTagsOptions(tags, USER_TAG_JOB_SEARCH_JOB_ROLE_GROUP.value).find((o) => o.value === group)?.label}
			>
				{tags.map(tagSelectOption)}
			</Select.OptGroup>)
			: options.map(tagSelectOption)
		}
	</>;

	return (
		<FormSelect
			options={options}
			// style={{minWidth: 200, width: 'unset'}}
			// dropdownMatchSelectWidth={200}
			mode='multiple'
			tagRender={(props) => {
				const {
					label, value, closable, onClose,
				} = props || {};
				const onPreventMouseDown = (event) => {
					event.preventDefault();
					event.stopPropagation();
				};
				return (
					<span style={{
						color: FIT_BLUE_ELECTRIC_COLOR,
					}}>
						<Tag
							color={value}
							onMouseDown={onPreventMouseDown}
							// closable={closable}
							closable={false}
							// onClose={onClose}
							style={{
								padding: '2px 8px',
								border: `1px solid ${FIT_BLUE_ELECTRIC_COLOR}`,
								borderRadius: 4,
								background: 'white',
								color: FIT_BLUE_ELECTRIC_COLOR,
								fontSize: 13,
								margin: '1px 2px 1px 0',
							}}
						>
							{label}
							&nbsp;
							<FaTimes
								onClick={onClose}
								style={{
									marginBottom: -2,
									color: FIT_BLUE_ELECTRIC_COLOR,
									cursor: 'pointer',
								}}
							/>
						</Tag>
					</span>
				);
			}
			}
		>
			{content}
		</FormSelect>
	);
};
export const SelectTag = (
	{
		category,
	},
) => {
	const tags = useSelector(getTagsUsers);

	const [noneOption, ...options] = getTagsOptions(tags, category?.value) || [];
	// LocalDebug.logInfo({ className, method: 'tagSelect' }, category, options);
	const groupedTags = {};

	if ([USER_TAG_CAREER_HARD_SKILL.value, USER_TAG_LOCATION_COUNTRY.value].includes(category?.value)) {
		options.sort(sortOn({ key: 'label', string: true }));
	}
	options.forEach(({ group, ...rest }, index, arr) => {
		if (!group || [USER_TAG_CAREER_HARD_SKILL.value].includes(category.value)) return;
		groupedTags[group] = [
			...groupedTags[group] || [],
			rest,
		];
	});

	const content = <>
		{/* {tagSelectOption(noneOption)} */}
		{Object.keys(groupedTags).length > 0
			? Object.entries(groupedTags).map(([group, tags], index) => <Select.OptGroup
				key={index}
				label={getTagsOptions(tags, USER_TAG_JOB_SEARCH_JOB_ROLE_GROUP.value).find((o) => o.value === group)?.label}
			>
				{tags.map(tagSelectOption)}
			</Select.OptGroup>)
			: options.map(tagSelectOption)
		}
	</>;

	return (
		<FormSelect
			options={options}
			// style={{minWidth: 200, width: 'unset'}}
			// dropdownMatchSelectWidth={200}
			mode='multiple'
			tagRender={(props) => {
				const {
					label, value, closable, onClose,
				} = props || {};
				const onPreventMouseDown = (event) => {
					event.preventDefault();
					event.stopPropagation();
				};
				return (
					<span style={{
						color: FIT_BLUE_ELECTRIC_COLOR,
					}}>
						<Tag
							color={value}
							onMouseDown={onPreventMouseDown}
							// closable={closable}
							closable={false}
							// onClose={onClose}
							style={{
								padding: '2px 8px',
								border: `1px solid ${FIT_BLUE_ELECTRIC_COLOR}`,
								borderRadius: 4,
								background: 'white',
								color: FIT_BLUE_ELECTRIC_COLOR,
								fontSize: 13,
								marginRight: 3,
							}}
						>
							{label}
							&nbsp;
							<FaTimes
								onClick={onClose}
								style={{
									marginBottom: -2,
									color: FIT_BLUE_ELECTRIC_COLOR,
									cursor: 'pointer',
								}}
							/>
						</Tag>
					</span>
				);
			}
			}
		>
			{content}
		</FormSelect>
	);
};

export const tagFormItem = (tags, parent = []) => (category, key) => (
	// <div key={key} style={{ width: '100%' }}>
	// 	<Col span={24} key={key} style={{ padding: '0px !important' }}>
	<FormItem
		key={category?.value}
		label={category?.label}
		name={[...parent || [], category?.value]}
		// style={{ padding: 0 }}
		// labelCol={labelCol}
		// wrapperCol={wrapperCol}
		// labelAlign={labelAlign}
	>
		{tagSelect(tags, category)}
	</FormItem>
	// </Col>
	// </div>
);

export const cssLinearGradient = ({ angle = '180deg', rgbPcts = [['#ff0000', '0%'], ['#0000ff', '100%']] }) => {
	return [
		'linear-gradient(',
		[
			angle,
			...rgbPcts.map(([color, pct]) => [color, pct].filter((i) => i).join(' ')),
		].join(','),
		')',
	].join('');
};

export const numToArray = (count) => (count ? [...Array(count).keys()] : []);

export const shuffleArr = (array1) => {
	const array = array1;
	let ctr = array.length; let temp; let
		index;
	while (ctr > 0) {
		index = Math.floor(Math.random() * ctr);
		ctr -= 1;
		temp = array[ctr];
		array[ctr] = array[index];
		array[index] = temp;
	}
	return array;
};

export const sumReducer = (prev, cur) => prev + cur;

export const getTranslatedOptions = (options, lang) => options
	?.map((option) => {
		const { value, label, fr } = option || {};
		return {
			value,
			label: lang === 'fr' ? fr : label,
		};
	});

export const momentShortenFrom = (s) => {
	return s
		?.replace?.(/^in /g, '')
		?.replace?.(/a year/g, '1y')
		?.replace?.(/ years/g, 'y')
		?.replace?.(/a month/g, '1mo')
		?.replace?.(/ months/g, 'mo')
		?.replace?.(/a day/g, '1d')
		?.replace?.(/ days/g, 'd')
		?.replace?.(/an hour/g, '1h')
		?.replace?.(/ hours/g, 'h')
		?.replace?.(/a minute/g, '1m')
		?.replace?.(/ minutes/g, 'm')
		?.replace?.(/a few seconds/g, ' <1min')
		?.replace?.(/ seconds/g, 's');
};

export const changeObjValue = (obj, key, value) => {
	const keys = isArray(key) ? key.slice() : key.split('.');
	const innerObjKey = keys.shift();
	return {
		...(obj || {}),
		...(keys.length === 0
			? { [key]: value }
			: { [innerObjKey]: changeObjValue((obj || {})[innerObjKey] || {}, keys.join('.'), value) }
		),
	};
};

export const padLeft = (str, fill = 0, char = ' ') => {
	const len = str.toString().length;
	const padAmount = fill - len;
	if (padAmount <= 0) {
		return str;
	}
	const padding = numToArray(padAmount).reduce((prev) => `${prev}${char}`, '');
	return `${padding}${str}`;
};

export const padRight = (str, fill = 0, char = ' ') => {
	const len = (str || '').toString().length;
	const padAmount = fill - len;
	if (padAmount <= 0) {
		return str;
	}
	const padding = numToArray(padAmount).reduce((prev) => `${prev}${char}`, '');
	return `${str}${padding}`;
};

export const documentId = (
	document,
	defaultValue = null,
) => document?.id || document?._id || defaultValue;

export const filterAutoCompleteItems = async (items, reducer = (item) => item?.name) => {
	return (items || [])
		.map((item) => {
			let value;
			if (typeof (documentId(item)) === 'object') {
				value = reducer(documentId(item));
			} else {
				value = documentId(item).split(':');
				value = value[1] || value[0];
			}
			return {
				value,
				label: value,
			};
		});
};

export const addSignToNum = (v) => (v >= 0 ? `+${v}` : v);

export const ArrowLeftIcon = ({ isBoldArrow = false, ...props }) => (
	isBoldArrow
		? <BiCaretLeft
			{...props}
			style={{
				marginBottom: -2,
				...props?.style,
			}}
		/>
		: <BiArrowBack
			{...props}
			style={{
				marginBottom: -2,
				...props?.style,
			}}
		/>
);

export const ArrowRightIcon = ({ isBoldArrow = false, ...props }) => (
	isBoldArrow
		? <BiCaretRight
			{...props}
			style={{
				marginBottom: -2,
				...props?.style,
			}}
		/>
		: <BiArrowBack
			{...props}
			style={{
				marginBottom: -2,
				transform: 'rotate(180deg)',
				...props?.style,
			}}
		/>
);

export const ArrowUpIcon = ({ isBoldArrow = false, ...props }) => (
	isBoldArrow
		? <BiCaretUp
			{...props}
			style={{
				marginBottom: -2,
				...props?.style,
			}}
		/>
		: <BiArrowBack
			{...props}
			style={{
				marginBottom: -2,
				transform: 'rotate(90deg)',
				...props?.style,
			}}
		/>
);

export const ArrowDownIcon = ({ isBoldArrow = false, ...props }) => (
	isBoldArrow
		? <BiCaretDown
			{...props}
			style={{
				marginBottom: -2,
				...props?.style,
			}}
		/>
		: <BiArrowBack
			{...props}
			style={{
				marginBottom: -2,
				transform: 'rotate(270deg)',
				...props?.style,
			}}
		/>
);

export const getDocumentScrollTop = () => {
	return document.documentElement.scrollTop || document.body.scrollTop;
};
export const missingMandatoryFields = (mandatoryFields, objectToCheck) => {
	return mandatoryFields?.reduce((acc, mandatoryField) => {
		const field = objectToCheck[mandatoryField];
		if (!field
			|| (isArray(field) && !field.length)) {
			return [...acc, mandatoryField];
		}
		if (isObject(field)) {
			const mandatorySubFields = JOB_FIELDS_JOB_FORM.find(
				(j) => j.mappedFrom === mandatoryField,
			)?.subFields?.filter((s) => s.mandatory)?.map((s) => s.mappedFrom);
			const missingSubfields = missingMandatoryFields(mandatorySubFields, field);
			if (missingSubfields) {
				return [...acc, ...missingSubfields];
			}
		}
		return acc;
	}, []);
};
export const regexpDiacriticInsensitive = (text) => {
	let regexpText = '';

	text
		.split('')
		.forEach((c) => {
			let diacriticFound = false;
			DIACRITIC_LISTS
				.forEach((list) => {
					if (list.includes(c)) {
						regexpText += `[${list.join(',')}]`;
						diacriticFound = true;
					}
				});
			if (!diacriticFound) regexpText += c;
		});
	return regexpText;
};

export const regexpEscapeSpecialChars = (text = '') => {
	let itemReplaced = text;
	SPECIAL_REGEXP_CHARS.forEach((char) => {
		itemReplaced = itemReplaced.replaceAll(char, `\\${char}`);
	});
	return itemReplaced;
};

export const regexpSanitize = (text) => {
	let sanitizedText = text;
	sanitizedText = regexpEscapeSpecialChars(sanitizedText);
	sanitizedText = regexpDiacriticInsensitive(sanitizedText);
	return sanitizedText;
};

export const sanitizeCompanyPath = (text) => {
	let sanitizedText = '';
	text
		.split('')
		.forEach((c) => {
			let diacriticFound = false;
			DIACRITIC_LISTS
				.forEach((list) => {
					if (list.includes(c)) {
						sanitizedText += list[0];
						diacriticFound = true;
					}
				});
			if (!diacriticFound) sanitizedText += c;
		});
	[
		...SPECIAL_REGEXP_CHARS,
		...' &"\'§!?./%£`*^¨€+=:;@#°,_'.split(''),
	]
		.forEach((c) => {
			sanitizedText = sanitizedText.split(c).join('');
		});
	return sanitizedText;
};

export const countDecimals = (value) => {
	if (isNaN(value)) return 0;
	const valueString = value?.toString?.();
	if (valueString.indexOf('e-') > -1) {
		const [base, trail] = valueString.split('e-');
		const deg = parseInt(trail, 10);
		return deg;
	}
	// count decimals for number in representation like "0.123456"
	if (Math.floor(value) === value) return 0;

	return (
		valueString
			.replaceAll(',', '.')
			.split('.')[1]
			.length
	) || 0;
};

export const uniqueStrings = (list, prop, allowNull = false) => (
	[...new Set(list.map((i) => prop(i)?.toString?.()))].filter((i) => i)
);

export const requiredRule = {
	required: true,
	message: 'This field is required',
};

export const requiredTrimRule = {
	...requiredRule,
	transform: (value) => value?.toString?.()?.trim?.(),
};

export const requiredEmailRules = [
	{
		type: 'email',
		message: 'The input is not valid email',
	},
	{
		...requiredTrimRule,
		message: 'Please input an email',
	},
];

export const isReactFragment = (variableToInspect) => {
	console.log('isReactFragment', variableToInspect?.type);
	if (variableToInspect?.type) {
		return variableToInspect?.type === 'Symbol(react.fragment)';
	}
	return variableToInspect === 'Symbol(react.fragment)';
};
