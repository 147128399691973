import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { BiChevronRight } from 'react-icons/bi';
import { Button, Space } from 'antd';
import { getCompanySelected } from '../../../../reducers/app';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import CompanyModel from '../../../../models/CompanyModel';
import { documentId } from '../../../../utils/common';
import { UMS_EMPTY_ICON_IMG, UMS_LABEL } from '../../../../constants/constant';
import surveys from '../../data/surveys';
import { getSurveyAnsweredAndUnansweredQuestions } from '../../data/shared';
import { SurveyContext } from '../providers/SurveyProvider';
import Box from '../../../app/box/Box';
import ReactMarkdown from 'react-markdown';
import ReactJson from 'react-json-view';
import { LocalDebug } from '../../../../utils/LocalDebug';
import ReactJsonDebug from '../../../app/debug/ReactJsonDebug';
// import UMSResultsExpirationBanners from '../company/UMS/results/UMSResultsExpirationBanners';

const UMSSurveyDraftFilled = ({
	survey,
	...props
}) => {
	const className = 'UMSSurveyFilled';
	const theme = useTheme();

	const { currentUser } = useAuthContext();
	const companySelected = useSelector(getCompanySelected);
	const {
		createOrUpdateSurveyDraft,
		dispatchSelectedSurveyAndReload,
		appLang, t,
	} = useContext(SurveyContext);

	const [currentUserDraft, setCurrentUserDraft] = useState(null);
	const [buttonSublabel, setButtonSublabel] = useState(null);

	const handleClick = async () => {
		if (currentUserDraft) {
			dispatchSelectedSurveyAndReload(currentUserDraft);
		} else {
			await createOrUpdateSurveyDraft({ withDispatch: true });
		}
	};

	useEffect(() => {
		setCurrentUserDraft(new CompanyModel(companySelected)
			.getUMSUserSurvey({ user: currentUser }));
	}, [companySelected, currentUser]);

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'currentUserDraft, appLang' }, { currentUserDraft, appLang });
		const surveyConf = surveys?.getSurveyDataOption?.(currentUserDraft?.survey);
		const { unanswered } = getSurveyAnsweredAndUnansweredQuestions({
			surveyConf,
			answers: currentUserDraft?.answers,
		});

		if (unanswered?.length > 0) {
			setButtonSublabel(
				<div>
					{t(`survey-filled.${unanswered?.length < 10 ? 'only' : 'still'}`)}
					{t('survey-filled.answers-left', { count: unanswered?.length, plural: unanswered?.length > 1 ? 's' : '' })}
				</div>,
			);
		} else {
			setButtonSublabel(t('survey-filled.all-answered'));
		}
	}, [currentUserDraft, appLang]);

	return (
		<>
			<div
				style={{
					maxWidth: 860,
					width: '100%',
					margin: '0 auto',
					fontSize: 16,
					display: 'grid',
					height: '100vh',
					gridTemplateColumns: '1fr',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						boxShadow: '0px 15px 60px -12px rgba(0,0,0,.3)',
						padding: 30,
						borderRadius: 12,
					}}
				>
					<Space
						wrap={false}
						align={'top'}
						size={30}
						style={{
							width: '100%',
							// marginBottom: 30,
						}}
					>
						<div style={{ fontSize: 16 }}>
							<h1>{t('survey-filled.title')}</h1>
							<ReactJsonDebug
								src={survey || {}}
								collapsed={0}
							/>
						</div>
					</Space>

				</div>
			</div>
		</>
	);
};

export default UMSSurveyDraftFilled;
