import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { LocalDebug } from '../../../utils/LocalDebug';
import RouteWrapper from '../../../RouteWrapper';
import SurveyContainer from './SurveyContainer';
import { PATHS } from '../../../constants/constant';
import surveys from '../data/surveys';
import {
	buildSectionQuestionPath,
	computeSurveyScores,
	getSurveyAnsweredAndUnansweredQuestions,
} from '../data/shared';
import CompanyService from '../../../services/company';
import { documentId } from '../../../utils/common';
import { getCompanyGenderScoreSurveyDraftSelected, getCompanySelected } from '../../../reducers/app';
import { COMPANY_GENDERSCORE_SURVEY_DRAFT_SELECTED } from '../../../actions/types';
import { loadCompany } from '../../../actions/company';
import { NotificationContext } from '../../app/notification/NotificationProvider';
import { GENDERSCORE_SURVEY_50PLUS_V2_VALUE } from '../../../constants/property/genderscore-survey';
import {
	EVENT, EventPushContext, buildEventCategory, buildEventContext, pushClick,
} from '../../../contexts/EventPushProvider';

export const SurveyContext = React.createContext();

const SurveyProvider = ({
	baseRoutePath = PATHS.GENDERSCORE_SURVEY(),
	children,
}) => {
	const className = 'SurveyProvider';

	const dispatch = useDispatch();
	const companySelected = useSelector(getCompanySelected);
	const draftSelected = useSelector(getCompanyGenderScoreSurveyDraftSelected);
	const { notifySuccess } = useContext(NotificationContext);
	const { userIdData } = useContext(EventPushContext);

	const [eventContext, setEventContext] = useState({});
	const [answers, setAnswers] = useState();
	const [surveyScores, setSurveyScores] = useState();
	const [surveyDraft, setSurveyDraft] = useState();
	const [surveyValue, setSurveyValue] = useState();
	const [surveyConf, setSurveyConf] = useState();
	const [buildSurveyRoutes, setBuildSurveyRoutes] = useState(null);
	const [openLeaveModal, setOpenLeaveModal] = useState(false);
	const [unansweredQuestions, setUnansweredQuestions] = useState([]);
	const [isSurveySubmissionPending, setIsSurveySubmissionPending] = useState(false);

	useEffect(() => {
		if (!surveyConf) return;

		const { answered, unanswered } = getSurveyAnsweredAndUnansweredQuestions({
			surveyConf, answers,
		});
		setUnansweredQuestions(unanswered);
		setSurveyScores(computeSurveyScores({ surveyConf, answers }));

		setEventContext({
			...buildEventCategory(EVENT.CATEGORY.GENDERSCORE_SURVEY),
			...buildEventContext({
				survey: surveyConf?.value,
				answered: answered?.length,
				unanswered: unanswered?.length,
			}),
			userIdData,
		});
	}, [surveyConf, answers]);

	useEffect(() => {
		const surveyOption = surveys?.getSurveyDataOption?.(surveyDraft?.survey);
		LocalDebug.logNull(
			{ className, effects: 'surveyDraft' },
			{ surveyDraft, surveyOption },
		);
		setSurveyValue(surveyOption?.value);
		setSurveyConf(surveyOption);
	}, [surveyDraft]);

	const routeBuilder = ({
		SurveySectionComponent,
		SurveyQuestionComponent,
		SurveyScoringDetailsComponent,
		SurveyReviewComponent,
	}) => (
		<>
			<Route
				path={baseRoutePath}
				element={<SurveyContainer />}
			>
				<Route
					path={buildSectionQuestionPath({ section: ':section' })}
					element={<RouteWrapper component={SurveySectionComponent} />}
				/>
				<Route
					path={buildSectionQuestionPath({ section: ':section', question: ':question' })}
					element={<RouteWrapper
						component={SurveyQuestionComponent}
						info={buildSectionQuestionPath({ section: ':section', question: ':question' })}
					/>}
				/>
				<Route
					path={'scoring'}
					element={<RouteWrapper component={SurveyScoringDetailsComponent} />}
				/>
				<Route
					path={'review'}
					element={<RouteWrapper component={SurveyReviewComponent} />}
				/>
				<Route
					path='*'
					key={'*'}
					element={<RouteWrapper component={SurveySectionComponent} />}
				/>
			</Route>
		</>
	);

	useEffect(() => {
		// LocalDebug.logUseEffect({ className, effects: '' }, 'setBuildSurveyRoutes');
		setBuildSurveyRoutes((p) => {
			return routeBuilder;
		});
	}, []);

	const saveAnswers = async ({
		draft,
		answers: newAnswers,
		withSlack,
	}) => {
		const id = documentId(draft || draftSelected);
		const genderScore = computeSurveyScores({
			surveyConf,
			answers: {
				...answers || {},
				...newAnswers || {},
			},
		});
		LocalDebug.logInfo({ className, method: 'saveAnswers' }, { id, answers: newAnswers, genderScore });
		if (!id) return;

		const result = await CompanyService
			.saveGenderScoreSurveyDraftAnswers({
				id,
				answers: newAnswers,
				genderScore,
				withSlack,
			});

		const answersToSet = result?.data?.answers || {};

		setAnswers(answersToSet);

		await dispatchSelectSurveyDraft({ ...surveyDraft, answers: answersToSet });

		return answersToSet;
	};

	const notifySubmitSuccess = () => {
		notifySuccess({
			key: className,
			description: <>
				<p>
					<b>Thank you</b> for completing the survey!
				</p>
				<p>
					Discover in this page your <b>personalized GenderScore results:</b> your overall score,
					how you performed for each pillar, and tailored recommendations
					to progress in your DEI initiatives.
				</p>
			</>,
			duration: 0,
			placement: 'bottomRight',
		});
	};

	const submitSurvey = async ({
		draft,
		data,
		navigate,
	}) => {
		const id = documentId(draft || draftSelected);
		LocalDebug.logInfo({ className, method: 'submitSurvey' }, { id, answers });
		if (!id) return;

		const result = await CompanyService
			.submitGenderScoreSurveyDraft({ id, ...data });
		setIsSurveySubmissionPending(true);

		await dispatchSelectSurveyDraft(null);

		setTimeout(() => {
			navigate('/genderscore');
		}, 4500);

		setTimeout(async () => {
			setIsSurveySubmissionPending(false);
			notifySubmitSuccess();
			LocalDebug.logInfo({ className, method: 'submitSurvey' }, { result });
		}, 5000);
	};

	const dispatchSelectSurveyDraft = async (survey) => {
		LocalDebug.logInfo({ className, method: 'dispatchSelectSurveyDraft' }, { survey });
		await dispatch({
			type: COMPANY_GENDERSCORE_SURVEY_DRAFT_SELECTED,
			companyGenderScoreSurveyDraftSelected: (
				survey && survey?.isActive !== false
					? survey
					: null
			),
		});
		await dispatch(
			loadCompany(
				documentId(companySelected),
				{
					withGenderScoreAction: true,
					withGenderScoreSurveys: true,
					withUMSResults: true,
					withUMSSurveys: true,
				},
			),
		);
	};

	const createOrUpdateSurveyDraft = async ({ data, withDispatch }) => {
		LocalDebug.logInfo({ className, method: 'createOrUpdateSurveyDraft' }, { data, withDispatch });
		const { data: survey } = await CompanyService
			.createOrUpdateGenderScoreSurveyDraft({
				companyId: documentId(companySelected),
				survey: GENDERSCORE_SURVEY_50PLUS_V2_VALUE,
				id: documentId(draftSelected),
				...data || {},
			});
		if (withDispatch) {
			await dispatchSelectSurveyDraft(survey);
		}
	};

	const value = {
		baseRoutePath,
		eventContext,
		surveyDraft,
		setSurveyDraft,
		surveyValue,
		surveyConf,
		answers,
		setAnswers,
		saveAnswers,
		buildSurveyRoutes,
		routeBuilder,
		createOrUpdateSurveyDraft,
		dispatchSelectSurveyDraft,
		submitSurvey,
		surveyScores,
		openLeaveModal,
		setOpenLeaveModal,
		unansweredQuestions,
		setUnansweredQuestions,
		isSurveySubmissionPending,
		setIsSurveySubmissionPending,
	};

	return (
		<SurveyContext.Provider
			value={value}
		>
			{children}
		</SurveyContext.Provider>
	);
};

export default SurveyProvider;
