import { useTheme } from '@emotion/react';
import CompanyActionModel from '../../../../models/CompanyActionModel';
import ModalContainerBase from '../../../../contexts/modals/ModalContainerBase';
import CompanyActionModalLeftSide from './CompanyActionModalLeftSide';
import CompanyActionModalRightSide from './CompanyActionModalRightSide';

const CompanyActionModal = ({
	open,
	setOpen,
	action,
	view,
	index,
	withAddButton,
	onAddAction,
	onEditAction,
	onDeleteAction,
	isSendActionPending,
	setIsSendActionPending,
	...props
}) => {
	const className = 'CompanyActionModal';

	const theme = useTheme();

	action = action instanceof CompanyActionModel
		? action
		: new CompanyActionModel(action);

	return (
		<ModalContainerBase
			className='action-modal'
			open={open}
			setOpen={setOpen}
			width={1200}
			style={{
				padding: '0 !important',
				position: 'relative',
				marginTop: 30,
				marginBottom: 30,
				// background: 'red',
				maxHeight: 'calc(100vh - 60px)',
			}}
		>
			<div
				style={{
					margin: '-20px -24px',
				}}
			>
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '400px 1fr',
						overflow: 'scroll',
						width: '100%',
						maxHeight: 'calc(100vh - 60px - 0 * 72px)',
						height: 'calc(100vh - 60px - 0 * 72px)',
					}}
				>
					<CompanyActionModalLeftSide
						action={action}
						withAddButton={withAddButton}
						onAddAction={onAddAction}
						onEditAction={onEditAction}
						onDeleteAction={onDeleteAction}
						isSendActionPending={isSendActionPending}
						setIsSendActionPending={setIsSendActionPending}
					/>

					<CompanyActionModalRightSide
						action={action}
						withAddButton={withAddButton}
						onAddAction={onAddAction}
						onEditAction={onEditAction}
						onDeleteAction={onDeleteAction}
						isSendActionPending={isSendActionPending}
						setIsSendActionPending={setIsSendActionPending}
					/>

				</div>

			</div>
		</ModalContainerBase>
	);
};

export default CompanyActionModal;
