import { css } from '@emotion/css';
import moment from 'moment';
import {
	Badge, Button, message, Popover, Space, Switch, Tag as AntTag, Tooltip, Typography,
} from 'antd';
import * as dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import Link from '../Link';
import CheckboxTable from './CheckboxTable';
import DatePreview from './DatePreview';
import CompanyLogo from '../../company/CompanyLogo';
import JobStub from '../../jobs/JobStub';
import {
	BiEnvelope, BiLink, BiMessageError, BiRefresh,
} from 'react-icons/bi';
import {
	FaBan,
	FaCheck,
	FaCog, FaDatabase, FaEmber,
	FaEnvelope,
	FaFileAlt,
	FaHourglass,
	FaHourglassHalf,
	FaLinkedin,
	FaPen,
	FaPlusSquare, FaSyncAlt,
	FaTags,
} from 'react-icons/fa';
import UserStub from '../../user/stub/UserStub';
import Tag from '../../staff/tags/Tag';
import CompanyStub from '../../company/CompanyStub';
import {
	ENGLISH_LANG,
	FRENCH_LANG,
	getAclRoleTag,
	JOB_FORM_MANDATORY_FIELDS,
	JOB_TAG_CATEGORIES,
	JOB_TAG_CATEGORIES_STAFF,
	renderStageTag,
	renderStatusTag,
	renderStepTag, SHORTLISTS_ICON,
	SHORTLISTS_MENU,
	SOURCINGS_COL_LABEL,
	TAG_ADMIN_ICON,
	TAG_STAFF_ICON,
	USER_TAG_CAREER_HARD_SKILL,
	USER_TAG_CATEGORIES,
} from '../../../constants/constant';
import UserAvatar from '../../user/widgets/UserAvatar';
import config from '../../../config/config';
import CompanyGenderScoreRingScore from '../../company/genderscore/CompanyGenderScoreRingScore';
import {
	addSignToNum,
	displayHtml,
	documentId,
	findAtsProvider,
	getMaxStageSourcing,
	getMaxStepSourcing,
	getSourcingStageByValue,
	getSourcingStepByValue, isBoolean, isFunction, missingMandatoryFields, mixColors, momentShortenFrom,
	sumReducer,
} from '../../../utils/common';
import { localDebug } from '../../../utils/LocalDebug';
import SourcingStub from '../../sourcings/SourcingStub';
import UserJobRoles from '../../user/widgets/UserJobRoles';
import UserJobRemote from '../../user/widgets/UserJobRemote';
import UserHardSkills from '../../user/widgets/UserHardSkills';
import UserJobLevel from '../../user/widgets/UserJobLevel';
import UserDEICriteria from '../../user/widgets/UserDEICriteria';
import ReactJson from 'react-json-view';
import SourcingStubLite from '../../sourcings/SourcingStubLite';
import mainTheme from '../../../theme';
import SourcingATSStageTag from '../../sourcings/SourcingATSStageTag';
import SourcingArchiveReasonTags from '../../sourcings/SourcingArchiveReasonTags';
import SourcingATSPipelineTag from '../../sourcings/SourcingATSPipelineTag';
import ActivityPreview from './ActivityPreview';
import JobModel from '../../../models/JobModel';
import AtsLogo from '../../ats/AtsLogo';
import CompanyAccessAccessTypeTag from '../../company/access/CompanyAccessAccessTypeTag';
import InlineNavLink from '../button/InlineNavLink';
import CompanyModel from '../../../models/CompanyModel';
import CompanyMetaStatus from '../../seo/CompanyMetaStatus';
import CompanyService from '../../../services/company';
import CompanyPlanTag from '../../company/CompanyPlanTag';
import UserEmploymentVisa from '../../user/widgets/UserEmploymentVisa';
import UserStubLocation from '../../user/stub/UserStubLocation';
import UserLanguage from '../../user/widgets/UserLanguage';
import UserAvatarList from '../../user/widgets/UserAvatarList';
import ObjectIdDisplayer from '../ObjectIdDisplayer';
import ConfigureCompanySettingsMenu from '../../../menu/configure/ConfigureCompanySettingsMenu';
import GenderhireCandidatesAppliedMenu from '../../../menu/genderhire/GenderhireCandidatesAppliedMenu';
import GenderhireCandidatesSourcedMenu, { GenderhireCandidatesSourcedIcon } from '../../../menu/genderhire/GenderhireCandidatesSourcedMenu';
import {
	ExportOutlined, EyeInvisibleOutlined, EyeOutlined, LinkOutlined,
} from '@ant-design/icons';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const columnFixedWidth = (size) => ({
	className: css({ width: size, minWidth: size, maxWidth: size }),
});
const columnMinWidth = (size) => ({
	className: css({ width: size, minWidth: size }),
});
const columnMinMaxWidth = (minSize, maxSize) => ({
	className: css({ width: minSize, minWidth: minSize, maxWidth: maxSize }),
});

const fieldColumn = (theme, props = {}) => ({
	title: 'Field',
	dataIndex: 'field',
	key: 'field',
	isFilter: true,
	isAutoComplete: true,
	sorter: true,
	render: (value) => value,
	...columnFixedWidth(props?.colWidth || 120),
	...props,
});

const messagesToCountColumn = (theme, props = {}) => docsCountColumn(theme, {
	title: 'Messages',
	dataIndex: 'messagesToCount',
	sorter: false,
	style: { minWidth: 40 },
	icon: <FaEnvelope size={12} style={{ marginBottom: -1 }} />,
	...columnMinWidth(props?.colWidth || 76),
	removeInColumnFilter: true,
	...props,
});

const sourcingsMaxStepColumn = (theme, props = {}) => ({
	title: <span>Highest<br />sourcing step</span>,
	dataIndex: 'steps',
	removeInColumnFilter: true,
	align: 'center',
	render: (value, row) => {
		const user = (props?.value && props?.value(value, row)) || row;
		if (!user?.sourcings) return null;

		const { steps } = row;
		// getMax step
		const stepMax = getMaxStepSourcing(steps);
		const sourcingStep = getSourcingStepByValue(stepMax);

		return stepMax && (
			<Link {...props?.onClick ? { onClick: () => props.onClick(value, user) } : {}}>
				<AntTag
					color={sourcingStep.color}
					style={{ fontWeight: 'bold' }}
				>
					{sourcingStep.label}
				</AntTag>
			</Link>
		);
	},
	...columnFixedWidth(props?.colWidth || 140),
});

const sourcingsMaxStageColumn = (theme, props = {}) => ({
	title: <span>Highest<br />sourcing stage</span>,
	dataIndex: 'stages',
	removeInColumnFilter: true,
	align: 'center',
	render: (value, row) => {
		const user = (props?.value && props?.value(value, row)) || row;
		if (!user?.stages?.length) return null;

		const { stages } = user;
		const stageMax = getMaxStageSourcing(stages);
		const sourcingStage = getSourcingStageByValue(stageMax);

		return stageMax && (
			<Link {...props?.onClick ? { onClick: () => props.onClick(value, user) } : {}}>
				<AntTag
					color={sourcingStage?.color}
					style={{ fontWeight: 'bold' }}
				>
					{sourcingStage?.label}
				</AntTag>
			</Link>
		);
	},
	...columnFixedWidth(props?.colWidth || 140),
});

const jobStubColumn = (theme, props = {}) => ({
	title: 'Job',
	dataIndex: 'title',
	isFilter: false,
	isAutoComplete: false,
	sorter: true,
	render: (value, row) => {
		const { value: propValue, notProvided, ...restProps } = props || {};
		const job = propValue?.(value, row) || row?.job || row || value;
		return job
			? <JobStub
				job={job}
				withDropdown={true}
				onSelectJob={props?.onSelectJob}
				onEditJob={props?.onEditJob}
				{...[true, false].includes(props?.withOpenLink) ? { withOpenLink: props.withOpenLink } : {}}
				{...[true, false].includes(props?.withCompany) ? { withCompany: props.withCompany } : {}}
			/>
			: notProvided?.(value, row);
	},
	...columnFixedWidth(props?.colWidth || 300),
	...props,
});

const companyLogoColumn = (theme, props = {}) => ({
	title: 'Company',
	dataIndex: 'company',
	key: 'company',
	// isFilter: true,
	// isAutoComplete: true,
	align: 'center',
	// removeInColumnFilter: true,
	render: (value, row) => {
		const {
			value: valueFunc, size, withTooltip, withPlaceholder,
		} = props || {};
		const rest = { size, withTooltip, withPlaceholder };
		const company = valueFunc?.(value, row) || row.company || value;
		return <CompanyLogo company={company} {...rest} />;
	},
	...columnFixedWidth(props?.colWidth || 60),
	...props,
});

const companyGenderScoreColumn = (theme, props = {}) => ({
	title: <>Gender<br />score</>,
	dataIndex: ['genderScore', 'globalScore'],
	align: 'center',
	sorter: true,
	render: (value, row) => (row?.genderScore && Object.keys(row?.genderScore).length > 0
		&& <CompanyGenderScoreRingScore size={40} company={row} />),
	...columnFixedWidth(props?.colWidth || 80),
	...props,
});

const companyStubColumn = (theme, props = {}) => ({
	title: 'Company',
	dataIndex: 'company',
	key: 'company',
	sorter: true,
	isFilter: !true,
	// isAutoComplete: true,
	render: (value, row) => (<CompanyStub company={value} />),
	...columnFixedWidth(props?.colWidth || 200),
	...props,
});

const companyPlanColumn = (theme, props = {}) => ({
	title: 'Company Plan',
	dataIndex: 'companyPlan.planValue',
	key: 'companyPlan.planValue',
	sorter: true,
	isFilter: false,
	render: (value, row) => (<CompanyPlanTag company={row} style={{
		fontSize: 10,
	}}/>),
	...columnFixedWidth(props?.colWidth || 150),
	...props,
});

const jobLocationColumn = (theme, props = {}) => ({
	title: 'Location',
	dataIndex: 'location',
	key: 'location',
	isAutoComplete: false,
	isFilter: false,
	sorter: true,
	...columnFixedWidth(props?.colWidth || 160),
	...props,
});

const jobDepartmentColumn = (theme, props = {}) => ({
	title: 'Department',
	dataIndex: 'department',
	key: 'department',
	isAutoComplete: false,
	isFilter: false,
	sorter: true,
	...columnFixedWidth(props?.colWidth || 140),
	...props,
});

const jobTeamColumn = (theme, props = {}) => ({
	title: 'Team',
	dataIndex: 'team',
	key: 'team',
	isAutoComplete: false,
	isFilter: false,
	sorter: true,
	...columnFixedWidth(props?.colWidth || 140),
	...props,
});

const booleanColumn = (theme, props = {}) => ({
	title: 'Boolean',
	dataIndex: 'boolean',
	key: 'boolean',
	removeInColumnFilter: true,
	align: 'center',
	render: (value, row) => (
		<CheckboxTable isChecked={props && props.value ? props.value(value, row) : value === true} />),
	...columnFixedWidth(props?.colWidth || 80),
	...props,
});

const htmlColumn = (theme, props = {}) => ({
	title: 'HTML',
	dataIndex: 'html',
	sorter: false,
	isAutoComplete: false,
	isFilter: false,
	removeInColumnFilter: true,
	render: (value) => displayHtml(value, props?.paragraphsAsList),
	...columnMinWidth(props?.colWidth || 120),
	...props,
});

const toggleColumn = (theme, toggleCallback, props = {}) => ({
	title: 'Toggle',
	dataIndex: 'toggle',
	removeInColumnFilter: true,
	align: 'center',
	render: (value, row) => {
		return (
			<Space direction='horizontal' size={4}>
				<Switch
					className={props?.switchClassName || ''}
					checkedChildren={props?.checkedChildren || <FaCheck style={{ marginBottom: -2 }} />}
					unCheckedChildren={props?.unCheckedChildren || <FaBan style={{ marginBottom: -2 }} />}
					onClick={() => toggleCallback(row)}
					checked={value} />
			</Space>
		);
	},
	...columnFixedWidth(props?.colWidth || 80),
	...props,
});

const jobHasContentColumn = (theme, props = {}) => booleanColumn(theme, {
	title: 'Content?',
	dataIndex: 'content',
	key: 'content',
	sorter: true,
	// render: (value, row) => row.hasContent === true ? 'true' : 'false',
	render: (value, row) => (row.hasContent || value
		? <CheckboxTable isChecked={true} />
		: <Tooltip title='Content not yet fetched'><CheckboxTable isChecked={false} /></Tooltip>),
	// render: (value) => (<CheckboxTable isChecked={value && value !== ''}/>),
	...columnFixedWidth(props?.colWidth || 120),
	...props,
});

const jobIsPublishedColumn = (theme, props = {}) => booleanColumn(theme, {
	title: 'Published?',
	dataIndex: 'isPublished',
	key: 'isPublished',
	sorter: (a, b) => (a.isPublished > b.isPublished ? 1 : -1),
	...columnFixedWidth(props?.colWidth || 120),
	...props,
});

const togglePublishedColumn = (theme, toggleCallback, props = {}) => toggleColumn(theme, toggleCallback, {
	title: 'Published',
	dataIndex: 'isPublished',
	key: 'isPublished',
	switchClassName: 'ant-switch-large',
	checkedChildren: <EyeOutlined style={{ fontSize: 18, marginTop: 3, marginLeft: -4 }} />,
	unCheckedChildren: <EyeInvisibleOutlined style={{ fontSize: 18, marginTop: 1, marginRight: -4 }} />,
	sorter: (a, b) => (a.isPublished > b.isPublished ? 1 : -1),
	...columnFixedWidth(props?.colWidth || 120),
	...props,
});

const toggleIsActiveColumn = (theme, toggleCallback, props = {}) => toggleColumn(theme, toggleCallback, {
	title: 'Active?',
	dataIndex: 'isActive',
	key: 'isActive',
	sorter: (a, b) => (a.isActive > b.isActive ? 1 : -1),
	...columnFixedWidth(props?.colWidth || 120),
	...props,
});

const jobTogglePublishedColumn = (
	theme,
	toggleCallback,
	props = {},
) => (
	togglePublishedColumn(
		theme,
		toggleCallback,
		{
			sorter: (a, b) => (
				new JobModel(a).isJobPublished
				> new JobModel(b).isJobPublished ? 1 : -1
			),
			checkedChildren: <EyeOutlined />,
			unCheckedChildren: <EyeInvisibleOutlined />,
			render: (value, row) => {
				const jobModel = new JobModel(row);

				const toggle = (
					<Space direction='vertical' size={0}>
						{togglePublishedColumn(theme, toggleCallback, props).render(value, row)}
					</Space>
				);

				if (!jobModel?.areJobsCompanyJobsPublished) {
					return <Tooltip
						title={<>
						Jobs are <b>unpublished</b>
							{jobModel.isPublished && moment(jobModel?.companyJobsPublishedAt).isAfter(moment())
								? <>
									&nbsp;but <b>programmed</b> {
										moment(jobModel?.companyJobsPublishedAt).fromNow()
									}&nbsp;
								({moment(jobModel?.companyJobsPublishedAt).format('MMM Do, HH:mm')})</>
								: <></>
							}
						.<br />
						Modify jobs publication from your
							&nbsp;{new ConfigureCompanySettingsMenu().getInlineNavLink()}
						</>}>
						<div style={{ opacity: 0.5 }}>
							{toggle}
						</div>
					</Tooltip>;
				}

				if (jobModel?.isPublished) {
					return (
						<Tooltip
							title={<>
								Job offer is <b>published</b>
							</>}
						>
							{toggle}
						</Tooltip>
					);
				}

				const missingFields = missingMandatoryFields(JOB_FORM_MANDATORY_FIELDS, jobModel);
				if (jobModel?.isCustomize && missingFields?.length) {
					return (
						<Tooltip
							title={<>Job offer <b>cannot be published</b>: missing mandatory data. Edit the job offer to publish it</>}
						>
							{toggle}
						</Tooltip>
					);
				}
				return (
					<Tooltip
						title={<>Job offer is <b>not published</b></>}
					>
						{toggle}
					</Tooltip>
				);
			},
			...columnFixedWidth(props?.colWidth || 86),
			...props,
		},
	)
);

const jobPageLinkColumn = (theme, props = {}) => fieldColumn(theme, {
	title: 'Page',
	dataIndex: 'page',
	key: 'page',
	isFilter: false,
	isAutoComplete: false,
	removeInColumnFilter: true,
	sorter: false,
	align: 'center',
	render: (value, row) => (row.slug && <Tooltip title={`${config.appRoot}/job/${row?.slug}`}>
		<Button type='link'
			onClick={() => { window.open(`${config.appRoot}/job/${row?.slug}`, '_blank'); }}
			icon={<ExportOutlined />}
		/>
	</Tooltip>),
	...columnFixedWidth(props?.colWidth || 50),
	...props,
});

const jobExternalUriColumn = (theme, props = {}) => fieldColumn(theme, {
	title: 'Url',
	dataIndex: 'uri',
	key: 'uri',
	removeInColumnFilter: true,
	isFilter: false,
	isAutoComplete: false,
	sorter: false,
	align: 'center',
	render: (value, row) => (value && <Tooltip title={value}>
		<Button type='link'
			onClick={() => { window.open(value, '_blank'); }}
			icon={<ExportOutlined />}
		/>
	</Tooltip>),
	...columnFixedWidth(props?.colWidth || 50),
	...props,
});

const jobExternalApplyUriColumn = (theme, props = {}) => fieldColumn(theme, {
	title: 'Apply',
	dataIndex: 'applyUri',
	key: 'applyUri',
	removeInColumnFilter: true,
	isFilter: false,
	isAutoComplete: false,
	sorter: false,
	align: 'center',
	render: (value, row) => (value && <Tooltip title={value}>
		<Button type='link'
			onClick={() => { window.open(value, '_blank'); }}
			icon={<BiLink />}
		/>
	</Tooltip>),
	...columnFixedWidth(props?.colWidth || 50),
	...props,
});

const jobExternalLinkColumn = (theme, props = {}) => fieldColumn(theme, {
	title: 'Link',
	dataIndex: 'applyUri',
	key: 'applyUri',
	removeInColumnFilter: true,
	isFilter: false,
	isAutoComplete: false,
	sorter: false,
	align: 'center',
	render: (value, row) => ((value || value?.uri) && <Tooltip title={value || value?.uri}>
		<Button type='link'
			onClick={() => { window.open(value || value?.uri, '_blank'); }}
			icon={<BiLink />}
		/>
	</Tooltip>),
	...columnFixedWidth(props?.colWidth || 50),
	...props,
});

const companyAccessColumn = (theme, props = {}) => ({
	title: 'Access',
	dataIndex: 'companyAccess',
	sorter: false,
	removeInColumnFilter: true,
	render: (value, row) => {
		const access = props?.value?.(row) || value;
		// return <CompanyAccessStub companyAccess={access} />;
		return (<Space direction='vertical' size={-4}>
			<div style={{ margin: '-2px 0' }}><AtsLogo ats={access?.ats} size={12} />
				&nbsp;<b>{findAtsProvider(access?.ats)?.label}</b></div>
			<div style={{ margin: '-2px 0' }}><CompanyAccessAccessTypeTag companyAccess={access} /></div>
			{props?.withValue !== false && <div style={{ margin: '0 0' }}>
				<code style={{
					background: 'none',
					padding: 0,
					fontSize: 10,
					maxWidth: 120,
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
				}}>
					{access?.value}
				</code>
			</div>}
		</Space>);
	},
	...columnFixedWidth(props?.colWidth || 150),
});

const dateColumn = (theme, props = {}) => ({
	title: 'Date',
	dataIndex: 'date',
	sorter: true,
	removeInColumnFilter: true,
	align: 'center',
	render: (v, row) => <DatePreview
		date={props?.value && isFunction(props?.value) ? props?.value(v, row) : v}
		dateFormat='DD/MM/YY'
		withFromNow={false}
		withToolip={true}
		styles={{ date: { fontSize: 12, color: theme.color.darkgrey } }}
		{...props}
	/>,
	...columnFixedWidth(props?.colWidth || 80),
	...props,
});

const delayColumn = (theme, props = {}) => ({
	title: 'Delay',
	dataIndex: 'date',
	sorter: true,
	removeInColumnFilter: true,
	align: 'center',
	render: (v, row) => {
		const val = props?.value && isFunction(props?.value) ? props?.value(v, row) : v;
		return (
			<span
				styles={{ date: { fontSize: 12, color: theme.color.darkgrey } }}
				{...props}
			>
				{val ? moment(val).fromNow() : 'N/A'}
			</span>
		);
	},
	...columnFixedWidth(props?.colWidth || 80),
	...props,
});

const activityAtColumn = (theme, props = {}) => ({
	title: 'Activity',
	dataIndex: 'createdAt',
	key: 'createdAt',
	align: 'center',
	removeInColumnFilter: true,
	render: (value, row) => <ActivityPreview
		createdAt={row.createdAt}
		updatedAt={row.updatedAt}
		{...props}
	/>,
	...TableColumns.columnFixedWidth(props?.colWidth || 60),
	...props,
});

const createdAtColumn = (theme, props = {}) => dateColumn(theme, {
	title: 'Created',
	dataIndex: 'createdAt',
	key: 'createdAt',
	...props,
});

const updatedAtColumn = (theme, props = {}) => dateColumn(theme, {
	title: 'Updated',
	dataIndex: 'updatedAt',
	key: 'updatedAt',
	...props,
});
const churnedAtColumn = (theme, props = {}) => dateColumn(theme, {
	title: 'Churned',
	dataIndex: 'churnedAt',
	key: 'churnedAt',
	...props,
});

const sourcingStubColumn = (theme, props = {}, onUserSelected = null) => fieldColumn(theme, {
	title: 'User',
	dataIndex: 'user',
	isFilter: false,
	render: (value, row) => <SourcingStub
		sourcing={(props?.value && props.value(value, row)) || row}
		activeTab={props?.activeTab}
		withDropdown={props.withDropdown !== false}
	 />,
	...columnMinWidth(props?.colWidth || 300),
	...props,
});

const sourcingStubLiteColumn = (theme, props = {}, onUserSelected = null) => fieldColumn(theme, {
	title: 'Talent & job',
	dataIndex: 'user',
	isFilter: false,
	customOptions: {
		searchFields: [
			{ label: 'Candidate', value: 'user' },
			{ label: 'Job', value: 'job' },
		],
	},
	render: (value, row) => <SourcingStubLite
		sourcing={(props?.value && props.value(value, row)) || row}
		activeTab={props?.activeTab}
		withDropdown={props.withDropdown !== false}
		withUserPreview={props.withUserPreview !== false}
		{...isBoolean(props?.withUserPreviewButtons) && { withUserPreviewButtons: props.withUserPreviewButtons }}
		onSelectJob={props?.onSelectJob}
	 />,
	...columnMinWidth(props?.colWidth || 320),
	...props,
});

const sourcingStubLiteColumn2 = (theme, props = {}, onUserSelected = null) => fieldColumn(theme, {
	title: 'Talent & job',
	dataIndex: 'user',
	isFilter: false,
	render: (value, row) => <Space direction='vertical'>
		<SourcingStubLite
			sourcing={(props?.value && props.value(value, row)) || row}
			activeTab={props?.activeTab}
			withDropdown={props.withDropdown !== false}
			withUserPreview={props.withUserPreview !== false}
			{...isBoolean(props?.withUserPreviewButtons) && { withUserPreviewButtons: props.withUserPreviewButtons }}
			onSelectJob={props?.onSelectJob}
		 />
		<Space direction='horizontal'>
			<SourcingATSPipelineTag sourcing={row} />
			<SourcingATSStageTag sourcing={row} />
		</Space>
		<SourcingArchiveReasonTags sourcing={row} />
	</Space>,
	customOptions: {
		searchFields: [
			{ label: 'Candidate', value: 'user' },
			{ label: 'Job', value: 'job' },
		],
	},
	...columnMinWidth(props?.colWidth || 320),
	...props,
});
const desiredLocationColumn = (theme, props = {}) => fieldColumn(theme, {
	title: 'Desired job location',
	dataIndex: 'tagTree',
	isFilter: false,
	sorter: false,
	render: (value, row) => {
		return <UserStubLocation
			user={row}
			withDesiredLocation={true}
		/>;
	},
	...columnMinWidth(props?.colWidth || 200),
	...props,
});

const userStubColumn = (theme, props = {}, onUserSelected = null) => fieldColumn(theme, {
	title: 'User',
	dataIndex: 'user',
	isFilter: false,
	render: (value, row) => {
		const {
			customOptions, dataIndex, value: valueProps, ...otherProps
		} = props;

		const {
			withProfession, withLocation, withDEICriteria, withEmail,
			withBox, withDropdown, withPreview,
			direction, avatarSize, activeTab,
			withIsNew, withIsUnviewed, withTooltip,
			withIsNewlyOnboarded,
			withCoachedTag,
			withResumeTag,
			onClick, onRefresh,
			style,
		} = otherProps;

		const user = (props?.value && props.value(value, row)) || value;
		if (!user) return null;

		// localDebug('userStubColumn', { onRefresh })
		let component = <UserStub
			user={user}
			activeTab={props?.activeTab}
			{...onClick
				? { onClick: () => onClick(value, row) }
				: {}
			}
			{...{
				withProfession,
				withLocation,
				withDEICriteria,
				withEmail,
				withBox,
				withDropdown,
				withPreview,
				direction,
				avatarSize,
				activeTab,
				withIsNew,
				withIsNewlyOnboarded,
				withIsUnviewed,
				withTooltip,
				onRefresh,
				withCoachedTag,
				withResumeTag,
				style,
			}}
		/>;

		component = onClick
			? <Link onClick={() => props.onClick(value, row)} cursor='pointer'>
				{component}
			</Link>
			: component;

		return component;
	},
	...columnMinWidth(props?.colWidth || 300),
	...props,
});

const userAvatarColumn = (theme, props = {}) => fieldColumn(theme, {
	title: 'User',
	dataIndex: 'user',
	sorter: false,
	align: 'center',
	isFilter: false,
	render: (value, row) => {
		const user = (props?.value && props?.value(value, row)) || value;
		return user && <UserAvatar user={user} />;
	},
	...columnMinWidth(props?.colWidth || 70),
	...props,
});

const userAvatarListColumn = (theme, props = {}) => fieldColumn(theme, {
	title: 'Users',
	sorter: false,
	align: 'center',
	isFilter: false,
	render: (value, row) => {
		const users = (props?.value && props?.value(value, row)) || value;
		return users && <UserAvatarList users={users} {...props}/>;
	},
	...columnMinWidth(props?.colWidth || 70),
	...props,
});

const userEmailColumn = (theme, props = {}) => fieldColumn(theme, {
	title: 'Email',
	dataIndex: 'email',
	key: 'email',
	isFilter: false,
	isAutoComplete: false,
	sorter: false,
	render: (value, row) => {
		const val = props && props.value ? props.value(value, row) : value;
		return (val && val !== '' && <Popover
			content={<Space style={{ maxWidth: '400px' }}><span>{val}</span></Space>}
			title={<b>Email</b>}>
			<Button
				type='link'
				onClick={() => {
					navigator.clipboard.writeText(value);
					message.success('Email copied to clipboard');
				}}
				icon={<BiEnvelope />}
			/>
		</Popover>);
	},
	...columnFixedWidth(props?.colWidth || 50),
	...props,
});

const userWebsiteColumn = (theme, props = {}) => fieldColumn(theme, {
	title: 'Link',
	dataIndex: 'information.website',
	key: 'information.website',
	isFilter: false,
	isAutoComplete: false,
	sorter: false,
	render: (value, row) => (row.information?.website && <Popover content={<Space style={{ maxWidth: '400px' }}>
		<span>{row.information?.website}</span>
	</Space>} title={<b>Personal link</b>}>
		<Button type='link'
			onClick={() => {
				navigator.clipboard.writeText(row.information?.website);
				message.success('Link copied to clipboard');
			}}
			icon={row.information?.website.includes('linkedin') ? <FaLinkedin /> : <BiLink />}
		/>
	</Popover>),
	...columnFixedWidth(props?.colWidth || 50),
	...props,
});

const userJobRolesColumn = (theme, props = {}) => fieldColumn(theme, {
	title: 'Job roles',
	dataIndex: ['jobSearch', 'jobRole'],
	isFilter: false,
	isAutoComplete: false,
	sorter: false,
	render: (value, row) => {
		const val = props?.value ? props.value(value, row) : row;
		return <UserJobRoles user={val} {...props} />;
	},
	...columnMinWidth(props?.colWidth || 250),
	...props,
});

const userHardSkillsColumn = (theme, props = {}) => fieldColumn(theme, {
	title: 'Hard Skills',
	dataIndex: ['career', 'hardSkill'],
	isFilter: false,
	isAutoComplete: false,
	sorter: false,
	render: (value, row) => {
		const val = props?.value ? props.value(value, row) : row;
		return <UserHardSkills user={val} {...props} />;
	},
	...columnMinWidth(props?.colWidth || 250),
	...props,
});

const userJobRemoteColumn = (theme, props = {}) => fieldColumn(theme, {
	title: 'Remote preference',
	dataIndex: ['jobSearch', 'jobRemote'],
	isFilter: false,
	isAutoComplete: false,
	sorter: false,
	render: (value, row) => {
		const val = props?.value ? props.value(value, row) : row;
		return <UserJobRemote user={val} {...props} />;
	},
	...columnMinWidth(props?.colWidth || 130),
	...props,
});

const userEmploymentVisaColumn = (theme, props = {}) => fieldColumn(theme, {
	title: 'Needs a visa',
	dataIndex: ['jobSearch', 'employmentVisa'],
	isFilter: false,
	isAutoComplete: false,
	sorter: false,
	render: (value, row) => {
		const val = props?.value ? props.value(value, row) : row;
		return <UserEmploymentVisa user={val} {...props} />;
	},
	...columnMinWidth(props?.colWidth || 120),
	...props,
});
const userLanguagesColumn = (theme, props = {}) => fieldColumn(theme, {
	title: 'Languages',
	dataIndex: ['career', 'language'],
	isFilter: false,
	isAutoComplete: false,
	sorter: false,
	render: (value, row) => {
		const val = props?.value ? props.value(value, row) : row;
		return <UserLanguage user={val} {...props} />;
	},
	...columnMinWidth(props?.colWidth || 120),
	...props,
});

const userCareerSeekingColumn = (theme, props = {}) => fieldColumn(theme, {
	title: 'Job seeking',
	// dataIndex: 'career.seeking',
	dataIndex: ['career', 'seeking'],
	isFilter: false,
	isAutoComplete: false,
	removeInColumnFilter: true,
	sorter: true,
	align: 'center',
	customOptions: { searchField: 'career.seeking' },
	render: (value, row) => (<>
		{value === 'active' && <AntTag color='#87d068'><b>{value}</b></AntTag>}
		{value === 'open' && <AntTag color='geekblue'><b>{value}</b></AntTag>}
		{value === 'no' && <AntTag color='volcano'><b>{value}</b></AntTag>}
		{(!value || value === '') && <span style={{ color: theme.color.lightgrey }}>Not answered yet</span>}
	</>),
	// render: (value, row) => (value && value === 'open' && 'open'),
	...columnFixedWidth(props?.colWidth || 100),
	...props,
});

const userDEICriteriaColumn = (theme, props = {}) => ({
	title: 'DE&I Criteria',
	dataIndex: ['jobSearch', 'deiCriteria'],
	isFilter: false,
	isAutoComplete: false,
	sorter: true,
	align: 'left',
	removeInColumnFilter: true,
	customOptions: { searchField: 'jobSearch.deiCriteria' },
	render: (value, row) => {
		const val = props?.value ? props.value(value, row) : row;
		return <UserDEICriteria user={val} {...props} />;
	},
	...columnMinWidth(props?.colWidth || 110),
	...props,
});

const userExperienceColumn = (theme, props = {}) => ({
	title: 'Experience',
	dataIndex: ['career', 'experience'],
	isFilter: false,
	isAutoComplete: false,
	sorter: true,
	align: 'center',
	removeInColumnFilter: true,
	customOptions: { searchField: 'career.experience' },
	render: (value, row) => {
		const val = props?.value ? props.value(value, row) : row;
		return <UserJobLevel user={val} {...props} />;
	},
	...columnFixedWidth(props?.colWidth || 110),
	...props,
});

const userJobLevelColumn = (theme, props = {}) => ({
	title: <>Experience in desired role</>,
	dataIndex: ['jobSearch', 'jobLevel'],
	isFilter: false,
	isAutoComplete: false,
	sorter: true,
	align: 'left',
	removeInColumnFilter: true,
	customOptions: { searchField: 'jobSearch.jobLevel' },
	render: (value, row) => {
		const val = props?.value ? props.value(value, row) : row;
		return <UserJobLevel user={val} {...props} />;
	},
	...columnFixedWidth(props?.colWidth || 180),
	...props,
});

const ARTICLE_TAG_CATEGORIES = [
	{ value: 'content-topic', label: 'Topic' },
];
const COMPANY_TAG_CATEGORIES = [
	{ value: 'dei-criteria', label: 'DE&I criteria' },
];

const tagsColumns = (theme, props = {}, tagCategories) => (tagCategories || []).map(({ value, label }) => tagCategoryColumn(theme, { title: label, ...props }, value));

const tagCategoryColumn = (theme, props = {}, tagCategory) => ({
	title: tagCategory,
	dataIndex: ['tagTree', tagCategory],
	isFilter: false,
	isAutoComplete: false,
	customOptions: { searchField: `tagTree.${tagCategory}` },
	removeInColumnFilter: true,
	render: (value, row) => {
		const slugs = props && props.value ? props.value(value, row) : ((row.tagTree || {})[tagCategory] || []);
		if (!slugs || slugs.length === 0) return;
		const tags = slugs.sort().map((slug, index) => <Tag key={index} slug={slug} />);
		const visibleTags = tags.slice(0, 8);
		return (
			<Space direction='vertical' size={0} align='start'>
				<Space direction='horizontal' size={0} wrap={true}>
					{visibleTags}
				</Space>
				{tags.length > visibleTags.length
					&& <Popover content={
						<Space direction='horizontal' size={0} wrap={true} style={{ maxWidth: '600px' }}>
							{tags}
						</Space>
					} title={<b>Tags ({tags.length})</b>}>
						<span style={{
							fontSize: 10, background: '#eeeeff', color: '#0000ff', borderRadius: 4, padding: '3px 6px',
						}}>
							+ {`${tags.length - visibleTags.length} more tags`}
						</span>
					</Popover>}
			</Space>
		);
	},
	...columnMinMaxWidth(160, 160),
	...props,
});

const tagListColumn = (theme, props = {}) => ({
	title: 'Tags',
	dataIndex: 'tags',
	render: (value, row) => {
		if (!value || value.length === 0) return;
		const tags = value.sort().map((slug, index) => <Tag key={index} slug={slug} />);
		const visibleTags = tags.slice(0, 8);
		return (
			<Space direction='vertical' size={0} align='start'>
				<Space direction='horizontal' size={0} wrap={true}>
					{visibleTags}
				</Space>
				{tags.length > visibleTags.length
					&& <Popover content={
						<Space direction='horizontal' size={0} wrap={true} style={{ maxWidth: '600px' }}>
							{tags}
						</Space>
					} title={<b>Tags ({tags.length})</b>}>
						<span style={{
							fontSize: 10, background: '#eeeeff', color: '#0000ff', borderRadius: 4, padding: '3px 6px',
						}}>
							+ {`${tags.length - visibleTags.length} more tags`}
						</span>
					</Popover>}
			</Space>
		);
	},
	...columnMinWidth(props?.colWidth || 160),
	...props,
});

const tagColumn = (theme, props = {}, tag) => ({
	title: tag.split(':').shift(),
	dataIndex: tag,
	isFilter: true,
	isAutoComplete: true,
	render: (value, row) => <Tag slug={value} />,
	...columnMinWidth(props?.colWidth || 160),
	...props,
});

const docsCountColumn = (theme, props = {}) => ({
	title: 'Docs',
	dataIndex: 'documents',
	align: 'center',
	sorter: true,
	render: (value, row) => {
		const count = props && props.value ? props.value(value, row) : value;
		const icon = props.icon !== undefined ? props.icon : <FaFileAlt fontSize={12} style={{ marginBottom: -1 }} />;

		if (!count) return null;

		let component = <Space size={2} align='baseline' style={{
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			fontSize: 14,
			fontWeight: 700,
			background: (props.background || '#eeeeff'),
			color: (props.color || '#0000ff'),
			padding: '1px 5px',
			borderRadius: 6,
			minWidth: 30,
			align: 'center',
			...props?.style,
			...props?.onClick && { cursor: 'pointer' },
		}}
		>
			{props.iconPosition === 'left' && icon} {count} {props.iconPosition !== 'left' && icon}
		</Space>;

		component = props?.onClick
			? <Link onClick={() => props.onClick(value, row)}>{component}</Link>
			: component;

		component = props?.tooltip
			? <Tooltip title={props.tooltip}>{component}</Tooltip>
			: component;

		return component;
	},
	...columnMinWidth(props?.colWidth || 80),
	...props,
});

const sourcingsCountColumn = (theme, props = {}) => TableColumns.docsCountColumn(theme, {
	title: SOURCINGS_COL_LABEL,
	dataIndex: ['sourcingsCount'],
	sorter: false,
	removeInColumnFilter: true,
	icon: <GenderhireCandidatesSourcedIcon />,
	color: theme.color.orange,
	background: theme.color.orangeLightest,
	...props,
});

const shortlistsCountColumn = (theme, props = {}) => TableColumns.docsCountColumn(theme, {
	title: SHORTLISTS_MENU,
	dataIndex: ['shortlistsCount'],
	sorter: false,
	removeInColumnFilter: true,
	icon: SHORTLISTS_ICON,
	color: theme.color.orange,
	background: theme.color.orangeLightest,
	...props,
});

const sourcedCountColumn = (theme, props = {}) => TableColumns.docsCountColumn(theme, {
	title: new GenderhireCandidatesSourcedMenu().label,
	dataIndex: ['sourcedCount'],
	sorter: false,
	removeInColumnFilter: true,
	icon: <GenderhireCandidatesSourcedIcon />,
	color: theme.color.orange,
	background: theme.color.orangeLightest,
	...props,
});

const applicationsCountColumn = (theme, props = {}) => TableColumns.docsCountColumn(theme, {
	title: new GenderhireCandidatesAppliedMenu().label,
	dataIndex: ['applicationsCount'],
	sorter: false,
	removeInColumnFilter: true,
	icon: new GenderhireCandidatesAppliedMenu().icon,
	color: theme.color.orange,
	background: theme.color.orangeLightest,
	...props,
});

const jobTagsColumns = (theme, props = {}) => tagsColumns(theme, props, JOB_TAG_CATEGORIES);
const jobTagsStaffColumns = (theme, props = {}) => tagsColumns(theme, props, JOB_TAG_CATEGORIES_STAFF);
const userTagsColumns = (theme, props = {}) => tagsColumns(theme, props, USER_TAG_CATEGORIES);
const userTagCareerHardSkillColumn = (theme, props = {}) => tagsColumns(theme, props, [USER_TAG_CAREER_HARD_SKILL])[0];
const articleTagsColumns = (theme, props = {}) => tagsColumns(theme, props, ARTICLE_TAG_CATEGORIES);
const companyTagsColumns = (theme, props = {}) => tagsColumns(theme, props, COMPANY_TAG_CATEGORIES);
const companyLastBatchColumn = (theme, props = {}) => dateColumn(theme, {
	title: 'Last batch',
	dataIndex: ['batch', 'lastBatch', 'startDate'],
	removeInFilter: true,
	render: (value, row) => {
		if (!row.batch?.lastBatch?.startDate) return;
		let durationDisplay = <>
			<FaCog style={{ color: theme.color.orange, marginBottom: -2 }} />
			<FaHourglassHalf style={{ color: theme.color.orange, marginBottom: -2 }} />
		</>;
		let tagDurationDisplay = null;
		if (row.batch.lastBatch?.endDate) {
			const duration = dayjs.duration(dayjs(row.batch.lastBatch.endDate).diff(dayjs(row.batch.lastBatch.startDate)));
			durationDisplay = `${Math.round(duration.asHours())} h`;
			if (duration.asHours() < 1) durationDisplay = `${Math.round(duration.asMinutes())} min`;
			if (duration.asMinutes() < 1) durationDisplay = `${Math.round(duration.asSeconds())} s`;
			durationDisplay = <><FaCog
				style={{ color: theme.color.gray, marginBottom: -2 }} />{durationDisplay}</>;
			tagDurationDisplay = <><FaTags
				style={{ color: theme.color.orange, marginBottom: -2 }} /><FaHourglassHalf
				style={{ color: theme.color.orange, marginBottom: -2 }} /></>;
			if (row.batch.lastBatch?.taggedDate && row.batch.lastBatch?.endDate) {
				const tagDuration = dayjs.duration(dayjs(row.batch.lastBatch.taggedDate).diff(dayjs(row.batch.lastBatch.endDate)));
				tagDurationDisplay = `${Math.round(tagDuration.asHours())} h`;
				if (tagDuration.asHours() < 1) tagDurationDisplay = `${Math.round(tagDuration.asMinutes())} min`;
				if (tagDuration.asMinutes() < 1) tagDurationDisplay = `${Math.round(tagDuration.asSeconds())} s`;
				tagDurationDisplay = <><FaTags
					style={{ color: theme.color.gray, marginBottom: -2 }} />{tagDurationDisplay}</>;
			}
		}
		// durationDisplay = String(durationDisplay) + ' (' + duration.asMinutes() + ' asM)';
		// durationDisplay = String(durationDisplay) + ' (' + duration.asSeconds() + ' asS)';
		return <Space direction='vertical' size={5}>
			<span>{Math.round(dayjs.duration(dayjs(new Date()).diff(dayjs(row.batch.lastBatch?.startDate))).asHours())}h ago</span>
			{/* <span>{dayjs.duration(dayjs(new Date()).diff(dayjs(row.batch.lastBatch?.startDate))).asHours()}</span> */}
			{/* <span>{row.batch.lastBatch?.startDate}</span> */}
			{TableColumns.dateColumn(theme).render(row.batch?.lastBatch?.startDate)}
			<Space direction='horizontal' size={10} style={{ color: '#444' }}>
				<Space direction='horizontal' size={2} style={{ color: '#444' }}>{durationDisplay}</Space>
				<Space direction='horizontal' size={2} style={{ color: '#444' }}>{tagDurationDisplay}</Space>
			</Space>
		</Space>;
	},
	...columnFixedWidth(props?.colWidth || 120),
});

const campaignEmailStatusColumn = (theme, props = {}) => ({
	title: 'Status',
	dataIndex: 'status',
	removeInColumnFilter: true,
	align: 'center',
	render: (value, row) => {
		let render;
		let icon; let text; let background; const
			color = theme.color.white;
		if (value === -1) {
			icon = <FaEmber size={12} style={{ marginBottom: -1 }} />;
			text = <span style={{ whiteSpace: '' }}>Pending ({value})</span>;
			background = theme.color.mustard;
		}
		if (value === 0) {
			icon = <FaHourglass size={12} style={{ marginBottom: -1 }} />;
			text = <span style={{ whiteSpace: '' }}>Ready ({value})</span>;
			background = theme.color.blue;
		}
		if (value === 200) {
			icon = <FaCheck size={12} style={{ marginBottom: -1 }} />;
			text = <span style={{ whiteSpace: '' }}>Sent ({value})</span>;
			background = theme.color.darkturquoise;
		}
		if (value === 996) {
			icon = <FaBan size={12} style={{ marginBottom: -1 }} />;
			text = <span style={{ whiteSpace: '' }}>Location ({value})</span>;
			background = theme.color.orange;
		}
		if (value === 997) {
			icon = <FaBan size={12} style={{ marginBottom: -1 }} />;
			text = <span style={{ whiteSpace: '' }}>0 jobs ({value})</span>;
			background = theme.color.orange;
		}
		if (value === 998) {
			icon = <FaBan size={12} style={{ marginBottom: -1 }} />;
			text = <span style={{ whiteSpace: '' }}>Undefined jobs ({value})</span>;
			background = theme.color.orange;
		}
		if (value === 999) {
			icon = <FaBan size={12} style={{ marginBottom: -1 }} />;
			text = <span style={{ whiteSpace: '' }}>Excluded ({value})</span>;
			background = theme.color.red;
		}
		return docsCountColumn(theme, {
			icon, value: () => text, color, background, iconPosition: 'left',
		}).render(value, row);
	},
	...columnMinWidth(props?.colWidth || 200),
});

const sourcingArchiveReasonsColumn = (theme, props = {}) => ({
	title: 'Archive reasons',
	dataIndex: 'archiveReasons',
	align: 'center',
	removeInColumnFilter: true,
	render: (value, row) => <SourcingArchiveReasonTags sourcing={row} />,
	...columnFixedWidth(props?.colWidth || 160),
});

const sourcingCandidatePipelineColumn = (theme, props = {}) => {
	return ({
		title: 'Pipeline',
		dataIndex: 'candidatePipeline',
		width: 170,
		sorter: true,
		align: 'center',
		render: (value, row) => value,
		...columnMinWidth(props?.colWidth || 120),
	});
};

const sourcingCandidateStageColumn = (theme, props = {}) => {
	return ({
		title: 'Stage',
		dataIndex: 'candidateStage',
		width: 170,
		sorter: true,
		align: 'center',
		render: (value, row) => value,
		...columnMinWidth(props?.colWidth || 120),
	});
};

const sourcingCandidateOriginColumn = (theme, props = {}) => {
	return ({
		title: 'Origin',
		dataIndex: 'candidateOrigin',
		width: 170,
		sorter: true,
		align: 'center',
		render: (value, row) => value,
		...columnMinWidth(props?.colWidth || 120),
	});
};

const sourcingStageColumn = (theme, props = {}) => {
	return ({
		title: 'Stage',
		dataIndex: 'stage',
		width: 170,
		sorter: true,
		align: 'center',
		render: (value, row) => renderStageTag(value, row, theme, props),
		...columnMinWidth(props?.colWidth || 120),
	});
};

const sourcingStepColumn = (theme, props = {}) => {
	return ({
		title: 'Step',
		dataIndex: 'step',
		width: 170,
		sorter: true,
		align: 'center',
		render: (value, row) => renderStepTag(value, row, theme, props),
		...columnMinWidth(props?.colWidth || 120),
		...props,
	});
};

const sourcingStatusColumn = (theme, props = {}) => {
	return ({
		title: 'Status',
		dataIndex: 'status',
		sorter: true,
		align: 'center',
		render: (value, row) => renderStatusTag(value, row, theme, props),
		...columnMinWidth(props?.colWidth || 120),
		...props,
	});
};

const unReadMessageColumn = (theme, props = {}) => ({
	title: 'News',
	dataIndex: 'actionHistory',
	render: (value, row) => {
		if (!value || value.length === 0) return '';
		localDebug('value', value);
		const content = <Space>
			{
				(value || []).map(({ action }) => (
					<AntTag>
						{action?.title || action?.text}
					</AntTag>
				))
			}
		</Space>;
		return (
			<Popover
				content={content} title='Last actions'
			>
				<Badge
					count={value.length}
					size='small'
				>
					<Typography><BiMessageError size={20} /> </Typography>
				</Badge>
			</Popover>
		);
	},
});

const userRoleLabelColumn = (theme, props = {}) => ({
	title: 'Role',
	dataIndex: 'role',
	render: (value, row) => getAclRoleTag(value?.label),
	...TableColumns.columnFixedWidth(props?.colWidth || 160),
});

const refreshBtn = (theme, { icon, onClick, tooltip }) => {
	const button = <Button onClick={onClick}>
		<Space direction='horizontal' size={2} align='center'>
			{icon}
			<div style={{
				// background: theme.color.eblue,
				background: '#eee',
				position: 'absolute',
				top: '50%',
				left: '50%',
				marginTop: -2,
				marginLeft: 1,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: 12,
				height: 12,
				borderRadius: 12,
			}}>
				<FaSyncAlt
					style={{ fontSize: 7 }}
					// color={theme.color.white}
					color='#333'
				/>
			</div>
		</Space>
	</Button>;
	return tooltip
		? <Tooltip title={tooltip}>{button}</Tooltip>
		: button;
};

const metaDataColumn = (theme, props = {}) => ({
	dataIndex: ['meta', 'title'],
	render: (value, row) => {
		return (value
			? <Tooltip title='Edit meta data'>
				<Button type='text' onClick={() => props.onClick(row, 'Edit Meta')} style={{
					maxWidth: '100%', whiteSpace: 'normal', fontSize: 11, textAlign: 'left', lineHeight: 1.3,
				}}>
					{value}
				</Button>
			</Tooltip>
			: <Tooltip title='Add meta data'>
				<Button type='link' icon={<FaPlusSquare style={{ margin: -2 }} />}
					onClick={() => props.onClick(row, 'Add Meta')} />
			</Tooltip>
		);
	},
	removeInColumnFilter: true,
	...props,
	...columnMinWidth(props?.colWidth || 250),
});

const jsonColumn = (theme, props = {}) => ({
	title: 'JSON',
	dataIndex: 'json',
	align: 'center',
	render: (value, row) => {
		let processedValue = {};
		let iconColor = theme?.color?.fitBlueElectric || 'blue';
		try {
			processedValue = props?.value?.(row) || row || value || {};
		} catch (e) {
			processedValue = e;
			iconColor = theme?.color?.red || 'red';
			if (props?.withDebug) console.error({ processedValue });
		}
		return <Popover
			title='JSON'
			placement='left'
			content={
				<div style={{
					maxWidth: 700, maxHeight: '80vh', overflow: 'scroll', ...props?.style,
				}}>
					{/* <JSONPretty data={props?.value?.(row) || row || {}} /> */}
					<ReactJson
						src={processedValue}
						style={{
							fontSize: 10,
							// lineHeight: 1.3,
						}}
						sortKeys={props?.sortKeys || props?.sortKeys === false ? props?.sortKeys : true}
						collapsed={props?.collapsed || 1}
						displayDataTypes={false}
						name={false}
						quotesOnKeys={false}
						onAdd={props?.onAdd}
						onEdit={props?.onEdit}
					/>
				</div>
			}
		>
			<Link>{props?.label || <FaDatabase style={{ color: iconColor, marginBottom: -2 }} />}</Link>
		</Popover>;
	},
	removeInColumnFilter: true,
	...columnFixedWidth(props?.colWidth || 50),
	...props,
});

const jsonTagTreeColumn = (theme, props = {}) => ({
	title: 'Tag tree',
	dataIndex: 'tagTree',
	align: 'center',
	render: (value, row) => {
		let processedValue = {};
		let iconColor = theme?.color?.fitBlueElectric || 'blue';
		try {
			processedValue = props?.value?.(row) || value || {};
		} catch (e) {
			processedValue = e;
			iconColor = theme?.color?.red || 'red';
		}
		let content;
		if (!processedValue || Object.keys(processedValue)?.length === 0) {
			content = <span style={{ fontSize: 10, fontStyle: 'italic', color: theme.color.grey }}>N/A</span>;
		} else {
			const label = (<Space>
				<Link><FaTags style={{ color: iconColor, marginBottom: -2 }} /></Link>
				<span>{Object.values(processedValue)?.map((item) => item?.length).reduce(sumReducer, 0)} in {Object.keys(processedValue)?.length} cat.</span>
			</Space>);
			content = <Popover
				title='tagTree'
				placement='left'
				content={
					<div style={{
						maxWidth: 700, maxHeight: '80vh', overflow: 'scroll', ...props?.style,
					}}>
						<ReactJson
							src={processedValue}
							style={{ fontSize: 10 }}
							sortKeys={props?.sortKeys || props?.sortKeys === false ? props?.sortKeys : true}
							collapsed={props?.collapsed || 2}
							displayDataTypes={false}
							name={false}
							quotesOnKeys={false}
							onAdd={props?.onAdd}
							onEdit={props?.onEdit}
						/>
					</div>
				}
			>
				{label}
			</Popover>;
		}
		return (<Space direction='vertical'>
			{content}
			{props?.withRefreshLink && (
				<Link
					onClick={
						async () => {
							await CompanyService.recomputeTags(documentId(row));
							props?.onRefresh?.();
						}
					}>
					<span><BiRefresh style={{ marginBottom: -2 }} /> tags</span>
				</Link>
			)}
		</Space>);
	},
	removeInColumnFilter: true,
	...columnFixedWidth(props?.colWidth || 120),
	...props,
});

const mailjetDataRefreshColumn = (theme, props = {}) => ({
	title: <>Mailjet data</>,
	align: 'center',
	sorter: false,
	render: (value, row) => {
		const mailing = props && props.value ? props.value(value, row) : (row.mailing || value);
		return <Space direction='vertical' size={0}>
			<Link onClick={() => { props?.onClick?.(row); }}>
				<BiRefresh style={{ marginBottom: -2 }} /> Refresh
			</Link>
			<Typography.Text style={{ fontSize: 10, color: theme.color.grey }}>
				{mailing?.refreshedAt
			&& momentShortenFrom(moment(mailing?.refreshedAt).from(moment()))}
			</Typography.Text>
		</Space>;
	},
	...columnFixedWidth(props?.colWidth || 100),
});

const dateColumnProps = (props = {}) => ({
	dateFormat: 'DD/MM/YY',
	withFromNow: false,
	withToolip: true,
	align: 'center',
	styles: { date: { fontSize: 12, color: mainTheme.color.darkgrey } },
	...columnFixedWidth(props?.colWidth || 80),
});

const dateColumnLiteProps = (props = {}) => ({
	dateFormat: 'MMM Do',
	withFromNow: false,
	withToolip: true,
	align: 'center',
	styles: { date: { fontSize: 11, color: mainTheme.color.darkgrey } },
	...columnFixedWidth(props?.colWidth || 80),
});

/**
 * Displays a red cross if there are less than 3 meta-tags for that language defined for the company
 * Displays a flag if the 3 meta-tags for that language are defined for the company
 * @param {*} theme
 * @param {*} props
 * @returns
 */
const companyHasCustomMetas = (theme, openPopin, props = {}) => booleanColumn(theme, {
	title: 'Custom Metas?',
	dataIndex: 'meta',
	key: 'meta',
	sorter: true,
	render: (value, row) => {
		const companyModel = new CompanyModel(row);
		const isFullyCustomizedEN = companyModel.areAllMetasCustomized(ENGLISH_LANG);
		const isFullyCustomizedFR = companyModel.areAllMetasCustomized(FRENCH_LANG);

		return <Tooltip
			title={(
				<>
					{/* Status for meta tags in English */}
					<CompanyMetaStatus company={row}
						isFullyCustomized={isFullyCustomizedEN}
						isEnVersion={true}
						isTranslatable={row.isTranslatable}
					/>
					{/* Status for meta tags in French, only if it's translatable */}
					{row?.isTranslatable
						&& <CompanyMetaStatus company={row}
							isFullyCustomized={isFullyCustomizedFR}
							isEnVersion={false}
							isTranslatable={row.isTranslatable}
						/>
					}
					<br />
					<b>Click to edit meta data</b>
				</>
			)}
		>
			<span style={{ fontSize: 18, cursor: 'pointer' }} onClick={() => openPopin(row)}>
				{
					isFullyCustomizedEN
						? row.isTranslatable && isFullyCustomizedFR
							? '🇬🇧 🇫🇷'
							: '🇬🇧'
						: row.isTranslatable && isFullyCustomizedFR
							? '🇫🇷'
							: '❌'
				}
			</span>
		</Tooltip>;
	},
	...columnFixedWidth(props?.colWidth || 120),
	...props,
});

const staffColumnWrapper = (col) => ({
	...col,
	title: <>{col?.title} {TAG_STAFF_ICON}</>,
});

const adminColumnWrapper = (col) => ({
	...col,
	title: <>{col?.title} {TAG_ADMIN_ICON}</>,
});

const editedFromColumn = (theme, props = {}) => ({
	title: props?.title || 'Edited From',
	dataIndex: 'editedFrom',
	render: (value, row) => {
		if (!value || !Object.keys(value).length) {
			return <div style={{ fontSize: 12, color: theme.color.grey }}>
				<AntTag color='green'>ORIGINAL</AntTag>
			</div>;
		}
		const showOriginal = props?.fieldId === 'parentSavedSearchId'
		&& value?.parentSavedSearchId === value?.originalSavedSearchId;
		const isFilteredId = value?.[props?.fieldId] === props?.filterOptions?.searchId;
		return <div>
			<div>
				<ObjectIdDisplayer
					id={value?.[props?.fieldId]}
					withTooltip={true}
					withSlice={true}
					withFilter={true}
					style={{
						fontSize: 12,
						color: theme.color.grey,
						fontWeight: isFilteredId ? 'bold' : 'default',
						backgroundColor: isFilteredId ? '#91caff' : 'default',
					}}
					onClick={props?.onFilterId}
				/>
			</div>
			{showOriginal
				&& <span style={{ fontSize: 12, fontWeight: 'bold', color: theme.color.grey }}>
					ORIGINAL
				</span>}

			<div>
				<span style={{ fontSize: 12, fontWeight: 'bold' }}>Created: </span>
				<DatePreview
					date={value?.[props?.fieldDate]}
					dateFormat='DD/MM/YY'
					withFromNow={false}
					withToolip={true}
					styles={{ date: { fontSize: 12, color: theme.color.grey } }}
				/>
			</div>
		</div>;
	},
	...columnFixedWidth(props?.colWidth || 150),
});

const TableColumns = {
	JOB_TAG_CATEGORIES,
	JOB_TAG_CATEGORIES_STAFF,
	USER_TAG_CATEGORIES,
	staffColumnWrapper,
	adminColumnWrapper,
	jsonColumn,
	jsonTagTreeColumn,
	htmlColumn,
	booleanColumn,
	columnFixedWidth,
	columnMinWidth,
	userRoleLabelColumn,
	companyLogoColumn,
	companyStubColumn,
	companyPlanColumn,
	companyAccessColumn,
	activityAtColumn,
	createdAtColumn,
	delayColumn,
	dateColumn,
	docsCountColumn,
	sourcingsCountColumn,
	shortlistsCountColumn,
	sourcedCountColumn,
	applicationsCountColumn,
	messagesToCountColumn,
	sourcingsMaxStepColumn,
	sourcingsMaxStageColumn,
	fieldColumn,
	tagCategoryColumn,
	tagListColumn,
	tagColumn,
	toggleColumn,
	toggleIsActiveColumn,
	togglePublishedColumn,
	jobTogglePublishedColumn,
	jobStubColumn,
	jobLocationColumn,
	jobDepartmentColumn,
	jobTeamColumn,
	jobHasContentColumn,
	jobIsPublishedColumn,
	jobPageLinkColumn,
	jobExternalUriColumn,
	jobExternalApplyUriColumn,
	jobExternalLinkColumn,
	jobTagsColumns,
	jobTagsStaffColumns,
	tagsColumns,
	updatedAtColumn,
	churnedAtColumn,
	userCareerSeekingColumn,
	userDEICriteriaColumn,
	userExperienceColumn,
	userJobLevelColumn,
	userEmailColumn,
	userAvatarColumn,
	userAvatarListColumn,
	userStubColumn,
	userTagsColumns,
	userTagCareerHardSkillColumn,
	userWebsiteColumn,
	userJobRolesColumn,
	userHardSkillsColumn,
	userJobRemoteColumn,
	userEmploymentVisaColumn,
	userLanguagesColumn,
	unReadMessageColumn,
	sourcingStubColumn,
	sourcingStubLiteColumn,
	sourcingStubLiteColumn2,
	sourcingCandidatePipelineColumn,
	sourcingCandidateStageColumn,
	sourcingCandidateOriginColumn,
	sourcingStageColumn,
	sourcingStepColumn,
	sourcingStatusColumn,
	sourcingArchiveReasonsColumn,
	renderStepTag,
	renderStatusTag,
	articleTagsColumns,
	companyTagsColumns,
	companyGenderScoreColumn,
	companyLastBatchColumn,
	refreshBtn,
	campaignEmailStatusColumn,
	metaDataColumn,
	dateColumnProps,
	dateColumnLiteProps,
	companyHasCustomMetas,
	mailjetDataRefreshColumn,
	desiredLocationColumn,
	editedFromColumn,
};

export default TableColumns;
