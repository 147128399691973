/** @jsxImportSource @emotion/react */

import CKEditor from '../../../app/form/CkEditor';
import FormItem from '../../../company/form/FormItem';
import { useEffect } from 'react';

const CKEditorCustom = ({
	label, name, data, onChange, ...othersProps
}) => {
	useEffect(() => {
		return () => {};
	}, []);

	return (
		<div
			className="App"
			css={{ 'width': '100%', '.ck-content': { minHeight: 300 } }}
		>
			<label className="form-label" css={{ marginBottom: 5 }}>
				{label}
			</label>
			<FormItem
				name={name}
				{...othersProps}
				styles={{
					rootContainer: {
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
						gap: 10,
					},
					label: { marginTop: 6, width: 140 },
					container: { width: '100%' },
				}}
			>
				<CKEditor
					onChange={onChange}
					config={{
						toolbar: [
							'heading',
							'|',
							'bold',
							'italic',
							'bulletedList',
							'numberedList',
							'blockQuote',
							'|',
							'link',
							'mediaEmbed',
							'uploadImage',
							'|',
							'undo',
							'redo',
						],
						heading: {
							options: [
								{
									model: 'paragraph',
									title: 'Paragraph',
									class: 'ck-heading_paragraph',
								},
								{
									model: 'heading1',
									view: 'h1',
									title: 'Heading 1',
									class: 'ck-heading_heading1',
								},
								{
									model: 'heading2',
									view: 'h2',
									title: 'Heading 2',
									class: 'ck-heading_heading2',
								},
								{
									model: 'heading3',
									view: 'h3',
									title: 'Heading 3',
									class: 'ck-heading_heading3',
								},
							],
						},
					}}
					value={data}
				/>
			</FormItem>
		</div>
	);
};

export default CKEditorCustom;
