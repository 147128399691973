import { STAFF_ARTICLES_ICON, STAFF_ARTICLES_TITLE } from '../../../constants/constant';
import { useEffect, useState } from 'react';

import AddButton from '../../../components/app/button/AddButton';
import ArticleFormModal from '../../../components/staff/content/articles/ArticleFormModal';
import ArticleTable from '../../../components/staff/content/articles/ArticleTable';
import PageLayout from '../../../components/app/layout/PageLayout';
import SectionTitle from '../../../components/app/SectionTitle';
import { Space } from 'antd';
import { getQueryParams } from '../../../utils/common';
import { localDebug } from '../../../utils/LocalDebug';
import { updateOrCreateArticle } from '../../../actions/article';
import { useDispatch } from 'react-redux';
import ArticleModel from '../../../models/ArticleModel';
import ArticlesMenu from '../../../menu/staff/content/ArticlesMenu';

const ArticlesPage = () => {
	const queryParams = getQueryParams();

	const [openArticleForm, setOpenArticleForm] = useState(false);
	const [isRefresh, setIsRefresh] = useState(0);
	const [isPreview, setIsPreview] = useState(false);
	const [optionsFilterTable, setOptionsFilterTable] = useState({
		sortField: 'publishedAt',
	});
	const [initialValues, setInitialValues] = useState({});
	const dispatch = useDispatch();

	const handleAddArticle = (topic) => {
		localDebug('handleAddArticle');

		const article = new ArticleModel({ topic, isAnonymous: false, userId: '5dd2a711d8ec89003819cc2f' });
		setInitialValues(article);
		setIsPreview(false);
		setOpenArticleForm(true);
	};
	const onPreviewArticle = (article) => {
		setInitialValues(article);
		setIsPreview(true);
	};
	const onEditArticle = (values) => {
		const { translations, ...rest } = values;
		const article = new ArticleModel({
			...rest,
			translations,
			'translations-fr-title': translations?.fr?.title || '',
			'translations-fr-content': translations?.fr?.content || '',
			'translations-fr-contentLogged': translations?.fr?.contentLogged || '',
			'translations-fr-illustration': translations?.fr?.illustration || '',
		});
		setInitialValues(article);
		setIsPreview(false);
		setOpenArticleForm(true);
	};

	const handleSaveArticle = async () => {
		await dispatch(updateOrCreateArticle(initialValues));
		setOpenArticleForm(false);
		setIsRefresh((prev) => prev + 1);
	};
	useEffect(() => {
		setOptionsFilterTable((prev) => ({ ...prev, ...queryParams }));
	}, []);
	useEffect(() => {
		if (openArticleForm) {
			document.body.style.overflow = 'hidden!important';
		} else {
			document.body.style.overflow = 'unset!important';
		}
	}, [openArticleForm]);

	return (
		<PageLayout
			MenuClass={ArticlesMenu}
			rightActions={(

				<Space direction='horizontal' css={{ gap: 10 }}>
					<AddButton
						style={{ marginTop: 1 }}
						onClick={() => handleAddArticle('event')}
						title='Add new event'
					/>
					<AddButton
						style={{ marginTop: 1 }}
						onClick={() => handleAddArticle('story')}
						title='Add new story'
					/>
				</Space>
			)}
		>

			<ArticleTable
				filterOptions={optionsFilterTable}
				onEditArticle={onEditArticle}
				isRefresh={isRefresh}
			/>
			<ArticleFormModal
				open={openArticleForm}
				initialValues={initialValues}
				onPublish={handleSaveArticle}
				isPreview={isPreview}
				setIsPreview={setIsPreview}
				onPreview={onPreviewArticle}
				title={'Add Article'}
				setValues={setInitialValues}
				onClose={() => setOpenArticleForm(false)}
			/>
		</PageLayout>
	);
};

export default ArticlesPage;
