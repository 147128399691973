import CompaniesPage from '../../../containers/staff/companies/CompaniesPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';
import MenuBase from '../../MenuBase';
import { BuildOutlined } from '@ant-design/icons';

export const StaffCompaniesCompanyListIcon = BuildOutlined;

class StaffCompaniesCompanyListMenu extends MenuBase {
	static props = {
		name: 'staff-companies',
		path: '/staff/companies',
		label: 'Company list',
		icon: <StaffCompaniesCompanyListIcon />,
		iconLegacy: '🏢',
		Component: CompaniesPage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffCompaniesCompanyListMenu.props);
	}
}

export default StaffCompaniesCompanyListMenu;
