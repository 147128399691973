import { useTheme } from '@emotion/react';
import GenderScoreLayoutGrid from '../layout/GenderScoreLayoutGrid';
import BlockStaff from '../../../app/blocker/BlockStaff';
import {
	arrayWrap, copyClipboard, numToArray, scrollToTop, sortOn,
} from '../../../../utils/common';
import { RESOURCES_JSON } from '../../../../constants/genderscore-resources';
import {
	useContext, useEffect, useMemo, useState,
} from 'react';
import GenderScoreResourcesCard from './GenderScoreResourcesCard';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { AppDataContext } from '../../../../contexts/AppDataProvider';
import {
	Button, Select, Space, Switch, message,
} from 'antd';
import { BiCopy, BiSync } from 'react-icons/bi';
import { GENDERSCORE_PILLAR_OPTIONS_LIST } from '../../../../constants/genderscore-pillars';
import GenderScorePillarLogoLabel from '../pillars/GenderScorePillarLogoLabel';
import { useLocation } from 'react-router';
import {
	GENDERSCORE_LOG_FROM_ELEMENT_FILTER_PILLAR_LINK,
	GENDERSCORE_LOG_FROM_ELEMENT_FILTER_TYPE_LINK,
	writeGenderScoreLog,
} from '../../../../constants/genderscore-logs';
import slugify from '@sindresorhus/slugify';
import useScreenSize from '../../../../hooks/useScreenSize';
import { request } from '../../../../services/api';
import { LocalDebug } from '../../../../utils/LocalDebug';

const GenderScoreResourcesGrid = ({
	company,
	forceValue,
	pillar,
	index,
	...props
}) => {
	const className = 'GenderScoreResourcesGrid';

	const theme = useTheme();
	const location = useLocation();

	const {
		appSiderWidth,
	} = useContext(AppDataContext);

	const { isStaff, isStaffView } = useAuthContext();
	const { appSettings, refreshAppSettings } = useContext(AppDataContext);
	const screenSize = useScreenSize();

	const [useLocalVersion, setUseLocalVersion] = useState(false);
	const [windowInfo, setWindowInfo] = useState();
	const [selectedPillar, setSelectedPillar] = useState();
	const [selectedType, setSelectedType] = useState();
	const [screenResizedColumns, setScreenResizedColumns] = useState();
	const [resources, setResources] = useState([]);

	const genderScore = forceValue || company?.genderScore;

	useMemo(async () => {
		const items = await request.get('/genderscore/resource');

		LocalDebug.logInfo({ className, method: 'useMemo resources' }, { items, length: items?.data?.results?.length, test: items?.data?.results?.length > 0 });

		if (items?.data?.results?.length > 0) {
			LocalDebug.logInfo({ className, method: 'useMemo' }, [...new Set(items?.data?.results
				?.map((resource) => resource?.type)
				?.filter((item) => item))].join(', '));
			setResources(items?.data?.results);
		} else if (useLocalVersion) {
			setResources(RESOURCES_JSON);
		} else {
			try {
				const appSettingsResources = JSON.parse(appSettings?.resources);
				if (appSettingsResources?.length === 0) throw new Error('Remote content parsed but empty');
				setUseLocalVersion(false);
				setResources(appSettingsResources);
			} catch (e) {
				setUseLocalVersion(true);
				setResources(RESOURCES_JSON);
			}
		}
	}, [appSettings, useLocalVersion]);

	useEffect(() => {
		scrollToTop();
	}, [location.pathname]);

	useEffect(() => {
		const cardWidth = 364;
		const countCols = Math.floor(
			Math.max(
				1,
				(
					// screen width
					(screenSize.width || 0)
					// menu width
					- (appSiderWidth || 0)
					// padding around grid
					- 24 * 2
					// adding gap
					+ 20
				)
				// card + gap
				/ (cardWidth + 20),
			),
		);
		setScreenResizedColumns(numToArray(countCols).map(() => `${cardWidth}px`).join(' '));
	}, [screenSize, appSiderWidth]);

	const onSelectPillar = (filteredPillar) => {
		setSelectedPillar(filteredPillar);
		writeGenderScoreLog({
			type: GENDERSCORE_LOG_FROM_ELEMENT_FILTER_PILLAR_LINK,
			context: filteredPillar,
			company,
		});
	};

	const onFilterResource = (resourceType) => {
		setSelectedType(resourceType);
		writeGenderScoreLog({
			type: GENDERSCORE_LOG_FROM_ELEMENT_FILTER_TYPE_LINK,
			context: resourceType,
			company,
		});
	};

	return (
		<div
			style={{
			}}
		>
			<Space style={{ marginBottom: 12 }}>

				{!pillar && (
					<Select
						options={(
							GENDERSCORE_PILLAR_OPTIONS_LIST
								.map((o) => ({
									...o,
									label: (
										<GenderScorePillarLogoLabel
											pillar={o.value}
											iconSize={18}
											labelSize={14}
											style={{
												label: { color: '#333' },
											}}
										/>
									),
								}))
						)}
						onChange={onSelectPillar}
						placeholder='Select a pillar'
						allowClear
						style={{ minWidth: 200, borderRadius: 6 }}
					/>
				)}

				<Select
					options={
						[...new Set(
							resources
								?.map((resource) => resource?.type)
								?.filter((item) => item),
						)]
							?.map((value) => ({ value, label: value, slug: value.toLowerCase() }))
							?.sort(sortOn({ key: 'slug' }, false))
					}
					onChange={onFilterResource}
					placeholder='Select a resource type'
					allowClear
					style={{ minWidth: 200, borderRadius: 6 }}
				/>

			</Space>

			<GenderScoreLayoutGrid
				columns={screenResizedColumns}
			>
				{resources
					?.filter((item) => !pillar
						|| item.pillars?.includes?.(pillar))
					?.filter((item) => !selectedPillar
						|| item.pillars?.includes?.(selectedPillar))
					?.filter((item) => !selectedType
						|| arrayWrap(item.type)?.includes?.(selectedType))
					?.map((resource, i) => (
						<div key={i}>
							<GenderScoreResourcesCard
								company={company}
								resource={resource}
								withPillarTags={true}
								pillar={pillar}
							/>
						</div>
					))}
			</GenderScoreLayoutGrid>
		</div>
	);
};

export default GenderScoreResourcesGrid;
