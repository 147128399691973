import { Modal, Space } from 'antd';
import { RiDeleteBinFill, RiEdit2Fill } from 'react-icons/ri';
import { deleteArticle, updateOrCreateArticle } from '../../../../actions/article';
import { useEffect, useState } from 'react';

import ActionButton from '../../../app/table/ActionButton';
import ArticleImage from './ArticleImage';
import ArticleMetaPopover from './ArticleMetaPopover';
import Table from '../../../app/table/Table';
import TableColumns from '../../../app/table/TableColumns';
import config from '../../../../config/config';
import { documentId, getDefaultArticleMeta } from '../../../../utils/common';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';

const ArticleTable = (
	{
		onDataLoaded,
		path = '/article',
		filterOptions = { sortField: 'publishedAt' },
		isRefresh = 0,
		onSearchDataColumn,
		onToggleIsActive,
		// onToggleIs50Content,
		onEditArticle,
		isEditor,
		...props
	},
) => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);
	const [openMetaPopover, setOpenMetaPopover] = useState(false);
	const [articleSelected, setArticleSelected] = useState(null);
	const [popoverTitle, setPopoverTitle] = useState('Add Meta');

	// const toggleIsActiveArticle = (row) => {
	//     const textDisable = row.isActive ? 'disable' : 'active'
	//     Modal.confirm ({
	//         title: `Are you sure you want to ${textDisable} this article?`,
	//         okText: 'Yes',
	//         cancelText: 'No',
	//         onOk() {
	//             onToggleIsActive();
	//         }
	//     });
	// }

	// const handleEditArticle = (row) => {
	//     const initialValue = {
	//         ...row,
	//     }
	//     onEditArticle(initialValue);
	// }

	const onToggleIs50Content = async (data) => {
		await dispatch(updateOrCreateArticle(data));
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const toggleIs50Content = (row) => {
		const textDisable = row.is50Content ? 'hide' : 'show';
		Modal.confirm({
			title: `Are you sure you want to ${textDisable} this article in 'Learn' section?`,
			okText: 'Yes',
			cancelText: 'No',
			onOk() {
				return onToggleIs50Content({
					id: documentId(row),
					is50Content: !row.is50Content,
				});
			},
		});
	};

	const toggleHasReplay = async (row) => {
		await dispatch(
			updateOrCreateArticle({ id: documentId(row), hasReplay: !row.hasReplay }),
		);
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const handleEditMeta = (row, title) => {
		// ---------------------------------------------- //
		// -------------- default meta ------------------ //
		// ---------------------------------------------- //
		let { meta } = row;
		if (!meta) {
			meta = getDefaultArticleMeta(row);
		}
		setArticleSelected({ ...row, meta });
		setPopoverTitle(title);
		setOpenMetaPopover(true);
	};

	const handleSaveMeta = async (dataMeta) => {
		await dispatch(updateOrCreateArticle(dataMeta));
		setIsRefreshDataLayout((prev) => prev + 1);
		setOpenMetaPopover(false);
	};

	const onDeleteArticle = async (values) => {
		await dispatch(deleteArticle(values));
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const toggleCustomMeta = async (row) => {
		const { meta } = row;
		if (!meta) return;
		const { isCustom } = meta;
		const textCustom = isCustom ? 'remove' : 'use';

		Modal.confirm({
			title: `Are you sure you want to ${textCustom} custom meta data for this article?`,
			okText: 'Yes',
			cancelText: 'No',
			onOk() {
				return handleSaveMeta({
					id: documentId(row),
					meta: { ...meta, isCustom: !meta.isCustom },
				});
			},
		});
	};

	const columns = [
		{
			title: 'Image',
			dataIndex: 'illustration',
			render: (value, row) => <ArticleImage article={row} size={140} />,
		},
		{
			title: 'Title',
			dataIndex: 'title',
			render: (value, row) => (
				<a
					href={`${config.appRoot}/article/${row?.slug}`}
					target='_blank'
					rel='noreferrer'
					className='link'
					style={{ fontSize: 14, fontWeight: 700, lineHeight: 1.3 }}
				>
					{value}
				</a>
			),
			...TableColumns.columnMinWidth(300),
		},
		{
			title: 'Topic',
			dataIndex: 'topic',
			align: 'center',
			render: (value, row) => (
				<b
					style={{
						textTransform: 'uppercase',
						fontWeight: 700,
						color: theme.color.darkgrey,
					}}
				>
					{value}
				</b>
			),
			...TableColumns.columnMinWidth(70),
		},
		TableColumns.userAvatarColumn(theme, { title: 'Author' }),
		TableColumns.companyLogoColumn(theme, {
			title: 'Company',
			...TableColumns.columnMinWidth(70),
		}),
		TableColumns.dateColumn(theme, {
			title: 'Published at',
			dataIndex: 'publishedAt',
		}),
		TableColumns.toggleColumn(theme, toggleIs50Content, {
			title: 'Learn section',
			dataIndex: 'is50Content',
			...TableColumns.columnMinWidth(100),
		}),
		TableColumns.toggleColumn(theme, toggleHasReplay, {
			title: 'Has replay?',
			dataIndex: 'hasReplay',
			...TableColumns.columnMinWidth(100),
		}),

		TableColumns.toggleColumn(theme, toggleCustomMeta, {
			title: 'Custom meta?',
			dataIndex: ['meta', 'isCustom'],
			...TableColumns.columnMinWidth(100),
		}),
		{
			title: 'actions',
			dataIndex: 'id',
			key: 'id',
			render: (value, row) => {
				return (
					<Space direction='horizontal' size={4}>
						<ActionButton
							tooltipTitle='Edit article'
							onClick={() => onEditArticle(row)}
							icon={<RiEdit2Fill />}
						/>
						<ActionButton
							tooltipTitle='Delete row'
							icon={<RiDeleteBinFill />}
							styles={{ color: theme.color.orange }}
							title='Are you sure you want to delete this row？'
							okText='Yes'
							cancelText='No'
							onConfirm={() => onDeleteArticle(value)}
							withConfirm
						/>
					</Space>
				);
			},
		},

		// TableColumns.metaDataColumn(theme, {
		//   title: 'Meta title 🇬🇧',
		//   dataIndex: ['meta', 'title'],
		//   onClick: handleEditMeta,
		// }),

		// TableColumns.metaDataColumn(theme, {
		//   title: 'Meta description 🇬🇧',
		//   dataIndex: ['meta', 'description'],
		//   onClick: handleEditMeta,
		// }),
		// TableColumns.metaDataColumn(theme, {
		//   title: 'Meta title 🇫🇷',
		//   dataIndex: ['meta', 'fr', 'title'],
		//   onClick: handleEditMeta,
		// }),
		// TableColumns.metaDataColumn(theme, {
		//   title: 'Meta description 🇫🇷',
		//   dataIndex: ['meta', 'fr', 'description'],
		//   onClick: handleEditMeta,
		// }),

		// {
		//     title: 'Meta title 🇬🇧',
		//     dataIndex: ['meta', 'title'],
		//     render: (value, row) => {
		//         const contentMeta = value ? <Button type="text" onClick={() => handleEditMeta(row)}>{value}</Button> : <Tooltip title="Add meta data">
		//                 <Button type="link" icon={<FaPlusSquare style={{margin: -2}}/>} onClick={() => handleEditMeta(row)}/>
		//         </Tooltip>
		//         return contentMeta
		//     },
		//     ...TableColumns.columnMinWidth(300),
		// },

		// TableColumns.createdAtColumn(theme),
		// TableColumns.updatedAtColumn(theme),
		// ...TableColumns.articleTagsColumns(theme),
	];

	useEffect(() => {
		setIsRefreshDataLayout((prev) => prev + 1);
	}, [isRefresh]);

	return (
		<>
			<Table
				path={path}
				// onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefreshDataLayout}
				onSearchDataColumn={onSearchDataColumn}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				rowClassName={(row, index) => (row.is50Content === false ? 'rowDisabled' : '')
				}
				withDynamicSearch
				{...props}
			/>
			<ArticleMetaPopover
				open={openMetaPopover}
				initialValues={articleSelected}
				onSubmit={handleSaveMeta}
				title={popoverTitle}
				onReset={() => setOpenMetaPopover(false)}
			/>
		</>
	);
};

export default ArticleTable;
