import { onEnterBuilder } from '../../../actions/app';
import UserHiddenCompanyMappingsPage from '../../../containers/staff/users/UserHiddenCompanyMappingsPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-users-hidden-company-mappings',
	path: '/staff/user/hidden-company-mappings',
	label: 'Hidden Company Mappings',
	icon: <span>️🙈</span>,
	component: UserHiddenCompanyMappingsPage,
	onEnter: onEnterBuilder({
		origin: UserHiddenCompanyMappingsPage.name,
		shouldGoToPreviousHistory: true,
	}),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
