import { PERMS_GRANT_STAFF } from '../menu.utils';
import MenuBase from '../MenuBase';
import { SafetyCertificateOutlined } from '@ant-design/icons';
import StaffGSSurveyListMenu from './genderscore/StaffGSSurveyListMenu';
import StaffGSSurveyDetailsMenu from './genderscore/StaffGSSurveyDetailsMenu';
import StaffGSRankingsMenu from './genderscore/StaffGSRankingsMenu';
import StaffGSBadgeMakerMenu from './genderscore/StaffGSBadgeMakerMenu';
import { GENDERSCORE_LABEL } from '../../constants/constant';

export const StaffGenderscoreIcon = SafetyCertificateOutlined;

class StaffGenderscoreMenu extends MenuBase {
	static props = {
		name: 'staff-genderscore-menu',
		label: GENDERSCORE_LABEL,
		icon: <StaffGenderscoreIcon />,
		iconLegacy: '🏢',
		children: () => [
			(new StaffGSSurveyListMenu()).build(),
			(new StaffGSSurveyDetailsMenu()).build(),
			(new StaffGSRankingsMenu()).build(),
			(new StaffGSBadgeMakerMenu()).build(),
		],
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffGenderscoreMenu.props);
	}
}

export default StaffGenderscoreMenu;
