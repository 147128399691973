import config from '../../config/config';
import { getCloudinaryPublicId } from '../../utils/common';
import { LocalDebug } from '../../utils/LocalDebug';

const className = 'CloudinaryImageUploader';

export default class CloudinaryImageUploader {
	widget;

	imageUrl;

	cloudinaryPublicId;

	onSuccess;

	constructor({
		folder, uploadPreset = config.cloudinary.upload_preset, maxImageFileSize = 10000000, onSuccess,
	}) {
		try {
			this.onSuccess = onSuccess;
			this.widget = window.cloudinary.createUploadWidget(
				{
					cloudName: config.cloudinary.cloud_name,
					uploadPreset: uploadPreset || config.cloudinary.upload_preset,
					sources: ['local'],
					// use_filename: true,
					// unique_filename: true,
					maxImageFileSize: maxImageFileSize || 10000000,
					clientAllowedFormats: ['image'],
					folder,
				},
				(error, result) => {
					if (!error && result && result.event === 'success') {
						this.imageUrl = result.info.url;
						this.cloudinaryPublicId = getCloudinaryPublicId(this.imageUrl);
						if (this.onSuccess) this.onSuccess(result.info.url);
						this.widget.close();
					}
				},
			);
		} catch (e) {
			LocalDebug.logError({ className, method: 'constructor' }, e);
		}
		LocalDebug.logInfo({ className, method: 'constructor' }, { widget: this.widget });
	}

	showWidget() {
		LocalDebug.logInfo({ className, method: 'showWidget' }, { widget: this.widget });
		this.widget.open();
	}
}
