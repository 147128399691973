import shared, { optionalizeQuestion } from '../../shared';
import QUESTIONS from '../../questions';
import { GENDERSCORE_PILLAR_GENDERDATA_OPTION } from '../../../../../constants/genderscore-pillars';
import {
	GENDERDATA_BOARDPCT_RANGES,
	GENDERDATA_CLEVELPCT_RANGES,
	GENDERDATA_MGTPCT_RANGES,
	GENDERDATA_OVERALLPCT_RANGES,
	GENDERDATA_RATING_RANGES,
	GENDERDATA_TECHPCT_RANGES,
} from '../../ranges';

const section = {
	...GENDERSCORE_PILLAR_GENDERDATA_OPTION,
	details: `
### Let's start by taking stock of the gender situation

Inclusive Tech companies are tracking the percentage of women in their workforce and specifically in Engineering, Data and Product departments.`,
	questions: [
		QUESTIONS.GENDERDATA_OVERALLPCT,
		optionalizeQuestion({
			...QUESTIONS.GENDERDATA_TECHPCT,
			defaultSkippedValue: 27,
		}),
		optionalizeQuestion(QUESTIONS.GENDERDATA_ENGPCT),
		optionalizeQuestion(QUESTIONS.GENDERDATA_PRODUCTPCT),
		optionalizeQuestion(QUESTIONS.GENDERDATA_DATAPCT),
		QUESTIONS.GENDERDATA_MGTPCT,
		QUESTIONS.GENDERDATA_CLEVELPCT,
		QUESTIONS.GENDERDATA_BOARDPCT,
		QUESTIONS.GENDERDATA_ISRATED,
		{
			...QUESTIONS.GENDERDATA_RATING,
			clause: (answers) => answers?.[QUESTIONS.GENDERDATA_ISRATED.questionId] === 'yes',
		},
		QUESTIONS.GENDERDATA_ISTURNOVER,
		optionalizeQuestion({
			...QUESTIONS.GENDERDATA_TURNOVERPCT,
			clause: (answers) => answers?.[QUESTIONS.GENDERDATA_ISTURNOVER.questionId] === 'yes',
		}),
		optionalizeQuestion(QUESTIONS.GENDERDATA_PROMOTEDPCT),
	],
	scoring: [
		{
			question: QUESTIONS.GENDERDATA_OVERALLPCT,
			weight: 50,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_OVERALLPCT_RANGES,
		},
		{
			question: QUESTIONS.GENDERDATA_TECHPCT,
			weight: 30,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_TECHPCT_RANGES,
		},
		{
			question: QUESTIONS.GENDERDATA_MGTPCT,
			weight: 30,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_MGTPCT_RANGES,
		},
		{
			question: QUESTIONS.GENDERDATA_CLEVELPCT,
			weight: 30,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_CLEVELPCT_RANGES,
		},
		{
			question: QUESTIONS.GENDERDATA_BOARDPCT,
			weight: 10,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_BOARDPCT_RANGES,
		},
		{
			question: QUESTIONS.GENDERDATA_RATING,
			weight: 5,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_RATING_RANGES,
		},
		{
			question: QUESTIONS.GENDERDATA_ISTURNOVER,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
	],
};

export default section;
