import {
	Button, Card, Checkbox, Dropdown, Input, Menu, Space,
} from 'antd';
import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useTheme } from '@emotion/react';

const TextSearch = (
	{
		label = 'Search for',
		placeholder = 'Start typing...',
		withDropdown = true,
		onSearch,
		columnsFilterOptions = [],
		defaultColumnsFilterSelected = [],
		onChangeColumnsFilterOptions,
		onCheckedAll,
		onChange,
		initialValue,
		...props
	},
) => {
	const className = 'TextSearch';
	const theme = useTheme();

	const [disabled, setDisabled] = useState(true);

	const handlechangeColumnFilters = (values) => {
		onChangeColumnsFilterOptions(values);
	};

	const toggleCheckedAll = (status) => {
		const selected = status ? columnsFilterOptions.map((c) => c.value) : [];
		// onCheckedAll(selected);
		onChangeColumnsFilterOptions(selected);
	};

	useEffect(() => {
		setDisabled(columnsFilterOptions.length === defaultColumnsFilterSelected.length);
	}, [columnsFilterOptions, defaultColumnsFilterSelected]);

	const menu = (
		<Menu>
			<Space direction="vertical">
				<Card style={{ maxHeight: 200, overflow: 'auto' }}>
					<Checkbox.Group
						style={{ width: '100%' }}
						value={defaultColumnsFilterSelected}
						onChange={handlechangeColumnFilters}
					>
						{columnsFilterOptions.map((option, index) => {
							return (
								<div key={index}>
									<Checkbox
										value={option.value}
										key={index}
									>
										{`${option.label.charAt(0).toUpperCase()}${option.label.slice(1)}`}
									</Checkbox>
								</div>
							);
						})}
					</Checkbox.Group>
				</Card>
				<Space direction="horizontal">
					<Button type="link" disabled={disabled} onClick={() => toggleCheckedAll(true)}>Select All</Button>
					<Button type="link" disabled={!disabled} onClick={() => toggleCheckedAll(false)}>Deselect
						All</Button>
				</Space>
			</Space>
		</Menu>
	);
	return (
		<Space
			size={0}
			style={{ ...props?.style }}
		>
			{withDropdown
				? (
					<Dropdown className="filter" overlay={menu}>
						<Button type='text' style={{ fontSize: 12, fontWeight: 700, padding: '0 8px 0 8px' }}>
							{label}
						</Button>
					</Dropdown>
				)
				: (
					<span
						style={{
							fontSize: 12,
							// fontWeight: 700,
							// padding: '0 8px 0 8px'
						}}
					>
						{label}
					</span>
				)
			}

			<Input
				placeholder={placeholder}
				allowClear={true}
				// onSearch={onSearch}
				onChange={onChange}
				style={{ width: 180, borderRadius: 4 }}
				suffix={<FaSearch style={{ color: theme.color.grey }}/>}
				defaultValue={initialValue}
				// enterButton
			/>
		</Space>

	);
};

export default TextSearch;
