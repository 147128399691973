import { useTheme } from '@emotion/react';
import { Segmented } from 'antd';
import CompanyActionModel from '../../../../models/CompanyActionModel';
import CompanyActionCompletionStatusTag from '../CompanyActionCompletionStatusTag';
import { GS_ACTION_COMPLETION_STATUS_OPTIONS } from '../action-plan';

const CompanyActionModalCompletionStatusMgr = ({
	action,
	onEditAction,
	isSendActionPending = false,
	setIsSendActionPending,
	...props
}) => {
	const className = 'CompanyActionModalCompletionStatusMgr';

	const theme = useTheme();

	action = action instanceof CompanyActionModel
		? action
		: new CompanyActionModel(action);

	const completionStatusOptions = GS_ACTION_COMPLETION_STATUS_OPTIONS
		.map(({ value, label }) => ({
			value,
			label: (
				<div style={{ margin: '4px 0', pointerEvents: 'none' }}>
					<CompanyActionCompletionStatusTag
						action={{ completionStatus: value }}
						style={{
							background: 'none',
							color: value === action.completionStatus ? 'white' : theme.color.fitBlueElectric,
						}}
					/>
				</div>
			),
		}));

	return (
		<Segmented
			disabled={isSendActionPending}
			defaultValue={action.completionStatus}
			onChange={(value) => {
				setIsSendActionPending?.(true);
				onEditAction?.({ action: { ...action, completionStatus: value } });
			}}
			options={completionStatusOptions}
			block
			style={{ marginTop: 12 }}
		/>
	);
};

export default CompanyActionModalCompletionStatusMgr;
