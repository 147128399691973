import { Popover } from 'antd';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTheme } from '@emotion/react';
import CompanyGenderScoreDetailsPanel from './CompanyGenderScoreDetailsPanel';
import CompanyGenderScoreLabel from './CompanyGenderScoreLabel';
import GenderScoreColorRing from './GenderScoreColorRing';
import CompanyModel from '../../../models/CompanyModel';
import { LocalDebug } from '../../../utils/LocalDebug';

dayjs.extend(relativeTime);

const CompanyGenderScoreRingScore = (
	{
		company,
		pillar = 'globalScore',
		value,
		size = 32,
		padding = 0,
		withLabel = false,
		withScore = true,
		withPopover = true,
		notValue = null,
		forceScore,
		ringColor,
		scoreColor,
		girthRatio,
		backgroundRingColor,
		...props
	},
) => {
	const className = 'CompanyGenderScoreRingScore';

	const theme = useTheme();

	const innerSize = size - padding * 2;

	let genderScore = company?.genderScore;
	if (value) {
		if (isNaN(parseInt(value, 10))) {
			genderScore = value;
		} else {
			genderScore = { globalScore: value };
			withPopover = false;
		}
	}
	const score = forceScore || genderScore?.[pillar || 'globalScore'] || 0;

	if (!score) {
		LocalDebug.logInfo({ className }, { pillar, genderScore });
		return notValue;
	}

	if (pillar && pillar !== 'globalScore') {
		withLabel = false;
		withPopover = false;
	}

	const isUnpublished = company
		? !(new CompanyModel(company)?.isGenderScoreBadgeStaffPublished())
		: false;
	if (isUnpublished) {
		withPopover = false;
	}

	const editedCompany = {
		...company,
		genderScore,
	};

	const visual = (
		<div
			style={{
				position: 'relative',
				top: 0,
				left: 0,
				...isUnpublished
					? {
						opacity: 0.3,
						filter: 'grayscale(100%)',
					}
					: {},
				...props?.style,
			}}
		>
			<GenderScoreColorRing
				size={size}
				value={score}
				withBackgroundRing={true}
				ringColor={ringColor}
				girthRatio={girthRatio}
				backgroundRingColor={backgroundRingColor}
				{...props}
			/>
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: size,
					height: size,
					display: 'grid',
					gridTemplateRows: '1fr',
					gridTemplateColumns: '1fr',
					textAlign: 'center',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						fontFamily: 'Space Grotesk',
						width: '100%',
					}}
				>
					{withLabel && (
						<CompanyGenderScoreLabel
							company={editedCompany}
							style={{
								display: 'block',
								fontSize: innerSize / 9,
								fontWeight: 700,
								textTransform: 'uppercase',
								color: scoreColor || theme.color.blue,
								lineHeight: 1,
								...props?.style?.label,
							}}
						/>
					)}
					<div
						style={{
							fontSize: innerSize / (withLabel ? 2.3 : 2),
							fontWeight: 'bold',
							color: scoreColor || theme.color.blue,
							userSelect: 'none',
							lineHeight: 1,
							marginBottom: withLabel ? -6 : 0,
							...props?.style?.score,
						}}>
						{withScore && (forceScore || score)}
					</div>
				</div>
			</div>
		</div>
	);

	if (!withPopover) return visual;

	return (
		<Popover
			placement='right'
			content={<CompanyGenderScoreDetailsPanel company={editedCompany} />}
		>
			{visual}
		</Popover>
	);
};

export default CompanyGenderScoreRingScore;
