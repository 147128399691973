import slugify from '@sindresorhus/slugify';
import TECH_STACK from './hard-skills.json';
import { TECH_STACK_GROUP_OPTIONS } from './hard-skills';
import JOB_ROLE from './job-role.json';
import JOB_LEVEL from './job-level.json';
import JOB_REMOTE from './job-remote.json';
import JOB_CONTRACT from './job-contract.json';
import CAREER_SPECIFICS from './career-specifics.json';
import deiCriteriaJson from './dei-criteria.json';
import COUNTRIES from './countries.json';
import COUNTRIES_BY_SLUGS from './countries-by-slugs.json';
import CITIES from './cities.json';
import roles from '../config/roles';
import {
	FaCheckCircle, FaCode, FaCodeBranch,
	FaComment, FaDownload,
	FaKey,
	FaLock,
	FaRegTimesCircle,
} from 'react-icons/fa';
import {
	Button, Popover, Space, Tag, Tag as AntTag, Tooltip, Typography,
} from 'antd';
import * as dayjs from 'dayjs';
import Logo50inTech from '../components/app/Logo50inTech';
import * as emailTemplates from './email-templates';
import * as coachingStatus from './coachingStatus';
import {
	ACL_ROLE_50_ADMIN_LABEL,
	ACL_ROLE_50_COACH_LABEL,
	ACL_ROLE_50_STAFF_LABEL,
	ACL_ROLE_50_TESTER_LABEL,
	ACL_ROLE_COMPANY_RECRUITER_LABEL,
	ACL_ROLE_DOCUMENT_ADMIN_LABEL,
	ACL_ROLE_DOCUMENT_EDITOR_LABEL,
	ACL_ROLE_UMS_ADMIN,
	ACL_ROLE_UMS_EMPLOYEE,
	ACL_ROLE_UMS_MANAGER,
} from './acls';
import { EMPLOYMENT_VISA_OPTIONS } from './property/employment-visa';
import { ROLES_STAFF_CLIENT_NO_EDITOR } from '../menu/menu.utils';
import { CrownOutlined, PushpinOutlined, ThunderboltOutlined } from '@ant-design/icons';
// import { GenderhireCandidatesSourcedIcon } from '../menu/genderhire/GenderhireCandidatesSourcedMenu';
// import ConfigureCompanyPageMenu from '../menu/configure/ConfigureCompanyPageMenu';

export const PROD_HOSTNAME = 'admin.50intech.com';
export const PROD_STAFF_HOSTNAME = 'admin-staff.50intech.com';
export const STAGING_HOSTNAME = 'admin-staging.50intech.com';
export const STAGING_DEMO_HOSTNAME = 'admin-demo.50intech.com';
export const DEI_CRITERIA_LABELS = deiCriteriaJson;

export const UMS_LABEL = 'Unbiased Management Score';
export const UMS_LABEL_SHORT = 'UMS';
export const GENDERSCORE_LABEL = 'GenderScore';
export const GENDERHIRE_LABEL = 'GenderHire';

export const GENDERSCORE_EMPTY_ICON_IMG = (
	{
		width = 20,
	} = {
		width: 20,
	},
) => (
	<img
		width={width}
		src={[
			'https://res.cloudinary.com/dkatmtbbc/image/upload/w_',
			2 * width,
			',q_100/v1696975565/app/GenderScoreBadgeCheck.png',
		].join('')}
		alt={`${GENDERSCORE_LABEL} empty icon`}
	/>
);
export const UMS_EMPTY_ICON_IMG = GENDERSCORE_EMPTY_ICON_IMG;

const createTagRole = ({
	IconComponent, label, color, tooltip, ...props
}) => <Tooltip title={tooltip}>
	<Tag
		icon={<IconComponent style={{ fontSize: 7, ...props?.style?.icon }} />}
		color={color}
		style={{
			fontSize: 8,
			padding: '2px 4px',
			lineHeight: 1.1,
			verticalAlign: 'middle',
			cursor: 'pointer',
			...props?.style?.tag,
		}}>{label ? <>&nbsp;{label}</> : null}</Tag>
</Tooltip>;

export const TAG_ADMIN = createTagRole({
	label: 'ADMIN',
	IconComponent: FaLock,
	color: 'volcano',
	tooltip: 'This is only visible by 50inTech ADMIN members',
});
export const TAG_NOPRODENV = createTagRole({
	label: 'NOPRODENV',
	IconComponent: FaLock,
	color: 'volcano',
	tooltip: 'This is only visible when NOT in production environment',
});

export const TAG_NOPRODENV_ICON = createTagRole({
	IconComponent: FaLock,
	color: 'volcano',
	tooltip: 'This is only visible when NOT in production environment',
	style: {
		// icon: { fontSize: '12px', color: 'purple' },
		tag: {
			background: 'none', border: 'none', padding: '0px', margin: '0px',
		},
	},
});

export const TAG_TESTER = createTagRole({
	label: 'TESTER',
	IconComponent: FaLock,
	color: 'gold',
	tooltip: 'This is only visible by TESTER roles',
});
export const TAG_STAFF = createTagRole({
	label: 'STAFF',
	IconComponent: FaLock,
	color: 'purple',
	tooltip: 'This is only visible by 50inTech STAFF members',
});

export const TAG_STAFF_ICON = createTagRole({
	label: '',
	IconComponent: FaLock,
	tooltip: 'This is only visible by 50inTech STAFF members',
	style: {
		icon: { fontSize: '12px', color: 'purple' },
		tag: {
			background: 'none', border: 'none', padding: '0px', margin: '0px',
		},
	},
});

export const TAG_ADMIN_ICON = createTagRole({
	label: '',
	IconComponent: FaLock,
	tooltip: 'This is only visible by 50inTech ADMIN members',
	color: 'volcano',
	style: {
		icon: { fontSize: '12px' },
		tag: {
			background: 'none', border: 'none', padding: '0px', margin: '0px',
		},
	},
});

export const ACCOUNT_DETAILS = <span>Manage your 50inTech profile and settings</span>;
export const SOURCING_DETAILS = <span>Talents you selected for one of your jobs, or who directly applied on 50inTech.<br />Open
	their profile to contact them!</span>;
export const ADD_TO_TALENT_POOL_LABEL = 'Add to your Talent Pool';
export const SOURCINGS_TAB_LABEL = 'Applys & Sourcings';
export const SOURCINGS_COL_LABEL = <span>Applys &<br />Sourcings</span>;

export const SHORTLISTS_MENU = 'Saved for later';
export const SHORTLISTS_TITLE = SHORTLISTS_MENU;
export const SHORTLISTS_ICON = <PushpinOutlined />;
export const SHORTLISTS_DETAILS = <span>These are the talents you saved during your navigation.<br />You can now contact
	them, and if they agree to it, source them.</span>;

export const CANDIDATE_POOL_DETAILS = null;

export const CANDIDATES_DETAILS = null;

export const SOURCED_MENU = 'Talent Pool';
export const SOURCED_TITLE = SOURCED_MENU;
export const SOURCED_ICON = <span>💎</span>;
export const SOURCED_DETAILS = <span>Talents you selected for one of your jobs. Open their profile to contact them!</span>;

export const APPLICATIONS_MENU = 'Applications';
export const APPLICATIONS_TITLE = APPLICATIONS_MENU;
// export const APPLICATIONS_ICON = <span>💎</span>;
export const APPLICATIONS_ICON = <span>📥</span>;
export const APPLICATIONS_DETAILS = <span>Talents who directly applied on 50inTech. Open their profile to contact them!</span>;

export const ARCHIVED_MENU = 'Archived';
export const ARCHIVED_TITLE = ARCHIVED_MENU;
// export const APPLICATIONS_ICON = <span>💎</span>;
export const ARCHIVED_ICON = <span>📂</span>;
export const ARCHIVED_DETAILS = null;

export const MESSAGES_MENU = 'Messages';
export const MESSAGES_TITLE = MESSAGES_MENU;
export const MESSAGES_ICON = <span>💌</span>;

export const REPORTS_MENU = 'Reports';
export const REPORTS_TITLE = REPORTS_MENU;
export const REPORTS_ICON = <span>🔬</span>;

export const FEEDBACKS_MENU = 'Feedbacks';
export const FEEDBACKS_TITLE = FEEDBACKS_MENU;
export const FEEDBACKS_ICON = <span>💌</span>;

export const REACH_OUT_MENU = 'Reach Out';
export const REACH_OUT_ICON = <span>💌</span>;

// export const SPOTLIGHT_MENU = 'Spotlight';
export const SPOTLIGHT_MENU = 'Talent Selection';
// export const SPOTLIGHT_MENU = 'Screened by 50';
export const SCREENED_MENU = 'Screened';
// export const SPOTLIGHT_MENU = 'Qualified Talents';
// export const SPOTLIGHT_TITLE = SPOTLIGHT_MENU;
export const SPOTLIGHT_TITLE = SPOTLIGHT_MENU;
export const SCREENED_TITLE = 'Screened by 50inTech';
// export const SPOTLIGHT_ICON = <FaStar />;
// export const SPOTLIGHT_ICON = <span>💎</span>;
// export const SPOTLIGHT_ICON = <span>🔦</span>;
export const SPOTLIGHT_ICON = <span>🚨</span>;
export const SCREENED_ICON = SPOTLIGHT_ICON;
export const SPOTLIGHT_DETAILS = <span>This selection gathers all the female tech talents who were contacted by one of
	50inTech's Talent Coaches.<br />Discover their profile and see if there's a match for one of your openings!</span>;
export const SCREENED_DETAILS = SPOTLIGHT_DETAILS;

export const SUGGESTED_BY_50_MENU = 'Suggested';
export const SUGGESTED_BY_50_TITLE = 'Suggested by 50inTech';
export const SUGGESTED_BY_50_ICON = <span>👀</span>;
export const SUGGESTED_BY_50_DETAILS = null;
// export const SUGGESTED_BY_50_DETAILS = <span>This selection gathers all the female tech talents who were contacted by one of 50inTech's Talent Coaches.<br/>Discover their profile and see if there's a match for one of your openings!</span>;

// export const JOB_OFFERS_ICON = <FaLaptop />;
export const JOB_OFFERS_MENU = 'Jobs';
export const JOB_OFFERS_TITLE = 'Jobs';
// export const JOB_OFFERS_ICON = <FaBriefcase />;
export const JOB_OFFERS_ICON = <span>💼</span>;
export const JOB_OFFERS_DETAILS = <span>Find all your jobs and manage their publication status.</span>;
export const JOB_PRIORITIES_MENU = 'Job Priorities';
export const JOB_PRIORITIES_TITLE = 'Job priorities';
// export const JOB_PRIORITIES_ICON = <FaFire />;
export const JOB_PRIORITIES_ICON = <span>🔥</span>;
export const JOB_PRIORITIES_DETAILS = <span>Select and order your main priorities in terms of jobs.</span>;

export const COMPANY_LIST_MENU = 'Companies';
export const COMPANY_LIST_TITLE = COMPANY_LIST_MENU;
// export const COMPANY_LIST_ICON = <FaBuilding />;
export const COMPANY_LIST_ICON = <span>🏢</span>;
export const COMPANY_LIST_DETAILS = <span>Complete list of companies available on 50inTech.</span>;

export const SEARCH_MENU = 'Talent search';
export const SEARCH_TITLE = SEARCH_MENU;
export const SEARCH_ICON = <span>🔍</span>;
export const SEARCH_DETAILS = null;

export const TALENT_DB_MENU = 'Talent DB';
export const TALENT_DB_TITLE = 'Talent Database';
// export const TALENT_DB_ICON = <FaUsers />;
export const TALENT_DB_ICON = <span>🦸‍♀️</span>;

export const CONFIGURE_USERS_MENU = 'Users';
export const CONFIGURE_USERS_TITLE = CONFIGURE_USERS_MENU;
export const CONFIGURE_USERS_DETAILS = <span>Manage permissions for your colleagues.</span>;
export const CONFIGURE_USERS_ICON = <span>🔑</span>;

export const CONFIGURE_ICON = <span>⚙️</span>;
export const CONFIGURE_MENU = 'Configure';
export const CONFIGURE_TITLE = CONFIGURE_MENU;

export const COMPANY_SETTINGS_ICON = <span>🧰️</span>;
export const COMPANY_SETTINGS_MENU = <>Settings</>;
export const COMPANY_SETTINGS_TITLE = COMPANY_SETTINGS_MENU;
export const COMPANY_SETTINGS_DETAILS = <span>Manage your company settings.</span>;

export const ATS_SETUP_ICON = <span>⚙️</span>;
export const ATS_SETUP_MENU = 'ATS Setup';
export const ATS_SETUP_TITLE = ATS_SETUP_MENU;

export const ATS_ICON = <span>⚙️</span>;
export const ATS_MENU = 'ATS';
export const ATS_TITLE = ATS_MENU;
export const ATS_DETAILS = '';

export const ATS_PIPELINES_ICON = <span>🥓️</span>;
export const ATS_PIPELINES_MENU = 'ATS Pipelines';
export const ATS_PIPELINES_TITLE = ATS_PIPELINES_MENU;
export const ATS_PIPELINES_DETAILS = '';

export const ATS_ARCHIVE_REASONS_ICON = <span>🥓️</span>;
export const ATS_ARCHIVE_REASONS_MENU = 'ATS Archive Reasons';
export const ATS_ARCHIVE_REASONS_TITLE = ATS_ARCHIVE_REASONS_MENU;
export const ATS_ARCHIVE_REASONS_DETAILS = '';

export const COMPANY_ACLS_ICON = <FaKey />;

export const ADMIN_TOOLS_MENU = 'Admin Tools';
export const ADMIN_TOOLS_TITLE = 'Admin tools';
export const ADMIN_TOOLS_ICON = <span>🧰</span>;

export const STAFF_ARTICLES_MENU = 'Articles';
export const STAFF_ARTICLES_TITLE = STAFF_ARTICLES_MENU;
export const STAFF_ARTICLES_ICON = <span>📰</span>;

export const STAFF_TAGS_MENU = 'Tags';
export const STAFF_TAGS_TITLE = STAFF_TAGS_MENU;
export const STAFF_TAGS_ICON = <span>🏷️</span>;

export const STAFF_TAGS_WARNINGS_MENU = 'Tags Warnings';
export const STAFF_TAGS_WARNINGS_TITLE = STAFF_TAGS_WARNINGS_MENU;
export const STAFF_TAGS_WARNINGS_ICON = <span>🚨</span>;

export const TEMPLATE_MAIL_MENU = 'Email templates';
export const TEMPLATE_MAIL_TITLE = TEMPLATE_MAIL_MENU;
export const TEMPLATE_MAIL_ICON = <span>📧</span>;

export const LOG_MENU = 'Logs';
export const LOG_TITLE = LOG_MENU;
export const LOG_ICON = <span>📖</span>;

export const RECRUITER_ACTIVITY_MENU = 'Recruiter activity';
export const RECRUITER_ACTIVITY_TITLE = RECRUITER_ACTIVITY_MENU;
export const RECRUITER_ACTIVITY_ICON = <span>📈</span>;

export const GENDERSCORE_ANSWERS_MENU = 'Survey answers';
export const GENDERSCORE_ANSWERS_TITLE = GENDERSCORE_ANSWERS_MENU;
export const GENDERSCORE_ANSWERS_ICON = <span>📝</span>;

export const aclRoles = [
	{
		label: 'Admin',
		value: ACL_ROLE_50_ADMIN_LABEL,
		color: 'geekblue',
		tagLabel: '50 ADMIN',
		details: 'Super admin role reserved for 50inTech',
		permissions: [
			roles.ADMIN,
		],
	},
	{
		label: 'Staff',
		value: ACL_ROLE_50_STAFF_LABEL,
		color: 'blue',
		tagLabel: '50 STAFF',
		details: 'Staff role reserved for 50inTech',
		permissions: [
			roles.ADMIN,
		],
	},
	{
		label: 'Talent Coach',
		value: ACL_ROLE_50_COACH_LABEL,
		color: 'cyan',
		tagLabel: '50 COACH',
		details: 'Talent coach role reserved for 50inTech',
		permissions: [
			roles.ADMIN,
		],
	},
	{
		label: 'Tester',
		value: ACL_ROLE_50_TESTER_LABEL,
		color: 'lime',
		tagLabel: '50 TESTER',
		details: 'Tester role for staff and clients to try out features on staging environments',
		permissions: [
			roles.ADMIN,
		],
	},
	{
		label: 'Document Admin',
		value: ACL_ROLE_DOCUMENT_ADMIN_LABEL,
		color: 'volcano',
		tagLabel: 'ADMIN',
		details: 'Admin roles have full access to features, and can invite members of the company and manage their permissions.',
		permissions: ROLES_STAFF_CLIENT_NO_EDITOR,
		isCompanyRequired: true,
	},
	{
		label: 'Document Editor',
		value: ACL_ROLE_DOCUMENT_EDITOR_LABEL,
		color: 'gold',
		tagLabel: 'EDITOR',
		details: 'Editor roles can manage the contents of the company page displayed on 50inTech.',
		permissions: ROLES_STAFF_CLIENT_NO_EDITOR,
		isCompanyRequired: true,
	},
	// {
	//     label: 'Document Reader',
	//     value: 'document-reader',
	//     color: 'gold',
	//     tagLabel: 'READER',
	//     permissions: ROLES_STAFF_CLIENT_NO_EDITOR
	//     isCompanyRequired: true
	// },
	{
		label: 'Company Recruiter',
		value: ACL_ROLE_COMPANY_RECRUITER_LABEL,
		color: 'green',
		tagLabel: 'RECRUITER',
		details: 'Recruiter roles can source talents, receive the weekly "Talent" newsletter and notifications when talents apply.',
		permissions: ROLES_STAFF_CLIENT_NO_EDITOR,
		isCompanyRequired: true,
	},
	{
		label: 'UMS Admin',
		value: ACL_ROLE_UMS_ADMIN,
		color: 'volcano',
		tagLabel: 'UMS ADMIN',
		details: 'UMS Admin',
		permissions: [roles.STAFF],
		isCompanyRequired: true,
	},
	{
		label: 'UMS Manager',
		value: ACL_ROLE_UMS_MANAGER,
		color: 'magenta',
		tagLabel: 'UMS MANAGER',
		details: 'UMS Manager',
		permissions: [roles.STAFF, roles.UMS_ADMIN],
		isCompanyRequired: true,
	},
	{
		label: 'UMS Employee',
		value: ACL_ROLE_UMS_EMPLOYEE,
		color: 'green',
		tagLabel: 'UMS EMPLOYEE',
		details: 'UMS Employee',
		permissions: [roles.STAFF, roles.UMS_MANAGER],
		isCompanyRequired: true,
	},
];

export const getAclRoleLabel = (roleValue) => {
	const [roleObj] = aclRoles.filter((r) => r.value === roleValue);
	return roleObj?.tagLabel;
};

export const getAclRoleTag = (roleValue, style, props) => {
	const [roleObj] = aclRoles.filter((r) => r.value === roleValue);
	// localDebug('getAclRoleTag', { roleValue, props }, roleObj);
	if (!roleObj) return <div>no role found</div>;
	const tag = <AntTag color={roleObj.color} style={style} {...props}><strong>{roleObj.tagLabel}</strong></AntTag>;
	return roleObj.details?.length > 0 && props?.withTooltip !== false
		? <Tooltip title={roleObj.details} {...props}>{tag}</Tooltip>
		: tag;
};

export const getAclRoleDetails = (roleValue) => {
	const [roleObj] = aclRoles.filter((r) => r.value === roleValue);
	// localDebug('getAclRoleDetails', { roleValue, props }, roleObj);
	if (!roleObj) return '';
	return roleObj.details || '';
};

export const findOption = (options, value) => {
	const [option] = options.filter((opt) => opt?.value === value);
	return option;
};
export const findNextOption = (options, value) => {
	const index = options.findIndex((opt) => opt?.value === value);
	return options?.(index + 1);
};

export const getCareerExperienceOption = (value) => findOption(CAREER_EXPERIENCE_OPTIONS, value);
export const getExperienceLevelOption = (value) => findOption(EXPERIENCE_LEVEL_OPTIONS, value);
export const getCareerSeekingOption = (value) => findOption(CAREER_SEEKING_OPTIONS, value);

export const getExperienceTag = (value, full = false, notAvailable) => {
	const tags = CAREER_EXPERIENCE_OPTIONS
		.map((o, index) => {
			return o.value === value
				? <AntTag color='blue' key={index}
					style={{
						fontSize: 14, padding: '2px 8px', margin: 0, marginRight: -1,
					}}>{o?.label}</AntTag>
				: full && <AntTag key={index} style={{
					opacity: 0.5, fontSize: 14, padding: '2px 8px', margin: 0, marginRight: -1,
				}}>{o?.label}</AntTag>;
		}).filter((item) => item);
	if (tags?.length) {
		return tags;
	}
	return notAvailable;
};

export const getLevelTag = (value, full = false, notAvailable) => {
	const tags = EXPERIENCE_LEVEL_OPTIONS
		.map((o, index) => {
			return o.value === value
				? <AntTag color='blue' key={index}
					style={{
						fontSize: 14, padding: '2px 8px', margin: 0, marginRight: -1,
					}}>{o?.label}</AntTag>
				: full && <AntTag key={index} style={{
					opacity: 0.5, fontSize: 14, padding: '2px 8px', margin: 0, marginRight: -1,
				}}>{o?.label}</AntTag>;
		}).filter((item) => item);
	if (tags?.length) {
		return tags;
	}
	return notAvailable;
};

export const authState = {
	AUTH_REGISTERED: 'AUTH_REGISTERED',
	AUTH_COMPLETE: 'AUTH_COMPLETE',
};

export const atsProviderOptions = [
	{
		logo: 'https://res.cloudinary.com/dkatmtbbc/image/upload/app/ats/lever.jpg',
		loom: 'https://www.loom.com/share/150efb42056a4fe380a5c4c106edd46e',
		label: 'Lever',
		value: 'lever',
	},
	{
		logo: 'https://res.cloudinary.com/dkatmtbbc/image/upload/app/ats/greenhouse.jpg',
		loom: 'https://www.loom.com/share/71ddbe7d7a764d18a8c2fad28150fd09',
		label: 'Greenhouse',
		value: 'greenhouse',
	},
	{
		label: 'Welcome Kit',
		value: 'wttj',
	},
	{
		label: 'SmartRecruiters',
		value: 'smartrecruiters',
	},
	{
		label: 'Workable',
		value: 'workable',
	},
	{
		label: 'Workday',
		value: 'workday',
	},
	{
		label: 'Ashby',
		value: 'ashby',
	},
	{
		label: 'TeamTailor',
		value: 'teamtailor',
	},
	{
		label: 'TalentPlug',
		value: 'talentplug',
	},
	{
		label: 'Broadbean',
		value: 'broadbean',
	},
	{
		label: 'eQuest',
		value: 'equest',
	},
	{
		logo: 'https://res.cloudinary.com/dkatmtbbc/image/upload/app/ats/lever.jpg',
		loom: 'https://www.loom.com/share/150efb42056a4fe380a5c4c106edd46e',
		label: 'Lever (EU, for endpoint job fetching only)',
		value: 'lever_eu',
	},
	{
		label: 'Personio',
		value: 'personio',
	},
	{
		label: 'Recruitee',
		value: 'recruitee',
	},
	{
		label: 'RecrutDiploma',
		value: 'recrutdiploma',
	},
	{
		label: 'Jobs that make sense',
		value: 'jobsmakesense',
	},
	{
		label: 'Crew',
		value: 'crew',
	},
	{
		label: 'Phenom',
		value: 'phenom',
	},
	{
		label: 'SuccessFactors',
		value: 'successfactors',
	},
	{
		label: 'Criteo',
		value: 'criteo',
	},
	{
		label: 'Slack',
		value: 'slack',
	},
	{
		label: 'OVH',
		value: 'ovh',
	},
	{
		label: 'GHCO',
		value: 'ghco',
	},
	{
		label: 'Deepomatic',
		value: 'deepomatic',
	},
	{
		label: 'Tarides',
		value: 'tarides',
	},
	{
		label: 'Taster',
		value: 'taster',
	},
	{
		label: 'OpenClassrooms',
		value: 'openclassrooms',
	},
	{
		label: 'BNPP',
		value: 'bnpp',
	},
	{
		label: 'UNICC',
		value: 'unicc',
	},
	{
		label: 'AXA',
		value: 'axa',
	},
	{
		label: 'Ivy Partners',
		value: 'ivypartners',
	},
	{
		label: 'Manual',
		value: 'manual',
	},
];

export const JOB_TAG_JOB_ROLE = { value: 'job-role', label: 'Role' };
export const JOB_TAG_JOB_ROLE_GROUP = { value: 'job-role-group', label: 'Role group' };
export const JOB_TAG_JOB_LEVEL = { value: 'job-level', label: 'Experience' };
export const JOB_TAG_JOB_LANGUAGE = { value: 'job-language', label: 'Language' };
export const JOB_TAG_JOB_CONTRACT = { value: 'job-contract', label: 'Contract' };
export const JOB_TAG_LOCATION_CITY = { value: 'location-city', label: 'City' };
export const JOB_TAG_LOCATION_COUNTRY = { value: 'location-country', label: 'Country' };
export const JOB_TAG_LOCATION_CONTINENT = { value: 'location-continent', label: 'Continent' };
export const JOB_TAG_JOB_REMOTE = { value: 'job-remote', label: 'Remote' };
export const JOB_TAG_JOB_DEI_CRITERIA = { value: 'job-dei-criteria', label: 'DE&I Criteria' };
export const JOB_TAG_JOB_DEI_CRITERIA_GROUP = { value: 'job-dei-criteria-group', label: 'DE&I Pillar' };

export const JOB_TAG_JOB_REMOTE_LEGACY = (remoteValue) => (remoteValue === true ? 'fully-remote' : undefined);

export const JOB_TAG_CATEGORIES = [
	JOB_TAG_JOB_ROLE,
	JOB_TAG_JOB_ROLE_GROUP,
	JOB_TAG_JOB_LEVEL,
	JOB_TAG_JOB_CONTRACT,
	JOB_TAG_LOCATION_CITY,
	JOB_TAG_LOCATION_COUNTRY,
	JOB_TAG_LOCATION_CONTINENT,
	JOB_TAG_JOB_REMOTE,
];

export const JOB_TAG_CATEGORIES_STAFF = [
	JOB_TAG_JOB_ROLE,
	JOB_TAG_JOB_ROLE_GROUP,
	JOB_TAG_JOB_LEVEL,
	JOB_TAG_JOB_CONTRACT,
	JOB_TAG_LOCATION_CITY,
	JOB_TAG_LOCATION_COUNTRY,
	JOB_TAG_LOCATION_CONTINENT,
	JOB_TAG_JOB_REMOTE,
	JOB_TAG_JOB_DEI_CRITERIA,
	JOB_TAG_JOB_DEI_CRITERIA_GROUP,
];

export const CONTINENT = 'continent';
export const COUNTRY = 'country';
export const CITY = 'city';

export const USER_TAG_PROFILE_GOAL = { value: 'profile-goal', label: 'Goal' };
export const USER_TAG_LOCATION = { value: 'location', label: 'Candidate location' };
export const USER_TAG_LOCATION_CONTINENT = { value: 'location-continent', label: 'Continent', filterLabel: 'Continent' };
export const USER_TAG_LOCATION_COUNTRY = { value: 'location-country', label: 'Country', filterLabel: 'Country' };
export const USER_TAG_LOCATION_CITY = { value: 'location-city', label: 'City', filterLabel: 'City' };
export const USER_TAG_JOB_SEARCH_JOB_ROLE = { value: 'job-search-job-role', label: 'Desired job role' };
export const USER_TAG_JOB_SEARCH_DEI_CRITERIA = { value: 'job-search-dei-criteria', label: 'DE&I Criteria' };
export const USER_TAG_JOB_SEARCH_DEI_CRITERIA_GROUP = {
	value: 'job-search-dei-criteria-group', label: 'DE&I Criteria group',
};
export const USER_TAG_JOB_SEARCH_JOB_ROLE_GROUP = { value: 'job-search-job-role-group', label: 'Job role group' };
export const USER_TAG_CAREER_EXPERIENCE = { value: 'career-experience', label: 'Experience' };
export const USER_TAG_JOB_SEARCH_JOB_LEVEL = { value: 'job-search-job-level', label: 'Experience in desired role' };
export const USER_TAG_CAREER_HARD_SKILL = { value: 'career-hard-skill', label: 'Hard skill', booleanQuery: 'and' };
export const USER_TAG_CAREER_HARD_SKILL_GROUP = { value: 'career-hard-skill-group', label: 'Hard skill group' };
export const USER_TAG_JOB_SEARCH_JOB_LOCATION = { value: 'job-search-job-location', label: 'Desired job location' };
export const USER_TAG_JOB_SEARCH_JOB_PLACE = { value: 'job-search-job-place', label: 'Job location' };
export const USER_TAG_JOB_SEARCH_JOB_LOCATION_CITY = {
	value: 'job-search-job-location-city', label: 'City (desired, explicit)', filterLabel: 'City',
};
export const USER_TAG_JOB_SEARCH_JOB_LOCATION_COUNTRY = {
	value: 'job-search-job-location-country', label: 'Country (desired, explicit)', filterLabel: 'Country',
};
export const USER_TAG_JOB_SEARCH_JOB_LOCATION_COUNTRY_INDUCED = {
	value: 'job-search-job-location-country-induced', label: 'Country (desired, induced)',
};
export const USER_TAG_JOB_SEARCH_JOB_LOCATION_CONTINENT = {
	value: 'job-search-job-location-continent', label: 'Continent (desired, explicit)', filterLabel: 'Continent',
};
export const USER_TAG_JOB_SEARCH_JOB_LOCATION_CONTINENT_INDUCED = {
	value: 'job-search-job-location-continent-induced', label: 'Continent (desired, induced)',
};
export const LOCATION_CITY = {
	value: 'city',
	label: 'City',
	tagValue: 'job-search-job-location-city',
};
export const LOCATION_COUNTRY = {
	value: 'country',
	 label: 'Country',
	tagValue: 'job-search-job-location-country',
};
export const LOCATION_CONTINENT = {
	value: 'continent',
	label: 'Continent',
	tagValue: 'job-search-job-location-continent',
};
export const USER_TAG_JOB_SEARCH_JOB_REMOTE = { value: 'job-search-job-remote', label: 'Remote preference' };
export const USER_TAG_CAREER_ROLE = { value: 'career-role', label: 'Current role' };
export const USER_TAG_CAREER_ROLE_GROUP = { value: 'career-role-group', label: 'Current role group' };
export const USER_TAG_CAREER_LANGUAGE = { value: 'career-language', label: 'Language', booleanQuery: 'and' };
export const USER_TAG_JOB_SEARCH_EMPLOYMENT_VISA = { value: 'job-search-employment-visa', label: 'Visa' };
export const USER_TAG_JOB_SEARCH_EMPLOYMENT_VISA_FILTER = { value: 'job-search-employment-visa', label: 'Hide users who need a visa' };

export const USER_TAG_CATEGORIES = [
	USER_TAG_CAREER_HARD_SKILL,
	USER_TAG_CAREER_HARD_SKILL_GROUP,
	USER_TAG_CAREER_LANGUAGE,
	USER_TAG_CAREER_ROLE,
	USER_TAG_CAREER_ROLE_GROUP,
	USER_TAG_CAREER_HARD_SKILL,
	USER_TAG_CAREER_HARD_SKILL_GROUP,
	USER_TAG_JOB_SEARCH_DEI_CRITERIA,
	USER_TAG_JOB_SEARCH_DEI_CRITERIA_GROUP,
	USER_TAG_JOB_SEARCH_JOB_ROLE,
	USER_TAG_JOB_SEARCH_JOB_ROLE_GROUP,
	USER_TAG_JOB_SEARCH_JOB_LEVEL,
	USER_TAG_JOB_SEARCH_JOB_LOCATION,
	USER_TAG_JOB_SEARCH_JOB_REMOTE,
	USER_TAG_JOB_SEARCH_JOB_LOCATION_CITY,
	USER_TAG_JOB_SEARCH_JOB_LOCATION_COUNTRY,
	USER_TAG_JOB_SEARCH_JOB_LOCATION_CONTINENT,
	USER_TAG_JOB_SEARCH_JOB_LOCATION_COUNTRY_INDUCED,
	USER_TAG_JOB_SEARCH_JOB_LOCATION_CONTINENT_INDUCED,
	USER_TAG_JOB_SEARCH_JOB_ROLE,
	USER_TAG_JOB_SEARCH_JOB_ROLE_GROUP,
	USER_TAG_PROFILE_GOAL,
	USER_TAG_LOCATION,
	USER_TAG_LOCATION_CONTINENT,
	USER_TAG_LOCATION_COUNTRY,
	USER_TAG_LOCATION_CITY,
	USER_TAG_JOB_SEARCH_EMPLOYMENT_VISA_FILTER,
];

export const USER_TAG_CATEGORIES_FILTERS_RECRUITER = [
	USER_TAG_JOB_SEARCH_JOB_ROLE,
	USER_TAG_JOB_SEARCH_JOB_LEVEL,
	USER_TAG_CAREER_HARD_SKILL,
	USER_TAG_LOCATION_COUNTRY,
];

export const USER_TAG_CATEGORIES_FILTERS_SEARCH_RECRUITER = [
	USER_TAG_JOB_SEARCH_JOB_ROLE,
	USER_TAG_JOB_SEARCH_JOB_LEVEL,
	USER_TAG_CAREER_HARD_SKILL,
	USER_TAG_CAREER_LANGUAGE,
	USER_TAG_JOB_SEARCH_JOB_REMOTE,
];

export const JOB_TAG_CATEGORIES_JOB_FORM = [
	{
		...JOB_TAG_JOB_LANGUAGE,
		mappedFrom: 'language',
		mandatory: true,
		maxTagCount: 3,
		details: 'Candidates need to be proficient in...',
		tooltip: 'Select 1 or more languages',
	},
	{
		...JOB_TAG_JOB_LEVEL,
		mappedFrom: 'experienceLevel',
		mandatory: true,
		maxOptionCount: 2,
		details: 'The expected level of experience of the candidates',
		tooltip: 'Select up to 2 adjacent levels of experience',
	},
	{
		...JOB_TAG_JOB_REMOTE,
		mappedFrom: 'remote',
		mandatory: true,
		details: 'Will your candidates work remotely?',
		tooltip: 'Select 1 or more options',
	},
];

export const JOB_FIELDS_JOB_FORM = [
	...JOB_TAG_CATEGORIES_JOB_FORM,
	{
		mappedFrom: 'title',
		mandatory: true,
	},
	{
		mappedFrom: 'uri',
		mandatory: true,

	},
	{
		mappedFrom: 'salaryRange',
		mandatory: true,
		subFields: [{
			mappedFrom: 'min',
			mandatory: true,
		},
		{
			mappedFrom: 'max',
			mandatory: true,
		},
		{
			mappedFrom: 'currency',
			mandatory: true,
		},
		{
			mappedFrom: 'interval',
			mandatory: true,
		},
		],
	},
	{
		mappedFrom: 'content',
		mandatory: true,
	},
	{
		mappedFrom: 'location',
		mandatory: true,
	},
	{
		mappedFrom: 'contract',
		mandatory: false,
	},
	{
		mappedFrom: 'department',
		mandatory: false,
	},

];
export const JOB_FORM_MANDATORY_FIELDS = [
	...JOB_FIELDS_JOB_FORM.reduce((acc, j) => {
		return j.mandatory ? [...acc, j.mappedFrom] : acc;
	}, []),
];
export const isJobFormFieldMandatory = (fieldName) => {
	return JOB_FORM_MANDATORY_FIELDS.includes(fieldName);
};

export const USER_TAG_CATEGORIES_FILTERS_STAFF = [
	// USER_TAG_PROFILE_GOAL,
];

const accessTypeIcon = (background, children) => (size = 12) => (
	<div style={{
		background,
		color: 'white',
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: size,
		height: size,
		fontSize: size * 2 / 3,
		borderRadius: size / 6,
	}}>
		{children}
	</div>);

export const accessTypeOptions = [
	{
		label: 'API',
		value: 'api',
		icon: accessTypeIcon('purple', <FaCodeBranch />),
	},
	{
		label: 'Endpoint',
		value: 'endpoint',
		icon: accessTypeIcon('darkturquoise', <FaDownload />),
	},
	{
		label: 'Web Scraping',
		value: 'scraping',
		icon: accessTypeIcon('#ffbb00', <FaCode />),
	},
	{
		label: 'Manual',
		value: 'manual',
		icon: accessTypeIcon('darkturquoise', <FaDownload />),
	},
];

const staticSchema = new Map();

const jobSchema = [
	'title',
	'applyUri',
	'uri',
	'content',
	'location',
	'contract',
	'department',
	'remote',
	'slug',
	'team',
];

const userSchema = [
	'slug',
	'myTags',
	'growth',
	'information.avatar',
	'information.activities',
	'information.role',
	'information.position',
	'information.company',
	'information.roleCategory',
	'information.roleSeniority',
	'information.skills',
	'information.interest',
	'information.website',
	'information.linkedIn',
	'information.gitHub',
	'information.twitter',
	'information.facebook',
	'information.instagram',
	'information.biography',
	'information.location.country',
	'information.location.city',
	'information.demo',
	'location.country',
	'location.city',
	'confidential.declaredWoman',
	'confidential.jobSeeking',
	'confidential.gender',
	'confidential.isWoman',
	'confidential.resumeReviewed',
	'confidential.goal',
	'confidential.role',
	'confidential.contentInterest',
	'confidential.availabilityStatus',
	'confidential.communityConsent',
	'mentor.mentorConsent',
	'mentor.mentorSubject',
	'mentor.mentorSubjectCustom',
	'mentor.mentorAvailability',
	'mentee.menteeConsent',
	'contact.role',
	'career.seeking',
	'career.contract',
	'career.department',
	'career.experience',
	'career.expertise',
	'career.personalNeeds',
	'career.hardSkill',
	'career.careerSpecific',
	'jobSearch.deiCriteria',
	'jobSearch.deiCriteriaGroup',
	'jobSearch.employmentVisa',
	'jobSearch.jobLevel',
	'jobSearch.jobRole',
	'jobSearch.jobRoleGroup',
	'jobSearch.jobLocation',
	'jobSearch.jobRemote',
	'jobSearch.jobDetail',
];

const companySchema = [
	'name',
	'path',
	'industry',
	'teamsize',
	'revenue',
	'locations',
	'data.label',
	'data.data.title',
	'data.data.value',
	'data.data.subtitle',
	'fitIntro',
	'fitDesc',
	'desc',
	'about',
	'mission',
	'values',
	'benefits',
	'recruitment',
	'weLike',
	'quote',
	'diversityActions',
	'communities',
	'certifications',
	'slug',
	'deiCriteria',
];

const articleSchema = [
	'topic',
	'title',
	'illustration',
	'content',
	'contentLogged',
	'link',
	'city',
	'country',
	'slug',
	'group',
];

staticSchema.set('Job', jobSchema);
staticSchema.set('User', userSchema);
staticSchema.set('Company', companySchema);
staticSchema.set('Article', articleSchema);

export const schemaOptions = staticSchema;

export const SOURCE_VALUE_50INTECH = 'sourcing-50intech';
export const SOURCE_VALUE_RECRUITER = 'sourcing-client';
export const SOURCE_VALUE_DIRECT_APPLY = 'direct-apply';
export const SOURCE_VALUE_CONNECT_REQUEST = 'connect-request';

export const SOURCE_SHORTLISTS_LIST = [
	SOURCE_VALUE_50INTECH,
	SOURCE_VALUE_RECRUITER,
	SOURCE_VALUE_CONNECT_REQUEST,
];
export const SOURCE_APPLICATIONS_LIST = [
	SOURCE_VALUE_DIRECT_APPLY,
];
export const SOURCE_SOURCED_LIST = [
	SOURCE_VALUE_50INTECH,
	SOURCE_VALUE_RECRUITER,
	SOURCE_VALUE_CONNECT_REQUEST,
];

export const SOURCING_ORIGIN_APPLY = {
	// icon: <FaPaperPlane />,
	icon: APPLICATIONS_ICON,
	value: SOURCE_VALUE_DIRECT_APPLY,
	label: APPLICATIONS_MENU,
	atsIcon: <span>🙋‍♀️</span>,
	atsLabel: 'Candidate',
	color: 'gold',
};
export const SOURCING_ORIGIN_50 = {
	icon: <ThunderboltOutlined />,
	value: SOURCE_VALUE_50INTECH,
	label: 'Matched by 50i',
	atsIcon: <Logo50inTech size={18} style={{ marginBottom: -4 }} />,
	atsLabel: '50inTech',
	color: 'gold',
};
export const SOURCING_ORIGIN_RECRUITER = {
	icon: <CrownOutlined />,
	value: SOURCE_VALUE_RECRUITER,
	// label: 'Sourced by you',
	label: SOURCED_MENU,
	atsIcon: <span>💡</span>,
	atsLabel: 'Recruiter',
	color: 'gold',
};
export const SOURCING_ORIGIN_MESSAGE = {
	icon: <FaComment />,
	value: SOURCE_VALUE_CONNECT_REQUEST,
	label: 'Message',
	atsIcon: <FaComment style={{ marginBottom: -2 }} />,
	atsLabel: 'Direct message',
	color: 'gold',
};
export const sourcingSources = [
	SOURCING_ORIGIN_APPLY,
	SOURCING_ORIGIN_50,
	SOURCING_ORIGIN_RECRUITER,
	SOURCING_ORIGIN_MESSAGE,
];

export const NEW_STATUS = 'new';
export const PROCESS_STATUS = 'process';
export const STANDBY_STATUS = 'standby';
export const STOP_STATUS = 'stop';

export const DEFAULT_STEP_VALUE = 'hr-review';

export const sourcingStatus = [
	{
		value: NEW_STATUS,
		label: 'New',
		color: 'gold',
		step: 1,
	},
	{
		value: 'process',
		label: 'In process',
		color: 'green',
		step: 2,
	},
	{
		value: 'standby',
		label: 'Standby',
		color: 'purple',
		step: 3,
	},
	{
		value: STOP_STATUS,
		label: 'Archived',
		color: 'volcano',
		step: 4,
	},
];

export const STAGE_NEW = 'new';
export const STAGE_SHORTLIST_RECRUITER = 'shortlist-recruiter';
export const STAGE_SHORTLIST_50INTECH = 'shortlist-50intech';
export const STAGE_SHORTLIST_REACHED_OUT = 'shortlist-reached-out';
export const STAGE_SHORTLIST_ATS_PUSHED = 'shortlist-ats-pushed';
export const STAGE_LEAD_NEW = 'lead-new';
export const STAGE_LEAD_REACHED_OUT = 'lead-reached-out';
export const STAGE_LEAD_RESPONDED = 'lead-responded';
export const STAGE_CONTACTED = 'contacted';
export const STAGE_RESPONDED = 'responded';
export const STAGE_STARTED = 'started';
export const STAGE_APPLICANT_NEW = 'applicant-new';
export const STAGE_PHONE_SCREEN = 'phone-screen';
export const STAGE_ONSITE_INTERVIEW = 'onsite-interview';
export const STAGE_INTERVIEWED = 'interviewed';
export const STAGE_TESTED = 'tested';
export const STAGE_REFERENCE_CHECK = 'reference-check';
export const STAGE_OFFER = 'offer';
export const STAGE_HIRED = 'hired';

export const SOURCING_STAGE_OPTION_SHORTLIST_RECRUITER = {
	label: 'Saved talents',
	icon: '🌱',
	value: STAGE_SHORTLIST_RECRUITER,
	aliases: [],
	filters: {
		candidateStage: STAGE_SHORTLIST_RECRUITER,
	},
};
export const SOURCING_STAGE_OPTION_SHORTLIST_50INTECH = {
	label: '50inTech Recommendations',
	icon: '♨️',
	value: STAGE_SHORTLIST_50INTECH,
	aliases: [],
	filters: {
		candidateStage: STAGE_SHORTLIST_50INTECH,
	},
};
export const SOURCING_STAGE_OPTION_SHORTLIST_REACHED_OUT = {
	label: 'Reached out',
	icon: '👋',
	value: STAGE_SHORTLIST_REACHED_OUT,
	aliases: [],
	filters: {
		candidateStage: STAGE_SHORTLIST_REACHED_OUT,
	},
};

export const SOURCING_STAGE_OPTION_LEAD_NEW = {
	label: 'New lead',
	icon: '🌱',
	value: STAGE_LEAD_NEW,
	aliases: [STAGE_NEW],
	filters: {
		candidateStage: STAGE_LEAD_NEW,
	},
};
export const SOURCING_STAGE_OPTION_REACHED_OUT = {
	label: 'Reached out',
	icon: '👋',
	value: STAGE_LEAD_REACHED_OUT,
	aliases: [STAGE_CONTACTED],
	filters: {
		candidateStage: STAGE_LEAD_REACHED_OUT,
	},
};
export const SOURCING_STAGE_OPTION_RESPONDED = {
	label: 'Responded',
	// icon: '🙌',
	icon: '🙋‍♀️',
	value: STAGE_LEAD_RESPONDED,
	aliases: [STAGE_RESPONDED],
	filters: {
		candidateStage: STAGE_LEAD_RESPONDED,
	},
};
export const SOURCING_STAGE_OPTION_NEW_APPLICANT = {
	label: 'New applicant',
	icon: '✍️',
	value: STAGE_APPLICANT_NEW,
	aliases: [
		STAGE_NEW,
		STAGE_LEAD_NEW,
	],
	filters: {
		candidateStage: STAGE_APPLICANT_NEW,
	},
};
export const SOURCING_STAGE_OPTION_PHONE_SCREEN = {
	label: 'Phone screen',
	icon: '☎️',
	value: STAGE_PHONE_SCREEN,
	aliases: [STAGE_STARTED, STAGE_INTERVIEWED],
	filters: {
		candidateStage: STAGE_PHONE_SCREEN,
	},
};
export const SOURCING_STAGE_OPTION_ONSITE_INTERVIEW = {
	label: 'On-site interview',
	icon: '☕',
	value: STAGE_ONSITE_INTERVIEW,
	aliases: [STAGE_INTERVIEWED],
	filters: {
		candidateStage: STAGE_ONSITE_INTERVIEW,
	},
};
export const SOURCING_STAGE_OPTION_TESTED = {
	label: 'Test',
	// icon: '🧪',
	icon: '⚗️',
	value: STAGE_TESTED,
	filters: {
		candidateStage: STAGE_TESTED,
	},
};
export const SOURCING_STAGE_OPTION_REFERENCE_CHECK = {
	label: 'Reference check',
	icon: '📇',
	value: STAGE_REFERENCE_CHECK,
	aliases: [STAGE_TESTED],
	filters: {
		candidateStage: STAGE_REFERENCE_CHECK,
	},
};
export const SOURCING_STAGE_OPTION_OFFER = {
	label: 'Offer',
	icon: '💐',
	value: STAGE_OFFER,
	filters: {
		candidateStage: STAGE_OFFER,
	},
};
export const SOURCING_STAGE_OPTION_HIRED = {
	label: 'Hired',
	icon: '🎉',
	value: STAGE_HIRED,
	filters: {
		candidateStage: STAGE_HIRED,
	},
};
export const SOURCING_STAGE_OPTION_INTERVIEW = {
	label: 'Interviews',
	// category: SOURCING_CATEGORY_OPTION_INTERVIEWS,
};

export const SOURCING_STAGE_OPTIONS = [
	SOURCING_STAGE_OPTION_LEAD_NEW,
	SOURCING_STAGE_OPTION_REACHED_OUT,
	SOURCING_STAGE_OPTION_RESPONDED,
	SOURCING_STAGE_OPTION_NEW_APPLICANT,
	SOURCING_STAGE_OPTION_PHONE_SCREEN,
	SOURCING_STAGE_OPTION_ONSITE_INTERVIEW,
	SOURCING_STAGE_OPTION_TESTED,
	SOURCING_STAGE_OPTION_REFERENCE_CHECK,
	SOURCING_STAGE_OPTION_OFFER,
	SOURCING_STAGE_OPTION_HIRED,
	SOURCING_STAGE_OPTION_INTERVIEW,
];

export const SOURCING_PIPELINE_SHORTLIST = 'shortlist';
export const SOURCING_PIPELINE_LEAD = 'lead';
export const SOURCING_PIPELINE_APPLICANT = 'applicant';
export const SOURCING_PIPELINE_INTERVIEW = 'interview';

export const SOURCING_PIPELINE_OPTION_SHORTLISTED = {
	label: 'Saved for later',
	icon: SHORTLISTS_ICON,
	value: SOURCING_PIPELINE_SHORTLIST,
	options: [
		SOURCING_STAGE_OPTION_SHORTLIST_RECRUITER,
		SOURCING_STAGE_OPTION_SHORTLIST_50INTECH,
		SOURCING_STAGE_OPTION_SHORTLIST_REACHED_OUT,
	],
	filters: {
		candidatePipeline: SOURCING_PIPELINE_SHORTLIST,
		isArchived: false,
	},
};
export const SOURCING_PIPELINE_OPTION_LEAD = {
	label: 'Leads',
	icon: SHORTLISTS_ICON,
	value: SOURCING_PIPELINE_LEAD,
	options: [
		SOURCING_STAGE_OPTION_LEAD_NEW,
		SOURCING_STAGE_OPTION_REACHED_OUT,
		SOURCING_STAGE_OPTION_RESPONDED,
	],
	filters: {
		candidatePipeline: SOURCING_PIPELINE_LEAD,
		isArchived: false,
	},
};
export const SOURCING_PIPELINE_OPTION_APPLICANT = {
	label: 'Applicants',
	icon: APPLICATIONS_ICON,
	value: SOURCING_PIPELINE_APPLICANT,
	options: [
		SOURCING_STAGE_OPTION_NEW_APPLICANT,
		// SOURCING_STAGE_OPTION_LEAD_NEW,
		// SOURCING_STAGE_OPTION_REACHED_OUT,
		// SOURCING_STAGE_OPTION_RESPONDED,
	],
	filters: {
		candidatePipeline: SOURCING_PIPELINE_APPLICANT,
		isArchived: false,
	},
};
export const SOURCING_PIPELINE_OPTION_INTERVIEW = {
	label: 'Interviews',
	icon: <CrownOutlined />,
	value: SOURCING_PIPELINE_INTERVIEW,
	options: [
		SOURCING_STAGE_OPTION_PHONE_SCREEN,
		SOURCING_STAGE_OPTION_ONSITE_INTERVIEW,
		SOURCING_STAGE_OPTION_TESTED,
		SOURCING_STAGE_OPTION_REFERENCE_CHECK,
		SOURCING_STAGE_OPTION_OFFER,
		SOURCING_STAGE_OPTION_HIRED,
	],
	filters: {
		candidatePipeline: SOURCING_PIPELINE_INTERVIEW,
		isArchived: false,
	},
};

export const SOURCING_PIPELINE_OPTIONS = [
	SOURCING_PIPELINE_OPTION_SHORTLISTED,
	SOURCING_PIPELINE_OPTION_LEAD,
	SOURCING_PIPELINE_OPTION_APPLICANT,
	SOURCING_PIPELINE_OPTION_INTERVIEW,
];

export const ARCHIVE_REASON_UNDERQUALIFIED = 'underqualified';
export const ARCHIVE_REASON_UNRESPONSIVE = 'unresponsive';
export const ARCHIVE_REASON_TIMING = 'timing';
export const ARCHIVE_REASON_WITHDREW = 'withdrew';
export const ARCHIVE_REASON_OFFER_DECLINED = 'offer-declined';
export const ARCHIVE_REASON_POSITION_CLOSED = 'position-closed';
export const ARCHIVE_REASON_HIRED = 'hired';

export const ARCHIVE_REASON_UNDERQUALIFIED_OPTION = {
	label: 'Underqualified',
	icon: '💯',
	value: ARCHIVE_REASON_UNDERQUALIFIED,
	filters: {
		isArchived: true,
		atsArchiveReason: ARCHIVE_REASON_UNDERQUALIFIED,
	},
};
export const ARCHIVE_REASON_UNRESPONSIVE_OPTION = {
	label: 'Unresponsive',
	value: ARCHIVE_REASON_UNRESPONSIVE,
	icon: '👻',
	filters: {
		isArchived: true,
		atsArchiveReason: ARCHIVE_REASON_UNRESPONSIVE,
	},
};
export const ARCHIVE_REASON_TIMING_OPTION = {
	label: 'Timing',
	icon: '⌛',
	value: ARCHIVE_REASON_TIMING,
	filters: {
		isArchived: true,
		atsArchiveReason: ARCHIVE_REASON_TIMING,
	},
};
export const ARCHIVE_REASON_WITHDREW_OPTION = {
	label: 'Withdrew',
	icon: '🚪',
	value: ARCHIVE_REASON_WITHDREW,
	filters: {
		isArchived: true,
		atsArchiveReason: ARCHIVE_REASON_WITHDREW,
	},
};
export const ARCHIVE_REASON_OFFER_DECLINED_OPTION = {
	label: 'Offer declined',
	icon: '🙏',
	value: ARCHIVE_REASON_OFFER_DECLINED,
	filters: {
		isArchived: true,
		atsArchiveReason: ARCHIVE_REASON_OFFER_DECLINED,
	},
};
export const ARCHIVE_REASON_POSITION_CLOSED_OPTION = {
	label: 'Position closed',
	icon: '🔇',
	value: ARCHIVE_REASON_POSITION_CLOSED,
	filters: {
		isArchived: true,
		atsArchiveReason: ARCHIVE_REASON_POSITION_CLOSED,
	},
};
export const ARCHIVE_REASON_HIRED_OPTION = {
	label: 'Hired',
	value: ARCHIVE_REASON_HIRED,
	filters: {
		isArchived: true,
		atsArchiveReason: ARCHIVE_REASON_HIRED,
	},
};

export const SOURCING_OPTION_ARCHIVED = {
	label: 'Archived',
	icon: ARCHIVED_ICON,
	options: [
		ARCHIVE_REASON_UNDERQUALIFIED_OPTION,
		ARCHIVE_REASON_UNRESPONSIVE_OPTION,
		ARCHIVE_REASON_TIMING_OPTION,
		ARCHIVE_REASON_WITHDREW_OPTION,
		ARCHIVE_REASON_OFFER_DECLINED_OPTION,
		ARCHIVE_REASON_POSITION_CLOSED_OPTION,
		// ARCHIVE_REASON_HIRED_OPTION,
	],
	filters: {
		isArchived: true,
		candidateStage: null,
		candidatePipeline: null,
	},
};

export const STAGE_SHORTLISTS_LIST = [
	STAGE_NEW,
	STAGE_CONTACTED,
	STAGE_RESPONDED,
];
export const STAGE_SOURCED_LIST = [
	STAGE_STARTED,
	STAGE_CONTACTED,
	STAGE_RESPONDED,
	STAGE_INTERVIEWED,
	STAGE_TESTED,
	STAGE_OFFER,
	STAGE_HIRED,
];
export const STAGE_APPLICATIONS_LIST = [
	STAGE_NEW,
	STAGE_CONTACTED,
	STAGE_APPLICANT_NEW,
	STAGE_RESPONDED,
	STAGE_STARTED,
	// STAGE_ACCEPTED,
	STAGE_INTERVIEWED,
	STAGE_TESTED,
	STAGE_OFFER,
	STAGE_HIRED,
];
export const sourcingStages = [
	{
		value: STAGE_NEW, label: 'New', color: 'gold', step: 1,
	},
	{
		value: STAGE_CONTACTED, label: 'Contacted', color: 'cyan', step: 2,
	},
	{
		value: STAGE_RESPONDED, label: 'Responded', color: 'cyan', step: 3,
	},
	{
		value: STAGE_STARTED, label: 'Started', color: 'geekblue', step: 4,
	},
	// { value: STAGE_ACCEPTED, label: 'Interviewed', color: 'geekblue', step: 5 },
	{
		value: STAGE_INTERVIEWED, label: 'Interviewed', color: 'geekblue', step: 5,
	},
	{
		value: STAGE_TESTED, label: 'Tested', color: 'geekblue', step: 6,
	},
	{
		value: STAGE_OFFER, label: 'Offer proposed', color: 'purple', step: 7,
	},
	{
		value: STAGE_HIRED, label: 'Hired', color: 'green', step: 8,
	},
];

export const sourcingSteps = [
	{
		value: 'hr-review',
		label: 'HR Review',
		color: 'gold',
		step: 1,
	},
	{
		value: 'hr-interview',
		label: 'HR Interview',
		color: 'cyan',
		step: 2,
	},
	{
		value: 'biz-interview',
		label: 'Business Interview',
		color: 'geekblue',
		step: 3,
	},
	{
		value: 'offer-proposal',
		label: 'Offer Proposal',
		color: 'purple',
		step: 4,
	},
	{
		value: 'hired',
		label: 'Hired',
		color: '#108ee9',
		step: 5,
	},
];

export const SOURCING_ACTION_CREATION = 'creation';
export const SOURCING_ACTION_ARCHIVE = 'archive';
export const SOURCING_ACTION_STAGE = 'stage';
export const SOURCING_ACTION_STATUS = 'status';
export const SOURCING_ACTION_STEP = 'step';
export const SOURCING_ACTION_COMMENT = 'comment';
export const SOURCING_ACTION_CONTACT = 'contact';
export const SOURCING_ACTION_MANAGER = 'manager';

export const actionOptions = [
	{
		value: SOURCING_ACTION_STAGE,
		label: 'Stage',
	},
	// {
	//     value: SOURCING_ACTION_ARCHIVE,
	//     label: 'Archive'
	// },
	// {
	//     value: SOURCING_ACTION_STATUS,
	//     label: 'Status'
	// },
	// {
	//     value: SOURCING_ACTION_STEP,
	//     label: 'Recruitment step'
	// },
	{
		value: SOURCING_ACTION_COMMENT,
		label: 'Comment',
	},
];

/* Lever
 - 'Underqualified'
 - 'Unresponsive'
 - 'Timing'
 - 'Withdrew'
 - 'Offer declined'
 - 'Position closed'
 - 'Hired'
 */
export const sourcingArchiveReasons = [
	'Underexperienced',
	'Underqualified',
	'Overexperienced',
	'Unresponsive',
	'Timing',
	'Withdrew',
	'Offer declined',
	'Position closed',
	'Position misfit',
	'Salary misfit',
	'Hard skills misfit',
	'Soft skills misfit',
	'Mobility misfit',
	'Contract misfit',
	'Not available anymore',
];

export const DATE_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_ONLY_YEAR = 'YYYY';
export const DATE_FORMAT_NO_TIME = 'DD/MM/YYYY';
export const DATE_FORMAT_NO_TIME_WITH_LINEBREAK = 'DD/MM[\n]YYYY';
export const renderDate = (theme, date, format = DATE_FORMAT) => <Typography
	style={{ fontSize: '8px', color: theme.color.darkgrey, whiteSpace: 'nowrap' }}
>
	{dayjs(date).format(format)}
</Typography>;

export const renderStageTag = (value, row, theme, props = {}) => {
	// const archivedTag = row?.isArchived && <AntTag color='volcano' style={{fontWeight: 'bold'}}>ARCHIVED</AntTag>
	// {row?.archivedAt ? renderDate(theme, row?.archivedAt) : null}
	const { label, color } = (
		((sourcingStages.filter((s) => s.value === value) || [])[0]) || { label: value, color: 'volcano' });
	const {
		renderStyle, withButton, onClick, removeInColumnFilter, ...otherProps
	} = props;
	// const Component = withButton ? Button : 'div';
	const Component = 'div';
	return (
		<Tooltip title='Click to edit stage' style={{ cursor: 'pointer' }}>
			<Component {...otherProps} onClick={() => { if (onClick) onClick(row); }}>
				<Space direction='vertical' size={4} style={{ ...(props?.renderStyle ? props?.renderStyle(row) : {}) }}>
					<AntTag color={color} style={{
						userSelect: 'none', cursor: 'pointer', fontWeight: 'bold', textTransform: 'uppercase',
					}}>{label}</AntTag>
					{/* {archivedTag} */}
					{row?.updatedStageAt || row?.archivedAt || row?.createdAt ? renderDate(theme, row?.updatedStageAt || row?.archivedAt || row?.createdAt) : null}
				</Space>
			</Component>
		</Tooltip>
	);
};

export const renderStepTag = (value, row, theme, props = {}) => {
	const { label, color } = (((sourcingSteps.filter((s) => s.value === value) || [])[0]) || {});
	const {
		renderStyle, withButton, onClick, removeInColumnFilter, ...otherProps
	} = props;
	const Component = withButton ? Button : 'div';
	return (
		<Component {...otherProps} onClick={() => { if (onClick) onClick(row); }}>
			<Space direction='vertical' size={4} style={{ ...(props?.renderStyle ? props?.renderStyle(row) : {}) }}>
				<AntTag color={color} style={{ fontWeight: 'bold' }}>{label}</AntTag>
				{row?.updatedStepAt ? renderDate(theme, row.updatedStepAt) : null}
			</Space>
		</Component>
	);
};

const DisplayTagStatus = ({
	isStopped, status, color, ...popoverProps
}) => {
	const Component = isStopped ? Popover : 'span';
	return (
		<Component {...popoverProps}>
			<AntTag color={color} style={{ fontWeight: 'bold' }}>{status}</AntTag>
		</Component>
	);
};

export const renderStatusTag = (value, row, theme, props = {}) => {
	const { label, color } = (((sourcingStatus.filter((s) => s.value === value) || [])[0]) || {});
	let archiveReasonsContent;
	if (row?.archiveReasons) {
		const { payload } = row.archiveReasons;
		// localDebug('archiveReasons', row.archiveReasons);
		archiveReasonsContent = <Space>
			{/* <UserAvatar user={creator} /> */}
			{([
				...payload?.raison || [], // Levea storage
				...payload?.tags || [], // Legacy storage (hashtag format)
				...payload?.archiveReasons || [], // New storage
			]).map((reason, key) => {
				return <AntTag color='orange' key={key} style={{ fontWeight: 'bold' }}>{reason}</AntTag>;
			})}
		</Space>;
	}

	const {
		renderStyle, withButton, onClick, removeInColumnFilter, ...otherProps
	} = props;
	const Component = withButton ? Button : 'div';

	return (
		<Component {...otherProps} onClick={() => { if (onClick) onClick(row); }}>
			<Space direction='vertical' size={4} style={{ ...(renderStyle ? renderStyle(row) : {}) }}>
				<DisplayTagStatus
					isStopped={row?.status === STOP_STATUS}
					color={color}
					status={label}
					value={row?.archiveReasons}
					content={archiveReasonsContent}
					title={row?.archiveReasons ? 'Archive reasons' : null}
				/>
				{row?.updatedStatusAt ? renderDate(theme, row.updatedStatusAt) : null}
			</Space>
		</Component>
	);
};

export const MESSAGE_DOCUMENT_REF = 'Message';
export const SOURCING_DOCUMENT_REF = 'Sourcing';
export const ACCESS_DOCUMENT_REF = 'CompanyAccess';
export const TAG_RULE_DOCUMENT_REF = 'TagRule';

export const notProvidedStyle = { fontStyle: 'italic', color: '#5D5D5D' };
export const NOT_PROVIDED_YET_LABEL = <span style={notProvidedStyle}>Not provided yet</span>;
export const NOT_ANSWERED_LABEL = <span style={notProvidedStyle}>Not answered</span>;

export const CAREER_SEEKING_ACTIVE_VALUE = 'active';
export const CAREER_SEEKING_ACTIVE_COLOR = '#87d068';
export const CAREER_SEEKING_ACTIVE_TAG_COLOR = '#87d068';
export const CAREER_SEEKING_ACTIVE_TAG_STYLE = {
	background: CAREER_SEEKING_ACTIVE_COLOR, color: 'white', borderColor: CAREER_SEEKING_ACTIVE_COLOR,
};
export const CAREER_SEEKING_ACTIVE_ICON = '🔥';
export const CAREER_SEEKING_ACTIVE_LABEL = 'Actively looking for a job';
export const CAREER_SEEKING_ACTIVE_LABEL_SHORT = 'Active';

export const CAREER_SEEKING_OPEN_VALUE = 'open';
export const CAREER_SEEKING_OPEN_COLOR = '#0000ff';
export const CAREER_SEEKING_OPEN_TAG_COLOR = 'steelblue';
export const CAREER_SEEKING_OPEN_TAG_STYLE = {
	background: CAREER_SEEKING_OPEN_TAG_COLOR, color: 'white', borderColor: 'transparent',
};
export const CAREER_SEEKING_OPEN_ICON = '🤔';
export const CAREER_SEEKING_OPEN_LABEL = 'Open to opportunities';
export const CAREER_SEEKING_OPEN_LABEL_SHORT = 'Open';

export const CAREER_SEEKING_NO_VALUE = 'no';
export const CAREER_SEEKING_NO_COLOR = '#ff0000';
export const CAREER_SEEKING_NO_TAG_COLOR = 'volcano';
export const CAREER_SEEKING_NO_STYLE = { tagColor: CAREER_SEEKING_NO_TAG_COLOR, borderColor: 'transparent' };
export const CAREER_SEEKING_NO_ICON = '😴';
export const CAREER_SEEKING_NO_LABEL = 'Not looking right now';
export const CAREER_SEEKING_NO_LABEL_SHORT = 'Not looking';

export const CAREER_SEEKING_OPTIONS = [
	{
		value: CAREER_SEEKING_ACTIVE_VALUE,
		icon: CAREER_SEEKING_ACTIVE_ICON,
		label: CAREER_SEEKING_ACTIVE_LABEL,
		labelShort: CAREER_SEEKING_ACTIVE_LABEL_SHORT,
		color: CAREER_SEEKING_ACTIVE_COLOR,
		tagStyle: CAREER_SEEKING_ACTIVE_TAG_STYLE,
	},
	{
		value: CAREER_SEEKING_OPEN_VALUE,
		icon: CAREER_SEEKING_OPEN_ICON,
		label: CAREER_SEEKING_OPEN_LABEL,
		labelShort: CAREER_SEEKING_OPEN_LABEL_SHORT,
		color: CAREER_SEEKING_OPEN_COLOR,
		tagStyle: CAREER_SEEKING_OPEN_TAG_STYLE,
	},
	{
		value: CAREER_SEEKING_NO_VALUE,
		icon: CAREER_SEEKING_NO_ICON,
		label: CAREER_SEEKING_NO_LABEL,
		labelShort: CAREER_SEEKING_NO_LABEL_SHORT,
		color: CAREER_SEEKING_NO_COLOR,
		tagStyle: CAREER_SEEKING_NO_STYLE,
	},
	{ value: null, style: {} },
];

export const groupHardSkills = (values) => {
	const groups = {};
	values.forEach((value) => {
		// localDebug({ value });
		const [option] = TECH_STACK.filter((o) => o.value === value || o.label === value);
		const { group, label } = option || { group: 'Custom skills', label: value };
		const gLabel = TECH_STACK_GROUP_OPTIONS.find((o) => o.value === group)?.label || group;
		groups[gLabel] = [...groups[gLabel] || [], label];
	});
	return groups;
};

export const getHardSkillLabel = (value) => {
	const [option] = TECH_STACK.filter((o) => o.value === value);
	const { label } = option || { group: 'Custom skills', label: value };
	return label;
};

export const getCareerSpecificsLabel = (value) => {
	return CAREER_SPECIFICS?.[value];
};

export const getCoachingExperienceLabel = (user) => {
	let xp = parseInt(user?.coaching?.coachingExperience, 10);
	let xpt = parseInt(user?.coaching?.coachingExperienceTech, 10);
	xp = xp >= 0 ? xp : null;
	xpt = xpt >= 0 ? xpt : null;
	if (xp === null && xpt === null) return null;
	// localDebug({ xp, xpt });
	if ((xp && !xpt) || (xp === xpt)) {
		return `${xp} year${xp > 1 ? 's' : ''} in tech`;
	}
	return `${xpt} year${xpt > 1 ? 's' : ''} in tech (${xp} year${xp > 1 ? 's' : ''} overall)`;
};

export const getJobRoleLabel = (value) => {
	return JOB_ROLE[value];
};

export const getJobLevelLabel = (value) => {
	return JOB_LEVEL.find((o) => o.value === value)?.label || value;
};

export const getSortedJobLevels = () => JOB_LEVEL.map((o) => o.value);

export const getJobContractLabel = (value) => {
	return JOB_CONTRACT.find((o) => o.value === value)?.label || value;
};

export const getSortedJobContracts = () => JOB_CONTRACT.map((o) => o.value);

export const getSortedJobRemotes = () => JOB_REMOTE.map((o) => o.value);

export const addEmojiFlagToCountry = (country) => {
	const countryData = COUNTRIES_BY_SLUGS?.[country];
	return countryData
		? `${countryData.label} ${countryData.emoji}`
		: country;
};

// Get the label for the continent from the user tags
export const getContinentLabelFromTags = (continentTags, continentSlug) => {
	const continentData = continentTags
		.find((tag) => tag.value === continentSlug);
	return {
		continentName: continentData?.label,
		continentLabel: continentData?.label,
	};
};
// Get the label for the country from the user tags
export const getCountryLabelFromTags = (countryTags, countrySlug) => {
	const countryData = countryTags
		.find((tag) => tag.value === countrySlug);
	return {
		countryName: countryData?.label,
		countryLabel: `${countryData?.label} ${COUNTRIES_BY_SLUGS[countrySlug]?.emoji || ''}`,
	};
};
// Get the label for the city from the user tags
export const getCityLabelFromTags = (cityTags, citySlug) => {
	const cityData = cityTags
		.find((tag) => tag.value === citySlug);
	const { country, countryName } = CITIES.find((c) => c.value === citySlug);
	return {
		cityName: cityData?.label,
		cityLabel: `${cityData?.label}, ${countryName} ${COUNTRIES_BY_SLUGS[country]?.emoji || ''}`,
	};
};

const LOCATION_LABELS_MAP = new Map();

export const getLocationTagKey = (parentCategory, childCategory) => {
	switch (childCategory) {
		case 'continent':
			return parentCategory === USER_TAG_JOB_SEARCH_JOB_LOCATION.value
				? USER_TAG_JOB_SEARCH_JOB_LOCATION_CONTINENT.value
				: USER_TAG_LOCATION_CONTINENT.value;
		case 'country':
			return parentCategory === USER_TAG_JOB_SEARCH_JOB_LOCATION.value
				? USER_TAG_JOB_SEARCH_JOB_LOCATION_COUNTRY.value
				: USER_TAG_LOCATION_COUNTRY.value;
		case 'city':
			return parentCategory === USER_TAG_JOB_SEARCH_JOB_LOCATION.value
				? USER_TAG_JOB_SEARCH_JOB_LOCATION_CITY.value
				: USER_TAG_LOCATION_CITY.value;
		default:
			return '';
	}
};

export const getJobLocationLabel = ({
	value,
	withCountryCode = false,
	withCountryFlag = false,
	withCityCountry = false,
	withCityCountryCode = false,
	withCityCountryFlag = false,
	withFlagNbsp = false,
} = {
	withCountryCode: false,
	withCountryFlag: false,
	withCityCountry: false,
	withCityCountryCode: false,
	withCityCountryFlag: false,
	withFlagNbsp: false,
}) => {
	try {
		const key = JSON.stringify({
			value,
			withCountryCode,
			withCountryFlag,
			withCityCountry,
			withCityCountryCode,
			withCityCountryFlag,
			withFlagNbsp,
		});
		const stored = LOCATION_LABELS_MAP.get(key);
		if (stored) {
			return stored;
		}

		if (!value) return null;
		const [location, country] = value.split('|');
		let [category, locValue] = location.split(':');
		locValue = slugify(locValue);
		let label = '';

		if (category === 'city') {
			let cityCountry = locValue;
			if (country) {
				const [countryCategory, countryValue] = country.split(':');
				cityCountry = `${locValue}-${countryValue}`;
			}

			const cityData = CITIES
				.find((o) => o.value?.toLowerCase() === (`${cityCountry}`)?.toLowerCase());

			const countryData = withCityCountry && COUNTRIES
				.find((o) => o?.value === cityData?.country);

			label = [
				cityData?.label || locValue,
				[
					countryData?.[withCityCountryCode ? 'code' : 'label'] || cityData?.countryName || cityData?.country,
					withCityCountryFlag
						? countryData?.emoji
						: null,
				]
					.filter((item) => item),
			]
				.filter((item) => item);

			if (withFlagNbsp && label?.[1]?.length > 1) {
				label = <span>{label[0]}, {label[1][0]}&nbsp;{label[1][1]}</span>;
			} else {
				label = [label[0], label?.[1]?.join?.(' ')]
					.filter((item) => item)
					.join(', ');
			}
		}

		if (category === 'country') {
			const countryData = COUNTRIES
				.find((o) => o?.value === locValue);
			label = [
				countryData
					? countryData?.[withCountryCode ? 'code' : 'label']
					: null,
				withCountryFlag
					? countryData?.emoji
					: null,
			]
				.filter((item) => item);
			if (withFlagNbsp && label?.length > 1) {
				label = <span>{label[0]}&nbsp;{label[1]}</span>;
			} else {
				label = label.join(' ');
			}
		}

		if (category === 'continent') {
			label = {
				'anywhere': 'Anywhere',
				'europe': 'Europe',
				'asia': 'Asia',
				'africa': 'Africa',
				'south-america': 'South America',
				'north-america': 'North America',
				'oceania': 'Oceania',
			}[locValue] || locValue;
		}
		LOCATION_LABELS_MAP.set(key, label);
		if (LOCATION_LABELS_MAP.size > 500) {
			const iterator = LOCATION_LABELS_MAP.keys();
			LOCATION_LABELS_MAP.delete(iterator.next().value);
		}
		return label;
	} catch (e) {
		return value;
	}
};

const EMPLOYMENT_VISA_LABELS_MAP = new Map();

export const getEmploymentVisaLabel = ({
	value,
	withFlag = false,
	withFlagNbsp = false,
} = {
	withFlag: false,
	withFlagNbsp: false,
}) => {
	try {
		const key = JSON.stringify({
			value,
			withFlag,
			withFlagNbsp,
		});
		const stored = EMPLOYMENT_VISA_LABELS_MAP.get(key);
		if (stored) {
			return stored;
		}

		if (!value) return null;
		const option = EMPLOYMENT_VISA_OPTIONS
			.find((o) => o.value === value);

		const label = option?.label || value;

		EMPLOYMENT_VISA_LABELS_MAP.set(key, label);
		return label;
	} catch (e) {
		return value;
	}
};

const EMPLOYMENT_ELIGIBILITY_LABELS_MAP = new Map();

export const getEmploymentEligibilityLabel = ({
	values,
	employmentVisaOption,
	withFlag = false,
	withFlagNbsp = false,
} = {
	withFlag: false,
	withFlagNbsp: false,
}) => {
	try {
		const key = JSON.stringify({
			values,
			employmentVisaOption,
			withFlag,
			withFlagNbsp,
		});
		const stored = EMPLOYMENT_ELIGIBILITY_LABELS_MAP.get(key);
		if (stored) {
			return stored;
		}

		if (!(values?.length >= 1)) return null;

		let label = null;
		if (!values.includes(employmentVisaOption.value)) {
			label = employmentVisaOption.eligibilityLabel;
		}

		EMPLOYMENT_ELIGIBILITY_LABELS_MAP.set(key, label);
		return label;
	} catch (e) {
		return null;
	}
};

export const getJobRemoteLabel = (value) => {
	return JOB_REMOTE.find((o) => o.value === value)?.label || value;
};

export const getDEICriteriaLabel = (value) => {
	return DEI_CRITERIA_LABELS[value];
};

export const userTagDefaultStyle = {
	fontSize: 14,
	borderColor: '#ccc',
	color: '#222',
	background: '#f9f9f9',
	margin: 0,
	// marginRight: -1,
};

export const userTagTinyStyle = {
	...userTagDefaultStyle,
	fontSize: 11,
};

export const userTagSmallStyle = {
	...userTagDefaultStyle,
	fontSize: 12,
};

export const userTagMediumStyle = {
	...userTagDefaultStyle,
	fontSize: 14,
};

export const userTagStyle = (size) => {
	return {
		tiny: userTagTinyStyle,
		small: userTagSmallStyle,
		medium: userTagMediumStyle,
	}[size] || userTagMediumStyle;
};

export const userTag50Style = {
	color: '#096dd9',
	background: '#e6f7ff',
	borderColor: '#91d5ff',
};

export const CAREER_EXPERIENCE_LESS_THAN_ONE_VALUE = 'less-than-one';
export const CAREER_EXPERIENCE_LESS_THAN_ONE_LABEL = '<1 year';
export const CAREER_EXPERIENCE_ONE_TO_TWO_VALUE = 'one-to-two';
export const CAREER_EXPERIENCE_ONE_TO_TWO_LABEL = '1-2 years';
export const CAREER_EXPERIENCE_THREE_TO_FOUR_VALUE = 'three-to-four';
export const CAREER_EXPERIENCE_THREE_TO_FOUR_LABEL = '3-4 years';
export const CAREER_EXPERIENCE_FIVE_TO_EIGHT_VALUE = 'five-to-eight';
export const CAREER_EXPERIENCE_FIVE_TO_EIGHT_LABEL = '5-8 years';
export const CAREER_EXPERIENCE_MORE_THAN_NINE_VALUE = 'more-than-nine';
export const CAREER_EXPERIENCE_MORE_THAN_NINE_LABEL = '9+ years';
export const CAREER_EXPERIENCE_OPTIONS = [
	{ value: CAREER_EXPERIENCE_LESS_THAN_ONE_VALUE, label: CAREER_EXPERIENCE_LESS_THAN_ONE_LABEL },
	{ value: CAREER_EXPERIENCE_ONE_TO_TWO_VALUE, label: CAREER_EXPERIENCE_ONE_TO_TWO_LABEL },
	{ value: CAREER_EXPERIENCE_THREE_TO_FOUR_VALUE, label: CAREER_EXPERIENCE_THREE_TO_FOUR_LABEL },
	{ value: CAREER_EXPERIENCE_FIVE_TO_EIGHT_VALUE, label: CAREER_EXPERIENCE_FIVE_TO_EIGHT_LABEL },
	{ value: CAREER_EXPERIENCE_MORE_THAN_NINE_VALUE, label: CAREER_EXPERIENCE_MORE_THAN_NINE_LABEL },
];

export const EXPERIENCE_LEVEL_ANSWER_ENTRY = 'entry';
export const EXPERIENCE_LEVEL_ANSWER_JUNIOR = 'junior';
export const EXPERIENCE_LEVEL_ANSWER_MID = 'mid-level';
export const EXPERIENCE_LEVEL_ANSWER_SENIOR = 'senior';
export const EXPERIENCE_LEVEL_ANSWER_LEAD = 'lead';
export const EXPERIENCE_LEVEL_ANSWER_LEADERSHIP = 'leadership';

export const EXPERIENCE_LEVEL_OPTIONS = [
	{ value: EXPERIENCE_LEVEL_ANSWER_ENTRY, label: 'Entry level', details: 'Looking for the first experience' },
	{ value: EXPERIENCE_LEVEL_ANSWER_JUNIOR, label: 'Junior', details: '1-2 years of experience' },
	{ value: EXPERIENCE_LEVEL_ANSWER_MID, label: 'Mid-level', details: '3-4 years of experience' },
	{ value: EXPERIENCE_LEVEL_ANSWER_SENIOR, label: 'Senior', details: 'Highly skilled, no management experience' },
	{ value: EXPERIENCE_LEVEL_ANSWER_LEAD, label: 'Lead', details: 'Highly skilled, with some management experience' },
	{ value: EXPERIENCE_LEVEL_ANSWER_LEADERSHIP, label: 'Leadership', details: 'Managers, Directors & C-levels' },
];

export const BOOLEAN_YES_ICON = <FaCheckCircle color='darkturquoise' style={{ marginBottom: -2 }} />;
export const BOOLEAN_YES_LABEL = <>{BOOLEAN_YES_ICON} <strong>Yes</strong></>;

export const BOOLEAN_NO_ICON = <FaRegTimesCircle color='red' style={{ marginBottom: -2 }} />;
export const BOOLEAN_NO_LABEL = <>{BOOLEAN_NO_ICON} <strong>No</strong></>;

export const BOOLEAN_OPTIONS = [
	{ label: BOOLEAN_YES_LABEL, value: 'true' },
	{ label: BOOLEAN_NO_LABEL, value: 'false' },
];

export const BOOLEAN_OR_NULL_OPTIONS = [
	{ label: <i style={{ color: '#999' }}>Not set</i>, value: null },
	...BOOLEAN_OPTIONS,
];

export const TRUE_OR_NULL_OPTIONS = [
	{ label: '', value: null },
	{ label: BOOLEAN_YES_LABEL, value: 'true' },
];

export const teamSizeOptions = [
	{
		value: '< 15 employees',
		label: '< 15 employees',
		fr: '< 15 employé·e·s',
	},
	{
		value: '15-50 employees',
		label: '15-50 employees',
		fr: '15-50 employé·e·s',
	},
	{
		value: '50 - 250 employees',
		label: '50 - 250 employees',
		fr: '50-250 employé·e·s',
	},
	{
		value: '250 - 2000 employees',
		label: '250 - 2000 employees',
		fr: '250-2000 employé·e·s',
	},
	{
		value: '> 2000 employees',
		label: '> 2000 employees',
		fr: '> 2000 employé·e·s',
	},
];

export const mailVariableOptions = [
	{
		value: '{{talentFirstName}}',
		label: 'Talent\'s first name',
	},
	{
		value: '{{talentFullName}}',
		label: 'Talent\'s full name',
	},
	{
		value: '{{userFirstName}}',
		label: 'Your first name',
	},
	{
		value: '{{userFullName}}',
		label: 'Your full name',
	},
	{
		value: '{{userPosition}}',
		label: 'Your position',
	},
	{
		value: '{{userCompany}}',
		label: 'Your company',
	},
	{
		value: '{{companyPage}}',
		label: 'Your 50inTech company page',
	},
	{
		value: '{{userCalendly}}',
		label: 'Your Calendly link',
	},
];

const translationOptions = new Map();

export const FRENCH_LANG = 'fr';
export const ENGLISH_LANG = 'en';

const englishVersion = {
	button: 'English Version',
	emoji: '🇬🇧',
	notice: <span>
		You're currently editing the <strong>🇬🇧 English version</strong> of this content
	</span>,
};

const frenchVersion = {
	button: 'French Version',
	emoji: '🇫🇷',
	notice: <span>
		You're currently editing the <strong>🇫🇷 French version</strong> of this content.
		{<>
			<br />
			If you leave it <b>blank</b>, the <b>English version</b> will be used instead.
		</>}
	</span>,
};

translationOptions.set(ENGLISH_LANG, englishVersion);
translationOptions.set(FRENCH_LANG, frenchVersion);
export const translations = translationOptions;

// --------------------- ARTICLE/COMPANY META --------------------------- //
export const EXTRA_META_DESCRIPTION = '~500 characters';
export const EXTRA_META_TITLE = '~100 characters';
export const EXTRA_META_ALT = '~100 characters';
export const EXTRA_META_PUBLISHER = '~100 characters';
export const META_TITLE_MAX_LENGTH = 100;
export const META_DESCRIPTION_MAX_LENGTH = 500;
export const META_ALT_MAX_LENGTH = 100;
export const META_PUBLISHER_MAX_LENGTH = 100;

export const EMAIL_TEMPLATES = emailTemplates;
export const COACHING_STATUS = coachingStatus;

export const EMAIL_MAILJET_URL = 'https://app.mailjet.com';
export const getMailjetTemplateBuildLink = (templateId) => `${EMAIL_MAILJET_URL}/template/${templateId}/build`;
export const getMailjetTemplatePreviewLink = (templateId) => `${EMAIL_MAILJET_URL}/template/${templateId}/preview`;

const objectToQueryString = (obj) => {
	if (!(Object.keys(obj)?.length > 0)) return '';
	const query = Object.entries(obj || {})
		.map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
		.join('&');
	return `?${query}`;
};

export const PATHS = {
	LOGIN: '/login',
	RESET_PASSWORD: '/resetpassword',
	EMAIL_TEMPLATES: '/configure/email-templates',
	COMPANY_PAGE: '/configure/company-page',
	CONFIGURE_SETTINGS: '/configure/settings',
	GENDERSCORE_SURVEY: (path) => `/genderscore/survey${path ? `/${path}` : ''}`,
	GENDERSCORE_RESULTS: '/genderscore',
	UMS_SURVEY: (path) => `/ums/survey${path ? `/${path}` : ''}`,
	UMS_RESULTS: '/ums',
	DISCOVER: (query) => `/${objectToQueryString(query)}`,
};

export const QUESTIONS_SUBPATH = '';

export const LINKS = {
	PRIVACY_POLICY: 'https://drive.google.com/file/d/1j9Kvp9nlkr0LElhjrKMzFGmi-KKgmx9X/view?usp=sharing',
};

export const PrivacyPolicyLink = ({
	title = 'Privacy Policy',
}) => (
	<a
		target='_blank'
		href={LINKS.PRIVACY_POLICY}
		rel='noreferrer'
	>
		{title}
	</a>
);
/**
 * Limit the display of the number of results
 * This limit only affects the indicator of the total
 * and is applied only if no limit is set from AppSetting
 */
export const DEFAULT_TOTAL_INDICATOR_LIMIT = 1000;
