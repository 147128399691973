import { PERMS_GRANT_STAFF } from '../../menu.utils';
import CompanyGenderScoreSurveysPage from '../../../containers/staff/companies/CompanyGenderScoreSurveysPage';
import { GENDERSCORE_EMPTY_ICON_IMG } from '../../../constants/constant';
import MenuBase from '../../MenuBase';
import CompanyGenderScoreRingScore from '../../../components/company/genderscore/CompanyGenderScoreRingScore';
import { UnorderedListOutlined } from '@ant-design/icons';

export const StaffCompaniesGSSurveyListIcon = () => (
	<CompanyGenderScoreRingScore
		size={14}
		scoreColor={'rgba(0,0,0,0.88)'}
		ringColor={'#000000'}
		forceScore={'?'}
		value={67}
	/>
);

class StaffGSSurveyListMenu extends MenuBase {
	static props = {
		name: 'staff-genderscore-surveys',
		path: '/staff/genderscore/surveys',
		label: 'Survey List',
		icon: <UnorderedListOutlined />,
		// icon: () => ({
		// 	size = 24,
		// 	background = 'white',
		// 	color,
		// 	genderScore,
		// 	ringColor,
		// } = {
		// 	size: 24,
		// }) => {
		// 	return (
		// 		<span>
		// 			<div
		// 				style={{
		// 					margin: `0 -${size * 10 / 100}px`,
		// 					background: 'none',
		// 					borderRadius: size * 20 / 100,
		// 					padding: 0,
		// 				}}
		// 			>
		// 				<CompanyGenderScoreRingScore
		// 					size={size}
		// 					scoreColor={color}
		// 					ringColor={'#999'}
		// 					forceScore={<span style={{ color: '#aaa' }}>?</span>}
		// 					value={67}
		// 				/>
		// 			</div>
		// 		</span>
		// 	);
		// },

		iconLegacy: GENDERSCORE_EMPTY_ICON_IMG(),
		Component: CompanyGenderScoreSurveysPage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffGSSurveyListMenu.props);
	}
}

export default StaffGSSurveyListMenu;
