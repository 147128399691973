import { useTheme } from '@emotion/react';
import { BiLinkExternal } from 'react-icons/bi';
import { getCloudinaryPublicId, documentId } from '../../../../utils/common';
import { Space } from 'antd';
import Box from '../../../app/box/Box';
import { GENDERSCORE_PILLAR_DEIACTIONS_VALUE, getPillarLabel } from '../../../../constants/genderscore-pillars';
import GenderScorePillarLogoLabel from '../pillars/GenderScorePillarLogoLabel';
import { Image, Transformation } from 'cloudinary-react';
import { useMemo } from 'react';
import {
	GENDERSCORE_LOG_FROM_ELEMENT_RESOURCE_CARD_LINK,
	writeGenderScoreLog,
} from '../../../../constants/genderscore-logs';
import { FaStar } from 'react-icons/fa';

const TagListSeparator = (
	{
		defaultContainer = 'span',
		placement = 'right',
		withSeparator = true,
		children,
		...props
	},
) => {
	const theme = useTheme();

	// const separator = <span style={{ opacity: .2 }}>&nbsp;\ </span>;
	const separator = placement === 'right'
		? <span style={{ opacity: 0.7, color: props?.separatorColor || theme.color.fitBlueElectric }}>&nbsp;• </span>
		: <span style={{ opacity: 0.7, color: props?.separatorColor || theme.color.fitBlueElectric }}> •&nbsp;</span>;

	return (
		<ListSeparator
			defaultContainer={defaultContainer}
			placement={placement}
			separator={separator}
			withSeparator={withSeparator}
			{...props}
		>
			{children}
		</ListSeparator>
	);
};

const GenderScoreResourcesCard = ({
	company,
	pillar,
	resource = {},
	withPillarHeader = false,
	withPillarTags = false,
	withTags = false,
	...props
}) => {
	const className = 'GenderScoreResourcesCard';

	const theme = useTheme();

	const openLink = (selectedResource, target = '_blank') => {
		writeGenderScoreLog({
			type: GENDERSCORE_LOG_FROM_ELEMENT_RESOURCE_CARD_LINK,
			context: documentId(selectedResource) || selectedResource?.title,
			company,
		});
	};

	const buildPillarContent = ({
		pillar, withLogo, withLabel, withTooltip,
	}) => {
		return (
			<GenderScorePillarLogoLabel
				pillar={pillar}
				withLogo={withLogo}
				withLabel={withLabel}
				withTooltip={withTooltip}
				tooltipTitle={withLabel
					? null
					: <>This resource is useful to improve in the <strong>{getPillarLabel(pillar)}</strong> pillar</>
				}
			/>
		);
	};

	const buildPillarListContent = ({ withLogo, withLabel, withTooltip }) => {
		return (
			<Space
				size={8}
			>
				{(
					resource.pillars?.length > 0
						? resource.pillars
						: [GENDERSCORE_PILLAR_DEIACTIONS_VALUE]
				)
					?.filter((pillar) => getPillarLabel(pillar))
					?.map((pillar) => (
						<div key={pillar}>
							{buildPillarContent({
								pillar, withLogo, withLabel, withTooltip,
							})}
						</div>
					))}

			</Space>
		);
	};

	const memoizedContent = useMemo(() => {
		return (
			<div
				style={{
					position: 'relative',
				}}
			>
				<Box
					style={{
						padding: '0px 24px 24px 24px',
						paddingBottom: 60,
						overflow: 'hidden',
						background: 'white',
					}}
				>
					<Space
						direction='vertical'
						size={0}
						style={{
						}}
					>

						{withPillarHeader && buildPillarListContent({ withLabel: true, withLogo: true, withTooltip: true })}

						{resource.image && resource.image.indexOf('cloudinary') > 0 && (
							<div style={{
								position: 'relative',
								width: 'calc(100% + 48px)',
								maxWidth: 'calc(100% + 48px)',
								height: 200,
								margin: '0 -24px',
								marginBottom: -8,
								overflow: 'hidden',
							}}>
								{resource?.options?.image?.crop === 'fit'
									? (
										<>
											<div
												style={{
													position: 'absolute',
													filter: 'blur(5px) brightness(50%)',
													top: 0,
													left: 0,
													margin: '-25%',
												}}
											>
												<Image width={'150%'} height={'150%'} publicId={getCloudinaryPublicId(resource.image)} secure='true'>
													<Transformation
														width={480}
														height={270}
														crop='thumb'
													/>
												</Image>
											</div>
											<div
												style={{
													position: 'absolute',
													top: 0,
													left: 0,
													padding: 10,
												}}
											>
												<Image width={'100%'} height={'100%'} publicId={getCloudinaryPublicId(resource.image)} secure='true'>
													<Transformation
														width={480}
														height={255}
														crop='pad'
														background='rgb:55ff0000'
														gravity={resource?.extra?.image?.gravity}
													/>
												</Image>
											</div>
										</>
									)
									: (
										<>
											<Image width={'100%'} height={'100%'} publicId={getCloudinaryPublicId(resource.image)} secure='true'>
												<Transformation
													width={480}
													height={270}
													crop='thumb'
													gravity={resource?.extra?.image?.gravity}
												/>
											</Image>
										</>
									)}
							</div>
						)}

						<h5
							style={{
								marginTop: 24,
								marginBottom: 6,
								lineHeight: 1.3,
							}}
						>
							{resource.title}
						</h5>

						<div
							style={{
								fontSize: 10,
								opacity: 0.5,
								marginBottom: 8,
							}}
						>
							<strong>{resource.type}</strong>&nbsp;
							<span>
							from <strong>{resource.source}</strong>
							</span>
						</div>

						<p
							style={{
								fontSize: 12,
								opacity: 0.7,
							}}
						>
							{resource.summary}
						</p>

						{withPillarTags && buildPillarListContent({ withLabel: false, withLogo: true, withTooltip: true })}

						{withTags && (
							<TagListSeparator
								style={{
									marginTop: 18,
									separatorColor: 'purple',
								}}
							>
								{resource.tags?.map?.((tag) => (
									<span
										key={tag}
										style={{
											fontSize: 10,
											color: 'purple',
										}}
									>
										{tag}
									</span>
								))}
							</TagListSeparator>
						)}

						<a
							href={resource.url}
							target='_blank'
							rel="noreferrer"
							onClick={() => openLink(resource)}
							style={{
								position: 'absolute',
								bottom: 24,
								right: 24,
								marginTop: 12,
							}}
						>
							<span>Open&nbsp;</span>

							<BiLinkExternal
								style={{
									marginBottom: -2,
								}}
							/>
						</a>

					</Space>

				</Box>

				{resource.source?.indexOf('50inTech') >= 0 && (
					<div
						style={{
							position: 'absolute',
							top: -3,
							right: 5,
							padding: '2px 5px',
							background: theme.color.fitBlueElectric,
							borderRadius: 3,
							color: 'white',
							fontSize: 12,
							fontWeight: 'bold',
							boxShadow: '0px 5px 20px -8px rgba(0,0,0,.3)',
						}}
					>
						<FaStar style={{ marginBottom: -1 }}/> by 50inTech
					</div>
				)}

			</div>
		);
	}, [resource, withPillarHeader, withPillarTags, withTags]);

	return memoizedContent;
};

export default GenderScoreResourcesCard;
