import { useTheme } from '@emotion/react';
import Table from '../../components/app/table/Table';
import TableColumns from '../../components/app/table/TableColumns';
import { companyGenderScoreScoreColumnsGroup } from '../../components/company/CompanyGenderScoreTableColumns';
import {
	arrayWrap, mergeReducer, sortOn,
} from '../../utils/common';
import QUESTIONS from '../../components/genderscore/data/questions';
import surveys from '../../components/genderscore/data/surveys';
import { getPillarColorMain, getPillarIcon } from '../../constants/genderscore-pillars';
import {
	Col,
	Divider,
	Popover, Progress, Row, Segmented, Space,
} from 'antd';
import SurveyQuestionContent from '../../components/genderscore/survey/SurveyQuestionContent';
import SurveyQuestionDetails from '../../components/genderscore/survey/SurveyQuestionDetails';
import { useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import ReactJsonDebug from '../../components/app/debug/ReactJsonDebug';
import Box from '../../components/app/box/Box';
import { useSelector } from 'react-redux';
import { getCompanies } from '../../reducers/app';
import CompanyLogo from '../../components/company/CompanyLogo';
import { PercentageOutlined, TrophyOutlined } from '@ant-design/icons';

const tableViewOptions = [
	{
		value: 'leaderboard',
		label: <><TrophyOutlined /> <b>Leaderboard</b></>,
	},
	{
		value: 'averages',
		label: <><PercentageOutlined /> <b>Averages</b></>,
	},
];

export const CompanyStubExpand = ({ company, companyName }) => {
	const companies = useSelector(getCompanies);

	if (!company && companyName) {
		company = companies.find((c) => c?.name === companyName);
	}
	return (
		<>
			<CompanyLogo
				size={22}
				company={company}
			/>
			<span>&nbsp;</span>
			<div
				style={{
					fontSize: 12,
					display: 'inline-block',
					width: 'calc(100% - 26px)',
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					verticalAlign: 'middle',
				}}
			>
				{company.name}
			</div>
		</>
	);
};
class QuestionAverageItemClass {
	id;

	answers;

	average;

	question;

	questionId;

	respondants;

	constructor(props) {
		Object.assign(this, props);
		this.id = this.questionId;
		this.question = QUESTIONS[this.questionId];
		if (['number', 'pct'].includes(this.question.input)) {
			this.answers = this.answers.map((item) => ({ ...item, answer: Number(item.answer) }));
		}
	}

	get answersSortedByAnswer() {
		return this.answers?.sort(sortOn({ key: 'answer' }, true));
	}

	get answersSortedByCompany() {
		return this.answers?.sort(sortOn({ key: 'companyName' }, false));
	}

	isRowExpandable() {
		return ['number', 'pct', 'single', 'multiple'].includes(this.question.input);
	}

	expandedRowRender() {
		try {
			switch (this.question.input) {
				case 'number':
				case 'pct':
					return (
						<Row
							gutter={[40, 1]}
						>
							{this?.answersSortedByAnswer
								?.map(({ companyName, answer }, index) => {
									return (
										<Col
											key={companyName}
											span={6}
										>
											<Row
												style={{
													paddingBottom: 1,
													borderBottom: '1px dotted #ddd',
												}}
											>
												<Col
													span={3}
													style={{
														fontSize: 8,
														color: '#999',
														verticalAlign: 'middle',
														paddingTop: 5,
													}}
													align='center'
												>
													<span>#{index + 1}</span>
												</Col>
												<Col span={17}>
													<CompanyStubExpand companyName={companyName} />
												</Col>
												<Col
													span={4}
													align='right'
													style={{
														fontSize: 12,
													}}
												>
													<span><b>{answer}{this.question.suffix}</b></span>
												</Col>
											</Row>
										</Col>
									);
								})}
						</Row>
					);
				case 'single':
				case 'multiple':
					return (
						<Row gutter={[0, 10]}>
							{this.question
								?.options
								?.map(({ label, value }, index) => {
									const answers = this?.answersSortedByCompany
										?.filter(({ answer }) => arrayWrap(answer).includes(value));

									return (
										<Col span={24} key={index}>
											<Box
												style={{
													padding: 20,
													borderRadius: 10,
													// background: 'white',
													width: '100%',
												}}
											>
												<Space
													style={{
														justifyContent: 'space-between',
														alignItems: 'center',
														width: '100%',
													}}
												>
													<div
														style={{
															fontSize: 16,
															fontWeight: 'bold',
														}}
													>
														{label}
													</div>
													<div
														style={{
															fontSize: 12,
															color: '#666',
														}}
													>
														{answers.length} compan{answers?.length > 1 ? 'ies' : 'y'}
													</div>
												</Space>
												<Divider style={{ marginTop: 10, marginBottom: 10 }}/>
												<Row gutter={[10, 0]} style={{ width: '100%' }}>
													{answers
														?.map(({ companyId, companyName }) => {
															return (
																<Col
																	key={companyId}
																	span={4}
																>
																	<CompanyStubExpand companyName={companyName} />
																</Col>
															);
														})
													}
												</Row>
											</Box>
										</Col>
									);
								})
							}
						</Row>
					);
				default:
					return <ReactJsonDebug src={this?.answers} />;
			}
		} catch (e) {
			return (
				<>
					<div>{e?.message}</div>
					<ReactJsonDebug src={this} />
				</>
			);
		}
	}
}

// class AverageItemClasse {
// 	answer;

// 	companyId;

// 	company;

// 	constructor(props) {
// 		Object.assign(this, props);
// 		this.company = companies.find((c) => documentId(c) === this.companyId);
// 		console.log();
// 	}
// }

const GenderScoreRankings = () => {
	const className = 'GenderScoreRankings';
	const theme = useTheme();

	const { isStaffView } = useAuthContext();
	const companies = useSelector(getCompanies);

	const [tableView, setTableView] = useState(tableViewOptions?.[0]?.value);

	return (
		<>
			<Space
				style={{
					width: '100%',
					justifyContent: 'center',
					marginBottom: 12,
				}}
			>
				<Segmented
					size='large'
					options={tableViewOptions}
					onChange={(value) => setTableView(value)}

				/>
			</Space>

			{tableView === 'averages' && (
				<Table
					path={'/genderscore/averages'}
					ItemClass={QuestionAverageItemClass}
					withTableHeader={false}
					withPagination={false}
					expandable={{
						rowExpandable: (row) => row.isRowExpandable(),
						expandedRowRender: (row) => row.expandedRowRender(),
					}}
					columns={[
						...isStaffView ? [TableColumns.jsonColumn(theme)] : [],
						// questionStubCol(theme, { surveyConf }),
						{
							title: 'Question',
							dataIndex: ['question', 'label'],
							render: (value, row) => {
								const question = QUESTIONS[row?.question?.questionId];

								// const surveyConf = surveys?.getSurveyDataOption?.(row?.survey);

								const questionSections = surveys
									.options
									.map(({ sections }) => sections
										.map((section) => section.questions
											.map(({ questionId: qId }) => ({ [qId]: section }))))
									.flat(4)
									.reduce(mergeReducer, {});

								const section = questionSections[question.questionId];

								return (
									<div
										style={{
											borderLeft: `4px solid ${getPillarColorMain(section.value)}`,
											paddingLeft: 8,
										}}
									>
										<Space direction='horizontal' align='start'>
											<Space
												direction="vertical"
												size={4}
												style={{
													fontSize: 12,
													lineHeight: 1.3,
												}}
											>
												<Space
													direction='horizontal'
													style={{
														justifyContent: 'flex-start',
														alignItems: 'center',
														height: 18,
														lineHeight: 1,
													}}
												>
													{getPillarIcon(section?.value, { width: 18, height: 18 })}

													<div
														style={{
															fontSize: 11,
															fontWeight: 'bold',
															color: theme.color.grey,
														}}
													>
														{`${section.label}`}
													</div>
												</Space>

												<Popover
													content={(
														<div
															className={`survey ${section.value}`}
															style={{
																padding: 20,
																maxWidth: 640,
																zoom: 0.7,
															}}
														>
															<SurveyQuestionContent
																question={question}
																pillar={section.value}
																answers={{}}
															/>
														</div>
													)}
												>
													<span
														style={{
															fontSize: 16,
															fontWeight: 'bold',
														}}
													>
														{question.shortLabel}
													</span>
												</Popover>

												<SurveyQuestionDetails
													question={{
														...row,
														ranges: section?.scoring
															?.find((q) => q?.question?.questionId === question?.questionId)
															?.ranges,
													}}
												/>
											</Space>
										</Space>
									</div>
								);
							},
							...TableColumns.columnFixedWidth(320),
						},
						{
							title: 'Answer average',
							dataIndex: 'average',
							render: (value, row) => {
								switch (row?.question?.input) {
									case 'number':
										return (
											<span style={{ fontSize: 16 }}>{value}</span>
										);
									case 'pct':
										return (
											<Progress
												size={52}
												type='circle'
												percent={value}
											/>
										);
									case 'single':
									case 'multiple':
										return value
											.sort(sortOn({ key: 'pct' }, true))
											.map((option, index) => (
												<Row
													key={index}
													gutter={[20, 5]}
													style={{ fontSize: 14 }}
												>
													<Col span={8}>
														<Progress
															size={'small'}
															percent={option.pct}
														/>
													</Col>
													<Col span={16}>
														<b>{option.label}</b>
													</Col>
												</Row>
											));
									default:
										return value;
								}
							},
						},
						{
							title: 'Respondants',
							dataIndex: ['respondants'],
							align: 'center',
							...TableColumns.columnFixedWidth(80),
						},
					]}
				/>
			)}

			{tableView === 'leaderboard' && (
				<Table
					path={'/genderscore/leaderboard'}
					// withTableHeader={false}
					withPagination={false}
					columns={[
						TableColumns.jsonColumn(theme),
						TableColumns.companyStubColumn(theme),
						companyGenderScoreScoreColumnsGroup(theme, { withRank: true }),
					]}
				/>
			)}
		</>
	);
};

export default GenderScoreRankings;
