import { useTheme } from '@emotion/react';
import { BiChevronRight } from 'react-icons/bi';
import ReactMarkdown from 'react-markdown';
import Link from '../../app/Link';
import { ANSWER_VALUE_SKIPPED } from '../data/shared';
import GenderScorePillarLogoLabel from '../../company/genderscore/pillars/GenderScorePillarLogoLabel';
import SurveyQuestionInputNumber from '../../survey/inputs/SurveyQuestionInputNumber';
import SurveyQuestionInputText from '../../survey/inputs/SurveyQuestionInputText';
import SurveyQuestionInputPct from '../../survey/inputs/SurveyQuestionInputPct';
import SurveyQuestionSingleChoice from '../../survey/inputs/SurveyQuestionInputSingleChoice';
import SurveyQuestionMultipleChoice from '../../survey/inputs/SurveyQuestionInputMultipleChoice';
import SurveyQuestionInput from './SurveyQuestionInput';

const SurveyQuestionContent = ({
	question,
	pillar,
	answers,
	value,
	onChange = () => {},
	onSkip = () => {},
}) => {
	const className = 'SurveyQuestionContent';

	const theme = useTheme();

	return (
		<>
			<div
				style={{
					fontSize: 24,
					fontWeight: 300,
					color: '#999',
				}}
			>
				<GenderScorePillarLogoLabel
					pillar={pillar}
					iconSize={22}
					labelSize={16}
					style={{
						icon: {
							marginBottom: -2,
						},
						label: {
							fontWeight: 'bold',
							color: theme.color.darkgrey,
						},
						marginBottom: -10,
					}}
				/>
			</div>

			<span
				style={{
					fontWeight: 'unset',
					color: '#222',
					lineHeight: 1.2,
				}}
			>
				<ReactMarkdown>
					{`## ${question?.label}`}
				</ReactMarkdown>
			</span>
			{question?.sublabel && (
				<div
					style={{
						fontSize: 16,
						fontWeight: 300,
						color: '#444',
						marginBottom: 0,
					}}
				>
					<ReactMarkdown>
						{question?.sublabel}
					</ReactMarkdown>
				</div>
			)}

			<SurveyQuestionInput
				question={question}
				onChange={onChange}
				value={value}
				align={'left'}
			/>

			{question?.optional && (
				<p
					style={{
						marginTop: 30,
						fontSize: 14,
						fontWeight: 300,
						color: '#444',
					}}
				>
					{answers?.[question?.questionId] === ANSWER_VALUE_SKIPPED
						? (
							<>
								You <b>skipped this question</b>.
								You can still answer it if you want,
								or <Link onClick={onSkip}>
									<b>skip it again</b>
									<BiChevronRight
										style={{
											fontSize: 16,
											marginBottom: -4,
										}}
									/>
								</Link>
							</>
						)
						: (
							<>{question?.skipLabel || 'If you cannot answer or if it\'s not relevant'},
										you can <Link onClick={onSkip}>
								<b>skip this question</b>
								<BiChevronRight
									style={{
										fontSize: 16,
										marginBottom: -4,
									}}
								/>
							</Link>
							</>
						)
					}
				</p>
			)}
		</>
	);
};

export default SurveyQuestionContent;
