import talentDb from './users/talent-db';
import userHiddenCompanyMappings from './users/user-hidden-company-mappings';
import { PERMS_GRANT_STAFF } from '../menu.utils';
import MenuBase from '../MenuBase';
import { TeamOutlined } from '@ant-design/icons';

class StaffUsersMenu extends MenuBase {
	static props = {
		name: 'staff-users-menu',
		label: 'Users',
		icon: <TeamOutlined />,
		iconLegacy: <span>👩‍💻</span>,
		children: () => [
			talentDb,
			userHiddenCompanyMappings,
		],
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffUsersMenu.props);
	}
}

export default StaffUsersMenu;
