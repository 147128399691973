import { useTheme } from '@emotion/react';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';
import CompanyActionLibrary from './CompanyActionLibrary';
import { LocalDebug } from '../../../utils/LocalDebug';

const CompanyActionLibraryModal = ({
	actions,
	pillar,
	open,
	setOpen,
	onAddAction,
	onEditAction,
	onDeleteAction,
	isSendActionPending,
	setIsSendActionPending,
}) => {
	const className = 'CompanyActionLibraryModal';

	const theme = useTheme();

	// LocalDebug.logInfo({ className }, { actions: actions?.length });

	return (
		<ModalContainerBase
			open={open}
			setOpen={setOpen}
			width={1200}
			style={{
				position: 'relative',
				marginTop: 30,
				marginBottom: 30,
				maxHeight: 'calc(100vh - 60px)',
			}}
		>
			<div
				style={{
					width: 'calc(100% - 32px)',
					overflow: 'scroll',
					// width: '100%',
					height: 'calc(100vh - 60px - 40px)',
				}}
			>
				<CompanyActionLibrary
					actions={actions}
					pillar={pillar}
					viaModal={true}
					onAddAction={onAddAction}
					onEditAction={onEditAction}
					onDeleteAction={onDeleteAction}
					isSendActionPending={isSendActionPending}
					setIsSendActionPending={setIsSendActionPending}
				/>
			</div>

		</ModalContainerBase>
	);
};

export default CompanyActionLibraryModal;
