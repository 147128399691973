import PageLayout from '../../../components/app/layout/PageLayout';
import SectionTitle from '../../../components/app/SectionTitle';
import UserHiddenCompaniesTable from '../../../components/user/UserHiddenCompanyMappingsTable';

const UserHiddenCompanyMappingsPage = () => {
	return (
		<PageLayout
			withBackground={false}
			title={<SectionTitle title="Hidden Companies" renderAlone={false}/>}
		>
			<UserHiddenCompaniesTable
			/>
		</PageLayout>
	);
};

export default UserHiddenCompanyMappingsPage;
