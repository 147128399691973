import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import PageLayout from '../../../components/app/layout/PageLayout';
import SurveyScoringDetails from '../../../components/genderscore/survey/SurveyScoringDetails';
import SurveySelect from '../../../components/survey/SurveySelect';
import { LocalDebug } from '../../../utils/LocalDebug';
import {
	addQueryParams, documentId, removeQueryParams, sortOn,
} from '../../../utils/common';
import { useContext, useState } from 'react';
import { SurveyContext } from '../../../components/genderscore/survey/SurveyProvider';
import SurveyStatusSelect from '../../../components/genderscore/survey/SurveyStatusSelect';
import { Space } from 'antd';
import { useQueryParams } from '../../../hooks/useQueryParams';
import StaffGSSurveyDetailsMenu from '../../../menu/staff/genderscore/StaffGSSurveyDetailsMenu';
import StaffCompaniesCompanyListMenu from '../../../menu/staff/companies/StaffCompaniesCompanyListMenu';
import StaffGSSurveyListMenu from '../../../menu/staff/genderscore/StaffGSSurveyListMenu';

const CompanyGenderScoreSurveyDetailsPage = () => {
	const className = 'CompanyGenderScoreSurveyDetailsPage';
	const theme = useTheme();
	const dispatch = useDispatch();

	const companySelected = useSelector(getCompanySelected);
	const [status, setStatus] = useState();

	const { setAnswers, setSurveyDraft } = useContext(SurveyContext);

	const queryParams = useQueryParams();

	return (
		<PageLayout
			MenuClass={StaffGSSurveyDetailsMenu}
			rightActions={(
				<Space direction='vertical'>
					{(new StaffCompaniesCompanyListMenu()).getInlineNavLink()}
					{(new StaffGSSurveyListMenu()).getInlineNavLink()}
				</Space>
			)}
		>
			<Space
				style={{
					marginBottom: 12,
				}}
			>

				<b>Status</b>
				<SurveyStatusSelect
					onChange={(value) => setStatus(value)}
					style={{ marginRight: 40 }}
				/>

				<b>Survey</b>
				<SurveySelect
					value={queryParams.surveyId}
					searchOptions={{
						...companySelected ? { companyId: documentId(companySelected) } : {},
						...status ? { status } : {},
					}}
					onChange={async (value) => {
						LocalDebug.logInfo({ className, method: 'onChange' }, { value });
						if (value) {
							addQueryParams({ name: 'surveyId', value: documentId(value) });
						} else {
							removeQueryParams(['surveyId']);
						}
						setSurveyDraft(value);
						setAnswers(value?.answers || {});
					}}
					sorter={(items) => items
						?.sort?.(sortOn({ key: (s) => s?.startedAt }))
						?.sort?.(sortOn({ key: (s) => s?.companyInfo?.name }))
					}
					style={{
						width: 500,
					}}
				/>
			</Space>

			<SurveyScoringDetails />

		</PageLayout>
	);
};

export default CompanyGenderScoreSurveyDetailsPage;
