import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { useQueryParams } from './hooks/useQueryParams';
import { LocalDebug } from './utils/LocalDebug';

const RouteWrapper = ({
	component: Component,
	onEnter,
}) => {
	const className = 'RouteWrapper';

	const location = useLocation();
	const searchParams = useQueryParams();
	const params = useParams();

	// dispatch an action in the page first load
	// the "onEnter" props is not used by React Router since v3
	// so we just simulate it inside a wrapper instead of making a useEffect for each page
	useEffect(() => {
		if (!onEnter) return;
		LocalDebug.logUseEffect({ className, effects: 'location.pathname, onEnter' }, {
			'location.pathname': location.pathname,
			onEnter,
			'Component': Component?.name,
		});
		onEnter({ location: { ...location, query: searchParams }, searchParams, params });
	}, [location.pathname, onEnter]);

	return (
		<Component />
	);
};

export default RouteWrapper;
