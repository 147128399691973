import shared, { optionalizeQuestion } from '../../shared';
import QUESTIONS from '../../questions';
import { GENDERSCORE_PILLAR_GENDERDATA_OPTION } from '../../../../../constants/genderscore-pillars';
import {
	GENDERDATA_BOARDPCT_RANGES,
	GENDERDATA_CLEVELPCT_RANGES,
	GENDERDATA_EGAPRO_SCORE_RANGES,
	GENDERDATA_MGTPCT_RANGES,
	GENDERDATA_OVERALLPCT_RANGES,
	GENDERDATA_RATING_RANGES,
	GENDERDATA_TECHPCT_RANGES,
} from '../../ranges';

const section = {
	...GENDERSCORE_PILLAR_GENDERDATA_OPTION,
	details: `
### Let's start by taking stock of the gender situation

Inclusive Tech companies are tracking the percentage of women in their workforce and specifically in Engineering, Data and Product departments.`,
	questions: [
		QUESTIONS.V3_GD_WORKFORCE_WOMEN_RATIO,
		QUESTIONS.V3_GD_WORKFORCE_WOMEN_TOPMGT_RATIO,
		QUESTIONS.V3_GD_WORKFORCE_WOMEN_OTHERMGT_RATIO,
		QUESTIONS.V3_GD_TECH_EMPLOYEES,
		QUESTIONS.V3_GD_TECH_WOMEN_RATIO,
		QUESTIONS.V3_GD_TECH_WOMEN_TOPMGT_RATIO,
		QUESTIONS.V3_GD_TECH_WOMEN_OTHERMGT_RATIO,
		optionalizeQuestion(QUESTIONS.V3_GD_TECH_WOMEN_ENGINEERING_RATIO),
		optionalizeQuestion(QUESTIONS.V3_GD_TECH_WOMEN_PRODUCT_RATIO),
		optionalizeQuestion(QUESTIONS.V3_GD_TECH_WOMEN_DATA_RATIO),
		QUESTIONS.V3_GD_LABELS_GLASSDOOR_PRESENCE,
		{
			...QUESTIONS.V3_GD_LABELS_GLASSDOOR_SCORE,
			clause: (answers) => answers?.[QUESTIONS.V3_GD_LABELS_GLASSDOOR_PRESENCE.questionId] === 'yes',
		},
		QUESTIONS.V3_GD_LABELS_EGAPRO_MEASUREMENT,
		{
			...QUESTIONS.V3_GD_LABELS_EGAPRO_SCORE,
			clause: (answers) => answers?.[QUESTIONS.V3_GD_LABELS_EGAPRO_MEASUREMENT.questionId] === 'yes',
		},
		QUESTIONS.V3_GD_LABELS_OTHER_CERTS,
		QUESTIONS.V3_GD_RETENTION_MEDIAN_TENURE,
		QUESTIONS.V3_GD_RETENTION_PROMOTION_RATE,
		QUESTIONS.V3_GD_RETENTION_WOMEN_PROMOTION_RATE,
		QUESTIONS.V3_GD_RETENTION_TURNOVER_TRACKING,
		{
			...QUESTIONS.V3_GD_RETENTION_TURNOVER_RATE,
			clause: (answers) => answers?.[QUESTIONS.V3_GD_RETENTION_TURNOVER_TRACKING.questionId]?.startsWith?.('yes'),
		},
		{
			...QUESTIONS.V3_GD_RETENTION_WOMEN_TURNOVER_RATE,
			clause: (answers) => answers?.[QUESTIONS.V3_GD_RETENTION_TURNOVER_TRACKING.questionId] === 'yes-gender-breakdown',
		},
		/*
		QUESTIONS.GENDERDATA_OVERALLPCT,
		optionalizeQuestion({
			...QUESTIONS.GENDERDATA_TECHPCT,
			defaultSkippedValue: 27,
		}),
		optionalizeQuestion(QUESTIONS.GENDERDATA_ENGPCT),
		optionalizeQuestion(QUESTIONS.GENDERDATA_PRODUCTPCT),
		optionalizeQuestion(QUESTIONS.GENDERDATA_DATAPCT),
		QUESTIONS.GENDERDATA_MGTPCT,
		QUESTIONS.GENDERDATA_CLEVELPCT,
		QUESTIONS.GENDERDATA_BOARDPCT,
		QUESTIONS.GENDERDATA_ISRATED,
		{
			...QUESTIONS.GENDERDATA_RATING,
			clause: (answers) => answers?.[QUESTIONS.GENDERDATA_ISRATED.questionId] === 'yes',
		},
		QUESTIONS.GENDERDATA_ISTURNOVER,
		optionalizeQuestion({
			...QUESTIONS.GENDERDATA_TURNOVERPCT,
			clause: (answers) => answers?.[QUESTIONS.GENDERDATA_ISTURNOVER.questionId] === 'yes',
		}),
		optionalizeQuestion(QUESTIONS.GENDERDATA_PROMOTEDPCT),
		*/
	],
	scoring: [
		{
			question: QUESTIONS.V3_GD_WORKFORCE_WOMEN_RATIO,
			weight: 100,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_OVERALLPCT_RANGES,
		},
		{
			question: QUESTIONS.V3_GD_WORKFORCE_WOMEN_TOPMGT_RATIO,
			weight: 80,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_MGTPCT_RANGES,
		},
		{
			question: QUESTIONS.V3_GD_WORKFORCE_WOMEN_OTHERMGT_RATIO,
			weight: 70,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_MGTPCT_RANGES,
		},
		// {
		// 	question: QUESTIONS.V3_GD_TECH_EMPLOYEES,
		// 	weight: 1,
		// 	computer: shared.COMPUTE_PCT,
		// 	ranges: GENDERDATA_OVERALLPCT_RANGES,
		// },
		{
			question: QUESTIONS.V3_GD_TECH_WOMEN_RATIO,
			weight: 60,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_TECHPCT_RANGES,
		},
		{
			question: QUESTIONS.V3_GD_TECH_WOMEN_TOPMGT_RATIO,
			weight: 50,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_MGTPCT_RANGES,
		},
		{
			question: QUESTIONS.V3_GD_TECH_WOMEN_OTHERMGT_RATIO,
			weight: 50,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_MGTPCT_RANGES,
		},
		{
			question: QUESTIONS.V3_GD_LABELS_GLASSDOOR_SCORE,
			weight: 5,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_RATING_RANGES,
		},
		{
			question: QUESTIONS.V3_GD_LABELS_EGAPRO_SCORE,
			weight: 30,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_EGAPRO_SCORE_RANGES,
			clause: (value, answers) => (
				// this answer should be used in the score ONLY IF
				// GENDERDATA_ISEGAPRO answer IS NOT 'not-applicable' (or value -1)
				![-1, 'not-applicable'].includes(answers?.[QUESTIONS.V3_GD_LABELS_EGAPRO_MEASUREMENT.questionId])
			),
		},
		{
			question: QUESTIONS.V3_GD_RETENTION_MEDIAN_TENURE,
			weight: 20,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_GD_RETENTION_PROMOTION_RATE,
			weight: 10,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_OVERALLPCT_RANGES,
		},
		{
			question: QUESTIONS.V3_GD_RETENTION_WOMEN_PROMOTION_RATE,
			weight: 10,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_OVERALLPCT_RANGES,
		},
		{
			question: QUESTIONS.V3_GD_RETENTION_TURNOVER_TRACKING,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
		// {
		// 	question: QUESTIONS.V3_GD_RETENTION_TURNOVER_RATE,
		// 	weight: 1,
		// 	computer: shared.COMPUTE_PCT,
		// 	ranges: GENDERDATA_OVERALLPCT_RANGES,
		// },
		// {
		// 	question: QUESTIONS.V3_GD_RETENTION_WOMEN_TURNOVER_RATE,
		// 	weight: 1,
		// 	computer: shared.COMPUTE_PCT,
		// 	ranges: GENDERDATA_OVERALLPCT_RANGES,
		// },
	],
};

export default section;
