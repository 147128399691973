import {
	Button, Checkbox, Modal, Space, Switch, Tag, Tooltip,
} from 'antd';
import {
	arrayToObjectListByProp, booleanAndReducer, booleanOrReducer, canUserManageRoles, dateDiffFromNow, documentId,
} from '../../utils/common';

import ActionButton from '../app/table/ActionButton';
import CheckboxTable from '../app/table/CheckboxTable';
import { RiEdit2Fill } from 'react-icons/ri';
import Table from '../app/table/Table';
import TableColumns from '../app/table/TableColumns';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { getAclRoleTag } from '../../constants/constant';
import Box from '../app/box/Box';
import CompanyLogo from '../company/CompanyLogo';
import { ACL_EXTERNAL_LABELS, ACL_INTERNAL_LABELS, ACL_ROLE_50_ADMIN_LABEL } from '../../constants/acls';
import moment from 'moment';
import UserAvatar from '../user/widgets/UserAvatar';
import Link from '../app/Link';

const AclUserTable = (
	{
		onDataLoaded,
		path = '/acl/users',
		filterOptions = {},
		isRefresh = 0,
		onSearchDataColumn,
		onToggleIsActive,
		onResendInvitation,
		onEditAcl,
		isEditor,
		...props
	},
) => {
	const theme = useTheme();

	const {
		isAdmin, isDocumentAdmin, isStaffView, isAclEditor, currentUser,
	} = useAuthContext();
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const toggleIsActiveAcl = (row) => {
		const textDisable = row.isActive ? 'disable' : 'active';
		if (false && isAdmin) {
			onToggleIsActive({ ...row, isActive: !row.isActive });
		} else {
			Modal.confirm({
				title: `Are you sure you want to ${textDisable} this role?`,
				okText: 'Yes',
				cancelText: 'No',
				onOk() {
					onToggleIsActive({ ...row, isActive: !row.isActive });
				},
			});
		}
	};

	const handleEditAcl = (row) => {
		const initialValue = {
			...row,
			userId: documentId(row.user),
			roleId: row.role?.label,
			companyId: documentId(row?.company),
		};
		onEditAcl(initialValue);
	};

	const handleRefresh = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const userStubCol = TableColumns.userStubColumn(theme, {
		dataIndex: 'user',
		fixed: 'left',
		avatarSize: 32,
		withEmail: true,
		withProfession: true,
		withLocation: false,
		withDEICriteria: false,
		withBox: false,
		withDropdown: isStaffView,
		withPreview: false,
		withIsNew: false,
		withIsUnviewed: false,
		withTooltip: false,
		withIsNewlyOnboarded: false,
		withCoachedTag: false,
		withResumeTag: false,
		onRefresh: handleRefresh,
	});
	const companyLogoCol = TableColumns.companyLogoColumn(theme);
	const roleLabelCol = TableColumns.userRoleLabelColumn(theme);

	const buildRolesCol = ({
		title,
		allowedRoles = [],
	}) => ({
		title: title || 'Roles',
		key: title || 'Roles',
		dataIndex: 'roles',
		removeInColumnFilter: true,
		render: (value, row) => {
			const roles = allowedRoles
				.map((roleLabel) => row?.roles?.filter((r) => r?.role?.label === roleLabel))
				.flat();
			const rolesByCompany = arrayToObjectListByProp(
				roles,
				(r) => documentId(r?.company) || 'null',
			);
			return (
				<Space size={4} direction='vertical' >
					{Object.entries(rolesByCompany)
						.map(([companyId, companyRoles], companyIndex) => (
							<Box
								key={companyIndex}
								style={{
									padding: 4,
								}}
							>
								<Space size={4}>
									{isStaffView && <CompanyLogo company={companyRoles?.[0]?.company} />}
									<Space direction='vertical' size={2}>
										{companyRoles
											?.map((role, index) => (
												<div
													key={index}
													style={{
														display: 'grid',
														gridTemplateColumns: '52px 120px 1fr',
														verticalAlign: 'middle',
														alignItems: 'center',
														columnGap: 6,
													}}
												>
													<Space size={4}>
														<Switch
															size='small'
															checked={role.isActive}
															onChange={() => {
																toggleIsActiveAcl(role);
															}}
														/>

														<Button
															size='small'
															icon={<RiEdit2Fill />}
															onClick={() => handleEditAcl(role)}
														/>
													</Space>

													<div style={{ opacity: role.isActive ? 1 : 0.5 }}>
														{getAclRoleTag(role?.role?.label)}
													</div>

													<Space
														size={3}
														style={{
															fontSize: 8,
															fontStyle: 'italic',
															color: theme.color.grey,
														}}
													>
														<span>by</span>
														<UserAvatar user={role?.invitedUser} size={20} />
														{/* <span>
															on {moment(role?.createdAt).format('MMM Do, YYYY')}
														</span> */}
													</Space>

												</div>
											))}
									</Space>
								</Space>

							</Box>
						))
					}
				</Space>
			);
		},
	});

	const clientRolesCol = buildRolesCol({ title: 'Roles', allowedRoles: ACL_EXTERNAL_LABELS });
	const staffRolesCol = buildRolesCol({ title: '50inTech roles', allowedRoles: ACL_INTERNAL_LABELS });

	const invitedUserStubCol = TableColumns.userStubColumn(theme, {
		title: 'Invited by',
		dataIndex: 'invitedUser',
		removeInColumnFilter: true,
		onRefresh: handleRefresh,
		...TableColumns.columnMinWidth(200),
	});

	const invitationStatusCol = {
		title: 'Invitation status',
		dataIndex: 'isInvitePending',
		key: 'isInvitePending',
		removeInColumnFilter: true,
		render: (value, row) => {
			const isInvitePending = row?.roles
				?.map((r) => r?.isInvitePending === true)
				?.reduce(booleanOrReducer);
			const invitedAt = row?.roles
				?.map((r) => r?.invitedAt)
				?.reduce((p, c) => (c ? Math.max(p || c, c) : p));

			// if (row?.invitedUser) {
			const diff = dateDiffFromNow(invitedAt, isStaffView ? 'minute' : 'hour');
			return (
				<>
					{isInvitePending === true && (
						<Space direction='vertical' size={6}>
							<Tag color='blue'><b>PENDING</b></Tag>
							{isAclEditor
								&& (
									<Link
										disabled={diff < 1}
										onClick={() => onResendInvitation(row)}
										style={{ whiteSpace: 'nowrap' }}
									>
									Resend&nbsp;invite
										{/* {moment(invitedAt).format('YYYY-MM-DD HH:mm:ss')} */}
									</Link>
								)
							}
						</Space>
					)}
					{isInvitePending !== true && <Tag color={theme.color.green}><b>ACCEPTED</b></Tag>}
				</>
			);
			// }
			return null;
		},
		...TableColumns.columnMinWidth(100),
	};

	// Invitation status
	const createdAtCol = TableColumns.createdAtColumn(theme);
	const updatedAtCol = TableColumns.updatedAtColumn(theme);
	const actionsCol = {
		title: 'actions',
		dataIndex: 'id',
		key: 'id',
		render: (value, row) => {
			return (canUserManageRoles(currentUser, [row?.role?.label])
				&& <Space direction="horizontal" size={4}>
					<Tooltip title="Toggle active">
						{TableColumns.toggleColumn(theme, toggleIsActiveAcl, { dataIndex: 'isActive' }).render(value, row)}
					</Tooltip>
					{/* <Switch onClick={() => toggleIsActiveAcl(row)} checked={row.isActive} /> */}
					<ActionButton
						tooltipTitle="Edit access"
						placement="top"
						onClick={() => handleEditAcl(row)}
						icon={<RiEdit2Fill/>}
					/>
				</Space>
			);
		},
		...TableColumns.columnFixedWidth(160),
	};

	const columns = isStaffView
		? [
			TableColumns.staffColumnWrapper(TableColumns.jsonColumn(theme)),
			userStubCol,
			companyLogoCol,
			invitationStatusCol,
			clientRolesCol,
			staffRolesCol,
		]
		: [
			userStubCol,
			invitationStatusCol,
			clientRolesCol,
		];

	return (
		<>
			<Table
				path={path}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				onSearchDataColumn={onSearchDataColumn}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				rowClassName={(row, index) => {
					const classNames = [];
					if (row?.roles
						?.map((r) => r.isActive === false)
						?.reduce(booleanAndReducer)
					) {
						classNames.push('rowDisabled');
					}
					if (row?.roles
						?.map((r) => r.isInvitePending === true)
						?.reduce(booleanAndReducer)
					) {
						classNames.push('rowPending');
					}
					return classNames.join(' ');
				}}
				withDynamicSearch
				{...props}
			/>
		</>
	);
};

export default AclUserTable;
