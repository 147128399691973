import { useTheme } from '@emotion/react';
import ReactJsonDebug from '../../app/debug/ReactJsonDebug';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import CompanyModel from '../../../models/CompanyModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { Button, Space } from 'antd';
import surveys from '../data/surveys';
import {
	countSurveyCompletedQuestions, countSurveyMandatoryQuestions, countSurveyQuestions, getSurveyAnsweredAndUnansweredQuestions,
	randomlyFakeQuestionAnswer,
	walkSectionQuestionForAnswers,
} from '../data/shared';
import SurveyProvider, { SurveyContext } from '../survey/providers/SurveyProvider';
import Box from '../../app/box/Box';
import { LocalDebug } from '../../../utils/LocalDebug';
import { documentId } from '../../../utils/common';
import { refreshCurrentUser } from '../../../actions/app';
import { request } from '../../../services/api';
import UMSStaffSurveyComputeAnalysisButton from './UMSStaffSurveyComputeAnalysisButton';
import UMSStaffSurveyComputeResultButton from './UMSStaffSurveyComputeResultButton';

const UMSStaffSurveyManager = ({
	survey,
	onSuccess,
}) => {
	const className = 'UMSStaffSurveyManager';
	const theme = useTheme();
	const dispatch = useDispatch();

	LocalDebug.logInfo(
		{ className },
		survey?.survey,
		'answers:',
		Object.values(survey?.answers || {})?.filter?.((i) => i)?.length,
	);
	// const companySelected = useSelector(getCompanySelected);

	const {
		surveyConf, answers, setSurveyDraft, setAnswers, saveAnswers, dispatchSelectSurveyDraft,
	} = useContext(SurveyContext);

	setSurveyDraft(survey);
	setAnswers(survey?.answers);
	// const [surveyConf, setSurveyConf] = useState();
	// const [answers, setAnswers] = useState({});
	// const [answeredQuestions, setAnsweredQuestions] = useState([]);
	// const [unansweredQuestions, setUnansweredQuestions] = useState([]);

	// useEffect(() => {
	// 	if (survey) {
	// 		const surveyOption = surveys?.getSurveyDataOption?.(survey?.survey);
	// 		setSurveyConf(surveyOption);
	// 		setAnswers(survey?.answers || {});
	// 		const { answered, unanswered } = getSurveyAnsweredAndUnansweredQuestions({ surveyConf, answers });
	// 		setAnsweredQuestions(answered);
	// 		setUnansweredQuestions(unanswered);
	// 	} else {
	// 		setSurveyConf(null);
	// 		setAnswers({});
	// 		setAnsweredQuestions([]);
	// 		setUnansweredQuestions([]);
	// 	}
	// }, [survey]);

	if (!surveyConf) return 'No conf found for this survey';

	const handleEditSurvey = async ({ answers: newAnswers, ...data }) => {
		const { survey: newSurvey } = await saveAnswers({
			draft: survey,
			answers: newAnswers,
			withSlack: false,
			withDispatch: false,
			...data,
		});

		LocalDebug.logInfo({ className, method: 'handleEditSurvey' }, { answers: newAnswers?.length, data, newSurvey });

		setSurveyDraft(newSurvey);
		setAnswers(newSurvey?.answers);
		dispatchSelectSurveyDraft(null);
		refreshCurrentUser()(dispatch);
		// onSuccess?.();
	};

	const handleSetAsComplete = async () => handleEditSurvey({
		answers: [],
		isComplete: true,
		status: 'CURRENT',
	});

	const handleSetAsIncomplete = async () => handleEditSurvey({
		answers: [],
		isComplete: false,
		status: 'DRAFT',
	});

	const handleFillAllAnswers = async () => handleEditSurvey({
		answers: walkSectionQuestionForAnswers({
			sections: surveyConf?.sections,
			questionAnswerMapper: ({ question }) => {
				if (!question) return {};
				return { [question?.questionId]: randomlyFakeQuestionAnswer({ question }) };
			},
		}),
	});

	const handleRandomAnswers = async () => handleEditSurvey({
		answers: walkSectionQuestionForAnswers({
			sections: surveyConf?.sections,
			questionAnswerMapper: ({ question }) => {
				const value = Math.random() > 0.5
					? randomlyFakeQuestionAnswer({ question })
					: null;
				return { [question?.questionId]: value };
			},
		}),
		isComplete: false,
		status: 'DRAFT',
	});

	const handleResetAnswers = async () => handleEditSurvey({
		answers: walkSectionQuestionForAnswers({
			sections: surveyConf?.sections,
			questionAnswerMapper: ({ question }) => ({ [question?.questionId]: null }),
		}),
		isComplete: false,
		status: 'DRAFT',
	});

	return (
		<Box>
			<h1>Survey</h1>
			<h2>{surveyConf.shortLabel}</h2>
			<ReactJsonDebug
				collapsed={0}
				src={{
					survey,
					surveyConf,
					// answeredQuestions,
					// unansweredQuestions,
					answers,
				}}
			/>
			{survey?.isComplete ? <b>Complete</b> : <b>Incomplete</b>}
			<div>
				<b>Completion:</b>
				&nbsp;
				{countSurveyCompletedQuestions({ surveyConf, answers, withOptional: false })}
				&nbsp;/&nbsp;
				{countSurveyMandatoryQuestions({ surveyConf, answers })}
				&nbsp;(mandatory),&nbsp;
				{countSurveyCompletedQuestions({ surveyConf, answers, withOptional: true })}
				&nbsp;/&nbsp;
				{countSurveyQuestions({ surveyConf })}
				&nbsp;(all - optional included)
			</div>

			{/* Answers: {getSurveyAnsweredAndUnansweredQuestions({ surveyConf, answers })} */}
			<Space>
				<Button onClick={handleResetAnswers}>Reset answers</Button>
				<Button onClick={handleRandomAnswers}>Random answers</Button>
				<Button onClick={handleFillAllAnswers}>Fill all answers</Button>
				<Button onClick={handleSetAsComplete}>Set as complete</Button>
				<Button onClick={handleSetAsIncomplete}>Set as incomplete</Button>
				<UMSStaffSurveyComputeResultButton survey={survey} />
				<UMSStaffSurveyComputeAnalysisButton survey={survey} />
			</Space>
		</Box>
	);
};

const UMSStaffSurveys = () => {
	const className = 'UMSStaffSurveys';
	const theme = useTheme();

	const { currentUser, isUMSManagerSurveyFilled, isUMSEmployeeSurveyFilled } = useAuthContext();

	const companySelected = useSelector(getCompanySelected);

	const [managerSurvey, setManagerSurvey] = useState();
	const [employeeSurvey, setEmployeeSurvey] = useState();

	useEffect(() => {
		if (companySelected) {
			const companyModel = new CompanyModel(companySelected);
			const newManagerSurvey = companyModel.getUMSManagerSurvey({ user: currentUser });
			const newEmployeeSurvey = companyModel.getUMSEmployeeSurvey({ user: currentUser });
			setManagerSurvey(newManagerSurvey);
			setEmployeeSurvey(newEmployeeSurvey);
		} else {
			setManagerSurvey(null);
			setEmployeeSurvey(null);
		}
	}, [companySelected, currentUser]);

	if (!companySelected) return null;

	return (
		<>
			<ReactJsonDebug
				collapsed={0}
				src={{
					managerSurvey,
					employeeSurvey,
					currentUser,
					isUMSManagerSurveyFilled,
					isUMSEmployeeSurveyFilled,
				}}
			/>

			<SurveyProvider>
				{managerSurvey && (
					<UMSStaffSurveyManager
						survey={managerSurvey}
					/>
				)}
			</SurveyProvider>

			<SurveyProvider>
				{employeeSurvey && (
					<UMSStaffSurveyManager
						survey={employeeSurvey}
					/>
				)}
			</SurveyProvider>

			{/* {[managerSurvey, employeeSurvey]
				.filter((i) => i)
				.map((survey, index) => (
					<div key={index}>
						<SurveyProvider>
							<UMSStaffSurveyManager
								survey={survey}
							/>
						</SurveyProvider>
					</div>
				))
			} */}
		</>
	);
};

export default UMSStaffSurveys;
