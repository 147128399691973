import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AppService from '../services/app';
import { DEFAULT_TOTAL_INDICATOR_LIMIT } from '../constants/constant';
import { LocalDebug } from '../utils/LocalDebug';

export const AppDataContext = React.createContext();

const AppDataProvider = ({
	children,
}) => {
	const className = 'AppDataProvider';

	const dispatch = useDispatch();

	const [shouldRefreshData, setShouldRefreshData] = useState(0);
	const [appSettings, setAppSettings] = useState({});
	const [appLang, setAppLang] = useState('en');

	const [apps1448ToolsVisible, setApps1448ToolsVisible] = useState(true);

	const [appSiderWidthExpanded, setAppSiderWidthExpanded] = useState(260);
	const [appSiderWidthCollapsed, setAppSiderWidthCollapsed] = useState(64);
	const [appSiderWidth, setAppSiderWidth] = useState(260);
	const [isAppSiderVisible, setIsAppSiderVisible] = useState(false);
	const [isAppSiderCollapsed, setIsAppSiderCollapsed] = useState(false);
	const [isAppHeaderVisible, setIsAppHeaderVisible] = useState(false);
	const [appHeaderHeight, setAppHeaderHeight] = useState(48);
	const [isAppBannerVisible, setIsAppBannerVisible] = useState(false);
	const [appBannerHeight, setAppBannerHeight] = useState(48);
	const [appTopOffset, setAppTopOffset] = useState(0);
	const [isSavedSearchNewTagEnabled, setIsSavedSearchNewTagEnabled] = useState(false);

	const [surveyTestVersion, setSurveyTestVersion] = useState('versionB');
	const [isAppSiderVisibleInSurvey, setIsAppSiderVisibleInSurvey] = useState(true);
	const [isProgressBarVisibleInSurvey, setIsProgressBarVisibleInSurvey] = useState(true);
	const [isProgressBarClickableInSurvey, setIsProgressBarClickableInSurvey] = useState(true);
	/**
	 * Limit the display of the number of results
	 * This limit only affects the indicator of the total
	 */
	const [dataFetcherIndicatorTotalLimit, setDataFetcherIndicatorTotalLimit] = useState(DEFAULT_TOTAL_INDICATOR_LIMIT);

	const refreshAppSettings = () => {
		setShouldRefreshData((p) => p + 1);
	};
	const toggleCollapseMenu = () => {
		setAppSiderWidth(isAppSiderCollapsed ? appSiderWidthExpanded : appSiderWidthCollapsed);
		setIsAppSiderCollapsed((prev) => !prev);
	};

	useEffect(() => {
		if (surveyTestVersion === 'versionA') {
			setIsAppSiderVisibleInSurvey(true);
			setIsProgressBarClickableInSurvey(false);
		}
		if (surveyTestVersion === 'versionB') {
			setIsAppSiderVisibleInSurvey(false);
			setIsProgressBarClickableInSurvey(true);
		}
	}, [surveyTestVersion]);

	useEffect(() => {
		const fetchAppSettings = async () => {
			const result = (await AppService.getData('/app/settings'))?.data;
			LocalDebug.logUseEffect({ className, effects: '' }, result);
			setAppSettings(result);
			setDataFetcherIndicatorTotalLimit(
				result?.dataFetcherIndicatorTotalLimit
				|| DEFAULT_TOTAL_INDICATOR_LIMIT,
			);
			setIsSavedSearchNewTagEnabled([true, 'true'].includes(result?.isSavedSearchNewTagEnabled));
		};
		fetchAppSettings();
	}, [shouldRefreshData]);

	useEffect(() => {
		setAppTopOffset(
			(isAppBannerVisible ? appBannerHeight : 0)
			+ (isAppHeaderVisible ? appHeaderHeight : 0),
		);
	}, [isAppBannerVisible, appBannerHeight, isAppHeaderVisible, appHeaderHeight]);

	return (
		<AppDataContext.Provider
			value={{
				appLang,
				setAppLang,
				appSettings,
				refreshAppSettings,
				appSiderWidth,
				setAppSiderWidth,
				appSiderWidthExpanded,
				setAppSiderWidthExpanded,
				appSiderWidthCollapsed,
				setAppSiderWidthCollapsed,
				isAppSiderVisible,
				setIsAppSiderVisible,
				isAppSiderVisibleInSurvey,
				setIsAppSiderVisibleInSurvey,
				isProgressBarVisibleInSurvey,
				setIsProgressBarVisibleInSurvey,
				isProgressBarClickableInSurvey,
				setIsProgressBarClickableInSurvey,
				isAppSiderCollapsed,
				setIsAppSiderCollapsed,
				toggleCollapseMenu,
				isAppHeaderVisible,
				setIsAppHeaderVisible,
				appHeaderHeight,
				setAppHeaderHeight,
				isAppBannerVisible,
				setIsAppBannerVisible,
				appBannerHeight,
				setAppBannerHeight,
				appTopOffset,
				dataFetcherIndicatorTotalLimit,
				isSavedSearchNewTagEnabled,

				// Releases
				apps1448ToolsVisible,
				setApps1448ToolsVisible,
			}}
		>
			{children}
		</AppDataContext.Provider>
	);
};

export default AppDataProvider;
