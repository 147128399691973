import shared from '../../shared';
import QUESTIONS from '../../questions';
import { GENDERSCORE_PILLAR_WORKLIFEBALANCE_OPTION } from '../../../../../constants/genderscore-pillars';

const section = {
	...GENDERSCORE_PILLAR_WORKLIFEBALANCE_OPTION,
	details: `
### For 50inTech's women community, flexibility at work is their main requirement when choosing a company.

The quality of your work-life balance policy is key to attracting and retaining women in Tech.`,
	questions: [
		QUESTIONS.V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE,
		{
			...QUESTIONS.V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE_OFFERING,
			clause: (answers) => answers?.[QUESTIONS.V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE.questionId] === 'yes',
		},
		QUESTIONS.V3_WLB_FLEXIBILITY_TIMEOFF_SCHEDULING_METHODS,
		QUESTIONS.V3_WLB_FLEXIBILITY_TIMEOFF_ENCOURAGEMENT,
		QUESTIONS.V3_WLB_PARENTALITY_BENEFITS_DURING,
		QUESTIONS.V3_WLB_PARENTALITY_BENEFITS_RETURN,
		QUESTIONS.V3_WLB_BENEFITS_LIST,
	],
	scoring: [
		{
			question: QUESTIONS.V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE_OFFERING,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 6,
		},
		{
			question: QUESTIONS.V3_WLB_FLEXIBILITY_TIMEOFF_SCHEDULING_METHODS,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 10,
		},
		{
			question: QUESTIONS.V3_WLB_FLEXIBILITY_TIMEOFF_ENCOURAGEMENT,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 6,
		},
		{
			question: QUESTIONS.V3_WLB_PARENTALITY_BENEFITS_DURING,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 6,
		},
		{
			question: QUESTIONS.V3_WLB_PARENTALITY_BENEFITS_RETURN,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 4,
		},
		{
			question: QUESTIONS.V3_WLB_BENEFITS_LIST,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 6,
		},

		/*
		{
			question: QUESTIONS.WORKLIFE_REMOTE,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.WORKLIFE_FLEXIBLEHOURS,
			weight: 8,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.WORKLIFE_FLEXIBLEHOLIDAYS,
			weight: 5,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.WORKLIFE_UNLIMITEDHOLIDAYS,
			weight: 1,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.WORKLIFE_PARENTALPAIDLEAVE,
			weight: 6,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.WORKLIFE_EXTMATERNITYLEAVE,
			weight: 3,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.WORKLIFE_EXTRABENEFITS,
			weight: 4,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 13,
		},
		{
			question: QUESTIONS.WORKLIFE_PARENTSREENTRY,
			weight: 4,
			computer: shared.COMPUTE_VALUE,
		},
		*/
	],
};

export default section;
