import { ENGLISH_LANG, FRENCH_LANG } from '../../../../constants/constant';
import { Switch } from 'antd';
import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import { CompanyPageContext } from './CompanyPageProvider';
import { LocalDebug } from '../../../../utils/LocalDebug';

const CompanyPageLangEditionSwitch = (
	{
		company,
		onChange,
		innerRef,
	},
) => {
	const className = 'CompanyPageLangEditionSwitch';

	const theme = useTheme();

	const { lang, setLang } = useContext(CompanyPageContext);

	if (!company.isTranslatable) return null;

	if (!company.isPublishedNow()) return null;

	return (
		<div ref={innerRef}>
			<Switch
				className='ant-switch-large ant-switch-unchecked-darkturquoise'
				checked={lang === ENGLISH_LANG}
				checkedChildren={<b>🇬🇧 English version</b>}
				unCheckedChildren={<b>🇫🇷 French version</b>}
				onChange={(value) => {
					const newLang = value ? ENGLISH_LANG : FRENCH_LANG;
					setLang(newLang);
					onChange?.(newLang);
				}}
			>
				<b>Language</b>
			</Switch>
		</div>
	);
};

export default CompanyPageLangEditionSwitch;
