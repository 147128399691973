import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import {
	addToFilterOptions, documentId, getQueryParams, removeFromFilterOptions,
} from '../../../utils/common';
import { useEffect, useState } from 'react';
import PageLayout from '../../../components/app/layout/PageLayout';
import CompanyGenderScoreSurveyTable from '../../../components/company/CompanyGenderScoreSurveyTable';
import CompanyPlanGenderScoreSurveySelect from '../../../components/company/companyPlan/genderscore/CompanyPlanGenderScoreSurveySelect';
import { LocalDebug } from '../../../utils/LocalDebug';
import { Space } from 'antd';
import SurveyStatusSelect from '../../../components/genderscore/survey/SurveyStatusSelect';
import StaffCompaniesCompanyListMenu from '../../../menu/staff/companies/StaffCompaniesCompanyListMenu';
import StaffGSSurveyListMenu from '../../../menu/staff/genderscore/StaffGSSurveyListMenu';

const CompanyGenderScoreSurveysPage = () => {
	const className = 'CompanyGenderScoreSurveysPage';

	const theme = useTheme();
	const companySelected = useSelector(getCompanySelected);

	const queryParams = getQueryParams();

	const [optionsFilterTable, setOptionsFilterTable] = useState({
		withAccess: false,
		withBatch: false,
	});

	const handleChangeFilter = (key, value) => {
		LocalDebug.logInfo({ className, method: 'handleChangeFilter' }, { key, value });
		if (value && value?.length > 0) {
			addToFilterOptions(key, value, setOptionsFilterTable);
		} else {
			removeFromFilterOptions(key, setOptionsFilterTable);
		}
	};

	useEffect(() => {
		setOptionsFilterTable((prev) => ({
			...prev,
			companyId: documentId(companySelected),
		}));
	}, [companySelected]);

	return (
		<PageLayout
			MenuClass={StaffGSSurveyListMenu}
			rightActions={(
				<Space direction='vertical'>
					{(new StaffCompaniesCompanyListMenu()).getInlineNavLink()}
				</Space>
			)}
		>
			<Space
				style={{
					marginBottom: 12,
				}}
			>
				<b>Status</b>
				<SurveyStatusSelect
					value={optionsFilterTable?.status}
					onChange={(value) => handleChangeFilter('status', value)}
					style={{ marginRight: 40 }}
				/>

				<div style={{ width: 100 }} />

				<b>Survey</b>
				<CompanyPlanGenderScoreSurveySelect
					value={optionsFilterTable.survey}
					allowClear
					onChange={(value) => handleChangeFilter('survey', value)}
				/>
			</Space>

			<CompanyGenderScoreSurveyTable
				filterOptions={optionsFilterTable}
				withDynamicSearch={false}
			/>

		</PageLayout>
	);
};

export default CompanyGenderScoreSurveysPage;
