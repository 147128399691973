import { Card } from 'antd';

const GridCardBase = (
	{
		height = 350,
		children,
		...props
	},
) => {
	const className = 'GridCardBase';

	return (
		<Card
			hoverable={true}
			bodyStyle={{ padding: 0, margin: 0 }}
			{...props}
			style={{
				padding: 0,
				margin: 0,
				width: '100%',
				height,
				overflow: 'hidden',
				position: 'relative',
				borderColor: '#ddd',
				borderRadius: 12,
				cursor: 'unset',
				...props?.style,
			}}>

			{children}

		</Card>
	);
};

export default GridCardBase;
