import { useTheme } from '@emotion/react';
import { isArray } from 'lodash';
import ReactMarkdown from 'react-markdown';
import { Divider } from 'antd';
import CompanyActionModel from '../../../../models/CompanyActionModel';

const CompanyActionModalRightSide = ({
	action,
	...props
}) => {
	const className = 'CompanyActionModalRightSide';

	const theme = useTheme();

	action = action instanceof CompanyActionModel
		? action
		: new CompanyActionModel(action);

	return (
		<div
			style={{
				padding: 30,
				maxWidth: 'calc(100% - 60px)',
				maxHeight: 'calc(100% - 60px)',
				// height: '100%',
				background: '#fcfcfe',
			}}
		>
			<h2>
				Guidelines to implement this action
			</h2>

			<div
				style={{
					fontSize: 14,
				}}
			>
				<ReactMarkdown
					linkTarget='_blank'
				>
					{action?.descriptionForMarkdown}
				</ReactMarkdown>
			</div>

			{isArray(action?.data) && action?.data?.length > 0 && (
				<>
					<Divider orientation='left'>
								Data / Statistics
					</Divider>

					{action?.data?.map((data, dataIndex) => (
						<div
							key={dataIndex}
							style={{
								padding: '1px 16px',
								// width: '100%',
								background: '#f5f5f5',
								fontSize: 13,
								fontStyle: 'italic',
								color: '#333',
								borderRadius: 6,
							}}
						>
							<ReactMarkdown
								linkTarget='_blank'
							>
								{data}
							</ReactMarkdown>
						</div>
					))}
				</>
			)}

		</div>
	);
};

export default CompanyActionModalRightSide;
