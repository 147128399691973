import { Avatar, Tooltip } from 'antd';
import { Image, Transformation } from 'cloudinary-react';
import { useTheme } from '@emotion/react';
import { relabelAtsProvider } from '../../utils/common';
import { useSelector } from 'react-redux';
import { getCompanySelectedApiAccess } from '../../reducers/app';
import { LocalDebug } from '../../utils/LocalDebug';

const AtsLogo = (
	{
		ats,
		radius,
		size = 36,
		withTooltip = true,
		inlineDisplay = true,
		...props
	},
) => {
	const className = 'AtsLogo';

	const theme = useTheme();
	const companySelectedApiAccess = useSelector(getCompanySelectedApiAccess);

	ats = ats || companySelectedApiAccess?.ats || 'ats-default';

	const borderRadius = radius >= 0 ? radius : Math.floor(size / 6);

	if (![
		'lever', 'lever_eu', 'lever_sandbox',
		'greenhouse', 'crew', 'phenom', 'ashby',
		'jobsmakesense', 'smartrecruiters',
		'workable', 'teamtailor', 'wttj',
		'workday', 'talentplug', 'broadbean', 'equest',
		'recruitee', 'personio', 'successfactors',
	].includes(ats)) {
		ats = 'ats-default';
	}

	const imageUrl = `app/ats/${ats}`;

	const component = (
		<div style={inlineDisplay && { display: 'inline-block' }}>
			<div style={{ position: 'relative' }}>
				{imageUrl
					? <Avatar
						shape='square'
						{...props}
						style={{
							outline: '1px solid rgba(0,0,0,.05)',
							outlineOffset: -1,
							borderRadius,
							width: size,
							height: size,
							position: 'relative',
							marginBottom: 2,
							...props?.style,
						}}
						src={
							<Image width={size} height={size} publicId={imageUrl} secure='true'>
								<Transformation format='auto' />
								<Transformation width={2 * size} height={2 * size} crop='thumb' />
								{/* <Transformation radius={borderRadius} /> */}
							</Image>
						} />
					: <Avatar
						shape='square'
						{...props}
						size={size}
						style={{
							position: 'relative',
							fontWeight: 'bold',
							fontSize: `${Math.round(size / 2.2)}px`,
							borderRadius,
							...props?.style,
						}}>
						Lever
					</Avatar>
				}
			</div>
		</div>);

	return ats && <>{
		withTooltip
			? <Tooltip placement='top' title={<>Linked to <b>{relabelAtsProvider(ats)}</b></>}>
				{component}
			</Tooltip>
			: component
	}</>;
};

export default AtsLogo;
