import shared from '../../shared';
import QUESTIONS from '../../questions';
import { GENDERSCORE_PILLAR_DEIACTIONS_OPTION } from '../../../../../constants/genderscore-pillars';

const section = {
	...GENDERSCORE_PILLAR_DEIACTIONS_OPTION,
	details: `
### One out of two women is leaving tech due to **discrimination**.

DE&I policies help reduce unconscious bias in recruitment, promotion... and even among employees.`,
	questions: [
		QUESTIONS.V3_DEI_SAFETY_PSR_MEASUREMENTS,
		QUESTIONS.V3_DEI_SAFETY_ANTI_HARASSMENT_POLICY,
		QUESTIONS.V3_DEI_SAFETY_ANTI_DISCRIMINATION_POLICY,
		QUESTIONS.V3_DEI_SAFETY_REPORTING_PROCEDURES,
		QUESTIONS.V3_DEI_HEALTH_MENTAL_HEALTH_SERVICES,
		QUESTIONS.V3_DEI_HEALTH_WOMEN_MENTAL_HEALTH_SERVICES,
		QUESTIONS.V3_DEI_POLICIES_DESIGNATED_DEI,
		QUESTIONS.V3_DEI_POLICIES_PUBLIC_DEI_ENGAGEMENTS,
		QUESTIONS.V3_DEI_POLICIES_ALLOCATED_BUDGET,
		QUESTIONS.V3_DEI_POLICIES_BIAS_EDUCATION,
		QUESTIONS.V3_DEI_POLICIES_BIAS_EDUCATION_EFFICIENCY_MEASURE,
		QUESTIONS.V3_DEI_POLICIES_DEI_LEARNING_COMMITMENT,
		QUESTIONS.V3_DEI_RECRUITMENT_UNBIASED_RECRUITMENT_STRATEGIES,
		QUESTIONS.V3_DEI_RECRUITMENT_PRIORITIES,
		QUESTIONS.V3_DEI_RECRUITMENT_ATS_PRESENCE,
		QUESTIONS.V3_DEI_RECRUITMENT_ATS_DEI_FEATURES,
		QUESTIONS.V3_DEI_RECRUITMENT_JUNIOR_HIRING,
		QUESTIONS.V3_DEI_RECRUITMENT_SENIOR_HIRING,
		QUESTIONS.V3_DEI_RECRUITMENT_CAREER_CHANGE_HIRING,
	],
	scoring: [
		{
			question: QUESTIONS.V3_DEI_SAFETY_PSR_MEASUREMENTS,
			weight: 20,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 3,
		},
		{
			question: QUESTIONS.V3_DEI_SAFETY_ANTI_HARASSMENT_POLICY,
			weight: 30,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_DEI_SAFETY_ANTI_DISCRIMINATION_POLICY,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_DEI_SAFETY_REPORTING_PROCEDURES,
			weight: 5,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 6,
		},
		{
			question: QUESTIONS.V3_DEI_HEALTH_MENTAL_HEALTH_SERVICES,
			weight: 5,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 12,
		},
		{
			question: QUESTIONS.V3_DEI_HEALTH_WOMEN_MENTAL_HEALTH_SERVICES,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 6,
		},
		{
			question: QUESTIONS.V3_DEI_POLICIES_DESIGNATED_DEI,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_DEI_POLICIES_PUBLIC_DEI_ENGAGEMENTS,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_DEI_POLICIES_ALLOCATED_BUDGET,
			weight: 5,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_DEI_POLICIES_BIAS_EDUCATION,
			weight: 5,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 4,
		},
		{
			question: QUESTIONS.V3_DEI_POLICIES_BIAS_EDUCATION_EFFICIENCY_MEASURE,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_DEI_POLICIES_DEI_LEARNING_COMMITMENT,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 14,
		},
		{
			question: QUESTIONS.V3_DEI_RECRUITMENT_UNBIASED_RECRUITMENT_STRATEGIES,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 10,
		},
		{
			question: QUESTIONS.V3_DEI_RECRUITMENT_PRIORITIES,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 4,
		},
		{
			question: QUESTIONS.V3_DEI_RECRUITMENT_ATS_PRESENCE,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_DEI_RECRUITMENT_ATS_DEI_FEATURES,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_DEI_RECRUITMENT_JUNIOR_HIRING,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_DEI_RECRUITMENT_SENIOR_HIRING,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_DEI_RECRUITMENT_CAREER_CHANGE_HIRING,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
	],
};

export default section;
