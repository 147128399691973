import { useTheme } from '@emotion/react';
import CompanyService from '../../services/company';
import TableColumns from '../app/table/TableColumns';
import Table from '../app/table/Table';
import { useState } from 'react';
import CompanyStub from './CompanyStub';
import { useAuthContext } from '../../hooks/useAuthContext';
import useBodyScrollBlocker from '../../hooks/useBodyScrollBlocker';
import { documentId, filterAutoCompleteItems, mergeReducer } from '../../utils/common';
import { getGenderScoreSurveyTag } from '../../constants/property/genderscore-survey';
import { Dropdown, Space, Tooltip } from 'antd';
import {
	ANSWER_STATE_ANSWERED,
	ANSWER_STATE_LATER,
	ANSWER_STATE_SKIPPED,
	computeSurveyScores,
	getQuestionAnsweredState,
} from '../genderscore/data/shared';
import { getSurveyDataOption } from '../genderscore/data/surveys';
import { GENDERSCORE_PILLAR_VALUES } from '../../constants/genderscore-pillars';
import QUESTIONS from '../genderscore/data/questions';
import { ClockCircleOutlined } from '@ant-design/icons';
import { BiChevronDown, BiChevronsRight } from 'react-icons/bi';
import {
	FaBuilding, FaChartBar, FaDatabase, FaEye,
} from 'react-icons/fa';
import { buildScoresCol } from './CompanyGenderScoreTableColumns';
import { getGenderScoreStatusTag } from '../../constants/property/genderscore-status';
import CompanyModel from '../../models/CompanyModel';
import { Link, useNavigate } from 'react-router-dom';

const CompanyGenderScoreSurveyTable = (
	{
		path = '/genderscore/survey',
		onDataLoaded,
		filterOptions,
		isRefresh = 0,
		defaultColumnsOptions = ['name', 'path'],
	},
) => {
	const className = 'CompanyGenderScoreSurveyTable';

	const theme = useTheme();
	const navigate = useNavigate();

	const handleSearchAutoComplete = async (options) => {
		const { data: { companies } } = await CompanyService.findAll(options);
		return filterAutoCompleteItems(companies);
	};

	const [company, setCompany] = useState();
	const [openForm, setOpenForm] = useState(false);

	useBodyScrollBlocker(openForm, className);

	const sanitizeGenderScoreObj = (genderScore) => {
		const result = Object
			.entries(genderScore || {})
			.filter(([key, value]) => ['globalScore', ...GENDERSCORE_PILLAR_VALUES].includes(key) && (value || value === 0))
			.map(([key, value]) => ({ [key]: value }))
			.reduce(mergeReducer, {});

		return Object.keys(result)?.length > 0
			? result
			: null;
	};
	const handleRedirectGenderScoreSurveysPage = (survey) => {
		navigate(`/staff/companies/surveys?companySelected=${survey?.company?.path}`);
	};
	const handleRedirectGenderScoreSurveyDetailsPage = (survey) => {
		navigate(`/staff/companies/survey/details?surveyId=${documentId(survey)}`);
	};

	const actionsCol = {
		title: 'Staff actions',
		fixed: 'right',
		render: (value, row) => {
			const ActionMenuItem = ({
				title,
				onClick,
				Icon,
				children,
			}) => (
				<div onClick={onClick}>
					<Icon style={{
						marginBottom: -5,
						fontSize: 10,
						border: '1px solid #ddd',
						padding: 4,
						borderRadius: 4,
					}} />
					&nbsp;
					{children}
				</div>
			);
			const items = [
				{
					key: 'genderScore',
					type: 'group',
					label: 'GenderScore',
					children: [
						{
							key: 'genderScore/details',
							label: (
								<ActionMenuItem
									onClick={() => { handleRedirectGenderScoreSurveyDetailsPage(row); }}
									Icon={FaEye}
								>
										See details of this survey
								</ActionMenuItem>
							),
						},
						{
							key: 'genderScore/see-all',
							label: (
								<ActionMenuItem
									onClick={() => { handleRedirectGenderScoreSurveysPage(row); }}
									Icon={FaBuilding}
								>
										See all surveys for this company
								</ActionMenuItem>
							),
						},
					],
				},

			];

			return (
				<Dropdown menu={{ items }}>
					<Link>Actions <BiChevronDown style={{ marginBottom: -2 }}/></Link>
				</Dropdown>
			);
		},
		...TableColumns.columnFixedWidth(120),
	};

	const columns = [
		TableColumns.companyStubColumn(theme, {
			fixed: 'left',
			render: (value, row) => (
				row.company
					? <CompanyStub company={row.company} />
					: row?.companyInfo?.name
			),
			customOptions: { searchFields: [{ label: 'Company name', value: 'companyInfo.name' }] },
		}),
		TableColumns.userAvatarColumn(theme, {
			title: <>Created<br />by</>,
			...TableColumns.columnMinWidth(60),
		}),
		TableColumns.jsonColumn(theme),
		{
			title: 'Completion',
			dataIndex: 'submittedAt',
			key: 'submittedAt',
			sorter: true,
			removeInColumnFilter: true,
			render: (v, row) => {
				return (
					<>
						{row?.completionTime && (
							<div>
								Completed in <b>{(() => {
									let completionTime = row?.completionTime;
									if (completionTime < 60) return <>{completionTime} s</>;
									completionTime /= 60;
									if (completionTime < 24) return <>{Math.ceil(completionTime)} min</>;
									completionTime /= 24;
									return <>{Math.ceil(completionTime)} d</>;
								})()}</b>
							</div>
						)}
						{row?.startedAt && (
							<div>
								Started: <b>{TableColumns.dateColumn(theme).render(row?.startedAt)}</b>
							</div>
						)}
						{row?.submittedAt && (
							<div>
								Submitted: <b>{TableColumns.dateColumn(theme).render(row?.submittedAt)}</b>
							</div>
						)}
					</>
				);
			},
			...TableColumns.columnFixedWidth(140),
		},
		{
			title: 'Survey',
			dataIndex: 'survey',
			align: 'center',
			render: (value, row) => getGenderScoreSurveyTag(value),
			...TableColumns.columnFixedWidth(70),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			align: 'center',
			render: (value, row) => getGenderScoreStatusTag(value),
			...TableColumns.columnFixedWidth(90),
		},
		{
			title: <>Answers</>,
			dataIndex: 'answers',
			align: 'center',
			render: (value, row) => {
				const answersByState = {};
				Object
					.entries(value || {})
					.forEach(([questionId, answer]) => {
						const state = getQuestionAnsweredState({
							question: QUESTIONS?.[questionId],
							answers: { [questionId]: answer },
						});
						answersByState[state] = {
							...answersByState[state] || {},
							[questionId]: answer,
						};
					});
				const answeredCount = Object.keys(answersByState[ANSWER_STATE_ANSWERED] || {})?.length || 0;
				const laterCount = Object.keys(answersByState[ANSWER_STATE_LATER] || {})?.length || 0;
				const skippedCount = Object.keys(answersByState[ANSWER_STATE_SKIPPED] || {})?.length || 0;
				return TableColumns.jsonColumn(
					theme,
					{
						label: (
							<Space
								direction='vertical'
								style={{
									width: '100%',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<div>
									<b style={{ color: 'black' }}>{answeredCount}</b>
									&nbsp;&nbsp;<FaDatabase style={{
										color: theme.color.fitBlueElectric, marginBottom: -2,
									}} />
								</div>
								{(laterCount + skippedCount) > 0 && (
									<Space direction='horizontal'>
										{laterCount > 0 && (
											<span style={{ color: theme.color.purple }}>
												{laterCount} <ClockCircleOutlined style={{ marginBottom: -1 }} />
											</span>
										)}
										{skippedCount > 0 && (
											<span style={{ color: theme.color.grey }}>
												{skippedCount} <BiChevronsRight style={{ marginBottom: -2 }} />
											</span>
										)}
									</Space>
								)}
							</Space>
						),
						sortKeys: false,
						value: () => value,
					},
				).render();
			},
			...TableColumns.columnFixedWidth(100),
		},
		buildScoresCol({
			title: (
				<Tooltip
					title={(
						<>
							GenderScore <b>currently published</b> to candidates (if available)
						</>
					)}
					style={{ userSelect: 'none' }}
				>
					GS<br />(Published)
				</Tooltip>
			),
			score: (row) => row?.company?.genderScore,
		}),
		buildScoresCol({
			title: (
				<Tooltip
					title={(
						<>
							GenderScore as computed and extracted from <b>Outgrow</b> (if available)
						</>
					)}
					style={{ userSelect: 'none' }}
				>
					GS<br />(Outgrow)
				</Tooltip>
			),
			score: (row) => (row?.isOutgrow ? row?.genderScoreOutgrow : null),
			diff: (row) => (row?.isOutgrow ? sanitizeGenderScoreObj(row?.company?.genderScore) : null),
		}),
		buildScoresCol({
			title: (
				<Tooltip
					title={(
						<>
							GenderScore as computed upon <b>submission</b> of the answers,
							and stored in the <b>company-genderscore-survey</b> collection
						</>
					)}
					style={{ userSelect: 'none' }}
				>
					GS<br />(Survey)
				</Tooltip>
			),
			score: (row) => row?.genderScore,
			diff: (row) => sanitizeGenderScoreObj(
				row?.isOutgrow
					? row?.genderScoreOutgrow
					: row?.company?.genderScore,
			),
		}),
		buildScoresCol({
			title: (
				<Tooltip
					title={(
						<>
							GenderScore as computed from the <b>server's scoring formula</b>,
							upon fetching this document from the database
						</>
					)}
					style={{ userSelect: 'none' }}
				>
					GS<br />(Server)
				</Tooltip>
			),
			score: (row) => row?.genderScore?.server,
			diff: (row) => sanitizeGenderScoreObj(row?.genderScore),
		}),
		buildScoresCol({
			title: (
				<Tooltip
					title={(
						<>
							GenderScore as computed from the <b>client's scoring formula</b>,
							upon receiving this document from the database
						</>
					)}
					style={{ userSelect: 'none' }}
				>
					GS<br />(Client)
				</Tooltip>
			),
			score: (row) => computeSurveyScores({
				surveyConf: getSurveyDataOption(row?.survey),
				answers: row?.answers,
			}),
			diff: (row) => sanitizeGenderScoreObj(row?.genderScore?.server),
		}),
		actionsCol,
	];

	return (
		<>
			<Table
				path={path}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh}
				onSearchDataColumn={handleSearchAutoComplete}
				withDynamicSearch
				defaultColumnsOptions={defaultColumnsOptions}
			/>
		</>
	);
};

export default CompanyGenderScoreSurveyTable;
