import shared from '../../shared';
import QUESTIONS from '../../questions';
import { GENDERSCORE_PILLAR_WORKLIFEBALANCE_OPTION } from '../../../../../constants/genderscore-pillars';

const section = {
	...GENDERSCORE_PILLAR_WORKLIFEBALANCE_OPTION,
	details: `
### For 50inTech's women community, flexibility at work is their main requirement when choosing a company.

The quality of your work-life balance policy is key to attracting and retaining women in Tech.`,
	questions: [
		QUESTIONS.WORKLIFE_REMOTE,
		QUESTIONS.WORKLIFE_FLEXIBLEHOURS,
		QUESTIONS.WORKLIFE_FLEXIBLEHOLIDAYS,
		QUESTIONS.WORKLIFE_UNLIMITEDHOLIDAYS,
		QUESTIONS.WORKLIFE_PARENTALPAIDLEAVE,
		QUESTIONS.WORKLIFE_EXTMATERNITYLEAVE,
		QUESTIONS.WORKLIFE_EXTRABENEFITS,
		QUESTIONS.WORKLIFE_PARENTSREENTRY,
	],
	scoring: [
		{
			question: QUESTIONS.WORKLIFE_REMOTE,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.WORKLIFE_FLEXIBLEHOURS,
			weight: 8,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.WORKLIFE_FLEXIBLEHOLIDAYS,
			weight: 5,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.WORKLIFE_UNLIMITEDHOLIDAYS,
			weight: 1,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.WORKLIFE_PARENTALPAIDLEAVE,
			weight: 6,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.WORKLIFE_EXTMATERNITYLEAVE,
			weight: 3,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.WORKLIFE_EXTRABENEFITS,
			weight: 4,
			computer: shared.COMPUTE_VALUE,
			ranges: 100 / 13,
		},
		{
			question: QUESTIONS.WORKLIFE_PARENTSREENTRY,
			weight: 4,
			computer: shared.COMPUTE_VALUE,
		},
	],
};

export default section;
