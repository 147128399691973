import { useTheme } from '@emotion/react';
import {
	Row, Space, Tag, Tooltip,
} from 'antd';
import { FaExternalLinkSquareAlt } from 'react-icons/fa';
import {
	addSignToNum,
	documentId,
	mixColors,
} from '../../utils/common';
import CompanyModel from '../../models/CompanyModel';
import { GENDERSCORE_LABEL } from '../../constants/constant';
import { CompanySettingsCardDotOk } from './settings/CompanySettingsCard';
import { GenderScoreSurveyStatusDot } from './settings/CompanySettingsGenderScoreCard';
import UserAvatar from '../user/widgets/UserAvatar';
import TableColumns from '../app/table/TableColumns';
import { getGenderScoreSurveyTag } from '../../constants/property/genderscore-survey';
import CompanyGenderScoreRingScore from './genderscore/CompanyGenderScoreRingScore';
import {
	GENDERSCORE_PILLAR_DEIACTIONS_VALUE,
	GENDERSCORE_PILLAR_EQUALPAY_VALUE,
	GENDERSCORE_PILLAR_FAIRCAREERPATH_VALUE,
	GENDERSCORE_PILLAR_GENDERDATA_VALUE, GENDERSCORE_PILLAR_VALUES, GENDERSCORE_PILLAR_WORKLIFEBALANCE_VALUE, getPillarColorMain, getPillarLabel,
} from '../../constants/genderscore-pillars';
import { getGenderScoreStatusTag } from '../../constants/property/genderscore-status';
import moment from 'moment';
import GenderScorePillarLogoLabel from './genderscore/pillars/GenderScorePillarLogoLabel';
import JsonPopoverLink from '../app/debug/JsonPopoverLink';
import { TrophyOutlined } from '@ant-design/icons';
import CompanyGenderScoreBadge from './genderscore/CompanyGenderScoreBadge';

export const GSColumnType = {
	isEnabled: 'isEnabled',
	isAllowed: 'isAllowed',
	isStaffPublished: 'isStaffPublished',
	isSurveyEnabled: 'isSurveyEnabled',
	surveyStatus: 'surveyStatus',
	survey: 'survey',
	score: 'score',
	progress: 'progress',
	owner: 'owner',
	expiration: 'expiration',
	expirationBadge: 'expirationBadge',
};

const noValueCell = (theme) => <span style={{ fontSize: 10, fontStyle: 'italic', color: theme.color.grey }}>N/A</span>;

const diffTag = ({ diff, size }) => {
	if (diff === 0) return null;

	const style = size === 'small'
		? {
			fontSize: 8,
			padding: 0,
			width: '100%',
			borderRadius: 3,
			background: 'none',
			color: 'red',
		}
		: {
			fontSize: 11,
			padding: '3px 2px',
		};

	return (
		<div
			style={{
				fontWeight: 'bold',
				background: 'red',
				color: 'white',
				borderRadius: 3,
				lineHeight: 1,
				...style,
			}}
		>
			{addSignToNum(diff)}
		</div>
	);
};

export const scoresCell = ({
	value, row, score, diff, skip, withPillars, withRank, ...props
}) => {
	const { rank } = row?.stats?.rankings?.[props?.pillar || 'globalScore']?.find((c) => c?.name === row?.company?.name) || {};
	return <Space
		direction='horizontal'
		size={10}
		style={{
			justifyContent: 'center',
			alignItems: 'center',
			lineHeight: 0.6,
			...props?.style,
		}}
	>
		<Space direction='vertical'>
			<CompanyGenderScoreRingScore
				size={props?.size || 28}
				withPopover={false}
				notValue={props?.notValue}
				withLevel={props?.withLevel}
				{...score?.(row)?.globalScore > 0 ? {
					forceScore: score?.(row)?.globalScore,
					value: score?.(row)?.globalScore,
					...!props?.submittedAt ? {
						ringColor: '#999',
						style: {
							label: { color: '#999' },
							score: { color: '#999' },
						},
					} : {},
				}
					: {
						ringColor: '#eee',
						forceScore: <span style={{ color: '#aaa' }}>?</span>,
						value: 100,
					}
				}
			/>
			{diff && diff?.(row) && diffTag({
				diff: (score?.(row)?.globalScore || 0) - (diff?.(row)?.globalScore || 0),
			})}
		</Space>
		{withRank && (
			<span style={{ color: '#666', fontSize: 12, fontWeight: 'bold' }}>
					#{rank}
			</span>
		)}
		{withPillars && (
			<Space
				direction='vertical'
				size={1}
				// wrap={false}
				style={{
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
					lineHeight: 0.6,
				}}
			>
				{GENDERSCORE_PILLAR_VALUES
					.map((pillar) => (
						<Space
							direction='horizontal'
							key={pillar}
							size={1}
							align='top'
							style={{
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center',
							}}
						>
							<Tooltip
								title={(
									<div>
										{getPillarLabel(pillar)}
									</div>
								)}
								placement='left'
							>
								<div
									style={{
										fontSize: 9,
										padding: '0px 2px',
										borderRadius: 2,
										color: 'white',
										fontWeight: 'bold',
										background: getPillarColorMain(pillar),
										lineHeight: 1.3,
										userSelect: 'none',
									}}
								>
									{score?.(row)?.[pillar]}
								</div>
							</Tooltip>
							<div
								style={{
									width: '100%',
									textAlign: 'center',
									lineHeight: 0.6,
								}}
							>
								{diff && diff?.(row) && diffTag({
									diff: (score?.(row)?.[pillar] || 0) - (diff?.(row)?.[pillar] || 0),
									size: 'small',
								})}
							</div>
						</Space>
					))
				}
			</Space>
		)}
	</Space>;
};

export const badgesCell = ({
	value, row, score, diff, skip, withPillars, ...props
}) => {
	return <Space
		direction='horizontal'
		size={10}
		style={{
			justifyContent: 'center',
			alignItems: 'center',
			lineHeight: 0.6,
			...props?.style,
		}}
	>
		<Space direction='vertical'>
			<CompanyGenderScoreBadge
				size={props?.size || 28}
				withPopover={false}
				notValue={props?.notValue}
				{...score?.(row)?.globalScore > 0 ? {
					forceScore: score?.(row)?.globalScore,
					value: score?.(row)?.globalScore,
					...!props?.submittedAt ? {
						ringColor: '#999',
						style: {
							label: { color: '#999' },
							score: { color: '#999' },
						},
					} : {},
				}
					: {
						ringColor: '#eee',
						forceScore: <span style={{ color: '#aaa' }}>?</span>,
						value: 100,
					}
				}
			/>
			{diff && diff?.(row) && diffTag({
				diff: (score?.(row)?.globalScore || 0) - (diff?.(row)?.globalScore || 0),
			})}
		</Space>
		{withPillars && (
			<Space
				direction='vertical'
				size={1}
				// wrap={false}
				style={{
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
					lineHeight: 0.6,
				}}
			>
				{GENDERSCORE_PILLAR_VALUES
					.map((pillar) => (
						<Space
							direction='horizontal'
							key={pillar}
							size={1}
							align='top'
							style={{
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center',
							}}
						>
							<Tooltip
								title={(
									<div>
										{getPillarLabel(pillar)}
									</div>
								)}
								placement='left'
							>
								<div
									style={{
										fontSize: 9,
										padding: '0px 2px',
										borderRadius: 2,
										color: 'white',
										fontWeight: 'bold',
										background: getPillarColorMain(pillar),
										lineHeight: 1.3,
										userSelect: 'none',
									}}
								>
									{score?.(row)?.[pillar]}
								</div>
							</Tooltip>
							<div
								style={{
									width: '100%',
									textAlign: 'center',
									lineHeight: 0.6,
								}}
							>
								{diff && diff?.(row) && diffTag({
									diff: (score?.(row)?.[pillar] || 0) - (diff?.(row)?.[pillar] || 0),
									size: 'small',
								})}
							</div>
						</Space>
					))
				}
			</Space>
		)}
	</Space>;
};

export const scoreWithDate = ({
	theme, row, survey, onClick,
}) => <Space
	key={documentId(survey)}
	style={{ marginTop: 3 }}
>
	<Row>
		<span style={{
			fontWeight: 600,
			fontSize: 10,
			color: theme.color.darkgrey,
		}}>{moment(survey?.submittedAt || survey?.updatedAt).format('DD/MM/YYYY hh:mm:ss')}</span>
	</Row>
	<Row>
		{scoresCell({
			row,
			score: () => survey?.genderScore,
			submittedAt: survey?.submittedAt,
			withPillars: false,
			size: 22,
		})}
	</Row>
	<Row >
		{getGenderScoreStatusTag(survey?.status, { fontSize: 10 })}
	</Row>
	{onClick && <Row>
		<span
			style={{
				color: theme.color.darkgrey, cursor: 'pointer', marginRight: 5, fontSize: 10,
			}}
			onClick={() => onClick(documentId(survey))}>
			<FaExternalLinkSquareAlt style={{ marginTop: 5 }}/>
		</span>
	</Row>}
</Space>;

export const buildScoresCol = ({
	score, diff, skip, withPillars = true, ...props
}) => ({
	title: GENDERSCORE_LABEL,
	removeInColumnFilter: true,
	align: 'center',
	render: (value, row) => {
		if (skip && skip?.(row)) return '';
		return {
			props: {
				style: { background: props?.bgColor },
			},
			children: scoresCell({
				value,
				row,
				score,
				diff,
				skip,
				withPillars,
				submittedAt: row?.submittedAt || row?.genderScore?.submittedAt,
				...props,
			}),
		};
	},
	...TableColumns.columnFixedWidth(props?.width || 84),
	...props,
});

export const buildBadgesCol = ({
	score, diff, skip, withPillars = true, ...props
}) => ({
	title: GENDERSCORE_LABEL,
	removeInColumnFilter: true,
	align: 'center',
	render: (value, row) => {
		if (skip && skip?.(row)) return '';
		return {
			props: {
				style: { background: props?.bgColor },
			},
			children: badgesCell({
				value,
				row,
				score,
				diff,
				skip,
				withPillars,
				submittedAt: row?.submittedAt || row?.genderScore?.submittedAt,
				...props,
			}),
		};
	},
	...TableColumns.columnFixedWidth(props?.width || 84),
	...props,
});

const CompanyGenderScoreBooleanCell = (theme, {
	title,
	isOk,
	...props
}) => (
	<Tooltip
		title={title}
	>
		<CompanySettingsCardDotOk
			ok={isOk}
			style={{
				background: isOk
					? mixColors(theme.color.darkturquoise, theme.color.white, 80)
					: mixColors(theme.color.red, theme.color.white, 80),
				width: 24,
				height: 24,
				borderRadius: 24,
				marginLeft: 15,
				...props?.style,
			}}
		/>
	</Tooltip>
);
export const CompanyGenderScoreColumnCell = ({
	company,
	type,
	onOpen,
	onNavigate,
	onSurveyClick,
}) => {
	const theme = useTheme();
	const companyModel = new CompanyModel(company);
	let content;
	if (type === GSColumnType.isEnabled) {
		content = CompanyGenderScoreBooleanCell(theme, {
			title: <>Is {GENDERSCORE_LABEL} enabled?</>,
			isOk: companyModel.isGenderScoreEnabled(),
		});
	}
	if (type === GSColumnType.isAllowed) {
		content = (
			<GenderScoreSurveyStatusDot
				company={company}
				size={24}
				style={{ marginLeft: 15 }}
			/>
		);
	}
	if (type === GSColumnType.isStaffPublished) {
		content = CompanyGenderScoreBooleanCell(theme, {
			title: <>Is {GENDERSCORE_LABEL} result published?</>,
			isOk: companyModel.isGenderScoreBadgeStaffPublished(),
		});
	}
	if (type === GSColumnType.isSurveyEnabled) {
		content = CompanyGenderScoreBooleanCell(theme, {
			title: <>Is {GENDERSCORE_LABEL} survey accesible for the company?</>,
			isOk: companyModel.isGenderScoreSurveyEnabled(),
		});
	}
	if (type === GSColumnType.surveyStatus) {
		const lastSurveyStatus = companyModel?.getLastGenderScoreSurveyStatus();
		content = (
			<Tooltip
				title={<>Is {GENDERSCORE_LABEL} Survey Status?</>}
				details={<>The survey is in progress when the client has started the survey but did not submit it yet</>}
			>
				{lastSurveyStatus === 'N/A'
					? noValueCell(theme)
					: getGenderScoreStatusTag(lastSurveyStatus)
				}

			</Tooltip>
		);
	}
	if (type === GSColumnType.survey) {
		content = (
			<>
				<Tooltip
					title={<>{GENDERSCORE_LABEL} survey for this company</>}
				>
					{getGenderScoreSurveyTag(companyModel.getCompanyPlanGenderScoreSurvey())}
				</Tooltip>
			</>
		);
	}
	if (type === GSColumnType.owner) {
		content = (
			company?.genderScoreSurveyOwner
				? <UserAvatar user={company?.genderScoreSurveyOwner} />
				: noValueCell(theme)
		);
	}
	if (type === GSColumnType.expiration || type === GSColumnType.expirationBadge) {
		const isExpirationOfCurrentBadge = type === GSColumnType.expirationBadge;
		const daysToExpire = companyModel?.getGenderScoreDaysToExpire(
			{ isExpirationOfCurrentBadge },
		);
		if (isNaN(daysToExpire)) {
			content = noValueCell(theme);
		} else {
			let daysStyle = {
				fontSize: 12,
				color: theme.color.darkgrey,
			};
			if (daysToExpire < 30) {
				daysStyle = {
					...daysStyle,
					color: theme.color.orange,
					fontWeight: 800,
				};
			}
			if (daysToExpire < 7) {
				daysStyle = {
					...daysStyle,
					color: theme.color.red,
					fontWeight: 800,
				};
			}
			content = (
				<Tooltip
					title={<span>{isExpirationOfCurrentBadge && 'Badge '}Expiration date: <b>{moment(
						companyModel?.getGenderScoreExpirationDate(
							{ isExpirationOfCurrentBadge },
						),
					).format('DD/MM/YYYY')}</b></span>}
				>
					<Space direction='vertical'>
						{daysToExpire <= 0 && <Tag color='red' style={{ fontSize: 12 }}>EXPIRED</Tag>}
						<span style={daysStyle}>{daysToExpire} days</span>
					</Space>
				</Tooltip>
			);
		}
	}
	if (type === GSColumnType.progress) {
		const sortedSurveys = companyModel?.getGenderScoreSortedSurveys();
		if (!sortedSurveys?.length) {
			content = noValueCell(theme);
		} else {
			const sortedSurveysRenderDate = sortedSurveys.map(
				(s) => scoreWithDate({
					theme,
					row: companyModel,
					survey: s,
					onClick: onSurveyClick,
				}),
			);
			const maxRender = 3;
			const sortedSurveysRenderOnlyScore = sortedSurveys
				.splice(0, maxRender)
				.map(
					(s) => <Space
						direction='vertical'
						key={`${documentId(s)}score`}
						style={{ margin: 2 }}
					>
						<Row>
							{getGenderScoreStatusTag(s?.status, { fontSize: 7 })}
						</Row>
						<Row>
							{scoresCell({
								row: companyModel,
								score: () => s?.genderScore,
								submittedAt: s?.submittedAt,
								withPillars: false,
								size: 20,
							})}
						</Row>
					</Space>,
				);
			const tooltip = <Space direction='vertical' style={{ width: '100%' }}>
				{sortedSurveysRenderDate && <>
					<Row>
						{sortedSurveysRenderDate}
					</Row>
				</>}
			</Space>;
			content = <Space direction='vertical'>
				<Row>
					{sortedSurveysRenderOnlyScore}
				</Row>
				<Row>
					<Tooltip
						color='white'
						title={tooltip}
					>
						<span style={{
							color: theme.color.fitBlueElectric,
							fontSize: 12,
							fontWeight: 600,
						}}>See all</span>
					</Tooltip>
				</Row>
			</Space>;
		}
	}

	return (
		<>
			<div>
				<Space
					direction='horizontal'
					style={{
						width: '100%',
						alignItems: 'center',
					}}
				>
					{content}
				</Space>
			</div>
		</>
	);
};

export const companyGenderScoreColumn = (theme, props = {}) => ({
	title: <>{props?.title}</>,
	dataIndex: props?.dataIndex || 'companyPlan.genderScore',
	key: props?.key || 'companyPlan.genderScore',
	sorter: props?.sorter || true,
	isFilter: false,
	removeInColumnFilter: true,
	render: (value, row) => {
		return {
			props: {
				style: { background: props?.bgColor },
			},
			children: <CompanyGenderScoreColumnCell
				company={row}
				{...props}
			/>,
		};
	},
	...TableColumns.columnFixedWidth(props?.width),
	...props,
});

const subScoreColumnRender = (theme, { value, row, pillar }) => {
	const colors = [
		theme.color.redLighter,
		theme.color.mustard,
		theme.color.purple,
		'darkturquoise',
	];
	const colorIndex = Math.floor((value - 20) / 20);
	const cMain = colors[Math.min(0, Math.max(colors.length, colorIndex))];

	return <>
		{TableColumns.docsCountColumn(theme, {
			icon: null, color: cMain, background: 'transparent',
		}).render(value, row)}
		{row?.genderScore?.globalScore > 0 && (
			<>
				<div style={{ fontSize: 10 }}>
					<strong>
						#{row?.genderScore?.rankings?.[pillar]}
					</strong>/{row?.genderScore?.genderScores}
				</div>
				<div style={{ fontSize: 10 }}>
					<strong>{
						addSignToNum((row?.genderScore?.[pillar] || 0)
							- (row?.genderScore?.currentStatistics?.averages?.[pillar] || 0))
					}</strong> vs avg
				</div>
			</>
		)}
	</>;
};

const subScoreColumn = (theme, props = {}) => ({
	...props,
	sorter: true,
	align: 'center',
	removeInColumnFilter: true,
	render: (value, row) => {
		return {
			props: {
				style: { background: props?.bgColor },
			},
			children: subScoreColumnRender(theme, { value, row, pillar: props.dataIndex[1] }),
		};
	},
	...TableColumns.columnFixedWidth(70),
});

const subScoreRingColumnRender = (theme, {
	value, row, pillar, withRank,
}) => {
	// const count = row.stats.rankings?.[pillar]?.reduce((p, c) => Math.max(p?.rank || 0, c?.rank || 0), 0);
	const { rank } = row.stats.rankings?.[pillar]?.find((c) => c?.name === row?.company?.name) || {};

	// const styles = [
	// 	{ tagColor: 'yellow', trophy: true, labelStyle: { fontWeight: 'bold' } },
	// 	{ tagColor: 'blue', trophy: true, labelStyle: { fontWeight: 'bold' } },
	// 	{ tagColor: 'brown', trophy: true, labelStyle: { fontWeight: 'bold' } },
	// 	{ tagColor: null, labelStyle: { color: 'rgba(0,0,0,.4)' } },
	// ];
	// let { tagColor, trophy, labelStyle } = styles?.[Math.min(3, rank - 1)] || {};

	// tagColor = rank < (count / 2)
	// 	? mixColors('#00ff00', '#ffff00', 100 * rank / (count / 2))
	// 	: mixColors('#ffff00', '#ff0000', 100 * (rank - (count / 2)) / (count / 2));
	return (
		<>
			<Space
				style={{
					justifyContent: 'center',
				}}
			>
				<CompanyGenderScoreRingScore
					pillar={pillar}
					ringColor={getPillarColorMain(pillar)}
					size={28}
					value={row?.genderScore}
				/>
				{withRank && (
					<span style={{ color: '#999', fontSize: 11, fontWeight: 'bold' }}>
						#{rank}
					</span>
				)}
				{false && (<><div
					style={{
						width: 14,
						height: 14,
						borderRadius: 14,
						background: tagColor,
					}}
				/>
				{/* <Tag color={tagColor}> */}
				{/* <Space size={4}> */}
				<span
					style={labelStyle}
				>
					{/* <b> */}
						#{rank}
					{/* </b> */}
					{/* <span> / {count}</span> */}
				</span>
				{/* {trophy && <TrophyOutlined />} */}
				{/* </Space> */}
				{/* </Tag> */}
				</>)}
			</Space>
		</>
	);
};

const subScoreRingColumn = (theme, props = {}) => ({
	sorter: true,
	align: 'center',
	removeInColumnFilter: true,
	render: (value, row) => {
		return subScoreRingColumnRender(theme, {
			value, row, pillar: props.dataIndex[1], ...props,
		});
	},
	...TableColumns.columnFixedWidth(70),
	...props,
});

export const companyGenderScoreColumnsGroup = (theme, { onOpen, onNavigate, onSurveyClick }) => {
	const GENDERSCORE_COLUMNS_COLOR = theme.color.lilacLightest3;
	return {
		title: GENDERSCORE_LABEL,
		removeInColumnFilter: true,
		children: [
			companyGenderScoreColumn(theme, {
				type: GSColumnType.isEnabled,
				title: 'Enabled',
				dataIndex: 'companyPlan.genderScore.isEnabled',
				key: 'companyPlan.genderScore.isEnabled',
				width: 80,
				bgColor: GENDERSCORE_COLUMNS_COLOR,
			}),
			companyGenderScoreColumn(theme, {
				type: GSColumnType.isAllowed,
				title: 'Allowed',
				dataIndex: 'customField.isAllowed',
				key: 'customField.isAllowed',
				sorter: false, // cannot be sorted without an aggregation pipeline
				width: 80,
				bgColor: GENDERSCORE_COLUMNS_COLOR,
			}),
			companyGenderScoreColumn(theme, {
				type: GSColumnType.expiration,
				title: 'Expires in',
				dataIndex: 'companyPlan.genderScore.expiredAt',
				key: 'companyPlan.genderScore.expiredAt',
				width: 100,
				bgColor: GENDERSCORE_COLUMNS_COLOR,
			}),
			companyGenderScoreColumn(theme, {
				type: GSColumnType.survey,
				title: 'Survey',
				dataIndex: 'companyPlan.genderScore.survey',
				key: 'companyPlan.genderScore.survey',
				width: 70,
				bgColor: GENDERSCORE_COLUMNS_COLOR,
			}),
			companyGenderScoreColumn(theme, {
				type: GSColumnType.owner,
				dataIndex: 'companyPlan.genderScore.ownerId',
				key: 'companyPlan.genderScore.ownerId',
				title: <>Survey<br/>Owner</>,
				width: 70,
				bgColor: GENDERSCORE_COLUMNS_COLOR,
			}),
			companyGenderScoreColumn(theme, {
				type: GSColumnType.isSurveyEnabled,
				title: <>Survey<br/>Enabled</>,
				dataIndex: 'companyPlan.genderScore.isSurveyEnabled',
				key: 'companyPlan.genderScore.isSurveyEnabled',
				width: 80,
				bgColor: GENDERSCORE_COLUMNS_COLOR,
			}),
			companyGenderScoreColumn(theme, {
				type: GSColumnType.surveyStatus,
				title: <>Survey<br/>Status</>,
				sorter: false, // cannot be sorted without an aggregation pipeline
				width: 90,
				bgColor: GENDERSCORE_COLUMNS_COLOR,
			}),
			buildScoresCol({
				title: <>Current<br/>Score</>,
				score: (row) => row?.genderScore,
				dataIndex: 'genderScore.globalScore',
				key: 'genderScore.globalScore',
				withPillars: false,
				notValue: noValueCell(theme),
				width: 80,
				sorter: true,
				bgColor: GENDERSCORE_COLUMNS_COLOR,
			}),
			subScoreColumn(theme, {
				title: <Tooltip title='Current Score for Pillar Gender data'>GD</Tooltip>,
				dataIndex: ['genderScore', 'genderData'],
				bgColor: GENDERSCORE_COLUMNS_COLOR,
			}),
			subScoreColumn(theme, {
				title: <Tooltip title='Current Score for Pillar Career Path'>FCP</Tooltip>,
				dataIndex: ['genderScore', 'fairCareerPath'],
				bgColor: GENDERSCORE_COLUMNS_COLOR,
			}),
			subScoreColumn(theme, {
				title: <Tooltip title='Current Score for Pillar Equal Pay'>EP</Tooltip>,
				dataIndex: ['genderScore', 'equalPay'],
				bgColor: GENDERSCORE_COLUMNS_COLOR,
			}),
			subScoreColumn(theme, {
				title: <Tooltip title='Current Score for Pillar Work-life Balance'>WLB</Tooltip>,
				dataIndex: ['genderScore', 'worklifeBalance'],
				bgColor: GENDERSCORE_COLUMNS_COLOR,
			}),
			subScoreColumn(theme, {
				title: <Tooltip title='Current Score for Pillar HR & DEI Initiatives'>DEI</Tooltip>,
				dataIndex: ['genderScore', 'deiActions'],
				bgColor: GENDERSCORE_COLUMNS_COLOR,
			}),
			companyGenderScoreColumn(theme, {
				type: GSColumnType.expirationBadge,
				title: <>Badge<br/>Expires in</>,
				dataIndex: 'genderScore.expiredAt',
				key: 'genderScore.expiredAt',
				width: 100,
				bgColor: GENDERSCORE_COLUMNS_COLOR,
			}),
			companyGenderScoreColumn(theme, {
				type: GSColumnType.isStaffPublished,
				title: 'Published',
				dataIndex: 'companyPlan.genderScore.isStaffPublished',
				key: 'companyPlan.genderScore.isStaffPublished',
				width: 85,
				bgColor: GENDERSCORE_COLUMNS_COLOR,
			}),
			companyGenderScoreColumn(theme, {
				type: GSColumnType.progress,
				title: 'Progress',
				dataIndex: 'genderScoreSurveys',
				key: 'genderScoreSurveys',
				width: 150,
				bgColor: GENDERSCORE_COLUMNS_COLOR,
				onSurveyClick,
			}),
		],
	};
};

export const companyGenderScoreScoreColumnsGroup = (theme, props = {}) => {
	return {
		title: GENDERSCORE_LABEL,
		removeInColumnFilter: true,
		children: [
			buildScoresCol({
				title: <>Current<br/>Score</>,
				score: (row) => row?.genderScore,
				dataIndex: 'genderScore.globalScore',
				key: 'genderScore.globalScore',
				withPillars: false,
				withLevel: true,
				notValue: noValueCell(theme),
				size: 36,
				sorter: true,
				...props,
			}),
			subScoreRingColumn(theme, {
				title: <Tooltip title='Current Score for Pillar Gender data'>
					<GenderScorePillarLogoLabel pillar={GENDERSCORE_PILLAR_GENDERDATA_VALUE} />
				</Tooltip>,
				dataIndex: ['genderScore', 'genderData'],
				...props,
			}),
			subScoreRingColumn(theme, {
				title: <Tooltip title='Current Score for Pillar Career Path'>
					<GenderScorePillarLogoLabel pillar={GENDERSCORE_PILLAR_FAIRCAREERPATH_VALUE} />
				</Tooltip>,
				dataIndex: ['genderScore', 'fairCareerPath'],
				...props,
			}),
			subScoreRingColumn(theme, {
				title: <Tooltip title='Current Score for Pillar Equal Pay'>
					<GenderScorePillarLogoLabel pillar={GENDERSCORE_PILLAR_EQUALPAY_VALUE} />
				</Tooltip>,
				dataIndex: ['genderScore', 'equalPay'],
				...props,
			}),
			subScoreRingColumn(theme, {
				title: <Tooltip title='Current Score for Pillar Work-life Balance'>
					<GenderScorePillarLogoLabel pillar={GENDERSCORE_PILLAR_WORKLIFEBALANCE_VALUE} />
				</Tooltip>,
				dataIndex: ['genderScore', 'worklifeBalance'],
				...props,
			}),
			subScoreRingColumn(theme, {
				title: <Tooltip title='Current Score for Pillar HR & DEI Initiatives'>
					<GenderScorePillarLogoLabel pillar={GENDERSCORE_PILLAR_DEIACTIONS_VALUE} />
				</Tooltip>,
				dataIndex: ['genderScore', 'deiActions'],
				...props,
			}),
		],
	};
};
