import shared, { ANSWER_VALUE_SKIPPED, optionalizeQuestion } from '../../shared';
import QUESTIONS from '../../questions';
import { SALARYINCREASEPCT_RANGES } from '../../ranges';
import { GENDERSCORE_PILLAR_EQUALPAY_OPTION } from '../../../../../constants/genderscore-pillars';

const section = {
	...GENDERSCORE_PILLAR_EQUALPAY_OPTION,
	details: `
### On average, women in Tech earn ~€400,000 less than men over their working life. 

For 50inTech's women community, compensation transparency and concrete steps that tackle the gender pay gap are among the most important criteria when choosing their future employer.`,
	questions: [
		QUESTIONS.EQUALPAY_EQUALPAY,
		QUESTIONS.EQUALPAY_SALARYGRID,
		QUESTIONS.EQUALPAY_STOCKOPTIONS,
		QUESTIONS.EQUALPAY_SALARYREVIEW,
		QUESTIONS.EQUALPAY_MATERNITYRAISE,
		optionalizeQuestion(QUESTIONS.EQUALPAY_SALARYINCREASEPCT),
		QUESTIONS.EQUALPAY_PAYGAPDATA,
		QUESTIONS.EQUALPAY_INEQUITYMETHOD,
	],
	scoring: [
		{
			question: QUESTIONS.EQUALPAY_EQUALPAY,
			weight: 20,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.EQUALPAY_SALARYGRID,
			weight: 10,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.EQUALPAY_STOCKOPTIONS,
			weight: 5,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.EQUALPAY_SALARYREVIEW,
			weight: 8,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.EQUALPAY_MATERNITYRAISE,
			weight: 5,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.EQUALPAY_SALARYINCREASEPCT,
			weight: 5,
			computer: shared.COMPUTE_PCT,
			ranges: SALARYINCREASEPCT_RANGES,
		},
		{
			question: QUESTIONS.EQUALPAY_PAYGAPDATA,
			weight: 5,
			computer: shared.COMPUTE_VALUE,
			clause: (value, answers) => {
				// this answer should be used in the score ONLY IF
				// EQUALPAY_EQUALPAY answer IS 'no'(or value 0)
				return [0, 'no'].includes(answers?.[QUESTIONS.EQUALPAY_EQUALPAY.questionId]);
			},
		},
		{
			question: QUESTIONS.EQUALPAY_INEQUITYMETHOD,
			weight: 7,
			computer: shared.COMPUTE_VALUE,
		},
	],
};

export default section;
