import { useContext, useState } from 'react';
import { useTheme } from '@emotion/react';
import { CalendarOutlined, CheckSquareOutlined, PlusOutlined } from '@ant-design/icons';
import {
	Flex, Select, message,
} from 'antd';
import { DragDropContext } from '@hello-pangea/dnd';
import {
	documentId, sortOn,
} from '../../../utils/common';
import { LocalDebug } from '../../../utils/LocalDebug';
import useBodyScrollBlocker from '../../../hooks/useBodyScrollBlocker';
import { loadCompany, postCompanyActionPlan } from '../../../actions/company';
import CompanyActionModel from '../../../models/CompanyActionModel';
import {
	GS_ACTION_COMPLETION_STATUS_OPTIONS,
	GS_ACTION_GRID_VIEW_OPTIONS,
	GS_ACTION_GRID_VIEW_TIMEFRAME,
	GS_ACTION_TIMEFRAME_OPTIONS,
} from './action-plan';
import CompanyActionModal from './actionModal/CompanyActionModal';
import CompanyActionLibraryModal from './CompanyActionLibraryModal';
import { useDispatch } from 'react-redux';
import { AppDataContext } from '../../../contexts/AppDataProvider';
import CompanyActionPlanGridColumn from './CompanyActionPlanGridColumn';

const CompanyActionPlanGridViewSelect = ({
	value,
	onChange,
}) => {
	return (
		<Select
			value={value}
			onChange={onChange}
			options={GS_ACTION_GRID_VIEW_OPTIONS}
			style={{
				minWidth: 160,
			}}
		/>
	);
};

const CompanyActionPlanGrid = ({
	company,
	pillar,
	...props
}) => {
	const className = 'CompanyActionPlanGrid';

	const theme = useTheme();

	const dispatch = useDispatch();
	const { appSettings } = useContext(AppDataContext);

	const [actions, setActions] = useState(
		(company?.actionPlan?.actions || [])
			.map((item) => {
				// LocalDebug.logInfo({ className }, { item });
				return new CompanyActionModel({
					...item,
					...appSettings?.genderScoreActions
						?.find?.((action) => action.slug === item.slug) || {},
				});
			}),
	);

	const [openActionModal, setOpenActionModal] = useState(false);
	const [openLibraryModal, setOpenLibraryModal] = useState(false);
	const [selectedAction, setSelectedAction] = useState();
	const [selectedField, setSelectedField] = useState();
	const [selectedValue, setSelectedValue] = useState();
	const [isSendActionPending, setIsSendActionPending] = useState(false);

	const [view, setView] = useState(GS_ACTION_GRID_VIEW_OPTIONS?.[0]?.value);

	useBodyScrollBlocker(openActionModal);
	useBodyScrollBlocker(openLibraryModal);

	// LocalDebug.logInfo({ className }, { actions: actions?.length });

	const handleViewChange = (value) => {
		LocalDebug.logInfo({ className, method: 'handleViewChange' }, { value });
		setView(value);
	};

	const handleOpenAction = (newSelectedAction) => {
		LocalDebug.logInfo({ className, method: 'handleOpenAction' }, { newSelectedAction });
		setSelectedAction(newSelectedAction);
		if (newSelectedAction) setOpenActionModal(true);
	};

	const handleOpenLibrary = ({ field, value }) => {
		LocalDebug.logInfo({ className, method: 'handleOpenLibrary' }, { field, value });
		setOpenLibraryModal(true);
		setSelectedField(field);
		setSelectedValue(value);
	};

	const sendActions = async (newActions) => {
		setIsSendActionPending(true);
		setActions(newActions);
		await postCompanyActionPlan({
			companyId: documentId(company),
			actions: newActions
				.map(({
					_id,
					slug,
					title,
					pillarCategory,
					timeframe,
					timeframeIndex,
					completionStatus,
					completionStatusIndex,
				}) => ({
					actionId: _id,
					slug,
					title,
					pillarCategory,
					timeframe,
					timeframeIndex,
					completionStatus,
					completionStatusIndex,
				})),
		});
		await dispatch(loadCompany(documentId(company)));
		setIsSendActionPending(false);
	};

	const handleEditAction = async ({ action }) => {
		LocalDebug.logInfo({ className, method: 'handleEditAction' }, { action });
		const newActions = [
			...actions.map((item) => (item.slug === action.slug ? action : item)),
		];
		setSelectedAction((p) => newActions?.find((item) => item?.slug === p?.slug));
		await sendActions(newActions);
		message.success('The action was successfully edited');
	};

	const handleDeleteAction = async ({ action }) => {
		LocalDebug.logInfo({ className, method: 'handleDeleteAction' }, { action });
		setOpenActionModal(false);
		const newActions = [
			...actions.filter((item) => (item.slug !== action.slug)),
		];
		await sendActions(newActions);
		message.success('The action was successfully removed');
	};

	const handleAddAction = async ({ action }) => {
		LocalDebug.logInfo({ className, method: 'handleAddAction' }, { action, selectedField, selectedValue });
		const newActions = [
			...actions.map((item) => ({
				...item,
				timeframeIndex: item.timeframeIndex + 1,
				completionStatusIndex: item.completionStatusIndex + 1,
			})),
			{
				...action,
				...selectedField && selectedValue ? { [selectedField]: selectedValue } : {},
				timeframeIndex: 0,
				completionStatusIndex: 0,
			}];

		await sendActions(newActions);

		message.success('The action was successfully added to your plan');
	};

	const viewOptions = view === GS_ACTION_GRID_VIEW_TIMEFRAME
		? GS_ACTION_TIMEFRAME_OPTIONS
		: GS_ACTION_COMPLETION_STATUS_OPTIONS;

	const getActionList = ({ field, value }) => {
		// LocalDebug.logInfo({ className, method: 'getActionList' }, { actions: actions?.length });
		return actions?.filter((action) => action[field] === value).sort(sortOn({ key: `${field}Index` }));
	};

	const onDragEnd = async (result) => {
		const { source, destination } = result;

		if (!destination) {
			return;
		}
		/*
		combine: null
		destination: { droppableId: 'droppable-timeframe-timeframe-mid-term', index: 5 }
		draggableId: 'draggable-action-card-10'
		mode: 'FLUID'
		reason: 'DROP'
		source: {index: 5, droppableId: 'droppable-timeframe-timeframe-long-term'}
		type: 'DEFAULT'
		*/
		LocalDebug.logInfo({ className, method: 'onDragEnd' }, { result });

		const [, sourceField, sourceValue] = source.droppableId.split('_');
		const [, destinationField, destinationValue] = destination.droppableId.split('_');

		const fieldIndex = `${sourceField}Index`;

		const sourceActionList = getActionList({
			field: sourceField,
			value: sourceValue,
		});
		const sourceAction = sourceActionList?.[source?.index];
		const destinationActionList = getActionList({
			field: destinationField,
			value: destinationValue,
		});
		const lastDestinationAction = destinationActionList?.[destination.index];
		const destinationNewFieldIndex = lastDestinationAction?.[fieldIndex]
			|| (
				destinationActionList?.length > 0
					? (destinationActionList?.[destinationActionList.length - 1]?.[fieldIndex] || 0) + 1
					: 0
			)
			|| 0;

		LocalDebug.logInfo({ className, method: 'onDragEnd' }, {
			sourceField,
			sourceValue,
			fieldIndex,
			sourceAction: documentId(sourceAction),
			sourceActionList: sourceActionList?.length,
			destinationActionList: destinationActionList?.length,
			lastDestinationActionFieldIndex: lastDestinationAction?.[fieldIndex],
			sourceIndex: source.index,
			destinationIndex: destination.index,
			destinationNewFieldIndex,
		});

		const newActions = [
			...actions
				.sort(sortOn({ key: fieldIndex }))
				.filter((item) => documentId(item) !== documentId(sourceAction))
				.map((item) => ({
					...item,
					[fieldIndex]: item[fieldIndex]
							+ ((item[fieldIndex] >= destinationNewFieldIndex) ? 1 : 0),
				})),
			{
				...sourceAction,
				[sourceField]: destinationValue,
				[fieldIndex]: destinationNewFieldIndex,
			},
		]
			.sort(sortOn({ key: fieldIndex }))
			.map((item, index) => ({
				...item,
				[fieldIndex]: index,
			}));

		await sendActions(newActions);
	};

	return (
		<>
			<div>
				<Flex
					justify='space-between'
					align='center'
					style={{
						marginBottom: 12,
					}}
				>
					<h3 style={{ fontWeight: 400 }}>
						{view === GS_ACTION_GRID_VIEW_TIMEFRAME
							? <CalendarOutlined />
							: <CheckSquareOutlined />} Your actions
					</h3>
					<div>
						Sort by&nbsp;&nbsp;
						<CompanyActionPlanGridViewSelect
							value={view}
							onChange={handleViewChange}
						/>
					</div>
				</Flex>

				<DragDropContext onDragEnd={onDragEnd}>
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: `repeat(${viewOptions.length}, 1fr)`,
							columnGap: 20,
							rowGap: 20,
						}}
					>
						{viewOptions.map((option) => (
							<div
								key={option.value}
								style={{ height: '100%' }}
							>
								<CompanyActionPlanGridColumn
									droppable={true}
									field={view}
									value={option.value}
									onOpenAction={handleOpenAction}
									onOpenLibrary={() => handleOpenLibrary({ field: view, value: option.value })}
									actions={getActionList({
										field: view,
										value: option.value,
									})}
								/>
							</div>
						))}
					</div>
				</DragDropContext>

			</div>

			<CompanyActionModal
				open={openActionModal}
				setOpen={setOpenActionModal}
				action={selectedAction}
				onAddAction={handleAddAction}
				onEditAction={handleEditAction}
				onDeleteAction={handleDeleteAction}
				isSendActionPending={isSendActionPending}
				setIsSendActionPending={setIsSendActionPending}
			/>

			<CompanyActionLibraryModal
				open={openLibraryModal}
				setOpen={(newOpen) => {
					setSelectedField(null);
					setSelectedValue(null);
					setOpenLibraryModal(newOpen);
				}}
				actions={actions}
				onAddAction={handleAddAction}
				onEditAction={handleEditAction}
				onDeleteAction={handleDeleteAction}
				isSendActionPending={isSendActionPending}
				setIsSendActionPending={setIsSendActionPending}
			/>
		</>
	);
};

export default CompanyActionPlanGrid;
