import { GENDERSCORE_LABEL } from '../constant';

const GENDERSCORE_SURVEY_KEY = 'genderscore-survey';

const GENDERSCORE_SURVEY_MULTI = false;
const GENDERSCORE_SURVEY_FREE = false;

export const GENDERSCORE_SURVEY_1TO49_GROUP_VALUE = 'genderscore-survey-1-to-49-group';
export const GENDERSCORE_SURVEY_50PLUS_GROUP_VALUE = 'genderscore-survey-50-plus-group';

export const GENDERSCORE_SURVEY_1TO49_V2_VALUE = 'genderscore-survey-1-to-49-v2';
export const GENDERSCORE_SURVEY_50PLUS_V2_VALUE = 'genderscore-survey-50-plus-v2';

export const GENDERSCORE_SURVEY_1TO49_V2_LABEL = `${GENDERSCORE_LABEL} 1-49 v2`;
export const GENDERSCORE_SURVEY_50PLUS_V2_LABEL = `${GENDERSCORE_LABEL} 50+ v2`;

export const GENDERSCORE_SURVEY_1TO49_V3_VALUE = 'genderscore-survey-1-to-49-v3';
export const GENDERSCORE_SURVEY_50PLUS_V3_VALUE = 'genderscore-survey-50-plus-v3';

export const GENDERSCORE_SURVEY_1TO49_V3_LABEL = `${GENDERSCORE_LABEL} 1-49 v3`;
export const GENDERSCORE_SURVEY_50PLUS_V3_LABEL = `${GENDERSCORE_LABEL} 50+ v3`;

export const GENDERSCORE_SURVEY_1TO49_V2_OPTION = {
	group: GENDERSCORE_SURVEY_1TO49_GROUP_VALUE,
	value: GENDERSCORE_SURVEY_1TO49_V2_VALUE,
	label: GENDERSCORE_SURVEY_1TO49_V2_LABEL,
	shortLabel: 'GS <50 v2',
	tinyLabel: '<50',
	color: '#96bbf8',
};

export const GENDERSCORE_SURVEY_50PLUS_V2_OPTION = {
	group: GENDERSCORE_SURVEY_50PLUS_GROUP_VALUE,
	value: GENDERSCORE_SURVEY_50PLUS_V2_VALUE,
	label: GENDERSCORE_SURVEY_50PLUS_V2_LABEL,
	shortLabel: 'GS 50+ v2',
	tinyLabel: '50+',
	color: '#ff827c',
};

export const GENDERSCORE_SURVEY_1TO49_V3_OPTION = {
	group: GENDERSCORE_SURVEY_1TO49_GROUP_VALUE,
	value: GENDERSCORE_SURVEY_1TO49_V3_VALUE,
	label: GENDERSCORE_SURVEY_1TO49_V3_LABEL,
	shortLabel: 'GS <50 v3',
	tinyLabel: '<50',
	color: '#96bbf8',
};

export const GENDERSCORE_SURVEY_50PLUS_V3_OPTION = {
	group: GENDERSCORE_SURVEY_50PLUS_GROUP_VALUE,
	value: GENDERSCORE_SURVEY_50PLUS_V3_VALUE,
	label: GENDERSCORE_SURVEY_50PLUS_V3_LABEL,
	shortLabel: 'GS 50+ v3',
	tinyLabel: '50+',
	color: '#ff827c',
};

export const GENDERSCORE_SURVEY_OPTIONS = [
	GENDERSCORE_SURVEY_1TO49_V2_OPTION,
	GENDERSCORE_SURVEY_50PLUS_V2_OPTION,
	GENDERSCORE_SURVEY_1TO49_V3_OPTION,
	GENDERSCORE_SURVEY_50PLUS_V3_OPTION,
];

export const getGenderScoreSurveyGroupOptions = (group) => (
	GENDERSCORE_SURVEY_OPTIONS?.filter((o) => o?.group === group) || {}
);

export const getGenderScoreSurveyOption = (value) => (
	GENDERSCORE_SURVEY_OPTIONS?.find((o) => o?.value === value) || {}
);

export const getGenderScoreSurveyLabel = (value) => (
	getGenderScoreSurveyOption(value)?.label
);

export const getGenderScoreSurveyShortLabel = (value) => (
	getGenderScoreSurveyOption(value)?.shortLabel
);

export const getGenderScoreSurveyTinyLabel = (value) => (
	getGenderScoreSurveyOption(value)?.tinyLabel
);

export const getGenderScoreSurveyColor = (value) => (
	getGenderScoreSurveyOption(value)?.color
);

export const getGenderScoreSurveyTag = (value, style) => (
	<span
		style={{
			fontSize: 12,
			fontWeight: 'bold',
			padding: '2px 4px',
			borderRadius: 3,
			lineHeight: 1.2,
			background: getGenderScoreSurveyColor(value),
			color: 'white',
			...style || {},
		}}
	>
		{getGenderScoreSurveyTinyLabel(value)}
	</span>
);

export const getGenderScoreSurveyGroup = (value) => (
	getGenderScoreSurveyOption(value)?.group
);

export const GENDERSCORE_SURVEY_VALUES = GENDERSCORE_SURVEY_OPTIONS.map((o) => o.value);

const exporter = {
	key: GENDERSCORE_SURVEY_KEY,
	GENDERSCORE_SURVEY_KEY,
	GENDERSCORE_SURVEY_1TO49_V2_VALUE,
	GENDERSCORE_SURVEY_50PLUS_V2_VALUE,
	GENDERSCORE_SURVEY_1TO49_V3_VALUE,
	GENDERSCORE_SURVEY_50PLUS_V3_VALUE,
	multi: GENDERSCORE_SURVEY_MULTI,
	GENDERSCORE_SURVEY_MULTI,
	options: GENDERSCORE_SURVEY_OPTIONS,
	GENDERSCORE_SURVEY_OPTIONS,
	free: GENDERSCORE_SURVEY_FREE,
	GENDERSCORE_SURVEY_FREE,
	values: GENDERSCORE_SURVEY_VALUES,
	GENDERSCORE_SURVEY_VALUES,
	getGenderScoreSurvey: getGenderScoreSurveyOption,
	getGenderScoreSurveyLabel,
	getGenderScoreSurveyShortLabel,
	getGenderScoreSurveyTinyLabel,
	getGenderScoreSurveyColor,
	getGenderScoreSurveyTag,
};

export default exporter;
