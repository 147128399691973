/** @jsxImportSource @emotion/react */

import ArticleDivider from './ArticleDivider';
import CKEditorCustom from '../CKEditor';
import { Space } from 'antd';

function FormItemContent({
	label, name, onChange, isEnVersion, ...props
}) {
	return (
		<div css={{ borderRadius: 4, padding: '8px 12px', marginBottom: 20 }}>
			<ArticleDivider isEnVersion={isEnVersion} />
			<Space direction="vertical" css={{ width: '100%' }}>
				<CKEditorCustom
					name={name}
					onChange={(value) => onChange(name, value)}

					label={label}
					{...props}
				/>
			</Space>
		</div>
	);
}

export default FormItemContent;
