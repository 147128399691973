import TalentDbPage from '../../../containers/staff/users/TalentDbPage';
import { onEnterBuilder } from '../../../actions/app';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-users-talentdb',
	path: '/staff/users/talents',
	label: 'Talent DB',
	icon: <span>🦸‍♀️</span>,
	component: TalentDbPage,
	onEnter: onEnterBuilder({
		origin: TalentDbPage.name,
		shouldGoToPreviousHistory: true,
	}),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
