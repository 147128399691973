import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { LocalDebug } from '../../../../utils/LocalDebug';
import RouteWrapper from '../../../../RouteWrapper';
import UMSSurveyContainer from '../layout/UMSSurveyContainer';
import { PATHS } from '../../../../constants/constant';
import surveys from '../../data/surveys';
import {
	buildSectionQuestionPath,
	computeSurveyScores,
	getSurveyAnsweredAndUnansweredQuestions,
} from '../../data/shared';
import CompanyService from '../../../../services/company';
import { documentId } from '../../../../utils/common';
import { getCompanyUMSSurveyDraftSelected, getCompanySelected } from '../../../../reducers/app';
import { COMPANY_UMS_SURVEY_DRAFT_SELECTED } from '../../../../actions/types';
import { loadCompany } from '../../../../actions/company';
import { NotificationContext } from '../../../app/notification/NotificationProvider';
import { UMS_SURVEY_EMPLOYEE_V1_VALUE } from '../../../../constants/property/ums-survey';
import {
	EVENT, EventPushContext, buildEventCategory, buildEventContext, pushClick,
} from '../../../../contexts/EventPushProvider';
import { SURVEY_COPY } from '../../../../constants/property/survey-copy';
import { AppDataContext } from '../../../../contexts/AppDataProvider';

export const SurveyContext = React.createContext();

const SurveyProvider = ({
	baseRoutePath = PATHS.UMS_SURVEY(),
	children,
}) => {
	const className = 'SurveyProvider';

	const dispatch = useDispatch();
	const companySelected = useSelector(getCompanySelected);
	const draftSelected = useSelector(getCompanyUMSSurveyDraftSelected);
	const { notifySuccess } = useContext(NotificationContext);
	const { userIdData } = useContext(EventPushContext);
	const { appLang, setAppLang } = useContext(AppDataContext);

	const [eventContext, setEventContext] = useState({});
	const [answers, setAnswers] = useState();
	const [surveyScores, setSurveyScores] = useState();
	const [surveyDraft, setSurveyDraft] = useState();
	const [surveyValue, setSurveyValue] = useState();
	const [surveyConf, setSurveyConf] = useState();
	const [buildSurveyRoutes, setBuildSurveyRoutes] = useState(null);
	const [openLeaveModal, setOpenLeaveModal] = useState(false);
	const [unansweredQuestions, setUnansweredQuestions] = useState([]);
	const [isSurveySubmissionPending, setIsSurveySubmissionPending] = useState(false);

	useEffect(() => {
		if (!surveyConf) return;

		const { answered, unanswered } = getSurveyAnsweredAndUnansweredQuestions({
			surveyConf, answers,
		});
		setUnansweredQuestions(unanswered);
		setSurveyScores(computeSurveyScores({ surveyConf, answers }));

		setEventContext({
			...buildEventCategory(EVENT.CATEGORY.UMS_SURVEY),
			...buildEventContext({
				survey: surveyConf?.value,
				answered: answered?.length,
				unanswered: unanswered?.length,
			}),
			userIdData,
		});
	}, [surveyConf, answers]);

	useEffect(() => {
		const surveyOption = surveys?.getSurveyDataOption?.(surveyDraft?.survey);
		LocalDebug.logInfo(
			{ className, effects: 'surveyDraft' },
			{ surveyDraft, surveyOption },
		);
		setSurveyValue(surveyOption?.value);
		setSurveyConf(surveyOption);
	}, [surveyDraft]);

	const routeBuilder = ({
		SurveySectionComponent,
		SurveyQuestionComponent,
		SurveyScoringDetailsComponent,
		SurveyReviewComponent,
	}) => (
		<>
			<Route
				path={baseRoutePath}
				element={<UMSSurveyContainer />}
			>
				<Route
					path={buildSectionQuestionPath({ section: ':section' })}
					element={<RouteWrapper component={SurveySectionComponent} />}
				/>
				<Route
					path={buildSectionQuestionPath({ section: ':section', question: ':question' })}
					element={<RouteWrapper
						component={SurveyQuestionComponent}
						info={buildSectionQuestionPath({ section: ':section', question: ':question' })}
					/>}
				/>
				<Route
					path={'scoring'}
					element={<RouteWrapper component={SurveyScoringDetailsComponent} />}
				/>
				<Route
					path={'review'}
					element={<RouteWrapper component={SurveyReviewComponent} />}
				/>
				<Route
					path='*'
					key={'*'}
					element={<RouteWrapper component={SurveySectionComponent} />}
				/>
			</Route>
		</>
	);

	useEffect(() => {
		// LocalDebug.logUseEffect({ className, effects: '' }, 'setBuildSurveyRoutes');
		setBuildSurveyRoutes((p) => {
			return routeBuilder;
		});
	}, []);

	const saveAnswers = async ({
		draft,
		answers: newAnswers,
		withSlack,
		withDispatch = true,
		withReload = true,
		...data
	}) => {
		const id = documentId(draft || draftSelected);

		const answersToSet = {
			...answers || {},
			...newAnswers || {},
		};

		const ums = computeSurveyScores({
			surveyConf,
			// answers: {
			// 	...answers || {},
			// 	...newAnswers || {},
			// },
			answers: answersToSet,
		});

		LocalDebug.logInfo({ className, method: 'saveAnswers' }, {
			id, answers: newAnswers, answersToSet, ums,
		});

		if (!id) return null;

		CompanyService
		// const result = CompanyService
			.saveUMSSurveyDraftAnswers({
				id,
				answers: newAnswers,
				ums,
				withSlack,
				...data,
			});

		// const answersToSet = result?.data?.answers || {};

		setAnswers(answersToSet);

		const newSurvey = {
			...surveyDraft,
			answers: answersToSet,
			...data,
		};

		if (withDispatch) {
			dispatchSelectSurveyDraft(newSurvey);
		}

		if (withReload) {
			reloadCompany();
		}
		// await dispatchSelectSurveyDraft({ ...surveyDraft, answers: answersToSet });

		return {
			answers: answersToSet,
			survey: newSurvey,
		};
	};

	const notifySubmitSuccess = () => {
		notifySuccess({
			key: className,
			description: <>
				<p>
					<b>Thank you</b> for completing the survey!
				</p>
				<p>
					Discover in this page your <b>personalized UMS results:</b> your overall score,
					how you performed for each pillar, and tailored recommendations
					to progress in your DEI initiatives.
				</p>
			</>,
			duration: 0,
			placement: 'bottomRight',
		});
	};

	const submitSurvey = async ({
		draft,
		data,
		navigate,
	}) => {
		const id = documentId(draft || draftSelected);
		LocalDebug.logInfo({ className, method: 'submitSurvey' }, { id, answers });
		if (!id) return;

		setIsSurveySubmissionPending(true);

		const result = await CompanyService
			.submitUMSSurveyDraft({ id, ...data });

		await dispatchSelectSurveyDraft(null);

		setTimeout(() => {
			// TODO: change to a dynamic path
			LocalDebug.logInfo({ className, method: 'submitSurvey' }, 'navigate(/ums)');
			navigate('/ums');
		}, 4500);

		setTimeout(async () => {
			setIsSurveySubmissionPending(false);
			notifySubmitSuccess();
			LocalDebug.logInfo({ className, method: 'submitSurvey' }, { result });
		}, 5000);
	};

	const reloadCompany = async () => {
		if (!companySelected) return;
		await dispatch(
			loadCompany(
				documentId(companySelected),
				{
					withGenderScoreAction: true,
					withGenderScoreSurveys: true,
					withUMSResults: true,
					withUMSSurveys: true,
				},
			),
		);
	};

	const dispatchSelectSurveyDraft = async (survey) => {
		LocalDebug.logInfo({ className, method: 'dispatchSelectSurveyDraft' }, { survey });
		await dispatch({
			type: COMPANY_UMS_SURVEY_DRAFT_SELECTED,
			companyUMSSurveyDraftSelected: (
				survey && survey?.isActive !== false
					? survey
					: null
			),
		});
	};

	const dispatchSelectedSurveyAndReload = async (survey) => {
		LocalDebug.logInfo({ className, method: 'dispatchSelectedSurveyAndReload' }, { survey });
		await dispatchSelectSurveyDraft(survey);
		await reloadCompany();
	};

	const createOrUpdateSurveyDraft = async ({ data, withDispatch }) => {
		LocalDebug.logInfo(
			{ className, method: 'createOrUpdateSurveyDraft' },
			{
				data,
				withDispatch,
				draftSelected,
				surveyDraft,
			 },
		);
		const { data: survey } = await CompanyService
			.createOrUpdateUMSSurveyDraft({
				companyId: documentId(companySelected),
				survey: (surveyDraft || draftSelected)?.survey || UMS_SURVEY_EMPLOYEE_V1_VALUE,
				id: documentId(surveyDraft || draftSelected),
				...data || {},
			});
		if (withDispatch) {
			await dispatchSelectSurveyDraft(survey);
		}
	};

	const t = (token, values) => {
		let copy = SURVEY_COPY?.[appLang]?.[token]
			|| SURVEY_COPY?.[appLang === 'en' ? 'fr' : 'en']?.[token]
			|| token;
		Object
			.entries(values || {})
			.forEach(([key, value]) => copy = copy.replaceAll(`{${key}}`, value));
		return copy;
	};

	const value = {
		baseRoutePath,
		eventContext,
		surveyDraft,
		setSurveyDraft,
		surveyValue,
		surveyConf,
		answers,
		setAnswers,
		saveAnswers,
		buildSurveyRoutes,
		routeBuilder,
		createOrUpdateSurveyDraft,
		dispatchSelectSurveyDraft,
		dispatchSelectedSurveyAndReload,
		reloadCompany,
		submitSurvey,
		surveyScores,
		openLeaveModal,
		setOpenLeaveModal,
		unansweredQuestions,
		setUnansweredQuestions,
		isSurveySubmissionPending,
		setIsSurveySubmissionPending,
		appLang,
		setAppLang,
		t,
	};

	return (
		<SurveyContext.Provider
			value={value}
		>
			{children}
		</SurveyContext.Provider>
	);
};

export default SurveyProvider;
