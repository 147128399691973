import shared, { optionalizeQuestion } from '../../shared';
import QUESTIONS from '../../questions';
import { GENDERDATA_EGAPRO_SCORE_RANGES, GENDERDATA_RATING_RANGES } from '../../ranges';
import { GENDERSCORE_PILLAR_FAIRCAREERPATH_OPTION } from '../../../../../constants/genderscore-pillars';

const section = {
	...GENDERSCORE_PILLAR_FAIRCAREERPATH_OPTION,
	details: `
### Did you know that in Europe, women account for less than 8% of C-level positions?

Breaking the glass ceiling means ensuring that women have access to the same opportunities for internal career progression as men.`,
	questions: [
		QUESTIONS.V3_FCP_PRACTICES_COMPETENCY_MATRIX,
		QUESTIONS.V3_FCP_PRACTICES_CAREER_DEVELOPMENT_GRID,
		QUESTIONS.V3_FCP_PRACTICES_UNBIASED_LEADERSHIP_ROLES,
		QUESTIONS.V3_FCP_PRACTICES_SUCCESSION_PLAN,
		QUESTIONS.V3_FCP_PRACTICES_INTERNAL_MOBILITY_PROGRAM,
		QUESTIONS.V3_FCP_PRACTICES_MANAGERS_CAREER_PATHING,
		QUESTIONS.V3_FCP_PRACTICES_FORMALIZED_FEEDBACK_PROCESS,
		QUESTIONS.V3_FCP_PRACTICES_NONBIASED_ANNUAL_REVIEW,
		QUESTIONS.V3_FCP_DEVELOPMENT_MENTORSHIP_PROGRAM,
		{
			...QUESTIONS.V3_FCP_DEVELOPMENT_MENTORSHIP_PROGRAM_SATISFACTION,
			clause: (answers) => answers?.[QUESTIONS.V3_FCP_DEVELOPMENT_MENTORSHIP_PROGRAM.questionId] === 'yes',
		},
		QUESTIONS.V3_FCP_DEVELOPMENT_SPONSORSHIP_PROGRAM,
		{
			...QUESTIONS.V3_FCP_DEVELOPMENT_SPONSORSHIP_PROGRAM_SATISFACTION,
			clause: (answers) => answers?.[QUESTIONS.V3_FCP_DEVELOPMENT_SPONSORSHIP_PROGRAM.questionId] === 'yes',
		},
		QUESTIONS.V3_FCP_DEVELOPMENT_CAREER_COACHING_PROGRAM,
		{
			...QUESTIONS.V3_FCP_DEVELOPMENT_CAREER_COACHING_PROGRAM_SATISFACTION,
			clause: (answers) => answers?.[QUESTIONS.V3_FCP_DEVELOPMENT_CAREER_COACHING_PROGRAM.questionId] === 'yes',
		},
	],
	scoring: [
		{
			question: QUESTIONS.V3_GD_LABELS_GLASSDOOR_SCORE,
			weight: 5,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_RATING_RANGES,
		},
		{
			question: QUESTIONS.V3_GD_LABELS_EGAPRO_SCORE,
			weight: 10,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_EGAPRO_SCORE_RANGES,
			clause: (value, answers) => (
				// this answer should be used in the score ONLY IF
				// GENDERDATA_ISEGAPRO answer IS NOT 'not-applicable' (or value -1)
				![-1, 'not-applicable'].includes(answers?.[QUESTIONS.V3_GD_LABELS_EGAPRO_MEASUREMENT.questionId])
			),
		},

		{
			question: QUESTIONS.V3_FCP_PRACTICES_COMPETENCY_MATRIX,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_FCP_PRACTICES_CAREER_DEVELOPMENT_GRID,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_FCP_PRACTICES_UNBIASED_LEADERSHIP_ROLES,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_FCP_PRACTICES_SUCCESSION_PLAN,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_FCP_PRACTICES_INTERNAL_MOBILITY_PROGRAM,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_FCP_PRACTICES_MANAGERS_CAREER_PATHING,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_FCP_PRACTICES_FORMALIZED_FEEDBACK_PROCESS,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_FCP_PRACTICES_NONBIASED_ANNUAL_REVIEW,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_FCP_DEVELOPMENT_MENTORSHIP_PROGRAM,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_FCP_DEVELOPMENT_MENTORSHIP_PROGRAM_SATISFACTION,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_FCP_DEVELOPMENT_SPONSORSHIP_PROGRAM,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_FCP_DEVELOPMENT_SPONSORSHIP_PROGRAM_SATISFACTION,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_FCP_DEVELOPMENT_CAREER_COACHING_PROGRAM,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.V3_FCP_DEVELOPMENT_CAREER_COACHING_PROGRAM_SATISFACTION,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
	],
};

export default section;
