import '../../../scss/action-plan.scss';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTheme } from '@emotion/react';
import { Button, Space } from 'antd';
import {
	ArrowRightIcon, scrollToTop,
} from '../../../utils/common';
import EmptyData from '../../app/EmptyData';
import { LocalDebug } from '../../../utils/LocalDebug';
import { GENDERSCORE_ACTIONS_MAP } from '../../../constants/genderscore-actions';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { GENDERSCORE_LABEL, PATHS } from '../../../constants/constant';
import CompanyActionPlanGrid from './CompanyActionPlanGrid';

const ActionPlanGridNoData = ({
	description,
}) => {
	return (
		<div
			style={{
				width: '100%',
				height: 320,
				border: '1px dashed #ccc',
				borderRadius: 6,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				fontFamily: 'DM Sans!important',
			}}
		>
			<EmptyData
				description={description}
				style={{
					border: 'none',
					marginTop: 0,
					marginBottom: 0,
				}}
			/>
		</div>

	);
};

const ActionPlanGridNoDataGSFilledAdmin = () => {
	const navigate = useNavigate();

	return (
		<ActionPlanGridNoData
			description={(
				<Space
					direction='vertical'
					style={{ maxWidth: 600 }}
					size={20}
				>
					<span
						style={{
							color: '#333',
							fontSize: 14,
							lineHeight: 1.2,
						}}
					>
						Nothing yet!<br/>Start planning your next actions:
					</span>

					<Button
						type='primary'
						size='large'
						onClick={() => navigate('/genderscore')}
						style={{ marginBottom: 32 }}
					>
						<strong>Browse our recommended actions</strong>&nbsp;<ArrowRightIcon />
					</Button>
				</Space>
			)}
		/>
	);
};

const ActionPlanGridNoDataGSEmptyAdmin = () => {
	const navigate = useNavigate();

	return (
		<ActionPlanGridNoData
			description={(
				<Space
					direction='vertical'
					style={{ maxWidth: 600 }}
					size={20}
				>
					<span
						style={{
							color: '#333',
							fontSize: 14,
							lineHeight: 1.2,
						}}
					>
						Embark on your GenderScore journey with our game-changing action plans – your
						secret weapon for shaping a workplace that rocks with diversity, equity, and inclusion.
					</span>

					<Button
						type='primary'
						size='large'
						onClick={() => {
							navigate(PATHS.GENDERSCORE_SURVEY());
							scrollToTop();
						}}
						style={{ marginBottom: 32 }}
					>
						<strong>Complete your {GENDERSCORE_LABEL}</strong>&nbsp;<ArrowRightIcon />
					</Button>
				</Space>
			)}
		/>
	);
};

const ActionPlanGridNonAdmin = () => {
	const navigate = useNavigate();

	return (
		<ActionPlanGridNoData
			description={(
				<Space
					direction='vertical'
					style={{ maxWidth: 800 }}
					size={20}
				>
					<span
						style={{
							color: '#333',
							fontSize: 14,
							lineHeight: 1.2,
						}}
					>
						Equip yourself with insights to champion diversity, equity, and inclusion in your workspace
					</span>

					<Button
						type='primary'
						size='large'
						onClick={() => navigate('/resources')}
						style={{ marginBottom: 32 }}
					>
						<strong>Explore DEI resources</strong>&nbsp;<ArrowRightIcon />
					</Button>
				</Space>
			)}
		/>
	);
};

const CompanyActionPlan = ({
	company,
	forceValue,
	pillar,
	index,
	...props
}) => {
	const className = 'CompanyActionPlan';

	const theme = useTheme();

	const { isStaffView, isDocumentAdmin, isGenderScoreFilled } = useAuthContext();

	const location = useLocation();

	useEffect(() => {
		scrollToTop();
	}, [location.pathname]);

	if (!isDocumentAdmin) {
		return <ActionPlanGridNonAdmin />;
	}

	if (!isGenderScoreFilled) {
		return (
			<>
				<p style={{ fontSize: 16 }}>
					<strong>This is where you plan your transformative steps.</strong>
				</p>
				<p>
				Our action plans, available upon completing your GenderScore,
				are custom-tailored to elevate your company's DEI impact.
				</p>
				<ActionPlanGridNoDataGSEmptyAdmin />
			</>
		);
	}

	return <CompanyActionPlanGrid company={company} />;
};

export default CompanyActionPlan;
