import { ReadOutlined } from '@ant-design/icons';
import ArticlesPage from '../../../containers/staff/content/ArticlesPage';
import MenuBase from '../../MenuBase';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

export const ArticlesIcon = ReadOutlined;

class ArticlesMenu extends MenuBase {
	static props = {
		name: 'staff-content-articles',
		path: '/staff/content/articles',
		label: 'Articles',
		icon: <ArticlesIcon />,
		iconLegacy: '📰',
		Component: ArticlesPage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(ArticlesMenu.props);
	}
}

export default ArticlesMenu;
