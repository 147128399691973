import { useTheme } from '@emotion/react';
import { Select } from 'antd';
import { GENDERSCORE_STATUS_OPTIONS, getGenderScoreStatusTag } from '../../../constants/property/genderscore-status';

const SurveyStatusSelect = ({
	value,
	allowClear = true,
	mode = 'multiple',
	onChange,
	...props
}) => {
	const className = 'SurveyStatusSelect';

	const theme = useTheme();

	return (
		<Select
			value={value}
			mode={mode}
			allowClear={allowClear}
			onChange={onChange}
			options={GENDERSCORE_STATUS_OPTIONS.map((option, index) => ({
				...option, label: getGenderScoreStatusTag(option.value),
			}))}
			style={{
				minWidth: 120,
				...props?.style,
			}}
		/>
	);
};

export default SurveyStatusSelect;
