export const GENDERDATA_OVERALLPCT_RANGES = [
	[0, 0], [15, 25], [35, 50], [45, 80], [50, 100], [55, 80], [100, 25],
];
export const GENDERDATA_TECHPCT_RANGES = [
	[0, 0], [13, 25], [27, 50], [45, 80], [50, 100], [55, 80], [100, 25],
];
export const GENDERDATA_MGTPCT_RANGES = [
	[0, 0], [28, 50], [45, 80], [50, 100], [55, 80], [100, 25],
];
export const GENDERDATA_CLEVELPCT_RANGES = [
	[0, 0], [20, 25], [40, 50], [45, 80], [50, 100], [55, 80], [100, 25],
];
export const CLEVELPCT_50PLUS_RANGES = [
	[0, 0], [11, 50], [45, 80], [50, 100], [55, 80], [100, 25],
];
export const GENDERDATA_BOARDPCT_RANGES = [
	[0, 0], [7, 50], [45, 80], [50, 100], [55, 80], [100, 25],
];
export const GENDERDATA_RATING_RANGES = [
	[0, 0], [2.5, 25], [3.75, 50], [4.5, 90], [5, 100],
];
export const GENDERDATA_EGAPRO_SCORE_RANGES = [
	[0, 0], [75, 25], [85, 50], [100, 100],
];
export const SALARYINCREASEPCT_RANGES = [
	[0, 0], [4, 25], [15, 100],
];
export const ADJUSTED_PAY_GAP_RANGES = [
	[-10, 0], [-5, 50], [-2, 80], [0, 100], [2, 80], [5, 50], [10, 0],
];
export const UNADJUSTED_PAY_GAP_RANGES = [
	[-10, 0], [-5, 50], [-2, 80], [0, 100], [2, 80], [5, 50], [10, 0],
];
export const YESNO_RANGES = [
	['Yes', 100], ['No', 0],
];
