import { Popover } from 'antd';
import ReactJson from 'react-json-view';
import { FaDatabase } from 'react-icons/fa';
import Link from '../Link';

const defaultProps = {
	popover: {
		placement: 'left',
	},
	content: {
		style: {
			maxWidth: 700, height: '80vh', overflow: 'scroll',
		},
	},
	Renderer: ReactJson,
	rendererProps: {
		style: { fontSize: 10 },
		sortKeys: true,
		collapsed: 1,
		displayDataTypes: false,
		name: false,
		quotesOnKeys: false,
	},
};

const JsonPopoverLink = (
	{
		data,
		label,
		popover = {
			placement: 'left',
		},
		content = {
			style: {},
		},
		RenderComponent = ReactJson,
		...props
	},
) => {
	return <span
		className='json-popover-link'
	>
		<Popover
			title='JSON'
			placement={popover?.placement || defaultProps?.popover?.placement || 'left'}
			content={
				<div style={{ ...defaultProps?.content?.style, ...content?.style }}>
					{/* <JSONPretty data={props?.value?.(row) || row || {}} /> */}
					<ReactJson
						src={data}
						{...defaultProps?.rendererProps}
						{...props}
					/>
				</div>
			}
		>
			<Link
				style={{
					color: '#999',
					...props?.style,
				}}
			>
				{label || <FaDatabase style={{ marginBottom: -2 }} />}
			</Link>
		</Popover>
	</span>;
};

export default JsonPopoverLink;
