import { css } from '@emotion/css';
import {
	Badge, Popover, Space, Table, Tag, Tooltip,
} from 'antd';
import { FaDatabase } from 'react-icons/fa';
import {
	addSignToNum, arrayWrap, round, sumReducer,
} from '../../../utils/common';
import ReactMarkdown from 'react-markdown';
import SurveyQuestionDetails from './SurveyQuestionDetails';
import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import { isQuestionAnswered } from '../data/shared';
import SurveyQuestionAnswer from './SurveyQuestionAnswer';
import { SurveyContext } from './SurveyProvider';
import { getPillarColorMain, getPillarIcon } from '../../../constants/genderscore-pillars';
import CompanyGenderScoreRingScore from '../../company/genderscore/CompanyGenderScoreRingScore';
import SurveyQuestionContent from './SurveyQuestionContent';
import { InfoCircleFilled } from '@ant-design/icons';

const columnFixedWidth = (size) => ({
	className: css({
		width: size,
		minWidth: size,
		maxWidth: size,
		// background: 'red',
	}),
});
const columnMinWidth = (size) => ({
	className: css({
		width: size,
		minWidth: size,
	}),
});

export const questionStubCol = (theme, { surveyConf, answers }) => ({
	title: 'Question',
	dataIndex: 'index',
	render: (value, row) => {
		const {
			questionId,
			shortLabel,
		} = row;

		const section = surveyConf?.sections
			?.find((s) => s.questions?.find((q) => q.questionId === questionId));

		return (
			<div
				style={{
					borderLeft: `4px solid ${getPillarColorMain(section.value)}`,
					paddingLeft: 8,
				}}
			>
				<Space direction='horizontal' align='start'>
					<Space
						direction="vertical"
						size={4}
						style={{
							fontSize: 12,
							lineHeight: 1.3,
						}}
					>
						<Space
							direction='horizontal'
							style={{
								justifyContent: 'flex-start',
								alignItems: 'center',
								height: 18,
								lineHeight: 1,
							}}
						>
							{getPillarIcon(section?.value, { width: 18, height: 18 })}

							<div
								style={{
									fontSize: 11,
									fontWeight: 'bold',
									color: theme.color.grey,
								}}
							>
								{`#${value}`}
							</div>

							<Popover content={(
								<div
									style={{
										maxWidth: 500,
										fontSize: 12,
									}}
								>
									{JSON.stringify(row)}
								</div>)
							}>
								<FaDatabase style={{
									fontSize: 10,
									color: '#999',
								}} />
							</Popover>

						</Space>

						<Popover
							content={(
								<div
									className={`survey ${section.value}`}
									style={{
										padding: 20,
										maxWidth: 640,
										zoom: 0.7,
									}}
								>
									<SurveyQuestionContent
										question={row}
										pillar={section.value}
										answers={answers || {}}
									/>
								</div>
							)}
						>
							<span
								style={{
									fontSize: 16,
									fontWeight: 'bold',
								}}
							>
								{shortLabel} <InfoCircleFilled />
							</span>
						</Popover>

						<SurveyQuestionDetails
							question={{
								...row,
								ranges: section?.scoring
									?.find((q) => q?.question?.questionId === row?.questionId)
									?.ranges,
							}}
						/>
					</Space>
				</Space>
			</div>
		);
	},
	...columnFixedWidth(400),
});

const SurveyScoringDetails = ({
	survey,
}) => {
	const className = 'SurveyScoringDetails';

	const theme = useTheme();

	const {
		answers,
		surveyDraft,
		surveyConf,
	} = useContext(SurveyContext);

	if (!surveyConf) return null;

	const columns = [
		questionStubCol(theme, { surveyConf, answers }),
		{
			title: <>Answer</>,
			align: 'center',
			render: (value, row) => (
				<SurveyQuestionAnswer
					question={row}
					answer={answers?.[row?.questionId]}
					notAnswered={<></>}
					style={{
						tag: {
							fontSize: 11,
							lineHeight: 1.3,
							padding: '2px 5px',
						},
					}}
				/>
			),
		},
		{
			title: <>Score<br />{surveyDraft?.genderScore?.globalScore}</>,
			align: 'center',
			render: (value, row) => {
				const { questionId } = value || {};
				let points = surveyConf?.sections
					?.map((section) => {
						const sectionWeight = section?.scoring
							?.map?.((q) => q.weight)
							.reduce(sumReducer);
						const sectionQuestion = section?.scoring
							?.find((q) => q.question.questionId === questionId);
						if (sectionQuestion) {
							const pct = Math.round(100 * sectionQuestion.weight / sectionWeight);
							let score;

							if (sectionQuestion?.computer?.computer
								&& isQuestionAnswered({ question: value, answers })
							) {
								let questionAnswers = arrayWrap(answers?.[questionId]);
								let options = sectionQuestion?.question?.options;
								if (options?.length >= 1) {
									options = options
										?.filter((o) => (
											questionAnswers.includes(o.value)
											|| questionAnswers.includes(o.label)));
									questionAnswers = options
										?.map((o) => o.score)
										?.reduce(sumReducer, 0);
								}
								score = sectionQuestion?.computer?.computer?.({
									value: questionAnswers,
									ranges: sectionQuestion?.ranges,
								});
							} else if (sectionQuestion.weight > 0) {
								score = 0;
							}

							if (score === undefined) return [];

							return [
								score * sectionQuestion.weight / sectionWeight,
								100 * sectionQuestion.weight / sectionWeight,
							];
						}
					});
				points = points.filter((i) => i?.[0] >= 0 && i?.[1] >= 0);
				if (points?.length === 0) {
					return null;
				}
				const totalScore = points.map((i) => i?.[0]).reduce(sumReducer, 0);
				const totalPotential = points.map((i) => i?.[1]).reduce(sumReducer, 0);
				return (
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<CompanyGenderScoreRingScore
							value={round(100 * totalScore / totalPotential) + 0.0001}
							forceScore={addSignToNum(round(totalScore / 5, 2))}
							size={48}
							style={{
								score: { fontSize: 14 },
							}}
						/>
					</div>
				);
			},
			...columnFixedWidth(100),
		},
		...surveyConf?.sections
			?.map((section) => ({
				title: (
					<>
						<Space
							size={4}
							style={{
								width: '100%',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<span>{getPillarIcon(section?.value)}</span>
							<span>{section?.initials}</span>
						</Space>
					</>
				),
				align: 'center',
				render: (value) => {
					const { questionId } = value || {};
					const sectionWeight = section?.scoring
						?.map?.((q) => q.weight)
						.reduce(sumReducer);
					const sectionQuestion = section?.scoring
						?.find((q) => q.question.questionId === questionId);
					if (sectionQuestion) {
						const pct = Math.round(100 * sectionQuestion.weight / sectionWeight);
						let score;

						if (sectionQuestion?.computer?.computer
							&& isQuestionAnswered({ question: value, answers })
						) {
							let questionAnswers = arrayWrap(answers?.[questionId]);
							let options = sectionQuestion?.question?.options;
							if (options?.length >= 1) {
								options = options
									?.filter((o) => (
										questionAnswers.includes(o.value)
											|| questionAnswers.includes(o.label)));
								questionAnswers = options
									?.map((o) => o.score)
									?.reduce(sumReducer, 0);
							}
							score = sectionQuestion?.computer?.computer?.({
								value: questionAnswers,
								ranges: sectionQuestion?.ranges,
							});
						}
						return (
							<>
								<Space
									direction="vertical"
									size={4}
									style={{
										fontSize: 10,
										color: '#999',
									}}
								>
									<Tooltip
										title={
											<span>
												The answer to this question will account
												for <b>{pct}%</b> in the total score of
												the <b>{section.label}</b> pillar.<br />
												<i style={{ opacity: 0.7, fontSize: 12 }}>
													(so from this pillar, <b>{pct / 5}%</b> of
													the final <b>GenderScore</b>)
												</i>
											</span>
										}
									>
										<div
											style={{
												background: getPillarColorMain(section.value),
												color: 'white',
												margin: 0,
												fontWeight: 'bold',
												fontSize: 14,
												padding: '1px 5px',
												borderRadius: 3,
											}}
										>
											{pct}%
										</div>
									</Tooltip>
								</Space>
							</>
						);
					}
					return (
						<div
							style={{
								fontSize: 10,
								color: '#999',
							}}
						>
							<ReactMarkdown>*N/A*</ReactMarkdown>
						</div>
					);
				},
				...columnFixedWidth(80),
			})) || [],
	];

	return (
		<Table
			columns={columns}
			pagination={false}
			dataSource={
				surveyConf?.sections
					.map((s) => s.questions)
					.flat()
					.map((q, i) => ({
						...q,
						index: i + 1,
					}))}
			style={{
				border: '1px solid #eee',
				borderRadius: 5,
			}}
			size="middle"
			scroll={{
				x: true,
				y: '86vh',
			}}
		/>
	);
};

export default SurveyScoringDetails;
