/** @jsxImportSource @emotion/react */
import PageLayout from '../../../components/app/layout/PageLayout';
import UserTable from '../../../components/user/UserTable';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
	addMultipleQueryParams, filterAutoCompleteItems, getQueryParams, goTo, removeQueryParams,
} from '../../../utils/common';
import { TALENT_DB_ICON, USER_TAG_CATEGORIES } from '../../../constants/constant';
import { goToJob } from '../../../actions/jobs';
import UserService from '../../../services/user';
import { useAuthContext } from '../../../hooks/useAuthContext';
import SectionTitle from '../../../components/app/SectionTitle';
import FilterButton from '../../../components/app/filters/FilterButton';
import UserFilterModal from '../../../components/user/UserFilterModal';
import PopinContact from '../PopinContact';
import { LocalDebug } from '../../../utils/LocalDebug';

const TalentDbPage = () => {
	const className = 'TalentDbPage';

	const dispatch = useDispatch();

	const queryParams = getQueryParams({
		withCommaSeparatedStringsToArraysConversion: true,
	});

	const [openPopinContact, setOpenPopinContact] = useState(false);
	const { isEditor } = useAuthContext();
	const [optionsFilterTable, setOptionsFilterTable] = useState({});
	const [users, setUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	useEffect(() => {
		LocalDebug.logNull({ className, effects: '' }, queryParams);
		// ----------------------------------------------------- //
		// ----- create tag filter with query params ----------- //
		// ----------------------------------------------------- //
		const tags = {};
		USER_TAG_CATEGORIES.forEach((element) => {
			if (queryParams[element.value]) {
				tags[element.value] = [queryParams[element.value]];
			}
		});

		setOptionsFilterTable((prev) => ({
			...prev,
			...queryParams,
			...Object.keys(tags).length > 0 && { tags },
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ----------------------------------------------------- //
	// --------- after loading data sourcings -------------- //
	// ----------------------------------------------------- //
	const handleLoadedData = ({ items } = {}) => {
		setUsers(items || []);
	};

	const handleGoToSourcing = (params) => {
		goTo('/candidates', params);
	};

	const handleGoToJob = (jobs) => {
		dispatch(goToJob(jobs));
	};

	const onContactTalent = (row) => {
		setSelectedUser(row);
		setOpenPopinContact(true);
	};

	// ------------------------------------------- //
	// -------- search autocomplete data --------- //
	// ------------------------------------------- //
	const handleSearchAutoComplete = async (options) => {
		const { data: { users } } = await UserService.findAll(options, '/users/groupBy');
		return filterAutoCompleteItems(users);
	};

	// --------------------------------------- //
	// ------------ handle filter ------------ //
	// --------------------------------------- //
	const handleFilter = (values) => {
		const { tags, ...rest } = values;
		addMultipleQueryParams(rest);
		setOptionsFilterTable((prev) => {
			return ({ ...prev, ...rest, tags });
		});
	};

	const handleClearFilter = () => {
		setOptionsFilterTable({});
		setIsRefreshDataLayout((prev) => prev + 1);
		removeQueryParams();
	};

	return (
		<PageLayout
			withBackground={false}
			title={<SectionTitle icon={TALENT_DB_ICON} title="Talent Database" renderAlone={false} />}
			filter={<FilterButton
				title='Filter talents'
				modal={UserFilterModal}
				onFilter={handleFilter}
				onClearFilter={handleClearFilter}
				initialValues={optionsFilterTable}
			/>}
		>
			<UserTable
				onDataLoaded={handleLoadedData}
				dataSource={users}
				filterOptions={optionsFilterTable}
				isEditor={isEditor}
				onGoToSourcing={handleGoToSourcing}
				onGoToJob={handleGoToJob}
				isRefresh={isRefreshDataLayout}
				onSearchDataColumn={handleSearchAutoComplete}
				onContactTalent={onContactTalent}
			/>
			<PopinContact
				open={openPopinContact}
				selectedUser={selectedUser}
				setOpen={setOpenPopinContact}
				onRefresh={() => setIsRefreshDataLayout((prev) => prev + 1)}
			/>
		</PageLayout>
	);
};

export default TalentDbPage;
