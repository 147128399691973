import shared from '../../shared';
import QUESTIONS from '../../questions';
import { GENDERDATA_RATING_RANGES } from '../../ranges';
import { GENDERSCORE_PILLAR_FAIRCAREERPATH_OPTION } from '../../../../../constants/genderscore-pillars';

const section = {
	...GENDERSCORE_PILLAR_FAIRCAREERPATH_OPTION,
	details: `
### Did you know that in Europe, women account for less than 8% of C-level positions?

Breaking the glass ceiling means ensuring that women have access to the same opportunities for internal career progression as men.`,
	questions: [
		QUESTIONS.CAREERPATH_GRIDS,
		QUESTIONS.CAREERPATH_SKILLSMATRIX,
		QUESTIONS.CAREERPATH_CAREERPATHING,
		QUESTIONS.CAREERPATH_BUDDY,
	],
	scoring: [
		{
			question: QUESTIONS.GENDERDATA_RATING,
			weight: 10,
			computer: shared.COMPUTE_PCT,
			ranges: GENDERDATA_RATING_RANGES,
		},
		{
			question: QUESTIONS.CAREERPATH_GRIDS,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.CAREERPATH_SKILLSMATRIX,
			weight: 16,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.CAREERPATH_CAREERPATHING,
			weight: 5,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.CAREERPATH_BUDDY,
			weight: 1,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.DEI_JUNIORHIRES,
			weight: 2,
			computer: shared.COMPUTE_VALUE,
		},
		{
			question: QUESTIONS.DEI_CAREERCHANGEHIRES,
			weight: 2,
			computer: shared.COMPUTE_VALUE,
		},

	],
};

export default section;
